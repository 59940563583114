import { makeActions, ofType } from 'app/store/actions/utils';
import { BundleTemplate } from '../models/bundle.model';

export const BundleInvoicingActions = makeActions({
  loadBundleTemplatesForInvoicing: null,
  loadBundleTemplatesForInvoicingSuccess: ofType<{ items: BundleTemplate[]; }>(),
  loadBundleTemplatesForInvoicingFailure: ofType<{ payload: any; }>(),

  updateBundleTemplateInvoiceCodes: ofType<{ payload: BundleTemplate; id: string; }>(),
  updateBundleTemplateInvoiceCodesSuccess: ofType<{ tier: BundleTemplate; }>(),
  updateBundleTemplateInvoiceCodesFailure: ofType<{ error: any; }>(),
});
