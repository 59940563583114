<h2>
  {{ 'Company' | translate }}
  <button class="btn btn-link pb-0" *ngIf="!editing" (click)="editing = true">
    {{ 'Edit company information' | translate }}
  </button>
</h2>

<div *ngIf="!editing && currentTenant" class="p-3">
  <div class="text-muted">{{ 'VAT Number' | translate }}</div>
  <div class="mb-2">{{ currentTenant.vatNumber }}</div>
  <div class="text-muted">{{ 'Company Name' | translate }}</div>
  <div class="mb-2">{{ currentTenant.name }}</div>
  <div class="text-muted">{{ 'Address' | translate }}</div>
  <div class="mb-2">
    {{ currentTenant.streetAndNumber }},
    {{ currentTenant.zipcode }}
    {{ currentTenant.city }},
    {{ currentTenant.country.code }}
  </div>
  <div class="text-muted" *ngIf="currentTenant.activity">{{ 'Type of activity' | translate }}</div>
  <div class="mb-2" *ngIf="currentTenant.activity">{{ currentTenant.activity | titlecase }}</div>
  <div class="text-muted">{{ 'E-mail' | translate }}</div>
  <div class="mb-2">{{ currentTenant.email }}</div>
  <div class="text-muted">{{ 'Invoice E-mail' | translate }}</div>
  <div class="mb-2">{{ currentTenant.invoiceEmail }}</div>
  <div class="text-muted" *ngIf="currentTenant.eoAdditionalInvoiceEmails?.length">{{ 'Additional Invoice emails' | translate }}</div>
  <div class="mb-2" *ngIf="currentTenant.eoAdditionalInvoiceEmails?.length">{{ currentTenant.eoAdditionalInvoiceEmails?.join(", ") }}</div>
  <div class="text-muted" *ngIf="currentTenant.isServiceProvider && currentTenant.purchaseOrderEmail">{{ 'Purchase order email' | translate }}</div>
  <div class="mb-2" *ngIf="currentTenant.isServiceProvider && currentTenant.purchaseOrderEmail">{{ currentTenant.purchaseOrderEmail }}</div>
  <div class="text-muted" *ngIf="currentTenant.isServiceProvider && currentTenant.additionalPurchaseOrderEmails?.length">{{ 'Additional Purchase order emails' | translate }}</div>
  <div class="mb-2" *ngIf="currentTenant.isServiceProvider && currentTenant.additionalPurchaseOrderEmails?.length">{{ currentTenant.additionalPurchaseOrderEmails?.join(", ") }}</div>
</div>

<form *ngIf="editing" (ngSubmit)="submit()" [formGroup]="tenantForm" class="p-3">
  <div class="mb-3">
    <label class="form-label center-block">{{ 'VAT Number' | translate }}</label>
    <input class="form-control" formControlName="vatNumber">
  </div>
  <div class="mb-3">
    <label class="form-label center-block">{{ 'Company Name' | translate }}</label>
    <input class="form-control" formControlName="name">
  </div>
  <div class="mb-3">
    <label class="form-label center-block">{{ 'Address' | translate }}</label>
    <input class="form-control" formControlName="streetAndNumber">
  </div>
  <div class="mb-3">
    <label class="form-label center-block">{{ 'Zipcode' | translate }}</label>
    <input class="form-control" formControlName="zipcode">
  </div>
  <div class="mb-3">
    <label class="form-label center-block">{{ 'City' | translate }}</label>
    <input class="form-control" formControlName="city">
  </div>
  <div class="mb-3">
    <label class="form-label center-block">{{ 'Country' | translate }}</label>
    <ng-select
      formControlName="country"
      [items]="countries$ | async"
      [clearable]="false"
      bindLabel="label"
      bindValue="value"
    >
      <ng-template ng-label-tmp let-item="item">
        <span class="fi fi-{{item.value | lowercase}}"></span>
        {{item.label}}
      </ng-template>
    </ng-select>
  </div>
  <div class="mb-3">
    <label class="form-label center-block">{{ 'Type of activity' | translate }}</label>
    <ng-select
      formControlName="activity"
      [items]="activityTypes"
      [clearable]="false"
      bindLabel="label"
      bindValue="value"
    ></ng-select>
  </div>
  <div class="mb-3">
    <label class="form-label center-block">{{ 'E-mail' | translate }}</label>
    <input class="form-control" formControlName="email">
  </div>
  <div class="mb-3">
    <label class="form-label center-block">{{ 'Invoice E-mail' | translate }}</label>
    <input class="form-control" formControlName="invoiceEmail">
  </div>
  <div class="mb-3">
    <label class="form-label center-block">{{ 'Additional Invoice emails' | translate }}</label>
    <ng-select
      id="eoAdditionalInvoiceEmails"
      [items]="currentTenant?.eoAdditionalInvoiceEmails || []"
      [addTag]="true"
      [multiple]="true"
      [selectOnTab]="true"
      [isOpen]="false"
      (blur)="onAdditionalEmailsClose($event)"
      data-cy="eoAdditionalInvoiceEmails"
      formControlName="eoAdditionalInvoiceEmails"
    >
    </ng-select>
  </div>
  <div class="mb-3" *ngIf="currentTenant?.isServiceProvider">
    <label class="form-label center-block">{{ 'Purchase order email' | translate }}</label>
    <input class="form-control" formControlName="purchaseOrderEmail">
  </div>
  <div class="mb-3" *ngIf="currentTenant?.isServiceProvider">
    <label class="form-label center-block">{{ 'Additional Purchase order emails' | translate }}</label>
    <ng-select
      id="additionalPurchaseOrderEmails"
      [items]="currentTenant?.additionalPurchaseOrderEmails || []"
      [addTag]="true"
      [multiple]="true"
      [selectOnTab]="true"
      [isOpen]="false"
      (blur)="onAdditionalPurchaseOrderEmailsClose($event)"
      data-cy="additionalPurchaseOrderEmails"
      formControlName="additionalPurchaseOrderEmails"
    >
    </ng-select>
  </div>
  <app-loading-button btnType="submit" [isLoading]="savingTenant$ | async">
    {{ 'Submit' | translate }}
  </app-loading-button>
  <button class="btn btn-link" (click)="cancel()">
    {{ 'Cancel' | translate }}
  </button>
</form>
