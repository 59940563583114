import { Component } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { ApiService } from 'app/services/api.service';
import { ToastService } from 'app/services/toast.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent {
  email = '';
  loading = false;
  success = false;

  constructor(
    private api: ApiService,
    private toastr: ToastService,
  ) { }

  reset() {
    this.loading = true;
    this.api.post({
      path: '/user/reset',
      body: { email: this.email },
      params: { responseType: 'text' },
    }).toPromise().then(
      () => {
        this.success = true;
        this.loading = false;
      },
      e => {
        this.loading = false;
        if (e && e.status === 404) {
          this.toastr.showDanger({
            title: 'Something went wrong',
            message: marker('We couldn\'t find a user with this email.'),
          });
        }
      },
    );
  }
}
