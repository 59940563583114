import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, CurrencyPairActions } from 'app/store/actions';
import { CurrencyPairState } from 'app/store/models';

const empty: CurrencyPairState = {
  items: null,
  loadingItems: false,
  savingItem: false,
};

const reducer = createReducer<CurrencyPairState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(CurrencyPairActions.loadCurrencyPairs, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(CurrencyPairActions.loadCurrencyPairsSuccess, (state, { currencyPairs }) => ({
    ...state,
    items: currencyPairs,
    loadingItems: false,
  })),
  on(CurrencyPairActions.loadCurrencyPairsFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(CurrencyPairActions.createCurrencyPair, (state) => ({
    ...state,
    savingItem: true,
  })),
  on(CurrencyPairActions.createCurrencyPairSuccess, (state, { currencyPair }) => ({
    ...state,
    items: [...state.items || [], currencyPair],
    savingItem: false,
  })),
  on(CurrencyPairActions.createCurrencyPairFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(CurrencyPairActions.updateCurrencyPair, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(CurrencyPairActions.updateCurrencyPairSuccess, (state, { currencyPair }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === currencyPair.id ? currencyPair : x),
    savingItem: false,
  })),
  on(CurrencyPairActions.updateCurrencyPairFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(CurrencyPairActions.deleteCurrencyPair, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(CurrencyPairActions.deleteCurrencyPairSuccess, (state, { currencyPairId }) => ({
    ...state,
    items: (state.items || []).filter(x => x.id !== currencyPairId),
    savingItem: false,
  })),
  on(CurrencyPairActions.deleteCurrencyPairFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),
);

export function currencyPairReducer(state: CurrencyPairState | undefined, action: Action) {
  return reducer(state, action);
}
