import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';

import { LazyLoaderService } from 'app/services/lazy-loader.service';
import { PaymentProviderActions } from 'app/store/actions';
import { State } from 'app/store/models';
import { PaymentMethod, PaymentProvider } from 'app/store/models/payment-provider.model';
import { notNull, untilDestroyed } from 'app/utils/rxjs';

@Component({
  selector: 'app-admin-payment-methods',
  templateUrl: './admin-payment-methods.component.html',
  styleUrls: ['./admin-payment-methods.component.scss']
})
export class AdminPaymentMethodsComponent implements OnDestroy {
  loadingPaymentMethods$: Observable<boolean>;
  savingPaymentMethod$: Observable<boolean>;
  paymentProviderOptions$: Observable<{ value: string; label: string }[]>;
  paymentProviderMap: { [k: string]: PaymentProvider } = {};
  paymentMethods$: Observable<PaymentMethod[]>;
  activePaymentMethod: PaymentMethod | null = null;
  currencyOptions$: Observable<{ value: string; label: string }[]>;
  currencyMap: { [k: string]: string } = {};
  paymentMethodForm: UntypedFormGroup;
  modalInstance: any;

  @ViewChild('paymentMethodModal', { static: true })
  paymentMethodModal?: TemplateRef<any>;

  constructor(
    private store: Store<State>,
    private loader: LazyLoaderService,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private actions$: Actions,
  ) {
    this.loadingPaymentMethods$ = this.store.select(x => x.admin.paymentProvider.loadingPaymentMethods);
    this.savingPaymentMethod$ = this.store.select(x => x.admin.paymentProvider.savingPaymentMethod);
    this.store.select(x => x.admin.paymentProvider.items).pipe(
      filter(notNull),
      startWith([]),
      untilDestroyed(this),
    ).subscribe(paymentProviders => paymentProviders.forEach(
        x => {
          this.paymentProviderMap[x.id] = x;
        }
    ));
    this.paymentProviderOptions$ = this.store.select(
      x => (x.admin.paymentProvider.items || [])
        .map(paymentProvider => ({ label: paymentProvider.label, value: paymentProvider.id })),
    );
    this.paymentMethods$ = this.store.select(x => x.admin.paymentProvider.paymentMethods).pipe(
      filter(notNull),
      startWith([]),
    );
    this.currencyOptions$ = this.loader.getCurrencies().pipe(
      tap(currencies => currencies.forEach(x => this.currencyMap[x.code] = x['@id'])),
      map(currencies => currencies.map(currency => ({ label: currency.code, value: currency.code }))),
    );
    this.paymentMethodForm = this.fb.group({
      label: [null],
      fixedMargin: [null],
      percentageMargin: [null],
      iban: [null],
      bic: [null],
      allowedCurrencies: [null],
      enabled: [null],
      default: [null],
    });
    this.store.dispatch(PaymentProviderActions.loadPaymentProviders());
    this.store.dispatch(PaymentProviderActions.loadPaymentMethods());
    this.actions$.pipe(
      ofType(
        PaymentProviderActions.updatePaymentMethodSuccess,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      this.modalInstance.dismiss();
    });
  }

  editPaymentMethod(paymentMethod: PaymentMethod) {
    this.activePaymentMethod = paymentMethod;
    this.paymentMethodForm.reset();
    this.paymentMethodForm.patchValue({
      ...this.activePaymentMethod,
      fixedMargin: this.activePaymentMethod.fixedMargin
        ? parseFloat(this.activePaymentMethod.fixedMargin)
        : null,
      percentageMargin: this.activePaymentMethod.percentageMargin
        ? parseFloat(this.activePaymentMethod.percentageMargin)
        : null,
      allowedCurrencies: this.activePaymentMethod.allowedCurrencies.map(currency => currency.code),
      default: this.paymentProviderMap[paymentMethod.paymentProvider.id]?.defaultPaymentMethod?.id === paymentMethod.id,
    });

    this.modalInstance = this.modalService.open(this.paymentMethodModal);
  }

  onEditPaymentMethodSubmit() {
    if (this.paymentMethodForm.invalid || this.activePaymentMethod === null) {
      this.paymentMethodForm.markAllAsTouched();
      return;
    }

    this.store.dispatch(PaymentProviderActions.updatePaymentMethod({
      paymentMethod: {
        ...this.paymentMethodForm.value,
        id: this.activePaymentMethod?.id,
        fixedMargin: (this.paymentMethodForm.value.fixedMargin !== null)
          ? this.paymentMethodForm.value.fixedMargin.toFixed(2)
          : null,
        percentageMargin: (this.paymentMethodForm.value.percentageMargin !== null)
          ? this.paymentMethodForm.value.percentageMargin.toFixed(2)
          : null,
        allowedCurrencies: (this.paymentMethodForm.value.allowedCurrencies || []).map((currencyCode: string) => this.currencyMap[currencyCode]),
        enabled: !!this.paymentMethodForm.value.enabled,
        default: undefined,
      }
    }));

    if (this.paymentMethodForm.value.default &&
      this.paymentProviderMap[this.activePaymentMethod.paymentProvider.id]?.defaultPaymentMethod?.id !== this.activePaymentMethod.id)
    {
      this.store.dispatch(PaymentProviderActions.updatePaymentProvider({
        paymentProvider: {
          id: this.activePaymentMethod.paymentProvider.id,
          defaultPaymentMethod: this.activePaymentMethod['@id'],
        }
      }));
    }
  }

  ngOnDestroy() { }
}
