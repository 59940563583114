<button
  [type]="btnType"
  [ngClass]="btnClass"
  [disabled]="isLoading || isDisabled"
  [attr.data-cy]="cy"
  (click)="ngClick.emit(true)"
>
  <span translate class="btn__label" [ngClass]="{ invisible: isLoading }">
    <ng-content></ng-content>
  </span>
  <i class="fa fa-spinner fa-spin btn__loader" [ngClass]="{ visible: isLoading }"></i>
</button>
