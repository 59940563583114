import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { HydraCollection } from 'app/store/models';
import { SubscriptionActions } from 'app/store/actions';
import { Subscription, SubscriptionTemplate } from 'app/store/models/subscription.model';

@Injectable()
export class SubscriptionEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadSubscriptions$ = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionActions.loadSubscriptions),
    concatMap(() => this.api.get<HydraCollection<Subscription>>({
      path: '/subscriptions',
    }).pipe(
      map(data => SubscriptionActions.loadSubscriptionsSuccess({ items: data['hydra:member'] })),
      catchError(err => of(SubscriptionActions.loadSubscriptionsFailure(err))),
    )),
  ));

  loadSubscriptionsForProvider$ = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionActions.loadSubscriptionsForProvider),
    switchMap(params => this.api.get<HydraCollection<Subscription>>({
      path: `/subscriptions_for_provider?pagination=true`
        + (params.page ? `&page=${encodeURIComponent(params.page)}` : '')
        + (params.pageSize ? `&pageSize=${encodeURIComponent(params.pageSize)}` : '')
        + (params.query ? `&query=${encodeURIComponent(params.query)}` : '')
        + (params.status ? `&status=${encodeURIComponent(params.status)}` : ''),
    }).pipe(
      map(data => SubscriptionActions.loadSubscriptionsForProviderSuccess({
        items: data['hydra:member'],
        totalItems: data['hydra:totalItems'],
      })),
      catchError(err => of(SubscriptionActions.loadSubscriptionsForProviderFailure(err))),
    )),
  ));

  loadSubscriptionTemplatesForUser$ = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionActions.loadSubscriptionTemplatesForUser),
    concatMap(({ providerId }) => this.api.get<HydraCollection<SubscriptionTemplate>>({
      path: `/subscription_templates_latest_versions/${providerId}`,
    }).pipe(
      map(data => SubscriptionActions.loadSubscriptionTemplatesForUserSuccess({ items: data['hydra:member'] })),
      catchError(err => of(SubscriptionActions.loadSubscriptionTemplatesForUserFailure(err))),
    )),
  ));

  startSubscription$ = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionActions.startSubscription),
    concatMap(({ payload }) => this.api.post<Subscription>({
      path: `/subscriptions`,
      body: payload,
    }).pipe(
      map(data => SubscriptionActions.startSubscriptionSuccess({ subscription: data })),
      catchError(err => of(SubscriptionActions.startSubscriptionFailure(err))),
    )),
  ));

  cancelSubscription$ = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionActions.cancelSubscription),
    concatMap(({ id }) => this.api.put<Subscription>({
      path: `/subscriptions/${id}/cancel`,
      body: {},
    }).pipe(
      map(data => SubscriptionActions.cancelSubscriptionSuccess({ subscription: data })),
      catchError(err => of(SubscriptionActions.cancelSubscriptionFailure(err))),
    )),
  ));

  reactivateSubscription$ = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionActions.reactivateSubscription),
    concatMap(({ id }) => this.api.put<Subscription>({
      path: `/subscriptions/${id}/reactivate`,
      body: {},
    }).pipe(
      map(data => SubscriptionActions.reactivateSubscriptionSuccess({ subscription: data })),
      catchError(err => of(SubscriptionActions.reactivateSubscriptionFailure(err))),
    )),
  ));

  upgradeSubscription$ = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionActions.upgradeSubscription),
    concatMap(({ id, tierId, billing, upgradeNow }) => this.api.put<Subscription>({
      path: `/subscriptions/${id}/upgrade`,
      body: {
        subscriptionTemplateTierId: tierId,
        billing,
        upgradeNow,
      },
    }).pipe(
      map(data => SubscriptionActions.upgradeSubscriptionSuccess({ subscription: data })),
      catchError(err => of(SubscriptionActions.upgradeSubscriptionFailure(err))),
    )),
  ));

  cancelScheduledUpgrade$ = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionActions.cancelScheduledUpgrade),
    concatMap(({ id }) => this.api.put<Subscription>({
      path: `/subscriptions/${id}/cancel-upgrade`,
      body: { },
    }).pipe(
      map(data => SubscriptionActions.cancelScheduledUpgradeSuccess({ subscription: data })),
      catchError(err => of(SubscriptionActions.cancelScheduledUpgradeFailure(err))),
    )),
  ));
}
