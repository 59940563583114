import { makeActions, ofType } from 'app/store/actions/utils';
import { Tenant, TenantPayoutsFrequency } from 'app/store/models/tenant.model';

export const TenantActions = makeActions({
  loadTenants: null,
  loadTenantsSuccess: ofType<{ tenants: Tenant[]; }>(),
  loadTenantsFailure: ofType<{ error: any; }>(),

  loadPaginatedTenants: ofType<{
    page?: number;
    pageSize?: number;
    query?: string;
  }>(),
  loadPaginatedTenantsSuccess: ofType<{ items: Tenant[]; totalItems: number; }>(),
  loadPaginatedTenantsFailure: ofType<{ payload: any; }>(),

  createTenant: ofType<{ tenant: Tenant; }>(),
  createTenantSuccess: ofType<{ tenant: Tenant; }>(),
  createTenantFailure: ofType<{ error: any; }>(),

  updateTenant: ofType<{ tenant: Tenant|TenantPayoutsFrequency; }>(),
  updateTenantSuccess: ofType<{ tenant: Tenant; }>(),
  updateTenantFailure: ofType<{ error: any; }>(),

  deleteTenant: ofType<{ tenantId: string; }>(),
  deleteTenantSuccess: ofType<{ tenantId: string; }>(),
  deleteTenantFailure: ofType<{ error: any; }>(),

  loadReimbursementTenants: null,
  loadReimbursementTenantsSuccess: ofType<{ tenants: Tenant[]; }>(),
  loadReimbursementTenantsFailure: ofType<{ error: any; }>(),
});
