import { formatCurrency } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subscription as ObservableSubscription } from 'rxjs';

import { I18nService } from 'app/services/i18n.service';
import { SubscriptionActions } from 'app/store/actions';
import { State } from 'app/store/models';
import { BillingType, Subscription, SubscriptionUpgradeTier } from 'app/store/models/subscription.model';
import { untilDestroyed } from 'app/utils/rxjs';

import { RechargeWalletModalComponent } from './recharge-wallet-modal.component';

@Component({
  selector: 'app-subscription-upgrade-confirm-modal',
  templateUrl: './subscription-upgrade-confirm-modal.component.html',
  styleUrls: ['./subscription-upgrade-confirm-modal.component.scss'],
})
export class SubscriptionUpgradeConfirmModalComponent implements OnDestroy {
  insufficientFundsGuard$: ObservableSubscription;
  upgradingSubscription$: Observable<boolean>;
  upgradeTier: SubscriptionUpgradeTier|null = null;
  subscription: Subscription|null = null;
  billing: string|null = null;
  price: string|null = null;
  renewalDate: string|null = null;
  upgradeOptionsModel: string|null = null;
  message: string|null = null;
  messageNow: string|null = null;
  modalInstance: any;

  constructor(
    private store: Store<State>,
    private modal: NgbActiveModal,
    private actions$: Actions,
    private translate: TranslateService,
    private i18nService: I18nService,
    private modalService: NgbModal,
  ) {
    this.upgradingSubscription$ = this.store.select(x => x.subscription.savingItem);

    this.actions$.pipe(
      ofType(
        SubscriptionActions.upgradeSubscriptionSuccess,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      if (this.modal) {
        this.modal.close();
      }
    });

    this.insufficientFundsGuard$ = this.actions$.pipe(
      ofType(
        SubscriptionActions.upgradeSubscriptionFailure,
      ),
      untilDestroyed(this),
    ).subscribe((error) => {
      if (error.error.detail === 'billing: Tenant does not have enough balance.') {
        const modalRef = this.modalService.open(RechargeWalletModalComponent, { size: 'lg' });
        modalRef.componentInstance.setMessage(this.translate.instant('You have insufficient balance on your wallet to start this subscription. Please top up your wallet'));
        modalRef.result.then(
          () => {},
          () => {},
        );
      }
    });
  }

  setSubscription(subscription: Subscription): void {
    this.subscription = subscription;

    this.renewalDate = this.subscription.renewsOn ? new Date(this.subscription.renewsOn).toLocaleDateString() : null;
  }

  setUpgradeTier(upgradeTier: SubscriptionUpgradeTier) {
    this.upgradeTier = upgradeTier;
    this.upgradeOptionsModel = this.upgradeTier.allowUpgradeNow ? 'now' : 'schedule'
    this.message = this.translate.instant('You have chosen to upgrade to <b>{{tier}}</b>. Now select one of the possible options to activate your new subscription:', { tier: upgradeTier.templateTier.label });
  }

  setBilling(billing: string) {
    this.billing = billing;

    if (!this.upgradeTier) {
      return;
    }
    this.price = this.billing === BillingType.YEARLY
      ? this.upgradeTier.templateTier.priceYearly
      : this.upgradeTier.templateTier.priceMonthly;

    if (!this.subscription || !this.price) {
      return;
    }

    const currentPrice = this.subscription.billing === BillingType.MONTHLY
      ? this.subscription.subscriptionTemplateTier.priceMonthly
      : this.subscription.subscriptionTemplateTier.priceYearly;
    
    if (!currentPrice) {
      return;
    }

    this.messageNow = this.translate.instant('pay {{price}} now and receive the extra tokens immediately. The expiry date of your upgraded subscription will be that of your original subscription.', {
      price: formatCurrency(Math.max(0, parseFloat(this.price) - parseFloat(currentPrice)), this.i18nService.language, '') + ' ' + this.upgradeTier?.templateTier.subscriptionTemplate.serviceProvider.tenant.currency.code + '*'
    })
  }

  handleConfirm() {
    if (!this.subscription || !this.billing || !this.upgradeTier) {
      return;
    }

    this.store.dispatch(SubscriptionActions.upgradeSubscription({
      id: this.subscription.id,
      tierId: this.upgradeTier.templateTier.id,
      billing: this.billing,
      upgradeNow: this.upgradeOptionsModel === 'now'
    }));
  }

  handleCancel() {
    this.modal.dismiss();
  }

  ngOnDestroy() {
    this.insufficientFundsGuard$.unsubscribe();
  }
}
