import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, RegistrationActions } from 'app/store/actions';
import { RegistrationState } from 'app/store/models';

const empty: RegistrationState = {
  items: null,
  currentPage: null,
  totalItems: null,
  loadingItems: false,
  savingItem: false,
  showProcessed: false,
};

const reducer = createReducer<RegistrationState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(RegistrationActions.loadRegistrations, (state) => ({
    ...state,
    loadingItems: true,
    totalItems: null,
  })),
  on(RegistrationActions.loadRegistrationsSuccess, (state, { items, totalItems }) => ({
    ...state,
    items,
    totalItems,
    loadingItems: false,
  })),
  on(RegistrationActions.loadRegistrationsFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(RegistrationActions.updateRegistration, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(RegistrationActions.updateRegistrationSuccess, (state, { registration }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === registration.id ? registration : x),
    savingItem: false,
  })),
  on(RegistrationActions.updateRegistrationFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(RegistrationActions.approveRegistration, (state) => ({
    ...state,
    savingItem: true,
  })),
  on(RegistrationActions.approveRegistrationSuccess, (state, { registrationId }) => ({
    ...state,
    items: (state.items || []).filter(x => x.id !== registrationId),
    savingItem: false,
  })),
  on(RegistrationActions.approveRegistrationFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(RegistrationActions.declineRegistration, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(RegistrationActions.declineRegistrationSuccess, (state, { registrationId }) => ({
    ...state,
    items: (state.items || []).filter(x => x.id !== registrationId),
    savingItem: false,
  })),
  on(RegistrationActions.declineRegistrationFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(RegistrationActions.toggleProcessed, (state, _) => ({
    ...state,
    showProcessed: !state.showProcessed,
  })),
);

export function registrationReducer(state: RegistrationState | undefined, action: Action) {
  return reducer(state, action);
}
