import { AccountancyPeriod } from './accountancy-period.model';
import { Tenant } from './tenant.model';
import { Transaction } from './transaction.model';

export interface ExactOnlineQueue {
  '@id': string;
  '@type': string;
  id: string;
  action: string;
  data: any;
  customer: Tenant | null;
  provider: Tenant | null;
  transaction: Transaction | null;
  accountancyPeriod: AccountancyPeriod | null;
  processed: boolean;
  errorResponse: string|null;
  createdAt: string;
}

export enum ExactOnlineQueueItemType {
  ACTION_GET_DIVISIONS = 'GET_DIVISIONS',
  ACTION_CREATE_VOUCHER_INVOICE = 'CREATE_VOUCHER_INVOICE',
  ACTION_CREATE_TRANSACTIONS_INVOICE = 'CREATE_TRANSACTIONS_INVOICE',
  ACTION_CREATE_TRANSACTIONS_FEE_INVOICE = 'CREATE_TRANSACTIONS_FEE_INVOICE',
  ACTION_CREATE_ADMIN_FEE_INVOICE = 'CREATE_ADMIN_FEE_INVOICE',
}
