import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SessionActions } from 'app/store/actions';
import { State } from 'app/store/models';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  loading$: Observable<boolean>;
  mail = '';
  pass = '';

  constructor(private store: Store<State>) {
    this.loading$ = this.store.select(x => x.session.loading);
  }

  login() {
    this.store.dispatch(SessionActions.login({ mail: this.mail, pass: this.pass }));
  }
}
