<div class="card border-0 payment-choice">
  <div class="card-body d-flex flex-direction-row justify-content-between row py-2">
    <div class="pay-choice-node col d-flex justify-content-center align-items-center" (click)="changePaymentOption(false)" [ngClass]="!payLater ? 'active' : ''">
      <span class="d-flex justify-content-center align-items-center">{{'Pay now' | translate}}</span>
    </div>
    <div class="pay-choice-node col d-flex justify-content-center align-items-center" (click)="changePaymentOption(true)" [ngClass]="payLater ? 'active' : ''">
      <div class="d-flex align-items-center">
        <span class="d-flex justify-content-center align-items-center">{{'Pay later' | translate}}</span>
        <span class="new-span ms-2 d-flex justify-content-center align-items-center text-white text-sm rounded-1" *ngIf="!payLater">NEW</span>
      </div>
    </div>
  </div>
</div>
