import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges, OnInit {
  @Input()
  totalPages: number = 1;
  @Input()
  pageSource = new Subject<number>();

  currentPage = 1;
  pages = [1];
  paginationLength = 4;
  show = false;

  ngOnInit() {
    this.pageSource.subscribe((page) => {
      this.currentPage = page;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const totalPages = changes['totalPages'] && changes['totalPages'].currentValue;
    if (!totalPages) {
      return;
    }

    if (totalPages > 1) {
      this.show = true;
    }

    let paginationListStart = Math.max(1, this.currentPage - this.paginationLength);
    const paginationListEnd = Math.min(totalPages, paginationListStart + this.paginationLength * 2);
    paginationListStart = Math.max(1, paginationListEnd - this.paginationLength * 2);

    this.pages = [...Array(totalPages).keys()].map(i => i + 1)
      .filter(i => i >= paginationListStart && i <= paginationListEnd);
  }
}
