import { makeActions, ofType } from 'app/store/actions/utils';
import { ServiceProvider } from 'app/store/models';

export const ServiceProviderActions = makeActions({
  loadServiceProviders: null,
  loadServiceProvidersSuccess: ofType<{ serviceProviders: ServiceProvider[]; }>(),
  loadServiceProvidersFailure: ofType<{ error: any; }>(),

  createServiceProvider: ofType<{ serviceProvider: ServiceProvider; }>(),
  createServiceProviderSuccess: ofType<{ serviceProvider: ServiceProvider; }>(),
  createServiceProviderFailure: ofType<{ error: any; }>(),

  updateServiceProvider: ofType<{ serviceProvider: ServiceProvider; }>(),
  updateServiceProviderSuccess: ofType<{ serviceProvider: ServiceProvider; }>(),
  updateServiceProviderFailure: ofType<{ error: any; }>(),

  deleteServiceProvider: ofType<{ serviceProviderId: string; }>(),
  deleteServiceProviderSuccess: ofType<{ serviceProviderId: string; }>(),
  deleteServiceProviderFailure: ofType<{ error: any; }>(),
});
