import { makeActions, ofType } from 'app/store/actions/utils';
import { Service } from 'app/store/models';

export const ServiceActions = makeActions({
  loadServices: ofType<{
    page?: number;
    pageSize?: number;
    query?: string;
  }>(),
  loadServicesSuccess: ofType<{ services: Service[]; totalItems: number; }>(),
  loadServicesFailure: ofType<{ error: any; }>(),

  createService: ofType<{ service: Service; }>(),
  createServiceSuccess: ofType<{ service: Service; }>(),
  createServiceFailure: ofType<{ error: any; }>(),

  updateService: ofType<{ service: Service; }>(),
  updateServiceSuccess: ofType<{ service: Service; }>(),
  updateServiceFailure: ofType<{ error: any; }>(),

  deleteService: ofType<{ serviceId: string; }>(),
  deleteServiceSuccess: ofType<{ serviceId: string; }>(),
  deleteServiceFailure: ofType<{ error: any; }>(),
});
