import { makeActions, ofType } from 'app/store/actions/utils';
import { User } from 'app/store/models';

export const UserActions = makeActions({
  loadUsers: null,
  loadUsersSuccess: ofType<{ users: User[]; }>(),
  loadUsersFailure: ofType<{ error: any; }>(),

  createUser: ofType<{ user: User; }>(),
  createUserSuccess: ofType<{ user: User; }>(),
  createUserFailure: ofType<{ error: any; }>(),

  updateUser: ofType<{ user: User; }>(),
  updateUserSuccess: ofType<{ user: User; }>(),
  updateUserFailure: ofType<{ error: any; }>(),

  deleteUser: ofType<{ userId: string; }>(),
  deleteUserSuccess: ofType<{ userId: string; }>(),
  deleteUserFailure: ofType<{ error: any; }>(),
});
