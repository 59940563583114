import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, ServiceConfigurationActions } from 'app/store/actions';
import { ServiceConfigurationState } from 'app/store/models';

const empty: ServiceConfigurationState = {
  items: null,
  tenants: null,
  loadingItems: false,
  loadingTenants: false,
  savingItem: false,
};

const reducer = createReducer<ServiceConfigurationState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(ServiceConfigurationActions.loadServicesForConfigurator, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(ServiceConfigurationActions.loadServicesForConfiguratorSuccess, (state, { items }) => ({
    ...state,
    loadingItems: false,
    items: items,
  })),
  on(ServiceConfigurationActions.loadServicesForConfiguratorFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(ServiceConfigurationActions.loadTenantsForConfigurator, (state) => ({
    ...state,
    loadingTenants: true,
  })),
  on(ServiceConfigurationActions.loadTenantsForConfiguratorSuccess, (state, { items }) => ({
    ...state,
    loadingTenants: false,
    tenants: items,
  })),
  on(ServiceConfigurationActions.loadTenantsForConfiguratorFailure, (state) => ({
    ...state,
    loadingTenants: false,
  })),

  on(ServiceConfigurationActions.updateServiceConfiguration, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(ServiceConfigurationActions.updateServiceConfigurationSuccess, (state, { service }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === service.id ? service : x),
    savingItem: false,
  })),
  on(ServiceConfigurationActions.updateServiceConfigurationFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),
);

export function serviceConfigurationReducer(state: ServiceConfigurationState | undefined, action: Action) {
  return reducer(state, action);
}
