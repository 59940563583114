<h2>{{ 'Bundle overview' }}</h2>

<div class="d-flex flex-row align-items-center flex-wrap mb-3">
  <div class="input-group me-2">
    <div class="input-group-text"><i class="far fa-search"></i></div>
    <input
      class="form-control search-query"
      placeholder="{{ 'Search for company or bundle' | translate }}"
      [ngModel]="searchQuery"
      (ngModelChange)="searchQueryDebounced$.next($event)"
    />
  </div>

  <app-loading-button (click)="submitExport()" [isLoading]="exporting" [btnClass]="'btn btn-link'" [isDisabled]="exporting">
    {{ 'Export to CSV' | translate }}
  </app-loading-button>
</div>

<table class="table">
  <tr class="no-border">
    <th>{{ 'Company' | translate }}</th>
    <th>{{ 'Bundle' | translate }}</th>
    <th>{{ 'Version' | translate }}</th>
    <th>{{ 'Tokens remaining' | translate }}</th>
  </tr>
  <tr *ngFor="let bundle of bundles$ | async">
    <td>{{ bundle.tenant.name }}</td>
    <td>
      <i class="far" [ngClass]="bundle.bundleService.bundleTemplateServicePrice.bundleTemplateService.service.icon"></i>
      {{ bundle.bundleService.bundleTemplateServicePrice.bundleTemplateService.service.label }}
    </td>
    <td>{{ bundle.bundleTemplate.version }}</td>
    <td>
      {{ bundle.bundleService.tokensAvailable }}/{{ bundle.bundleService.bundleTemplateServicePrice.tokens }}
    </td>
  </tr>
</table>

<app-pagination [totalPages]="pages$ | async" [pageSource]="page$"></app-pagination>
