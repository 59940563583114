<h2>{{ 'Currencies' | translate }}</h2>
<button class="btn btn-link" (click)="newCurrency()">{{ 'New Currency' | translate }}</button>
<table class="table">
  <tr *ngFor="let currency of currencies$ | async | sortOn: 'code'">
    <td>{{ currency.code }}</td>
    <td>
      <button class="btn btn-sm btn-link" (click)="newCurrencyPair(currency)">{{ 'New Currency Pair' | translate }}</button>
    </td>
  </tr>
</table>

<h2>{{ 'Currency Pairs' | translate }}</h2>
<table class="table striped">
  <tr *ngFor="let currencyPair of currencyPairs$ | async | sortOn: 'baseCurrency.code'">
    <td>{{ currencyPair.baseCurrency.code }}-{{ currencyPair.counterCurrency.code }}</td>
    <td>{{ currencyPair.margin }}</td>
    <td>
      <button class="btn btn-sm btn-link" (click)="editCurrencyPair(currencyPair)">{{ 'Edit Currency Pair' | translate }}</button>
    </td>
  </tr>
</table>

<ng-template #currencyModal let-self>
  <div class="modal-header">
    <h4 *ngIf="!activeCurrency" class="modal-title">{{ 'New Currency' | translate }}</h4>
    <h4 *ngIf="activeCurrency" class="modal-title">
      {{ 'Edit Currency' | translate }} {{ activeCurrency?.code }}
    </h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="p-3"
      [formGroup]="currencyForm"
      (ngSubmit)="onNewCurrencySubmit()"
    >
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Code' | translate }}</label>
        <input class="form-control" formControlName="code" />
      </div>
      <app-loading-button btnType="submit" [isLoading]="savingCurrency$ | async">
        {{ 'Submit' | translate }}
      </app-loading-button>
    </form>
  </div>
</ng-template>

<ng-template #currencyPairModal let-self>
  <div class="modal-header">
    <h4 *ngIf="!activeCurrencyPair" class="modal-title">
      {{ 'New Currency Pair for Currency' | translate }} {{ activeCurrency?.code }}
    </h4>
    <h4 *ngIf="activeCurrencyPair" class="modal-title">
      {{ 'Edit Currency Pair' | translate }}
      {{ activeCurrencyPair?.baseCurrency.code }}-{{ activeCurrencyPair?.counterCurrency.code }}
    </h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="p-3"
      [formGroup]="currencyPairForm"
      (ngSubmit)="activeCurrencyPair ? onEditCurrencyPairSubmit() : onNewCurrencyPairSubmit()"
    >
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Counter Currency' | translate }}</label>
        <ng-select
          formControlName="counterCurrency"
          [items]="((counterCurrencyOptions$ | async) || {})[activeCurrencyPair ? 'all' : activeCurrency?.id]"
          bindLabel="label"
          bindValue="value"
        ></ng-select>
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Margin' | translate }}</label>
        <div class="input-group mb-3">
          <input class="form-control" type="number" formControlName="margin">
          <span class="input-group-text">%</span>
        </div>
      </div>
      <app-loading-button btnType="submit" [isLoading]="savingCurrencyPair$ | async">
        {{ 'Submit' | translate }}
      </app-loading-button>
    </form>
  </div>
</ng-template>
