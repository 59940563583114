import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';

import { LazyLoaderService } from 'app/services/lazy-loader.service';
import { ValidationService } from 'app/services/validation.service';
import { RegistrationActions } from 'app/store/actions';
import { Country, Registration, State, Tenant, VatVerificationResult } from 'app/store/models';
import { ActivityTypes } from 'app/store/models/tenant.model';
import { notNull, untilDestroyed } from 'app/utils/rxjs';

import { ConfirmActionModalComponent } from './confirm-action-modal.component';
import { CompanyIdentifierType } from 'app/store/models/country.model';
import { UenVerificationResult } from 'app/store/models/uen-verification-result.model';

@Component({
  selector: 'app-admin-registration',
  templateUrl: './admin-registration.component.html',
})
export class AdminRegistrationComponent implements OnDestroy {
  loadingRegistrations$: Observable<boolean>;
  savingRegistration$: Observable<boolean>;
  showProcessed: boolean = false;
  registrations$: Observable<Registration[]>;
  countries$: Observable<object[]>;
  countryMap: { [k: string]: Country } = {};
  currencyOptions$: Observable<{ value: string; label: string }[]>;
  currencyMap: { [k: string]: string } = {};
  activityTypes: { label: string, value: string }[] = [];
  activeRegistration: Registration | null = null;
  registrationForm: UntypedFormGroup;
  modalInstance: any;
  vatNumberIsValid = false;
  isVerifyingVatNumber = false;
  vatNumberIsVerified = false;
  vatVerificationResult?: VatVerificationResult = undefined;
  uenVerificationResult?: UenVerificationResult = undefined;
  duplicateVatNumberTenant?: Tenant = undefined;
  duplicateNameTenant?: Tenant = undefined;

  page$ = new BehaviorSubject<number>(1);
  pages$: Observable<number>;
  pageSize = 20;

  constructor(
    private store: Store<State>,
    private loader: LazyLoaderService,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private actions$: Actions,
    private validationService: ValidationService,
    private translate: TranslateService,
  ) {
    this.loadingRegistrations$ = this.store.select(x => x.admin.registration.loadingItems);
    this.savingRegistration$ = this.store.select(x => x.admin.registration.savingItem);
    this.store.select(x => x.admin.registration.showProcessed).pipe(untilDestroyed(this))
      .subscribe(x => this.showProcessed = x);
    this.registrations$ = this.store.select(x => x.admin.registration.items).pipe(
      filter(notNull),
      startWith([]),
    );
    this.countries$ = this.loader.getCountries().pipe(
      tap(countries => countries.forEach(x => this.countryMap[x.value] = x)),
      map(xs => xs.map(x => ({
        label: x.value,
        value: x.value,
      }))),
    );
    this.currencyOptions$ = this.loader.getCurrencies().pipe(
      tap(currencies => currencies.forEach(x => this.currencyMap[x.code] = x['@id'])),
      map(currencies => currencies.map(currency => ({ label: currency.code, value: currency.id }))),
    );
    Object.values(ActivityTypes).forEach(item => {
      this.activityTypes.push({ label: this.translate.instant('activity_' + item), value: item });
    });
    this.registrationForm = this.fb.group({
      companyName: [null, Validators.required],
      vatNumber: [null, Validators.required],
      currency: [null, Validators.required],
      streetAndNumber: [null, Validators.required],
      zipcode: [null, Validators.required],
      city: [null, Validators.required],
      country: [null, Validators.required],
      activity: [null],
      invoiceEmail: [null, Validators.required],
      phoneNumber: [null, Validators.required],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, Validators.required],
    });
    this.actions$.pipe(
      ofType(RegistrationActions.updateRegistrationSuccess),
      untilDestroyed(this),
    ).subscribe(() => {
      this.modalInstance.dismiss();
    });
    this.actions$.pipe(
      ofType(
        RegistrationActions.approveRegistrationSuccess,
        RegistrationActions.declineRegistrationSuccess,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      if (this.modalInstance) {
        this.modalInstance.dismiss();
      }
      this.page$.next(this.page$.value);
    });

    this.pages$ = this.store.select(x => x.admin.registration).pipe(map(x => {
      return Math.ceil((x.totalItems || 0) / this.pageSize);
    }));
    this.page$.subscribe(page => {
      this.store.dispatch(RegistrationActions.loadRegistrations({
        processed: this.showProcessed,
        page,
        pageSize: this.pageSize,
      }));
    });
  }

  editRegistration(registration: Registration, modal: any) {
    this.isVerifyingVatNumber = false;
    this.vatNumberIsValid = false;
    this.vatNumberIsVerified = false;
    this.vatVerificationResult = undefined;
    this.duplicateVatNumberTenant = undefined;
    this.duplicateNameTenant = undefined;

    this.activeRegistration = registration;
    this.registrationForm.reset();
    this.registrationForm.patchValue({
      ...this.activeRegistration,
      currency: this.activeRegistration.currency.id,
      country: this.activeRegistration.country.code,
    });
    this.modalInstance = this.modalService.open(modal, { size: 'lg' });
  }

  approveRegistration(registration: Registration) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.title = marker(
      'Are you sure you want to approve this registration request?',
    );
    modalRef.componentInstance.message = marker(
      'A new tenant with one user and user context will be created and e-mails will be sent out.',
    );
    modalRef.componentInstance.confirmButtonText = marker('Yes');
    modalRef.result.then(() => this.store.dispatch(
      RegistrationActions.approveRegistration({ registrationId: registration.id }),
    ));
  }

  declineRegistration(registration: Registration) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.title = marker(
      'Are you sure you want to decline this registration request?',
    );
    modalRef.componentInstance.message = marker(
      'You will still be able to approve it via the processed requests page.',
    );
    modalRef.componentInstance.confirmButtonText = marker('Yes');
    modalRef.result.then(() => this.store.dispatch(
      RegistrationActions.declineRegistration({ registrationId: registration.id }),
    ));
  }

  onEditRegistrtionSubmit() {
    if (this.registrationForm.invalid || this.activeRegistration === null) {
      this.registrationForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(RegistrationActions.updateRegistration({
      registration: {
        ...this.registrationForm.value,
        id: this.activeRegistration.id,
        currency: this.currencyMap[this.registrationForm.value.currency],
        country: this.countryMap[this.registrationForm.value.country]['@id'],
      },
    }));
  }

  toggleProcessed() {
    this.store.dispatch(RegistrationActions.toggleProcessed({
      processed: this.showProcessed,
      page: 1,
      pageSize: this.pageSize,
    }));
  }

  verifyVatNumber() {
    this.isVerifyingVatNumber = true;
    const data = this.registrationForm.value;
    if (this.countryMap[data.country].companyIdentifierType === CompanyIdentifierType.COMPANY_IDENTIFIER_VAT) {
      this.validationService.verifyVatNumberWithVIES(data.vatNumber, data.country).subscribe(
        result => {
          this.vatNumberIsValid = result.valid;
          this.vatVerificationResult = result;
        },
        err => {
          this.vatNumberIsValid = false;
          this.vatVerificationResult = undefined;
          this.isVerifyingVatNumber = false;
          this.vatNumberIsVerified = true;
          console.log(err);
        },
        () => {
          this.isVerifyingVatNumber = false;
          this.vatNumberIsVerified = true;
          this.uenVerificationResult = undefined;
        },
      );
    } else if (this.countryMap[data.country].companyIdentifierType === CompanyIdentifierType.COMPANY_IDENTIFIER_UEN) {
      this.validationService.verifyUenNumber(data.vatNumber).subscribe(
        result => {
          this.vatNumberIsValid = result.valid;
          this.uenVerificationResult = result;
        },
        err => {
          this.vatNumberIsValid = false;
          this.vatVerificationResult = undefined;
          this.isVerifyingVatNumber = false;
          this.vatNumberIsVerified = true;
          console.log(err);
        },
        () => {
          this.isVerifyingVatNumber = false;
          this.vatNumberIsVerified = true;
          this.vatVerificationResult = undefined;
        },
      );
    } else {
      this.vatNumberIsValid = true;
      this.isVerifyingVatNumber = false;
      this.vatNumberIsVerified = true;
      this.vatVerificationResult = undefined;
      this.uenVerificationResult = undefined;
    }
    this.validationService.checkIfVatNumberExists(data.vatNumber).subscribe(
      result => {
        if (result['hydra:totalItems'] !== 0) {
          this.duplicateVatNumberTenant = result['hydra:member'][0];
        } else {
          this.duplicateVatNumberTenant = undefined;
        }
      },
      err => {
        this.duplicateVatNumberTenant = undefined;
      },
    );
    this.validationService.checkIfTenantNameExists(data.companyName).subscribe(
      result => {
        if (result['hydra:totalItems'] !== 0) {
          this.duplicateNameTenant = result['hydra:member'][0];
        } else {
          this.duplicateNameTenant = undefined;
        }
      },
      err => {
        this.duplicateNameTenant = undefined;
      },
    );
  }

  getVatNumberLabel(): string {
    const data = this.registrationForm.value;
    const companyIdentifierType = this.countryMap[data.country] ? this.countryMap[data.country].companyIdentifierType : CompanyIdentifierType.COMPANY_IDENTIFIER_VAT;

    return this.translate.instant('label_' + companyIdentifierType);
  }

  getVatNumberButtonLabel(): string {
    const data = this.registrationForm.value;
    const companyIdentifierType = this.countryMap[data.country] ? this.countryMap[data.country].companyIdentifierType : CompanyIdentifierType.COMPANY_IDENTIFIER_VAT;

    return this.translate.instant('Verify') + ' ' + this.translate.instant('label_' + companyIdentifierType);
  }

  ngOnDestroy() { }
}
