import { LegalEntity } from "./legal-entity.model";
import { ServiceProvider } from "./service-provider.model";

export interface InvoiceType {
  id: string;
  serviceProvider: ServiceProvider|null;
  legalEntity: LegalEntity|null;
  software: string;
  action: string;
  templateName: string;
  emailTemplateName: string;
}

export enum InvoiceTypes {
  CREATE_VOUCHER_INVOICE = 'CREATE_VOUCHER_INVOICE',
  CREATE_TRANSACTIONS_INVOICE = 'CREATE_TRANSACTIONS_INVOICE',
  CREATE_TRANSACTIONS_FEE_INVOICE = 'CREATE_TRANSACTIONS_FEE_INVOICE',
  CREATE_ADMIN_FEE_INVOICE = 'CREATE_ADMIN_FEE_INVOICE',
}

export enum InvoiceSoftware {
  EXACT_ONLINE = 'EXACT_ONLINE',
}
