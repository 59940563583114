import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { CurrencyActions } from 'app/store/actions';
import { Currency, HydraCollection } from 'app/store/models';

@Injectable()
export class CurrencyEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadCurrencies$ = createEffect(() => this.actions$.pipe(
    ofType(CurrencyActions.loadCurrencies),
    concatMap(() => this.api.get<HydraCollection<Currency>>({
      path: '/currencies',
    }).pipe(
      map(data => CurrencyActions.loadCurrenciesSuccess({ currencies: data['hydra:member'] })),
      catchError(err => of(CurrencyActions.loadCurrenciesFailure(err))),
    )),
  ));

  createCurrency$ = createEffect(() => this.actions$.pipe(
    ofType(CurrencyActions.createCurrency),
    concatMap(({ currency }) => this.api.post<Currency>({
      path: '/currencies',
      body: currency,
    }).pipe(
      map(data => CurrencyActions.createCurrencySuccess({ currency: data })),
      catchError(err => of(CurrencyActions.createCurrencyFailure(err))),
    )),
  ));

  updateCurrency$ = createEffect(() => this.actions$.pipe(
    ofType(CurrencyActions.updateCurrency),
    concatMap(({ currency }) => this.api.put<Currency>({
      path: `/currencies/${currency.id}`,
      body: currency,
    }).pipe(
      map(data => CurrencyActions.updateCurrencySuccess({ currency: data })),
      catchError(err => of(CurrencyActions.updateCurrencyFailure(err))),
    )),
  ));

  deleteCurrency$ = createEffect(() => this.actions$.pipe(
    ofType(CurrencyActions.deleteCurrency),
    concatMap(({ currencyId }) => this.api.delete({
      path: `/currencies/${currencyId}`,
    }).pipe(
      map(() => CurrencyActions.deleteCurrencySuccess({ currencyId })),
      catchError(err => of(CurrencyActions.deleteCurrencyFailure(err))),
    )),
  ));
}
