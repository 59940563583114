import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { LegalEntityActions } from 'app/store/actions';
import { HydraCollection, LegalEntity } from 'app/store/models';

@Injectable()
export class LegalEnttyEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadLegalEntities$ = createEffect(() => this.actions$.pipe(
    ofType(LegalEntityActions.loadLegalEntities),
    concatMap(() => this.api.get<HydraCollection<LegalEntity>>({
      path: '/legal_entities',
    }).pipe(
      map(data => LegalEntityActions.loadLegalEntitiesSuccess({ legalEntities: data['hydra:member'] })),
      catchError(err => of(LegalEntityActions.loadLegalEntitiesFailure(err))),
    )),
  ));

  createLegalEntity$ = createEffect(() => this.actions$.pipe(
    ofType(LegalEntityActions.createLegalEntity),
    concatMap(({ legalEntity }) => this.api.post<LegalEntity>({
      path: '/legal_entities',
      body: legalEntity,
    }).pipe(
      map(data => LegalEntityActions.createLegalEntitySuccess({ legalEntity: data })),
      catchError(err => of(LegalEntityActions.createLegalEntityFailure(err))),
    )),
  ));

  updateLegalEntity$ = createEffect(() => this.actions$.pipe(
    ofType(LegalEntityActions.updateLegalEntity),
    concatMap(({ legalEntity }) => this.api.put<LegalEntity>({
      path: `/legal_entities/${legalEntity.id}`,
      body: legalEntity,
    }).pipe(
      map(data => LegalEntityActions.updateLegalEntitySuccess({ legalEntity: data })),
      catchError(err => of(LegalEntityActions.updateLegalEntityFailure(err))),
    )),
  ));

  deleteLegalEntity$ = createEffect(() => this.actions$.pipe(
    ofType(LegalEntityActions.deleteLegalEntity),
    concatMap(({ legalEntityId }) => this.api.delete({
      path: `/legal_entities/${legalEntityId}`,
    }).pipe(
      map(() => LegalEntityActions.deleteLegalEntitySuccess({ legalEntityId })),
      catchError(err => of(LegalEntityActions.deleteLegalEntityFailure(err))),
    )),
  ));
}
