import {Action, createReducer, on} from "@ngrx/store";
import {BundleConfigurationActions} from "../actions";
import {BundleConfigurationState, SubscriptionConfigurationState} from "../models";

const empty: BundleConfigurationState = {
  loadingVersions: false,
  versions: null,
  items: null,
  loadingItems: false,
  savingItem: false,
  publishingItem: false
};

const reducer = createReducer<BundleConfigurationState>(
  empty,
on(BundleConfigurationActions.loadBundleTemplates, (state) => ({
  ...state,
  loadingItems: true,
})),
  on(BundleConfigurationActions.loadBundleTemplatesSuccess, (state, { items }) => ({
    ...state,
    loadingItems: false,
    items: items,
  })),
  on(BundleConfigurationActions.loadBundleTemplatesFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(BundleConfigurationActions.loadBundleTemplateVersions, (state) => ({
    ...state,
    loadingVersions: true,
  })),
  on(BundleConfigurationActions.loadBundleTemplateVersionsSuccess, (state, { items }) => ({
    ...state,
    loadingVersions: false,
    versions: items,
  })),
  on(BundleConfigurationActions.loadBundleTemplateVersionsFailure, (state) => ({
    ...state,
    loadingVersions: false,
  })),

  on(BundleConfigurationActions.createBundleTemplate, (state, { payload }) => ({
    ...state,
    savingItem: !payload.published,
    publishingItem: payload.published,
  })),
  on(BundleConfigurationActions.createBundleTemplateSuccess, (state, { template }) => ({
    ...state,
    items: state.items !== null && state.items.find(x => x.versionId === template.versionId)
      ? (state.items || []).map(x => x.versionId === template.versionId ? template : x)
      : [...state.items || [], template],
    savingItem: false,
    publishingItem: false,
  })),
  on(BundleConfigurationActions.createBundleTemplateFailure, (state, _) => ({
    ...state,
    savingItem: false,
    publishingItem: false,
  })),

  on(BundleConfigurationActions.updateBundleTemplate, (state, { payload }) => ({
    ...state,
    savingItem: !payload.published,
    publishingItem: payload.published,
  })),
  on(BundleConfigurationActions.updateBundleTemplateSuccess, (state, { template }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === template.id ? template : x),
    savingItem: false,
    publishingItem: false,
  })),
  on(BundleConfigurationActions.updateBundleTemplateFailure, (state, _) => ({
    ...state,
    savingItem: false,
    publishingItem: false,
  })),

  on(BundleConfigurationActions.deleteBundleTemplate, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(BundleConfigurationActions.deleteBundleTemplateSuccess, (state, { templateId }) => ({
    ...state,
    items: (state.items || []).filter(x => x.id !== templateId),
    savingItem: false,
  })),
  on(BundleConfigurationActions.deleteBundleTemplateFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),
);

export function bundleConfigurationReducer(state: BundleConfigurationState | undefined, action: Action) {
  return reducer(state, action);
}
