import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { CurrencyPairActions } from 'app/store/actions';
import { CurrencyPair, HydraCollection } from 'app/store/models';

@Injectable()
export class CurrencyPairEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadCurrencyPairs$ = createEffect(() => this.actions$.pipe(
    ofType(CurrencyPairActions.loadCurrencyPairs),
    concatMap(() => this.api.get<HydraCollection<CurrencyPair>>({
      path: '/currency_pairs',
    }).pipe(
      map(data => CurrencyPairActions.loadCurrencyPairsSuccess({ currencyPairs: data['hydra:member'] })),
      catchError(err => of(CurrencyPairActions.loadCurrencyPairsFailure(err))),
    )),
  ));

  createCurrencyPair$ = createEffect(() => this.actions$.pipe(
    ofType(CurrencyPairActions.createCurrencyPair),
    concatMap(({ currencyPair }) => this.api.post<CurrencyPair>({
      path: '/currency_pairs',
      body: currencyPair,
    }).pipe(
      map(data => CurrencyPairActions.createCurrencyPairSuccess({ currencyPair: data })),
      catchError(err => of(CurrencyPairActions.createCurrencyPairFailure(err))),
    )),
  ));

  updateCurrencyPair$ = createEffect(() => this.actions$.pipe(
    ofType(CurrencyPairActions.updateCurrencyPair),
    concatMap(({ currencyPair }) => this.api.put<CurrencyPair>({
      path: `/currency_pairs/${currencyPair.id}`,
      body: currencyPair,
    }).pipe(
      map(data => CurrencyPairActions.updateCurrencyPairSuccess({ currencyPair: data })),
      catchError(err => of(CurrencyPairActions.updateCurrencyPairFailure(err))),
    )),
  ));

  deleteCurrencyPair$ = createEffect(() => this.actions$.pipe(
    ofType(CurrencyPairActions.deleteCurrencyPair),
    concatMap(({ currencyPairId }) => this.api.delete({
      path: `/currency_pairs/${currencyPairId}`,
    }).pipe(
      map(() => CurrencyPairActions.deleteCurrencyPairSuccess({ currencyPairId })),
      catchError(err => of(CurrencyPairActions.deleteCurrencyPairFailure(err))),
    )),
  ));
}
