import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { PaymentProviderActions } from 'app/store/actions';
import { PaymentProvider, HydraCollection } from 'app/store/models';
import { PaymentMethod } from 'app/store/models/payment-provider.model';

@Injectable()
export class PaymentProviderEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadPaymentProviders$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentProviderActions.loadPaymentProviders),
    concatMap(() => this.api.get<HydraCollection<PaymentProvider>>({
      path: '/payment_providers',
    }).pipe(
      map(data => PaymentProviderActions.loadPaymentProvidersSuccess({ paymentProviders: data['hydra:member'] })),
      catchError(err => of(PaymentProviderActions.loadPaymentProvidersFailure(err))),
    )),
  ));

  updatePaymentProvider$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentProviderActions.updatePaymentProvider),
    concatMap(({ paymentProvider }) => this.api.put<PaymentProvider>({
      path: `/payment_providers/${paymentProvider.id}`,
      body: paymentProvider,
    }).pipe(
      map(data => PaymentProviderActions.updatePaymentProviderSuccess({ paymentProvider: data })),
      catchError(err => of(PaymentProviderActions.updatePaymentProviderFailure(err))),
    )),
  ));

  loadPaymentMethods$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentProviderActions.loadPaymentMethods),
    concatMap(() => this.api.get<HydraCollection<PaymentMethod>>({
      path: '/payment_methods',
    }).pipe(
      map(data => PaymentProviderActions.loadPaymentMethodsSuccess({ paymentMethods: data['hydra:member'] })),
      catchError(err => of(PaymentProviderActions.loadPaymentMethodsFailure(err))),
    )),
  ));

  updatePaymentMethod$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentProviderActions.updatePaymentMethod),
    concatMap(({ paymentMethod }) => this.api.put<PaymentMethod>({
      path: `/payment_methods/${paymentMethod.id}`,
      body: paymentMethod,
    }).pipe(
      map(data => PaymentProviderActions.updatePaymentMethodSuccess({ paymentMethod: data })),
      catchError(err => of(PaymentProviderActions.updatePaymentMethodFailure(err))),
    )),
  ));

  loadAvailablePaymentMethods$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentProviderActions.loadAvailablePaymentMethods),
    concatMap(() => this.api.get<HydraCollection<PaymentMethod>>({
      path: '/available_payment_methods',
    }).pipe(
      map(data => PaymentProviderActions.loadAvailablePaymentMethodsSuccess({ paymentMethods: data['hydra:member'] })),
      catchError(err => of(PaymentProviderActions.loadAvailablePaymentMethodsFailure(err))),
    )),
  ));

  fetchEstimation$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentProviderActions.fetchEstimation),
    concatMap((data) => this.api.get<any>({
      path: `/available_payment_methods/${data.paymentMethodId}/estimation/${data.termsType}/${data.amount}`
    }).pipe(
      map(data => PaymentProviderActions.fetchEstimationSuccess(data)),
      catchError(err => of(PaymentProviderActions.fetchEstimationFailure(err))
    ))
  )))
}
