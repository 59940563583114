<h2>{{ 'Services' | translate }}</h2>
<button class="btn btn-link" (click)="newService()">
  {{ 'New Service' | translate }}
</button>

<div class="input-group">
  <div class="input-group-text"><i class="far fa-search"></i></div>
  <input
    class="form-control"
    [placeholder]="'Name or Service provider'"
    [ngModel]="searchQuery"
    (ngModelChange)="searchQueryDebounced$.next($event)"
  />
</div>

<table class="table">
  <tr class="no-border">
    <th>{{ 'Name' | translate }}</th>
    <th>{{ 'Service provider' | translate }}</th>
    <th>URL</th>
    <th>{{ 'VAT/GST rate' | translate }}</th>
    <th></th>
  </tr>
  <tr *ngFor="let service of services$ | async | sortOn: 'label'">
    <td>{{ service.label }}</td>
    <td>{{ service.serviceProvider.name }}</td>
    <td>{{ service.url }}</td>
    <td>{{ service.vatRatePercentage }}</td>
    <td>
      <button class="btn btn-sm btn-link" (click)="editService(service)">
        {{ 'Edit Service' | translate }}
      </button>
      <button class="btn btn-sm btn-link" (click)="deleteService(service)">
        {{ 'Delete Service' | translate }}
      </button>
    </td>
  </tr>
</table>

<app-pagination [totalPages]="pages$ | async" [pageSource]="page$"></app-pagination>

<ng-template #serviceModal let-self>
  <div class="modal-header">
    <h4 *ngIf="!activeService" class="modal-title">{{ 'Add new Service' | translate }}</h4>
    <h4 *ngIf="activeService" class="modal-title">{{ 'Edit Service' | translate }} {{ activeService.label }}</h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      (ngSubmit)="activeService ? onEditServiceSubmit() : onCreateServiceSubmit()"
      [formGroup]="serviceForm"
      class="p-3"
    >
      <div class="row">
        <div class="col-6">
          <div class="mb-3">
            <label class="form-label center-block">ID</label>
            <input class="form-control" formControlName="id" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Service Provider' | translate }}</label>
            <ng-select
              formControlName="serviceProvider"
              [items]="serviceProviderOptions$ | async"
              bindLabel="label"
              bindValue="value"
            ></ng-select>
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Label' | translate }}</label>
            <input class="form-control" formControlName="label" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'URL' | translate }}</label>
            <input class="form-control" formControlName="url" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Icon' | translate }}</label>
            <input class="form-control" formControlName="icon" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Description' | translate }}</label>
            <div class="description-wrapper">
              <textarea
                class="form-control"
                formControlName="description"
                maxlength="{{ descriptionMaxLength }}"
                rows="5"
              ></textarea>
              <span class="remaning">{{ descriptionMaxLength - serviceForm.value.description?.length }}</span>
            </div>
          </div>
          <div class="form-check mb-3">
            <input class="form-check-input mt-2" type="checkbox" formControlName="showOnDashboard" id="showOnDashboard" />
            <label class="form-label form-check-label" for="showOnDashboard">{{ 'Show on Dashboard' | translate }}</label>
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label class="form-label center-block">{{ 'VAT/GST Rate Percentage' | translate }}</label>
            <input
              class="form-control"
              formControlName="vatRatePercentage"
              data-cy="vatRatePercentage"
              type="number"
            />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Invoice Code' | translate }}</label>
            <input class="form-control" formControlName="eoInvoiceCode" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Same Country Code' | translate }}</label>
            <input class="form-control" formControlName="eoSameCountryCode" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Different EU Country Code' | translate }}</label>
            <input class="form-control" formControlName="eoDifferentEuCountryCode" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Different no EU Country Code' | translate }}</label>
            <input class="form-control" formControlName="eoDifferentNonEuCountryCode" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Cost Center' | translate }}</label>
            <input class="form-control" formControlName="eoCostCenter" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Cost Unit' | translate }}</label>
            <input class="form-control" formControlName="eoCostUnit" />
          </div>
          <div class="form-check mb-3">
            <input class="form-check-input mt-2" type="checkbox" formControlName="eoDoNotGroup" id="eoDoNotGroup" />
            <label class="form-label form-check-label" for="eoDoNotGroup">{{ 'Do not group on transactions invoice' | translate }}</label>
          </div>
        </div>
      </div>
      <app-loading-button btnType="submit" [isLoading]="savingService$ | async">
        {{ 'Submit' | translate }}
      </app-loading-button>
    </form>
  </div>
</ng-template>
