import { Account } from './account.model';
import { Country } from './country.model';
import { Currency } from './currency.model';
import { LegalEntity } from './legal-entity.model';
import { ServiceProvider } from './service-provider.model';

export interface Tenant {
  '@id': string;
  '@type': string;
  id: string;
  enabled: boolean;
  name: string;
  email: string;
  invoiceEmail: string;
  bankAccountNumber: string;
  bic: string;
  streetAndNumber: string;
  city: string;
  zipcode: string;
  country: Country;
  phoneNumber: string;
  locale: string;
  address: string;
  vatNumber: string;
  customerCode: string;
  currency: Currency;
  activity: string|null;
  accounts: Account[];
  payoutsFrequency: string|null;
  isServiceProvider?: boolean;
  serviceProvider?: ServiceProvider|null;
  isLegalEntity?: boolean;
  legalEntity?: LegalEntity|null;
  eoAdditionalInvoiceEmails?: string[];
  purchaseOrderEmail?: string;
  additionalPurchaseOrderEmails?: string[];
}

export interface TenantPayoutsFrequency {
  id: string;
  payoutsFrequency: string|null;
}

export enum ActivityTypes {
  ACTIVITY_TRANSPORT = 'transport',
  ACTIVITY_FORWARDING = 'forwarding',
  ACTIVITY_SEA_FREIGHT = 'sea_freight',
  ACTIVITY_SHIPPER = 'shipper',
  ACTIVITY_TERMINAL = 'terminal',
  ACTIVITY_WAREHOUSING = 'warehousing',
  ACTIVITY_OTHER = 'other',
}
