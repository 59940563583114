import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { ExactOnlineActions } from 'app/store/actions';
import { ExactOnlineQueue, HydraCollection } from 'app/store/models';

@Injectable()
export class ExactOnlineEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadExactOnlineQueue$ = createEffect(() => this.actions$.pipe(
    ofType(ExactOnlineActions.loadExactOnlineQueue),
    concatMap(() => this.api.get<HydraCollection<ExactOnlineQueue>>({
      path: '/exact_online_queues?processed=false&onHold=false',
    }).pipe(
      map(data => ExactOnlineActions.loadExactOnlineQueueSuccess({ exactOnlineQueue: data['hydra:member'] })),
      catchError(err => of(ExactOnlineActions.loadExactOnlineQueueFailure(err))),
    )),
  ));
}
