import { makeActions, ofType } from 'app/store/actions/utils';
import { CurrencyPair } from 'app/store/models';

export const CurrencyPairActions = makeActions({
  loadCurrencyPairs: null,
  loadCurrencyPairsSuccess: ofType<{ currencyPairs: CurrencyPair[]; }>(),
  loadCurrencyPairsFailure: ofType<{ error: any; }>(),

  createCurrencyPair: ofType<{ currencyPair: CurrencyPair; }>(),
  createCurrencyPairSuccess: ofType<{ currencyPair: CurrencyPair; }>(),
  createCurrencyPairFailure: ofType<{ error: any; }>(),

  updateCurrencyPair: ofType<{ currencyPair: CurrencyPair; }>(),
  updateCurrencyPairSuccess: ofType<{ currencyPair: CurrencyPair; }>(),
  updateCurrencyPairFailure: ofType<{ error: any; }>(),

  deleteCurrencyPair: ofType<{ currencyPairId: string; }>(),
  deleteCurrencyPairSuccess: ofType<{ currencyPairId: string; }>(),
  deleteCurrencyPairFailure: ofType<{ error: any; }>(),
});
