import { UserContext } from './user-context.model';

export interface Notification {
  '@id': string;
  '@type': string;
  id: string;
  type: string;
  userContext: UserContext;
  data: any;
}

export interface NotificationForm {
  id?: string;
  type?: string;
  userContext?: UserContext;
  data: any;
}

export enum NotificationType {
  LOW_BALANCE = 'LOW_BALANCE',
  SUBSCRIPTION_RENEWAL_BALANCE = 'SUBSCRIPTION_RENEWAL_BALANCE',
  SUBSCRIPTION_TOKENS = 'SUBSCRIPTION_TOKENS',
  PAYOUT = 'PAYOUT',
}
