import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { CommonActions } from 'app/store/actions';
import { Country, Currency, HydraCollection } from 'app/store/models';

@Injectable()
export class CommonEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadPublicCountries$ = createEffect(() => this.actions$.pipe(
    ofType(CommonActions.loadPublicCountries),
    concatMap(() => this.api.get<Country[]>({
      path: '/public/countries',
    }).pipe(
      map(countries => CommonActions.loadPublicCountriesSuccess({ countries })),
      catchError(err => of(CommonActions.loadPublicCountriesFailure(err))),
    )),
  ));

  loadPublicCurrencies$ = createEffect(() => this.actions$.pipe(
    ofType(CommonActions.loadPublicCurrencies),
    concatMap(() => this.api.get<HydraCollection<Currency>>({
      path: '/public/currencies',
    }).pipe(
      map(data => CommonActions.loadPublicCurrenciesSuccess({ currencies: data['hydra:member'] })),
      catchError(err => of(CommonActions.loadPublicCurrenciesFailure(err))),
    )),
  ));

  loadInvoicingSoftware$ = createEffect(() => this.actions$.pipe(
    ofType(CommonActions.loadInvoicingSoftware),
    concatMap(() => this.api.get<string[]>({
      path: '/supported-invoicing-software',
    }).pipe(
      map(invoicingSoftware => CommonActions.loadInvoicingSoftwareSuccess({ invoicingSoftware })),
      catchError(err => of(CommonActions.loadInvoicingSoftwareFailure(err))),
    )),
  ));
}
