import { Action, createReducer, on } from '@ngrx/store';

import { SessionActions, TenantActions } from 'app/store/actions';
import { SessionState } from 'app/store/models';

export const emptySession: SessionState = {
  currentTenantId: null,
  loading: false,
  loadingToken: false,
  loadingRegister: false,
  tenants: null,
  token: null,
  userContext: null,
};
const reducer = createReducer<SessionState>(
  emptySession,

  on(SessionActions.initSession, (_, { session }) => ({
    ...(session ? session : emptySession),
    userContext: null,
    tenants: null,
    loading: false,
    loadingRegister: false,
  })),

  on(SessionActions.loginSuccess, (_, { token }) => ({
    ...emptySession,
    token: {
      ...token,
      expires_at: String(Date.now() + Number(token.expires_in) * 1000),
      access_token_stored_at: String(Date.now()),
    },
    loadingToken: false,
  })),
  on(SessionActions.refreshSuccess, (session, { token }) => ({
    ...session,
    token: {
      ...token,
      expires_at: String(Date.now() + Number(token.expires_in) * 1000),
      access_token_stored_at: String(Date.now()),
    },
    loadingToken: false,
  })),
  on(SessionActions.login, (state) => ({ ...state, loading: true, loadingToken: true })),
  on(SessionActions.refresh, (state) => ({ ...state, loading: true, loadingToken: true })),
  on(SessionActions.loginFailure, (_) => emptySession),
  on(SessionActions.refreshFailure, (_) => emptySession),
  on(SessionActions.logout, (_) => emptySession),

  on(SessionActions.register, (state) => ({ ...state, loadingRegister: true })),
  on(SessionActions.registerSuccess, (state) => ({ ...state, loadingRegister: false })),
  on(SessionActions.registerFailure, (_) => emptySession),

  on(SessionActions.pickTenant, (state, { tenantId }) => ({
    ...state,
    currentTenantId: tenantId,
  })),
  on(SessionActions.dropTenant, (state, _) => ({
    ...state,
    currentTenantId: null,
    userContext: null,
    loading: false,
  })),

  on(SessionActions.loadUserTenants, (state, _) => ({ ...state, loading: true })),
  on(SessionActions.loadUserContext, (state, _) => ({ ...state, loading: true })),
  on(SessionActions.loadUserTenantsFailure, (state, _) => ({ ...state, loading: false })),
  on(SessionActions.loadUserContextFailure, (state, _) => ({ ...state, loading: false })),
  on(SessionActions.loadUserTenantsSuccess, (state, { tenants }) => ({
    ...state,
    tenants,
    loading: false,
  })),
  on(SessionActions.loadUserContextSuccess, (state, { userContext }) => ({
    ...state,
    userContext,
    tenants: (state.tenants || []).map(x => x.id === userContext.tenant.id ? userContext.tenant : x),
    loading: false,
  })),

  on(TenantActions.updateTenantSuccess, (state, { tenant }) => ({
    ...state,
    tenants: (state.tenants || []).map(x => x.id === tenant.id ? tenant : x),
  })),
);

let initialized = false;
export function sessionReducer(state: SessionState | undefined, action: Action) {
  const x = reducer(state, action);
  if (initialized) {
    localStorage.setItem('session', JSON.stringify(x));
  } else if (action.type === SessionActions.initSession.type) {
    initialized = true;
  }
  return x;
}
