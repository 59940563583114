import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { group, NonEmptyArray, sort } from 'fp-ts/es6/NonEmptyArray';
import { pipe } from 'fp-ts/es6/pipeable';

import { AccountType, Posting, Transaction } from 'app/store/models';
import {
  byTransactionChargedAt, byTransactionChargedDate,
} from 'app/store/models/transaction.model';

import { TransactionCardComponent } from './transaction-card.component';
import { I18nService } from 'app/services/i18n.service';

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss'],
})
export class TransactionListComponent implements OnChanges {
  @Input()
  loading = false;
  @Input()
  showProviderPromo = false;
  @Input()
  items: [Transaction[], AccountType] = [[], AccountType.SPENDER];
  @Output()
  reload = new EventEmitter<boolean>();
  groupedItems: NonEmptyArray<Transaction>[] = [];

  constructor(private modalService: NgbModal, private translate: I18nService) { }

  showTransactionPopup(transaction: Transaction) {
    const instance = this.modalService.open(TransactionCardComponent);
    instance.componentInstance.transaction = transaction;
    instance.componentInstance.accountType = this.items[1];
    instance.result.then(
      (result) => (result && result.reload) ? this.reload.emit(true) : null,
      () => { },
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const items = changes['items'];
    if (items && items.currentValue && items.currentValue[0]) {
      this.groupedItems = pipe(
        items.currentValue[0] as NonEmptyArray<Transaction>,
        sort(byTransactionChargedAt),
        group(byTransactionChargedDate),
      );
    }
  }

  getServiceProviderName(transaction: Transaction): string|null {
    return this.translate.getServiceProviderName(transaction);
  }

  getServiceLabel(transaction: Transaction): string|null {
    return this.translate.getServiceLabel(transaction);
  }

  getMainPosting(transaction: Transaction): Posting | undefined {
    return transaction.postings.find(p => p.account.type === this.items[1]);
  }
}
