<h2>{{ 'Subscription invoice codes' | translate }}</h2>
<table class="table">
  <thead>
    <tr>
      <th>{{ 'Provider' | translate }}</th>
      <th>{{ 'Subscription' | translate }}</th>
      <th>{{ 'Invoice code' | translate }}</th>
      <th>{{ 'Same Country Code' | translate }}</th>
      <th>{{ 'Different EU Country Code' | translate }}</th>
      <th>{{ 'Different no EU Country Code' | translate }}</th>
      <th>{{ 'Cost Center' | translate }}</th>
      <th>{{ 'Cost Unit' | translate }}</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let tierForm of subscriptionTemplateTierForms; let i = index;" [formGroup]="tierForm">
      <td>{{ tierMap[i]?.subscriptionTemplate.serviceProvider.name }}</td>
      <td>{{ tierMap[i]?.subscriptionTemplate.label }} - {{ tierMap[i]?.label }} [v{{ tierMap[i]?.subscriptionTemplate.version }}]</td>
      <td><input class="form-control" type="text" formControlName="eoInvoiceCode"></td>
      <td><input class="form-control" type="text" formControlName="eoSameCountryCode"></td>
      <td><input class="form-control" type="text" formControlName="eoDifferentEuCountryCode"></td>
      <td><input class="form-control" type="text" formControlName="eoDifferentNonEuCountryCode"></td>
      <td><input class="form-control" type="text" formControlName="eoCostCenter"></td>
      <td><input class="form-control" type="text" formControlName="eoCostUnit"></td>
      <td>
        <app-loading-button btnType="button" btnClass="btn btn-sm btn-link" (click)="save(tierForm, i, false)" [isLoading]="saveFlags[i]">
          {{ 'save' | translate }}
        </app-loading-button>
      </td>
    </tr>
  </tbody>
</table>

<h2 class="mt-3">{{ 'Bundle invoice codes' | translate }}</h2>
<table class="table">
  <thead>
    <tr>
      <th>{{ 'Provider' | translate }}</th>
      <th>{{ 'Bundle' | translate }}</th>
      <th>{{ 'Invoice code' | translate }}</th>
      <th>{{ 'Same Country Code' | translate }}</th>
      <th>{{ 'Different EU Country Code' | translate }}</th>
      <th>{{ 'Different no EU Country Code' | translate }}</th>
      <th>{{ 'Cost Center' | translate }}</th>
      <th>{{ 'Cost Unit' | translate }}</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let bundleTemplateForm of bundleTemplateForms; let i = index;" [formGroup]="bundleTemplateForm">
      <td>{{ bundleTemplateMap[i]?.serviceProvider.name }}</td>
      <td>{{ bundleTemplateMap[i]?.bundleTemplateService.service.label }} bundle [v{{ bundleTemplateMap[i]?.version }}]</td>
      <td><input class="form-control" type="text" formControlName="eoInvoiceCode"></td>
      <td><input class="form-control" type="text" formControlName="eoSameCountryCode"></td>
      <td><input class="form-control" type="text" formControlName="eoDifferentEuCountryCode"></td>
      <td><input class="form-control" type="text" formControlName="eoDifferentNonEuCountryCode"></td>
      <td><input class="form-control" type="text" formControlName="eoCostCenter"></td>
      <td><input class="form-control" type="text" formControlName="eoCostUnit"></td>
      <td>
        <app-loading-button btnType="button" btnClass="btn btn-sm btn-link" (click)="save(bundleTemplateForm, i, true)" [isLoading]="bundleSaveFlags[i]">
          {{ 'save' | translate }}
        </app-loading-button>
      </td>
    </tr>
  </tbody>
</table>