<div *ngIf="currentTenantIsServiceProvider && (account$ | async) === 'EARNER'" class="d-flex flex-row align-items-center flex-wrap mb-3">
  <div class="mb-3">
    <ng-select
      [items]="linkedTenants$ | async"
      bindLabel="name"
      bindValue="id"
      placeholder="{{ 'Select user company' | translate }}"
      [clearable]="true"
      [ngModel]="userCompany"
      (ngModelChange)="handleUserCompanyChange($event)"
    ></ng-select>
  </div>
</div>

<div class="d-flex flex-wrap justify-content-end" *ngIf="!currentTenantIsServiceProvider && !currentTenantIsLegalEntity && (account$ | async) === 'SPENDER'">
  <button class="btn btn-link" (click)="downloadFinancialReport()">
    <i class="far fa-download"></i>
    {{ 'Download financial report' | translate }}
  </button>
</div>

<table class="table">
  <tr class="no-border">
    <th>{{ 'Date' | translate }}</th>
    <th>{{ 'Description' | translate }}</th>
    <th>{{ 'Action' | translate }}</th>
  </tr>
  <tr *ngFor="let document of documents$ | async">
    <td>{{ document.invoiceDate | date:'dd-MM-yyyy' }}</td>
    <td>{{ document.description }}</td>
    <td>
      <app-loading-button
        btnType="button"
        btnClass="btn btn-sm btn-link"
        (click)="downloadDocument(document)"
        [isLoading]="downloadFlags[document.id]"
      >
        {{ 'Download' | translate }}
      </app-loading-button>
      <app-loading-button
        *ngIf="document.invoiceId && (account$ | async) === 'SPENDER'"
        btnType="button"
        btnClass="btn btn-sm btn-link"
        (click)="downloadReport(document.invoiceId)"
        [isLoading]="downloadFlags[document.invoiceId]"
      >
        {{ 'Download report' | translate }}
      </app-loading-button>
    </td>
  </tr>
</table>

<ng-template #financialReportModal let-self>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'Download financial report' | translate }}</h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      (ngSubmit)="onFinancialReportFormSubmit()"
      [formGroup]="financialReportForm"
    >
      <div class="mb-3 datepicker-only-month-select">
        <label class="form-label center-block" for="since">{{ 'Since' | translate }}</label>
        <div class="input-group">
          <ngb-datepicker
            formControlName="since"
            [minDate]="financialReportMinDate"
            [maxDate]="financialReportMaxDate"
            [weekdays]="false"
            [navigation]="'arrows'"
            (navigate)="financialReportFormDateChange($event, 'since')"
          ></ngb-datepicker>
        </div>
      </div>
      <div class="mb-3 datepicker-only-month-select">
        <label class="form-label center-block" for="until">{{ 'Until (including)' | translate }}</label>
        <div class="input-group">
          <ngb-datepicker
            formControlName="until"
            [minDate]="financialReportMinDate"
            [maxDate]="financialReportMaxDate"
            [weekdays]="false"
            [navigation]="'arrows'"
            (navigate)="financialReportFormDateChange($event, 'until')"
          ></ngb-datepicker>
        </div>
      </div>
      <app-loading-button btnType="submit" [isLoading]="downloadingFinancialReport">
        {{ 'Download' | translate }}
      </app-loading-button>
    </form>
  </div>
</ng-template>
