import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, LegalEntityActions } from 'app/store/actions';
import { LegalEntityState } from 'app/store/models';

const empty: LegalEntityState = {
  items: null,
  loadingItems: false,
  savingItem: false,
};

const reducer = createReducer<LegalEntityState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(LegalEntityActions.loadLegalEntities, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(LegalEntityActions.loadLegalEntitiesSuccess, (state, { legalEntities }) => ({
    ...state,
    items: legalEntities,
    loadingItems: false,
  })),
  on(LegalEntityActions.loadLegalEntitiesFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(LegalEntityActions.createLegalEntity, (state) => ({
    ...state,
    savingItem: true,
  })),
  on(LegalEntityActions.createLegalEntitySuccess, (state, { legalEntity }) => ({
    ...state,
    items: [...state.items || [], legalEntity],
    savingItem: false,
  })),
  on(LegalEntityActions.createLegalEntityFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(LegalEntityActions.updateLegalEntity, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(LegalEntityActions.updateLegalEntitySuccess, (state, { legalEntity }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === legalEntity.id ? legalEntity : x),
    savingItem: false,
  })),
  on(LegalEntityActions.updateLegalEntityFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(LegalEntityActions.deleteLegalEntity, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(LegalEntityActions.deleteLegalEntitySuccess, (state, { legalEntityId }) => ({
    ...state,
    items: (state.items || []).filter(x => x.id !== legalEntityId),
    savingItem: false,
  })),
  on(LegalEntityActions.deleteLegalEntityFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),
);

export function legalEntityReducer(state: LegalEntityState | undefined, action: Action) {
  return reducer(state, action);
}
