import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { HydraCollection } from 'app/store/models';
import { SubscriptionConfigurationActions } from 'app/store/actions';
import { SubscriptionTemplate } from 'app/store/models/subscription.model';

@Injectable()
export class SubscriptionConfigurationEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadSubscriptionTemplates$ = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionConfigurationActions.loadSubscriptionTemplates),
    concatMap(() => this.api.get<HydraCollection<SubscriptionTemplate>>({
      path: '/subscription_templates_latest_versions',
    }).pipe(
      map(data => SubscriptionConfigurationActions.loadSubscriptionTemplatesSuccess({ items: data['hydra:member'] })),
      catchError(err => of(SubscriptionConfigurationActions.loadSubscriptionTemplatesFailure(err))),
    )),
  ));

  loadSubscriptionTemplateHistory$ = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionConfigurationActions.loadSubscriptionTemplateVersions),
    concatMap(({ versionId }) => this.api.get<HydraCollection<SubscriptionTemplate>>({
      path: `/subscription_template_versions/${versionId}` ,
    }).pipe(
      map(data => SubscriptionConfigurationActions.loadSubscriptionTemplateVersionsSuccess({ items: data['hydra:member'] })),
      catchError(err => of(SubscriptionConfigurationActions.loadSubscriptionTemplateVersionsFailure(err))),
    )),
  ));

  createSubscriptionTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionConfigurationActions.createSubscriptionTemplate),
    concatMap(({ payload }) => this.api.post<SubscriptionTemplate>({
      path: `/subscription_templates`,
      body: payload,
    }).pipe(
      map(data => SubscriptionConfigurationActions.createSubscriptionTemplateSuccess({ template: data })),
      catchError(err => of(SubscriptionConfigurationActions.createSubscriptionTemplateFailure(err))),
    )),
  ));

  updateSubscriptionTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionConfigurationActions.updateSubscriptionTemplate),
    concatMap(({ payload, id }) => this.api.put<SubscriptionTemplate>({
      path: `/subscription_templates/${id}`,
      body: payload,
    }).pipe(
      map(data => SubscriptionConfigurationActions.updateSubscriptionTemplateSuccess({ template: data })),
      catchError(err => of(SubscriptionConfigurationActions.updateSubscriptionTemplateFailure(err))),
    )),
  ));

  deleteSubscriptionTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionConfigurationActions.deleteSubscriptionTemplate),
    concatMap(({ templateId }) => this.api.delete({
      path: `/subscription_templates/${templateId}`,
    }).pipe(
      map(() => SubscriptionConfigurationActions.deleteSubscriptionTemplateSuccess({ templateId })),
      catchError(err => of(SubscriptionConfigurationActions.deleteSubscriptionTemplateFailure(err))),
    )),
  ));
}
