import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { SessionActions } from 'app/store/actions/session.actions';
import { State } from 'app/store/models';
import { notNull } from 'app/utils/rxjs';

@Injectable()
export class MultipleTenantGuard  {
  constructor(private store: Store<State>) { }

  canActivate(): Observable<boolean> {
    return this.store.select(x => x.session.tenants).pipe(
      filter(notNull),
      map(data => {
        if (data.length !== 1) {
          return true;
        }
        this.store.dispatch(SessionActions.pickTenant({ tenantId: data[0].id }));
        return false;
      }),
    );
  }
}
