import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { State } from 'app/store/models';
import { selectCurrentTenant } from 'app/store/selectors';
import { notNull, untilDestroyed } from 'app/utils/rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnDestroy {
  currentTenantIsServiceProvider = false;

  constructor(
    private store: Store<State>,
  ) {
    this.store.select(selectCurrentTenant).pipe(
      untilDestroyed(this),
      filter(notNull),
    ).subscribe(tenant => {
      this.currentTenantIsServiceProvider = !!tenant.isServiceProvider || !!tenant.isLegalEntity;
    });
  }

  ngOnDestroy() { }
}
