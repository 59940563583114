import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sortOn' })
export class SortOnPipe implements PipeTransform {
  transform<X>(value: X[] | null, path: string): X[] {
    if (value === null) {
      return [];
    }
    const keys = path.split('.');
    return [...value].sort((a, b) => {
      let k = a as any;
      keys.forEach(key => k = k ? k[key] : k);
      let j = b as any;
      keys.forEach(key => j = j ? j[key] : j);
      if (typeof k === 'string') {
        k = k.toLowerCase() as unknown as typeof k;
      }
      if (typeof j === 'string') {
        j = j.toLowerCase() as unknown as typeof j;
      }
      return k > j ? 1 : k < j ? -1 : 0;
    });
  }
}
