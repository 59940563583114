<div style="max-width:900px;" *ngIf="{
  spenderAccount: (spenderAccount$ | async),
  earnerAccount: (earnerAccount$ | async)
}; let context">
  <div style="height:200px">
    <ngx-charts-bar-vertical
      [scheme]="{domain: ['#f5c827', '#f5d871', '#f4cf4c', '#f5e095']}"
      [results]="earnings$ | async"
      [legend]="false"
      [gradient]="false"
      [xAxis]="true"
      [yAxis]="true"
      [showGridLines]="true"
      [noBarWhenZero]="false"
      [showDataLabel]="true"
    ></ngx-charts-bar-vertical>
  </div>
  <div class="pt-2 mb-4 text-muted">
    {{ 'Latest earnings' | translate }}
    ( <button
        class="btn btn-sm"
        [ngClass]="(period$ | async) === Period.WEEK ? 'btn-dark' : 'btn-outline-dark'"
        (click)="period$.next(Period.WEEK)"
      >7d</button>
    / <button
        class="btn btn-sm"
        [ngClass]="(period$ | async) === Period.TWO_WEEKS ? 'btn-dark' : 'btn-outline-dark'"
        (click)="period$.next(Period.TWO_WEEKS)"
      >14d</button>
    )
  </div>


  <div class="h1 pt-4 mb-4 container mx-0">
    <div class="row">
      <div class="col-sm-12 col-md-6 p-0 pe-2 mb-2">
        <h5 class="mt-2 mb-2 text-muted">{{ 'Earner balance' | translate }}</h5>
        <accounts [accounts]="[context.earnerAccount]" [isSpender]="false"></accounts>
      </div>
      <div *ngIf="context.spenderAccount" class="col-sm-12 col-md-6 p-0 mb-2">
        <div class="container">
          <div class="row">
            <div class="col-auto mt-2 p-0">
              <h5 class="text-muted">{{ 'Spender balance' | translate }}</h5>
            </div>
          </div>
        </div>
        <accounts [accounts]="[context.spenderAccount]" [isSpender]="true" ></accounts>
      </div>
    </div>
  </div>

  <!-- payment choice -->
  <div class="w-100 mb-3" *ngIf="getPayLater(paymentMethods$ | async)">
    <app-payment-choice (payLaterEmit)="handlePaymentChoiceChange($event)"></app-payment-choice>
  </div>

  <div *ngIf="context.spenderAccount" class="card">
    <div class="card-body">
      <app-recharge-wallet [headerText]="'Recharge your spender balance' | translate" [payLater]="payLater"></app-recharge-wallet>
    </div>
  </div>
</div>
