<div class="border-0 cinvio-switch-wrapper w-100 rounded-1">
  <div class="d-flex justify-content-between row flex-direction-row py-2" style="padding: 0.5rem 1.25rem;">
    <div class="switch-choice-node col d-flex justify-content-center align-items-center" (click)="changeSwitchOption(true)" [ngClass]="firstOptionSelected ? 'active' : ''">
      <span class="d-flex justify-content-center align-items-center">{{(firstOption ?? '') | translate}}</span>
    </div>
    <div class="switch-choice-node col d-flex justify-content-center align-items-center" (click)="changeSwitchOption(false)" [ngClass]="!firstOptionSelected ? 'active' : ''" *ngIf="secondOption">
      <div class="d-flex align-items-center">
        <span class="d-flex justify-content-center align-items-center">{{(secondOption ?? '') | translate}}</span>
      </div>
    </div>
  </div>
</div>
