import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-redirect',
  template: '',
})
export class RedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    const payload = this.route.snapshot.paramMap.get('payload');
    if (!payload) {
      window.location.href = '/login';
      return;
    }
    const { token, redirect } = JSON.parse(window.atob(payload));
    localStorage.setItem('session', JSON.stringify({ token }));
    window.location.href = redirect;
  }
}
