<div style="margin:-1rem" class="pt-3">
  <ul class="nav nav-tabs ps-3">
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/services/providers">
        {{ 'Service providers' | translate }}
      </a>
    </li>
    <li class="nav-item" *ngIf="!currentTenantIsServiceProvider">
      <a class="nav-link" routerLinkActive="active" routerLink="/services/subscriptions">
        {{ 'My subscriptions' | translate }}
      </a>
    </li>
    <li class="nav-item" *ngIf="!currentTenantIsServiceProvider">
      <a class="nav-link" routerLinkActive="active" routerLink="/services/bundles">
        {{ 'My bundles' | translate }}
      </a>
    </li>
    <li class="nav-item" *ngIf="currentTenantIsServiceProvider">
      <a class="nav-link" routerLinkActive="active" routerLink="/services/configurator">
        {{ 'Service configurator' | translate }}
      </a>
    </li>
  </ul>
  <div class="p-3">
    <router-outlet></router-outlet>
  </div>
</div>
