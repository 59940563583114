import { InjectionToken } from '@angular/core';
import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, combineReducers } from '@ngrx/store';

import { State } from 'app/store/models';

import { bundleReducer } from './bundle.reducer';
import { bundleConfigurationReducer } from "./bundle-configuration.reducer";
import { bundleInvoicingReducer } from './bundle-invoicing.reducer';
import { commonReducer } from './common.reducer';
import { countryReducer } from './country.reducer';
import { currencyPairReducer } from './currency-pair.reducer';
import { currencyReducer } from './currency.reducer';
import { documentReducer } from './document.reducer';
import { exactOnlineReducer } from './exact-online.reducer';
import { legalEntityReducer } from './legal-entity.reducer';
import { oauthClientReducer } from './oauth-client.reducer';
import { paymentProviderReducer } from './payment-provider.reducer';
import { registrationReducer } from './registration.reducer';
import { serviceConfigurationReducer } from './service-configuration.reducer';
import { serviceReducer } from './service.reducer';
import { serviceProviderReducer } from './service-provider.reducer';
import { sessionReducer } from './session.reducer';
import { subscriptionReducer } from './subscription.reducer';
import { subscriptionConfigurationReducer } from './subscription-configuration.reducer';
import { subscriptionInvoicingReducer } from './subscription-invoicing.reducer';
import { tenantReducer } from './tenant.reducer';
import { userContextReducer } from './user-context.reducer';
import { userNotificationReducer } from './user-notification.reducer';
import { userServiceReducer } from './user-service.reducer';
import { userStatisticsReducer } from './user-statistics.reducer';
import { userTransactionReducer } from './user-transaction.reducer';
import { userReducer } from './user.reducer';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<State>>('App Reducers', {
  providedIn: 'root',
  factory: () => ({
    router: routerReducer,
    session: sessionReducer,
    bundle: bundleReducer,
    bundleConfiguration: bundleConfigurationReducer,
    common: commonReducer,
    document: documentReducer,
    notification: userNotificationReducer,
    service: userServiceReducer,
    serviceConfiguration: serviceConfigurationReducer,
    statistics: userStatisticsReducer,
    subscription: subscriptionReducer,
    subscriptionConfiguration: subscriptionConfigurationReducer,
    transaction: userTransactionReducer,
    userContext: userContextReducer,
    admin: combineReducers({
      country: countryReducer,
      currency: currencyReducer,
      currencyPair: currencyPairReducer,
      legalEntity: legalEntityReducer,
      oauthClient: oauthClientReducer,
      paymentProvider: paymentProviderReducer,
      registration: registrationReducer,
      serviceProvider: serviceProviderReducer,
      exactOnlineQueue: exactOnlineReducer,
      service: serviceReducer,
      tenant: tenantReducer,
      user: userReducer,
      subscriptionInvoicing: subscriptionInvoicingReducer,
      bundleInvoicing: bundleInvoicingReducer,
    }),
  }),
});
