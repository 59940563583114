import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CommonActions, UserServiceActions } from 'app/store/actions';
import { Country, Currency, Service, ServiceProvider, State, Tenant } from 'app/store/models';
import { notNull } from 'app/utils/rxjs';

@Injectable()
export class LazyLoaderService {
  constructor(private store: Store<State>) { }

  public getCountries(): Observable<Country[]> {
    return this.store.select(x => x.common).pipe(
      map(x => {
        if (x.countries === null && !x.countriesLoading) {
          this.store.dispatch(CommonActions.loadPublicCountries());
        }
        return x.countries;
      }),
      filter(notNull),
    );
  }

  public getCurrencies(): Observable<Currency[]> {
    return this.store.select(x => x.common).pipe(
      map(x => {
        if (x.currencies === null && !x.currenciesLoading) {
          this.store.dispatch(CommonActions.loadPublicCurrencies());
        }
        return x.currencies;
      }),
      filter(notNull),
    );
  }

  public getLinkedServices(): Observable<Service[]> {
    return this.store.select(x => x.service).pipe(
      map(x => {
        if (x.linkedItems === null && !x.loadingLinkedItems) {
          this.store.dispatch(UserServiceActions.loadLinkedServices());
        }
        return x.linkedItems;
      }),
      filter(notNull),
    );
  }

  public getUnlinkedServices(): Observable<Service[]> {
    return this.store.select(x => x.service).pipe(
      map(x => {
        if (x.unlinkedItems === null && !x.loadingUnlinkedItems) {
          this.store.dispatch(UserServiceActions.loadUnlinkedServices());
        }
        return x.unlinkedItems;
      }),
      filter(notNull),
    );
  }

  public getInvoicingSoftwareOptions(): Observable<string[]> {
    return this.store.select(x => x.common).pipe(
      map(x => {
        if (x.invoicingSoftware === null && !x.invoicingSoftwareLoading) {
          this.store.dispatch(CommonActions.loadInvoicingSoftware());
        }
        return x.invoicingSoftware;
      }),
      filter(notNull),
    );
  }

  public getSubProviders(): Observable<ServiceProvider[]> {
    return this.store.select(x => x.service).pipe(
      map(x => {
        if (x.subProviders === null && !x.loadingSubProviders) {
          this.store.dispatch(UserServiceActions.loadSubProviders());
        }
        return x.subProviders;
      }),
      filter(notNull),
    );
  }
}
