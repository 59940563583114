<div class="marketplace">
  <div class="provider mt-3">
    <div class="provider__img">
      <img *ngIf="services && services.length" [src]="sanitize(serviceProvider?.logo)">
    </div>
    <div class="provider__steps" *ngIf="showStepsToComplete()">
      <h3>{{ 'Steps to complete' | translate }}</h3>
      <ul class="list-unstyled">
        <li>
          <i class="fa fa-spinner fa-spin" *ngIf="walletCharged === null"></i>
          <i class="far fa-check" *ngIf="walletCharged !== null && walletCharged"></i>
          <i class="far fa-times" *ngIf="walletCharged !== null && !walletCharged"></i>
          <button type="button" class="btn btn-link btn-sm" (click)="topUp()">{{ 'Top up your wallet' | translate }}</button>
        </li>
        <li>
          <i class="fa fa-spinner fa-spin" *ngIf="accountPaired === null"></i>
          <i class="far fa-check" *ngIf="accountPaired !== null && accountPaired"></i>
          <i class="far fa-times" *ngIf="accountPaired !== null && !accountPaired"></i>
          <a
            *ngIf="services && services.length && serviceProvider?.platformUrl"
            href="{{ serviceProvider?.platformUrl }}"
            target="_blank"
          >{{ 'Pair your cinvio account' | translate }}
          </a>
          <span *ngIf="!services || !services.length || !serviceProvider?.platformUrl">{{ 'Pair your cinvio account' | translate }}</span>
        </li>
        <li *ngIf="subscriptionTemplates.length">
          <i class="fa fa-spinner fa-spin" *ngIf="subscriptionStarted === null"></i>
          <i class="far fa-check" *ngIf="subscriptionStarted !== null && subscriptionStarted"></i>
          <i class="far fa-times" *ngIf="subscriptionStarted !== null && !subscriptionStarted"></i>
          <button type="button" class="btn btn-link btn-sm" (click)="scrollToAnchor(scrollAnchor)">{{ 'Start a subscription' | translate }}</button>
        </li>
      </ul>
    </div>
  </div>

  <h3 class="mt-5">{{ 'Service catalogue' | translate }}</h3>
  <div class="services">
    <div class="service" *ngFor="let s of services">
      <div class="service__content">
        <div class="service__content--segment">
          <p>
            <b>
            <i class="far" [ngClass]="s.icon"></i>
            {{ s.label }}
            </b>
          </p>
          <p>{{ s.description }}</p>
        </div>
        <a *ngIf="s.url" class="btn btn-primary" href="{{ s.url }}" target="_blank">
          <i class="far fa-external-link"></i>
          {{ 'Go' | translate }}
        </a>
      </div>
    </div>
  </div>

  <h3 *ngIf="bundleTemplates.length" class="mt-5">{{ 'Bundles' | translate }}</h3>

  <table *ngIf="bundleTemplates.length" class="table bundles">
    <thead>
      <tr>
        <th>{{ 'Service' | translate }}</th>
        <th>{{ 'Tokens' | translate }}</th>
        <th>{{ 'Price per token' | translate }}</th>
        <th>{{ 'Price' | translate }}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let bundleTemplate of bundleTemplates">
        <td>
          <i class="far" [ngClass]="bundleTemplate.bundleTemplateService.service.icon"></i>
          {{ bundleTemplate.bundleTemplateService.service.label }}
        </td>
        <td>
          <ng-select
            [items]="bundlePriceOptions[bundleTemplate.id]"
            bindLabel="label"
            bindValue="value"
            [ngModel]="bundlePriceModel[bundleTemplate.id]"
            (ngModelChange)="handleBundlePriceChange($event)"
            [clearable]="false"
          ></ng-select>
        </td>
        <td>
          <money [amount]="bundlePricePerToken[bundleTemplate.id]" [currency]="bundleTemplate.serviceProvider.tenant.currency.code"></money><span class="currency"> {{'EXCL. VAT' | translate }}</span>
        </td>
        <td>
          <money [amount]="bundlePrice[bundleTemplate.id]" [currency]="bundleTemplate.serviceProvider.tenant.currency.code"></money><span class="currency"> {{'EXCL. VAT' | translate }}</span>
        </td>
        <td>
          <app-loading-button btnType="button"
            *ngIf="accountPaired"
            (click)="startBundle(bundleTemplate)"
            [isLoading]="saveFlags[bundleTemplate.id]"
          >
            {{ 'Buy' | translate }}!
          </app-loading-button>
          <app-loading-button btnType="button"
            *ngIf="!accountPaired"
            (click)="startBundle(bundleTemplate)"
            [isLoading]="saveFlags[bundleTemplate.id]"
            [isDisabled]="!accountPaired"
            ngbTooltip="{{ 'Please make sure you pair your cinvio account first.' | translate }}"
          >
            {{ 'Buy' | translate }}!
          </app-loading-button>
        </td>
      </tr>
    </tbody>
  </table>

  <h3 *ngIf="subscriptionTemplates.length" class="mt-5">{{ 'Subscriptions' | translate }}</h3>
  <div #scrollAnchor></div>

  <div *ngFor="let subscriptionTemplate of subscriptionTemplates">
    <p class="mt-3"><b>{{ subscriptionTemplate.label }}</b></p>
    <div class="tiers">
      <div class="tier" *ngFor="let subscriptionTemplateTier of subscriptionTemplate.subscriptionTemplateTiers">
        <div class="tier__content" [ngClass]="{'tier__content--highlighted': subscriptionTemplateTier.highlighted, 'disabled': (!accountPaired || subscriptionTemplate.active && accountPaired)}">
          <h4>{{ subscriptionTemplateTier.label }}</h4>
          <div class="tier__content--segment">
            <table>
              <tr *ngFor="let subscriptionTemplateTierService of subscriptionTemplateTier.subscriptionTemplateTierServices">
                <th>
                  <i class="far" [ngClass]="subscriptionTemplateTierService.service.icon"></i>
                  {{ serviceMap[subscriptionTemplateTierService.service['@id']] }}
                </th>
                <td>
                  <span *ngIf="subscriptionTemplateTierService.tokens !== null">
                    {{ subscriptionTemplateTierService.tokens }}/{{ (subscriptionTemplateTier.priceMonthly ? 'Month' : 'Year') | translate | lowercase }}
                  </span>
                  <span *ngIf="subscriptionTemplateTierService.tokens === null">{{ 'Unlimited' | translate}}</span>
                </td>
              </tr>
            </table>
            <div class="form">
              <div class="form-check" *ngIf="subscriptionTemplateTier.priceMonthly">
                <input
                  id="{{ 'monthly' + subscriptionTemplateTier.id }}"
                  name="{{ 'monthly' + subscriptionTemplateTier.id }}"
                  class="form-check-input"
                  [ngClass]="{'form-check-input-hidden': subscriptionTemplate.active || !subscriptionTemplateTier.priceYearly}"
                  type="radio"
                  [(ngModel)]="billingOptionsModel[subscriptionTemplateTier.id]"
                  value="monthly"
                />
                <label class="form-label form-check-label" for="{{ 'monthly' + subscriptionTemplateTier.id }}">
                  <money [amount]="subscriptionTemplateTier.priceMonthly" [currency]="subscriptionTemplate.serviceProvider.tenant.currency.code"></money><span class="currency"> {{'EXCL. VAT' | translate }} / {{ 'Month' | translate | lowercase }}</span>
                </label>
              </div>
              <div class="form-check" *ngIf="subscriptionTemplateTier.priceYearly">
                <input
                  id="{{ 'yearly' + subscriptionTemplateTier.id }}"
                  name="{{ 'yearly' + subscriptionTemplateTier.id }}"
                  class="form-check-input"
                  [ngClass]="{'form-check-input-hidden': subscriptionTemplate.active || !subscriptionTemplateTier.priceMonthly}"
                  type="radio"
                  [(ngModel)]="billingOptionsModel[subscriptionTemplateTier.id]"
                  value="yearly"
                />
                <label class="form-label form-check-label" for="{{ 'yearly' + subscriptionTemplateTier.id }}">
                  <money [amount]="subscriptionTemplateTier.priceYearly" [currency]="subscriptionTemplate.serviceProvider.tenant.currency.code"></money><span class="currency"> {{'EXCL. VAT' | translate }} / {{ 'Year' | translate | lowercase }}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="tier__content__footer">
            <app-loading-button btnType="button"
              *ngIf="!subscriptionTemplate.active && accountPaired"
              (click)="startSubscription(subscriptionTemplate, subscriptionTemplateTier)"
              [isLoading]="saveFlags[subscriptionTemplateTier.id]"
            >
              {{ 'Start' | translate }}!
            </app-loading-button>
            <app-loading-button btnType="button"
              *ngIf="!subscriptionTemplate.active && !accountPaired"
              [isDisabled]="!accountPaired"
              ngbTooltip="{{ 'Please make sure you pair your cinvio account first.' | translate }}"
            >
              {{ 'Start' | translate }}!
            </app-loading-button>
            <app-loading-button btnType="button"
              *ngIf="subscriptionTemplate.active && accountPaired"
              [isDisabled]="true"
              ngbTooltip="{{ 'Go to \'My subscriptions\' to view your active subscriptions.' | translate }}"
            >
              {{ 'Start' | translate }}!
            </app-loading-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
