import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CommonActions, SessionActions } from 'app/store/actions';
import { State } from 'app/store/models';

@Injectable()
export class TenantGuard  {
  constructor(private store: Store<State>, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(x => x.session).pipe(map(session => {
      if (!session.token) {
        this.store.dispatch(CommonActions.setOriginalUrl({ url: state.url }));
        this.store.dispatch(SessionActions.logout());
        return false;
      }
      if (!session.currentTenantId) {
        this.store.dispatch(CommonActions.setOriginalUrl({ url: state.url }));
        this.router.navigateByUrl('/select-tenant');
        return false;
      }
      return true;
    }));
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
