import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { OAuthClientActions, ServiceProviderActions } from 'app/store/actions';
import { OAuthClient, ProviderAuthLink, State } from 'app/store/models';
import { notNull, untilDestroyed } from 'app/utils/rxjs';

import { ConfirmActionModalComponent } from './confirm-action-modal.component';

@Component({
  selector: 'app-admin-client',
  templateUrl: './admin-client.component.html',
})
export class AdminClientComponent implements OnDestroy {
  loadingServiceProviders$: Observable<boolean>;
  loadingClients$: Observable<boolean>;
  savingClient$: Observable<boolean>;
  serviceProviderOptions$: Observable<{ value: string; label: string }[]>;
  serviceProviderMap: { [k: string]: string } = {};
  clients$: Observable<OAuthClient[]>;
  activeClient: OAuthClient | null = null;
  clientForm: UntypedFormGroup;
  modalInstance: any;

  @ViewChild('clientModal', { static: true })
  clientModal?: TemplateRef<any>;
  @ViewChild('showClientSecretModal', { static: true })
  showClientSecretModal?: TemplateRef<any>;
  @ViewChild('managePairedTenantsModal', { static: true })
  managePairedTenantsModal?: TemplateRef<any>;

  constructor(
    private store: Store<State>,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private actions$: Actions,
  ) {
    this.loadingServiceProviders$ = this.store.select(x => x.admin.serviceProvider.loadingItems);
    this.loadingClients$ = this.store.select(x => x.admin.oauthClient.loadingItems);
    this.savingClient$ = this.store.select(x => x.admin.oauthClient.savingItem);
    this.store.select(x => x.admin.serviceProvider.items).pipe(
      filter(notNull),
      startWith([]),
      untilDestroyed(this),
    ).subscribe(serviceProviders => serviceProviders.forEach(
        x => this.serviceProviderMap[x.id] = x['@id'] ? x['@id'] : '/api/v1/service_providers/' + x.id,
    ));
    this.serviceProviderOptions$ = this.store.select(
      x => (x.admin.serviceProvider.items || [])
        .map(serviceProvider => ({ label: serviceProvider.name, value: serviceProvider.id })),
    );
    this.clients$ = this.store.select(x => x.admin.oauthClient.items).pipe(
      filter(notNull),
      startWith([]),
      map(y => y.filter(z => z.name !== 'cinvio')), // filter out the cinvio client
    );
    this.clientForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      serviceProvider: [null, Validators.required],
      subServiceProviders: [null],
      name: [null, Validators.required],
      suspended: [null],
    });
    this.store.dispatch(ServiceProviderActions.loadServiceProviders());
    this.store.dispatch(OAuthClientActions.loadOAuthClients());
    this.actions$.pipe(
      ofType(
        OAuthClientActions.createOAuthClientSuccess,
        OAuthClientActions.updateOAuthClientSuccess,
        OAuthClientActions.unlinkPairedTenantSuccess,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      this.modalInstance.dismiss();
    });
  }

  newClient() {
    this.activeClient = null;
    this.clientForm.reset();
    this.modalInstance = this.modalService.open(this.clientModal);
  }

  editClient(client: OAuthClient) {
    this.activeClient = client;
    this.clientForm.reset();
    this.clientForm.patchValue({
      ...this.activeClient,
      serviceProvider: this.activeClient.serviceProvider.id,
      subServiceProviders: this.activeClient.subServiceProviders.map(provider => provider.id),
    });
    this.modalInstance = this.modalService.open(this.clientModal);
  }

  showClientSecret(client: OAuthClient) {
    this.activeClient = client;
    this.modalInstance = this.modalService.open(this.showClientSecretModal, { size: 'lg' });
  }

  managePairedTenants(client: OAuthClient) {
    this.activeClient = client;
    this.modalInstance = this.modalService.open(this.managePairedTenantsModal, { size: 'lg' });
  }

  unlinkClient(authLink: ProviderAuthLink) {
    if (this.activeClient === null) {
      return;
    }
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.title = marker('Unlink Client?');
    modalRef.componentInstance.message = marker('This Action will unlink Tenant ')
      + authLink.tenant.name
      + marker(' from Service Provider ')
      + this.activeClient.name;
    modalRef.componentInstance.confirmButtonText = marker('Yes');
    modalRef.result.then(() => this.store.dispatch(OAuthClientActions.unlinkPairedTenant({
      providerAuthLinkId: authLink.id,
    })));
  }

  onCreateClientSubmit() {
    if (this.clientForm.invalid) {
      this.clientForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(OAuthClientActions.createOAuthClient({
      oauthClient: {
        ...this.clientForm.value,
        serviceProvider: this.serviceProviderMap[this.clientForm.value.serviceProvider],
        suspended: !!this.clientForm.value.suspended,
        subServiceProviders: (this.clientForm.value.subServiceProviders || []).map((providerId: string) => this.serviceProviderMap[providerId])
      },
    }));
  }

  onEditClientSubmit() {
    if (this.clientForm.invalid || this.activeClient === null) {
      this.clientForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(OAuthClientActions.updateOAuthClient({
      oauthClient: {
        ...this.clientForm.value,
        serviceProvider: this.serviceProviderMap[this.clientForm.value.serviceProvider],
        id: this.activeClient.id,
        suspended: !!this.clientForm.value.suspended,
        subServiceProviders: (this.clientForm.value.subServiceProviders || []).map((providerId: string) => this.serviceProviderMap[providerId])
      },
    }));
  }

  ngOnDestroy() { }
}
