<div class="mt-3 d-flex flex-column align-items-start">
  <div class="subscription" *ngFor="let subscription of subscriptions$ | async">
    <div class="tenant-img">
      <img [src]="sanitize(subscription.subscriptionTemplateTier.subscriptionTemplate.serviceProvider.logo)">
    </div>
    <div class="services">
      <div class="service d-flex flex-column h-100 p-2">
        <h4>{{ subscription.subscriptionTemplateTier.subscriptionTemplate.label }} - {{ subscription.subscriptionTemplateTier.label }}</h4>
        <ul class="list-unstyled">
          <li>
            <i *ngIf="subscription.status === 'active'" class="far fa-toggle-on me-1"></i>
            <i *ngIf="subscription.status !== 'active'" class="far fa-toggle-off me-1"></i>
            {{ 'Status' }} {{ subscription.status | translate }}
            <span *ngIf="subscription.status === 'active' && subscription.activeUntil">
              ({{ 'Until' }} {{ subscription.activeUntil | date:'dd-MM-yyyy'}})
            </span>
            <span *ngIf="subscription.status === 'expired'">
              <i class="far fa-info-circle" ngbTooltip="{{ 'Top up your balance to enable the automatic renewal of this subscription.' | translate }}"></i>
            </span>
          </li>
          <li *ngIf="subscription.status === 'active' && subscription.renewsOn">
            <i class="far fa-money-bill me-1"></i>
            {{ 'Renews on' | translate }} {{ subscription.renewsOn | date:'dd-MM-yyyy' }}
            (<span *ngIf="subscription.billing === 'yearly'">
              <money [amount]="subscription.subscriptionTemplateTier.priceYearly" [currency]="subscription.subscriptionTemplateTier.subscriptionTemplate.serviceProvider.tenant.currency.code"></money><span class="currency"> {{'EXCL. VAT' | translate }} / {{ 'Year' | translate | lowercase }}</span>
            </span>
            <span *ngIf="subscription.billing === 'monthly'">
              <money [amount]="subscription.subscriptionTemplateTier.priceMonthly" [currency]="subscription.subscriptionTemplateTier.subscriptionTemplate.serviceProvider.tenant.currency.code"></money><span class="currency"> {{'EXCL. VAT' | translate }} / {{ 'Month' | translate | lowercase }}</span>
            </span>)
          </li>
          <li *ngIf="subscription.status === 'active' && subscription.scheduledUpgrade">
            <i class="far fa-upload me-1"></i>
            {{ 'Upgrades on' | translate }} {{ subscription.renewsOn | date:'dd-MM-yyyy' }}
            (<span *ngIf="subscription.scheduledUpgrade.toBilling === 'yearly'">
              <money [amount]="subscription.scheduledUpgrade.toTier.priceYearly" [currency]="subscription.subscriptionTemplateTier.subscriptionTemplate.serviceProvider.tenant.currency.code"></money><span class="currency"> {{'EXCL. VAT' | translate }} / {{ 'Year' | translate | lowercase }}</span>
            </span>
            <span *ngIf="subscription.scheduledUpgrade.toBilling === 'monthly'">
              <money [amount]="subscription.scheduledUpgrade.toTier.priceMonthly" [currency]="subscription.subscriptionTemplateTier.subscriptionTemplate.serviceProvider.tenant.currency.code"></money><span class="currency"> {{'EXCL. VAT' | translate }} / {{ 'Month' | translate | lowercase }}</span>
            </span>)
          </li>
          <li *ngIf="subscription.status === 'active' && subscription.activeUntil">
            <i class="far fa-toggle-off me-1"></i>
            {{ 'Expires on' | translate }} {{ subscription.activeUntil | date:'dd-MM-yyyy' }}
          </li>
          <li *ngIf="subscription.status === 'active' && subscription.tokensResetOn && (subscription.activeUntil === null || subscription.activeUntil > subscription.tokensResetOn)">
            <i class="far fa-redo me-1"></i>
            {{ 'Tokens reset on' | translate }} {{ subscription.tokensResetOn | date:'dd-MM-yyyy' }}
          </li>
        </ul>
        <table>
          <thead>
            <tr>
              <th>{{ 'Service' | translate }}</th>
              <th *ngIf="subscription.status === 'active'">{{ 'Available tokens' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let subscriptionService of getSubscriptionServices(subscription)">
              <td>
                <i class="far" [ngClass]="subscriptionService.subscriptionTemplateTierService.service.icon"></i>
                {{ subscriptionService.subscriptionTemplateTierService.service.label }}
              </td>
              <td *ngIf="subscription.status === 'active'">
                <span *ngIf="subscriptionService.tokensAvailable !== null">{{ subscriptionService.tokensAvailable }}/{{ subscriptionService.subscriptionTemplateTierService.tokens }}</span>
                <span *ngIf="subscriptionService.tokensAvailable === null">{{ 'Unlimited' | translate}}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="service-footer">
          <button
            class="btn btn-primary me-2"
            *ngIf="allowUpgrade(subscription)"
            (click)="openSubscriptionUpgradeModal(subscription)"
          >
            {{ 'Upgrade' | translate }}
          </button>
          <app-loading-button
            btnType="button"
            btnClass="btn btn-danger me-2"
            *ngIf="allowCancel(subscription)"
            (click)="cancelSubscription(subscription)"
            [isLoading]="saveFlags[subscription.id]"
          >
            {{ 'Cancel' | translate }}
          </app-loading-button>
          <app-loading-button
            btnType="button"
            btnClass="btn btn-primary me-2"
            *ngIf="allowReactivate(subscription)"
            (click)="reactivateSubscription(subscription)"
            [isLoading]="saveFlags[subscription.id]"
          >
            {{ 'Reactivate' | translate }}
          </app-loading-button>
          <app-loading-button
            btnType="button"
            btnClass="btn btn-danger me-2"
            *ngIf="allowCancelUpgrade(subscription)"
            (click)="cancelScheduledUpgrade(subscription)"
            [isLoading]="saveFlags[subscription.id]"
          >
            {{ 'Cancel upgrade' | translate }}
          </app-loading-button>
        </div>
      </div>
    </div>
  </div>
</div>
