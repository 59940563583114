<h2>{{ 'Service configurator' | translate }}</h2>
<table class="table">
  <tr>
    <th>{{ 'Name' | translate }}</th>
    <th>{{ 'Default price' | translate }}</th>
    <th></th>
  </tr>
  <tr *ngFor="let service of services$ | async | sortOn: 'label'">
    <td>
      <i class="far" [ngClass]="service.icon"></i>
      {{ service.label }}
    </td>
    <td>
      <money *ngIf="service.defaultPrice" [amount]="service.defaultPrice" [currency]="currentTenant?.currency.code"></money>
    </td>
    <td>
      <button class="btn btn-sm btn-link" (click)="editServiceConfiguration(service)">
        {{ 'Edit configuration' | translate }}
      </button>
      <app-loading-button (click)="toggleShowOnDashboard(service)" [isLoading]="saveFlags[service.id]" [btnClass]="'btn btn-sm btn-link'" [isDisabled]="saveFlags[service.id]">
        {{ service.showOnDashboard ? 'Unpublish service' : 'Publish service' | translate }}
      </app-loading-button>
    </td>
  </tr>
</table>

<ng-template #serviceConfigurationModal let-self>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'Edit configuration' | translate }}</h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      (ngSubmit)="onEditServiceConfigurationSubmit()"
      [formGroup]="serviceConfigurationForm"
      class="p-3"
    >
      <div class="mb-3">
        <label class="form-label center-block"><b>{{ 'Service name' | translate }}</b></label>
        <div class="input-group">
          <span class="input-group-text"><i class="far" [ngClass]="activeService?.icon"></i></span>
          <input class="form-control" formControlName="label" />
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label center-block"><b>{{ 'Description' | translate }}</b></label>
        <div class="description-wrapper">
          <textarea
            class="form-control"
            formControlName="description"
            maxlength="{{ descriptionMaxLength }}"
            rows="4"
          ></textarea>
          <span class="remaning">{{ descriptionMaxLength - serviceConfigurationForm.value.description?.length }}</span>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label center-block"><b>{{ 'Default price' | translate }}</b></label>
        <div class="row">
          <div class="col-5">
            <div class="input-group">
              <input class="form-control" type="number" formControlName="defaultPrice" min="0"><span class="input-group-text">{{ currentTenant?.currency.code }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label center-block"><b>{{ 'Custom prices' | translate }}</b></label>
        <div
          formArrayName="customServicePrices"
          class="mb-2"
          *ngFor="
            let customServicePrice of customServicePrices.controls;
            let csp = index
          "
        >
          <div [formGroupName]="csp" class="row">
            <div class="col-5">
              <ng-select
                [items]="tenants$ | async"
                bindLabel="name"
                bindValue="@id"
                data-cy="tenant"
                placeholder="{{ 'Select tenant' | translate }}"
                formControlName="tenant"
                [ngClass]="{'is-invalid': customServicePrice.get('tenant').invalid && customServicePrice.get('tenant').touched }"
              >
              </ng-select>
            </div>
            <div class="col-5">
              <div class="input-group">
                <input
                  class="form-control"
                  type="number"
                  data-cy="price"
                  placeholder="{{ 'Price' | translate }}"
                  formControlName="price"
                  min="0"
                ><span class="input-group-text">{{ currentTenant?.currency.code }}</span>
              </div>
            </div>
            <div class="col-2">
              <button
                class="btn btn-danger text-white"
                [disabled]="csp === 0"
                type="button"
                (click)="removeCustomServicePrice(csp)"
              >
                <i class="far fa-times"></i>
              </button>
              <button
                class="btn btn-primary ms-1"
                *ngIf="csp === customServicePrices.controls.length - 1"
                type="button"
                (click)="addCustomServicePrice()"
              >
                <i class="far fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <app-loading-button btnType="submit" [isLoading]="savingService$ | async">
        {{ 'Save' | translate }}
      </app-loading-button>
    </form>
  </div>
</ng-template>
