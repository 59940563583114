import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { I18nService } from 'app/services/i18n.service';
import { SessionActions } from 'app/store/actions';
import { RoleCode, State, Tenant, UserContext } from 'app/store/models';
import { selectCurrentTenant } from 'app/store/selectors';
import { notNull } from 'app/utils/rxjs';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent {
  opened = false;
  userContext$: Observable<UserContext>;
  tenants$: Observable<Tenant[]>;
  currentTenant$: Observable<Tenant>;

  routes = [
    {
      url: '/overview/user',
      name: marker('Balances'),
      icon: 'fa-compass',
      roles: [RoleCode.user],
      notRoles: [RoleCode.serviceProvider, RoleCode.admin],
    },
    {
      url: '/overview/provider',
      name: marker('Balances'),
      icon: 'fa-funnel-dollar',
      roles: [RoleCode.serviceProvider],
      notRoles: [RoleCode.admin],
    },
    {
      url: '/overview/cinvio',
      name: marker('Balances'),
      icon: 'fa-funnel-dollar',
      roles: [RoleCode.admin],
      notRoles: [RoleCode.superAdmin],
    },
    {
      url: '/services',
      name: marker('Services'),
      icon: 'fa-taxi',
      notRoles: [RoleCode.admin],
    },
    {
      url: '/subscriptions',
      name: marker('Subscriptions'),
      icon: 'fa-redo',
      roles: [RoleCode.serviceProvider],
      notRoles: [RoleCode.admin],
    },
    {
      url: '/bundles',
      name: marker('Bundles'),
      icon: 'fa-cubes',
      roles: [RoleCode.serviceProvider],
      notRoles: [RoleCode.admin],
    },
    {
      url: '/transactions',
      name: marker('Transactions'),
      icon: 'fa-file-invoice-dollar',
      notRoles: [RoleCode.superAdmin],
    },
    {
      url: '/documents',
      name: marker('Documents'),
      icon: 'fa-file-download',
      notRoles: [RoleCode.superAdmin],
    },
    {
      url: '/settings',
      name: marker('Settings'),
      icon: 'fa-cog',
    },
    {
      url: '/admin',
      name: marker('Administration'),
      icon: 'fa-star',
      roles: [RoleCode.superAdmin],
    },
  ];

  constructor(
    private store: Store<State>,
    private i18n: I18nService,
    private router: Router,
  ) {
    this.userContext$ = this.store.select(x => x.session.userContext).pipe(
      filter(notNull),
    );
    // switch UI language if language in local storage does not match the one from active user context
    this.userContext$.subscribe(uc => {
      const lang = localStorage.getItem('language');
      if (uc.locale && uc.locale !== lang) {
        this.i18n.language = uc.locale;
      }
    });
    this.tenants$ = this.store.select(x => x.session.tenants).pipe(
      filter(notNull),
      startWith([]),
    );
    this.currentTenant$ = this.store.select(selectCurrentTenant).pipe(
      filter(notNull),
    );
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.opened = false;
      }
    });
  }

  logout() {
    this.store.dispatch(SessionActions.logout());
  }

  changeTenant() {
    this.store.dispatch(SessionActions.dropTenant());
  }

  checkRouteAndRole(ctx: UserContext, { roles = [], notRoles = [] }) {
    return roles.every(x => ctx.roles.find(r => r.code === x))
      && notRoles.every(x => !ctx.roles.find(r => r.code === x));
  }

  getSpenderAccount(ctx: UserContext) {
    if (!ctx.roles.find(x => x.code === RoleCode.user)
      || ctx.roles.find(x => x.code === RoleCode.serviceProvider)) {
      return null;
    }
    return ctx.tenant.accounts?.find(x => x.type === 'SPENDER');
  }

  getEarnerAccount(ctx: UserContext) {
    if (!ctx.roles.find(x => x.code === RoleCode.serviceProvider)) {
      return null;
    }
    return ctx.tenant.accounts?.find(x => x.type === 'EARNER');
  }

  isServiceProvider(ctx: UserContext) {
    return ctx.roles.find(x => x.code === RoleCode.user);
  }
}
