import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, CurrencyActions } from 'app/store/actions';
import { CurrencyState } from 'app/store/models';

const empty: CurrencyState = {
  items: null,
  loadingItems: false,
  savingItem: false,
};

const reducer = createReducer<CurrencyState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(CurrencyActions.loadCurrencies, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(CurrencyActions.loadCurrenciesSuccess, (state, { currencies }) => ({
    ...state,
    items: currencies,
    loadingItems: false,
  })),
  on(CurrencyActions.loadCurrenciesFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(CurrencyActions.createCurrency, (state) => ({
    ...state,
    savingItem: true,
  })),
  on(CurrencyActions.createCurrencySuccess, (state, { currency }) => ({
    ...state,
    items: [...state.items || [], currency],
    savingItem: false,
  })),
  on(CurrencyActions.createCurrencyFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(CurrencyActions.updateCurrency, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(CurrencyActions.updateCurrencySuccess, (state, { currency }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === currency.id ? currency : x),
    savingItem: false,
  })),
  on(CurrencyActions.updateCurrencyFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(CurrencyActions.deleteCurrency, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(CurrencyActions.deleteCurrencySuccess, (state, { currencyId }) => ({
    ...state,
    items: (state.items || []).filter(x => x.id !== currencyId),
    savingItem: false,
  })),
  on(CurrencyActions.deleteCurrencyFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),
);

export function currencyReducer(state: CurrencyState | undefined, action: Action) {
  return reducer(state, action);
}
