<div *ngIf="!loading && (items[0] || []).length == 0">
  <div class="fw-bold">
    {{ 'No transactions found' | translate }}
  </div>
</div>

<table class="table table-bordered w-100">
  <thead>
    <tr>
      <th>{{ 'Service' | translate }}</th>
      <th>{{ 'Description' | translate }}</th>
      <th *ngIf="items[1] !== 'SPENDER'">{{ 'Company' | translate }}</th>
      <th *ngIf="items[1] !== 'EARNER'">{{ 'Service Provider' | translate }}</th>
      <th>{{ 'Date' | translate }}</th>
      <th>{{ 'Amount' | translate }}</th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let item of items[0]">
      <td>
        <button class="btn btn-link m-0 p-0" (click)="showTransactionPopup(item)">
          <i class="far fa-search"></i>
        </button>
        <span *ngIf="getServiceLabel(item)">{{ getServiceLabel(item) }}</span>
      </td>
      <td>{{ item.description | translate }}</td>
      <td *ngIf="items[1] !== 'SPENDER'">{{ getUserCompanyName(item) }}</td>
      <td *ngIf="items[1] !== 'EARNER'">
        <span *ngIf="getServiceProviderName(item)">
          {{ getServiceProviderName(item) }}
        </span>
      </td>
      <td>{{ item.chargedAt | date:'dd-MM-yyyy HH:mm:ss' }}</td>
      <td *ngIf="getMainPosting(item); let mainPosting">
        <b *ngIf="item.softCharged">
          {{ 'Reserved' | translate }}:
          <money [amount]="mainPosting.amount" [currency]="mainPosting.currency"></money>
        </b>
        <b
          *ngIf="!item.softCharged"
          [ngClass]="mainPosting.amount >= 0 ? 'text-success' : 'text-danger'"
        >
          <money [amount]="mainPosting.amount" [currency]="mainPosting.currency"></money>
        </b>
      </td>
    </tr>
  </tbody>
</table>
