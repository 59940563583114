import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as fileSaver from 'file-saver-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { State, Subscription } from 'app/store/models';
import { StatusType, SubscriptionService } from 'app/store/models/subscription.model';
import { SubscriptionActions } from 'app/store/actions';
import { notNull } from 'app/utils/rxjs';

@Component({
  selector: 'app-subscription-page',
  templateUrl: './subscription-page.component.html',
  styleUrls: ['./subscription-page.component.scss'],
})
export class SubscriptionPageComponent implements OnDestroy {
  loadingSubscriptions$: Observable<boolean>;
  subscriptions$: Observable<Subscription[]>;
  activeSubscription: Subscription | null = null;
  statusTypes: { label:string, value: string }[] = Object.values(StatusType).map(x => {
    return { label: x[0].toUpperCase() + x.substr(1).toLowerCase(), value: x };
  });

  page$ = new BehaviorSubject<number>(1);
  pages$: Observable<number>;
  pageSize = 10;
  searchQuery?: string;
  searchQueryDebounced$ = new BehaviorSubject<string|undefined>(undefined);
  status?: string;
  modalInstance: any;

  exportModal: NgbModalRef | null = null;
  exporting = false;

  @ViewChild('detailsModal', { static: true })
  detailsModal?: TemplateRef<any>;

  constructor(
    private store: Store<State>,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private api: ApiService,
  ) {
    this.loadingSubscriptions$ = this.store.select(x => x.subscription.loadingItems);

    this.subscriptions$ = this.store.select(x => x.subscription.providerItems).pipe(
      filter(notNull),
      startWith([]),
    );

    this.pages$ = this.store.select(x => x.subscription).pipe(map(x => {
      return Math.ceil((x.totalProviderItems || 0) / this.pageSize);
    }));
    this.page$.subscribe(page => {
      this.store.dispatch(SubscriptionActions.loadSubscriptionsForProvider({
        page,
        pageSize: this.pageSize,
        status: this.status,
        query: this.searchQueryDebounced$.getValue(),
      }));
    });
    this.searchQueryDebounced$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    )
    .subscribe(() => this.page$.next(1));
  }

  handleStatusChange(status?: string) {
    this.status = status;
    this.page$.next(this.page$.value);
  }

  viewDetails(subscription: Subscription) {
    this.activeSubscription = subscription;
    this.modalInstance = this.modalService.open(this.detailsModal);
  }

  submitExport() {
    this.exporting = true;
    this.api.getBlob({
      path: `/subscriptions_for_provider_report`
        + (this.status !== undefined ? `?status=${this.status}` : '')
        + (this.searchQueryDebounced$.getValue() ? `${this.status !== undefined ? '&' : '?'}search=${this.searchQueryDebounced$.getValue()}` : ''),
    }).toPromise().then(
      blob => {
        this.exporting = false;
        if (this.exportModal) {
          this.exportModal.dismiss();
          this.exportModal = null;
        }
        fileSaver.saveAs(blob, `subscriptions.csv`);
      },
      () => {
        this.exporting = false;
      },
    );
  }

  getSubscriptionServices(subscription: Subscription|null): SubscriptionService[] {
    let services = subscription?.subscriptionServices;
    return services ? services.filter(s => !s.deleted) : [];
  }

  ngOnDestroy() { }
}
