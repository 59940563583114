import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { TenantActions } from 'app/store/actions';
import { State, Tenant } from 'app/store/models';
import { selectCurrentTenant } from 'app/store/selectors';
import { notNull, untilDestroyed } from 'app/utils/rxjs';

@Component({
  selector: 'app-settings-payouts',
  templateUrl: './settings-payouts.component.html',
})
export class SettingsPayoutsComponent implements OnDestroy {
  loadingTenants$: Observable<boolean>;
  savingTenant$: Observable<boolean>;
  currentTenant: Tenant | null = null;
  payoutsForm: UntypedFormGroup;

  constructor(
    private store: Store<State>,
    private fb: UntypedFormBuilder,
  ) {
    this.loadingTenants$ = this.store.select(x => x.session.loading);
    this.savingTenant$ = this.store.select(x => x.admin.tenant.savingItem);
    this.payoutsForm = this.fb.group({
      payoutsFrequency: [null, Validators.required],
    });

    this.store.select(selectCurrentTenant).pipe(
      untilDestroyed(this),
      filter(notNull),
    ).subscribe(tenant => {
      this.currentTenant = tenant;
      this.payoutsForm.patchValue({
        payoutsFrequency: this.currentTenant.payoutsFrequency,
      });
    });
  }

  onPayoutsSubmit() {
    if (this.payoutsForm.invalid || this.currentTenant === null) {
      this.payoutsForm.markAllAsTouched();
      return;
    }

    this.store.dispatch(TenantActions.updateTenant({
      tenant: {
        id: this.currentTenant.id,
        payoutsFrequency: this.payoutsForm.value.payoutsFrequency,
      },
    }));
  }

  get bankAccountNumber() {
    if (!this.currentTenant) {
      return null;
    }

    if (this.currentTenant.isLegalEntity && this.currentTenant.legalEntity) {
      return this.currentTenant.legalEntity.bankAccountNumber;
    }
    if (this.currentTenant.isServiceProvider && this.currentTenant.serviceProvider) {
      return this.currentTenant.serviceProvider.bankAccountNumber;
    }

    return this.currentTenant.bankAccountNumber;
  }

  ngOnDestroy() { }
}
