import { Directive } from '@angular/core';
import { AsyncValidatorFn, UntypedFormControl, NG_ASYNC_VALIDATORS, AsyncValidator } from '@angular/forms';

import { ValidationService } from 'app/services/validation.service';

@Directive({
  selector: '[appVatNumberValidator]',
  providers: [
    { provide: NG_ASYNC_VALIDATORS, useExisting: VatNumberValidator, multi: true },
  ],
})
export class VatNumberValidator implements AsyncValidator {
  validator: AsyncValidatorFn;

  constructor(private validationService: ValidationService) {
    this.validator = this.validationService.vatNumberValidatorAsync();
  }

  validate(c: UntypedFormControl) {
    return this.validator(c);
  }
}
