<h2>{{ 'OAuth Clients' | translate }}</h2>
<button class="btn btn-link" (click)="newClient()">{{ 'New client' | translate }}</button>
<table class="table">
  <tr>
    <th>{{ 'Name' | translate }}</th>
    <th>{{ 'Service provider' | translate }}</th>
    <th>{{ 'Sub service providers' | translate }}</th>
    <th>{{ 'Suspended?' | translate }}</th>
    <th></th>
  </tr>
  <tr *ngFor="let client of clients$ | async | sortOn: 'name'">
    <td>{{ client.name }}</td>
    <td>{{ client.serviceProvider ? client.serviceProvider.name : '-' }}</td>
    <td>
      <ul *ngIf="client.subServiceProviders.length" class="list-unstyled">
        <li *ngFor="let provider of client.subServiceProviders">
          {{ provider.name }}
        </li>
      </ul>
    </td>
    <td>{{ client.suspended }}</td>
    <td>
      <button class="btn btn-sm btn-link" (click)="showClientSecret(client)">
        {{ 'Show Secret' | translate }}
      </button>
      <button class="btn btn-sm btn-link" (click)="editClient(client)">
        {{ 'Edit Client' | translate }}
      </button>
      <button class="btn btn-sm btn-link" (click)="managePairedTenants(client)">
        {{ 'Manage paired Tenants' | translate }}
      </button>
    </td>
  </tr>
</table>

<ng-template #clientModal let-self>
  <div class="modal-header">
    <h4 *ngIf="!activeClient" class="modal-title">{{ 'Add new Client' | translate }}</h4>
    <h4 *ngIf="activeClient" class="modal-title">{{ 'Edit Client' | translate }} {{ activeClient.name }}</h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      (ngSubmit)="activeClient ? onEditClientSubmit() : onCreateClientSubmit()"
      [formGroup]="clientForm"
      class="p-3"
    >
      <div class="mb-3">
        <label class="form-label center-block">ID</label>
        <input class="form-control" formControlName="id" />
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Service Provider' | translate }}</label>
        <ng-select
          formControlName="serviceProvider"
          [items]="serviceProviderOptions$ | async"
          bindLabel="label"
          bindValue="value"
        ></ng-select>
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Sub Service Providers' | translate }}</label>
        <ng-select
          formControlName="subServiceProviders"
          [items]="serviceProviderOptions$ | async"
          bindLabel="label"
          bindValue="value"
          [multiple]="true"
        ></ng-select>
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Label' | translate }}</label>
        <input class="form-control" formControlName="name" />
      </div>
      <div class="form-check mb-3">
        <input class="form-check-input mt-2" type="checkbox" formControlName="suspended" id="suspended" />
        <label class="form-label form-check-label" for="suspended">{{ 'Suspended' | translate }}</label>
      </div>
      <app-loading-button btnType="submit" [isLoading]="savingClient$ | async">
        {{ 'Submit' | translate }}
      </app-loading-button>
    </form>
  </div>
</ng-template>

<ng-template #showClientSecretModal let-self>
  <div class="modal-header">
    <h4>{{ 'Client Secret of Client ' | translate }} {{ activeClient?.name }}</h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <table>
      <tr>
        <th>{{ 'ID' | translate }}</th>
        <td>{{ activeClient?.id }}_{{ activeClient?.randomId }}</td>
      </tr>
      <tr>
        <th>{{ 'Secret' | translate }}</th>
        <td>{{ activeClient?.secret }}</td>
      </tr>
    </table>
  </div>
</ng-template>

<ng-template #managePairedTenantsModal let-self>
  <div class="modal-header">
    <h4>{{ 'Manage paired Tenants of Client ' | translate }} {{ activeClient?.name }}</h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table">
      <thead>
        <tr>
          <th>Tenant</th>
          <th>Company name</th>
          <th>Company ID</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let authLink of activeClient?.providerAuthLinks | sortOn: 'externalId'">
          <tr *ngIf="!authLink.deleted">
            <td>{{ authLink.tenant.name }}</td>
            <td>{{ authLink.externalCompanyName }}</td>
            <td>{{ authLink.externalId }}</td>
            <td>
              <button class="btn btn-sm btn-link" (click)="unlinkClient(authLink)">{{ 'Unlink Client' | translate }}</button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</ng-template>
