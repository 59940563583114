<div class="modal-header">
  <h4 *ngIf="!bundleTemplate" class="modal-title">{{ 'Add a new bundle' | translate }}</h4>
  <h4 *ngIf="bundleTemplate" class="modal-title">{{ 'Edit bundle' | translate }}</h4>
  <button type="button" class="btn-close" tabindex="-1" (click)="handleCancel()">
    <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
  </button>
</div>
<div class="container modal-body p-4">
  <form [formGroup]="bundleTemplateForm">

    <div class="mb-3">
      <label class="form-label center-block"><b>{{ 'Service name' | translate }}</b></label>
      <ng-select [items]="services$ | async"
                 bindLabel="label"
                 bindValue="@id"
                 formControlName="service">
      </ng-select>
      <div *ngIf="bundleTemplateForm.controls['service'].invalid && bundleTemplateForm.controls['service'].touched && bundleTemplateForm.controls['service'].errors?.unpublished" class="invalid-feedback">
        {{ 'You cannot publish a bundle that includes an unpublished service.' | translate }}
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        {{ 'Tokens' | translate }}
      </div>
      <div class="col-4">
        {{ 'Price per token' | translate }}
      </div>
      <div class="col-2">
        {{ 'Price' | translate }}
      </div>
    </div>

    <div formArrayName="servicePrices" >
      <ng-template
        ngFor
        let-item
        [ngForOf]="bundleTemplateForm?.get('servicePrices')['controls']"
        let-i="index"
      >
        <div class="row mb-3"
             [formGroupName]="i"
        >
          <div class="col-3">
            <input class="form-control"
                   type="number"
                   name="tokens"
                   min="0"
                   (change)="setDefaultValueOptions()"
                   formControlName="tokens">
          </div>
          <div class="col-4">
            <div class="input-group">
              <input class="form-control"
                    type="number"
                    min="0"
                    name="pricePerToken"
                    formControlName="pricePerToken"><span class="input-group-text">{{ currentTenant?.currency.code }}</span>
            </div>
          </div>
          <div class="col-2 pt-1">
            <money [amount]="item.value.tokens * item.value.pricePerToken" [currency]="currentTenant?.currency.code"></money><span class="currency">*</span>
          </div>
          <div class="col-2">
            <button class="btn btn-link" (click)="removeServicePrice(i)">
              {{ 'Remove' | translate}}
            </button>
          </div>
        </div>
      </ng-template>
    </div>

    <div class="row">
      <button class="btn btn-link" (click)="addNewServicePrice()">
        {{ 'Add new range' | translate}}
      </button>
    </div>

    <div class="mb-3">
      <hr>
      <div class="row">
        <div class="col-6">
          <label class="form-label center-block"><b>{{ 'Default value' | translate }}</b></label>
          <ng-select [items]="defaultValueOptions"
                    bindLabel="label"
                    bindValue="value"
                    formControlName="defaultValue">
          </ng-select>
        </div>
      </div>
    </div>

    <div class="mb-3 mt-4">
      <app-loading-button
        btnType="button"
        btnClass="btn btn-primary"
        (click)="onSubmit(false)"
        [isLoading]="saving$ | async"
        [isDisabled]="!bundleTemplateForm.valid"
      >
        {{ 'Save a draft' | translate }}
      </app-loading-button>

      <app-loading-button
        btnType="button"
        btnClass="btn btn-primary ms-3"
        (click)="onSubmit(true)"
        [isLoading]="publishing$ | async"
        [isDisabled]="!bundleTemplateForm.valid"
      >
        {{ 'Publish a new version' | translate }}
      </app-loading-button>
    </div>
  </form>
</div>
