import { contramap, Ord, ordString } from 'fp-ts/es6/Ord';

import { Service } from "./service.model";
import { Tenant } from "./tenant.model";
import { ServiceProvider } from "./service-provider.model";

export interface Bundle {
  '@id': string;
  '@type': string;
  id: string;
  tenant?: Tenant;
  bundleTemplate: BundleTemplate;
  bundleService: BundleService;
}

export interface BundleTemplate {
  '@id': string;
  '@type': string;
  id: string;
  published: boolean;
  versionId: string;
  version: number;
  serviceProvider: ServiceProvider;
  bundleTemplateService: BundleTemplateService;
  selectedPrice?: BundleTemplateServicePrice | null;
}

export interface BundleTemplateService {
  '@id': string;
  '@type': string;
  id: string;
  bundleTemplate?: BundleTemplate;
  service: Service;
  bundleTemplateServicePrices: BundleTemplateServicePrice[];
}

export interface BundleService {
  bundle?: Bundle;
  bundleTemplateServicePrice: BundleTemplateServicePrice;
  tokensAvailable: number;
  tokensReserved: number;
  tokenConsumptions: number;
}

export interface BundleTemplateServicePrice {
  '@id': string;
  pricePerToken: string;
  tokens: number;
  bundleTemplateService?: BundleTemplateService;
  isDefault: boolean;
}

export const byBundleTemplateServiceProvider: Ord<Bundle>
  = contramap<string, Bundle>(x => x.bundleTemplate.serviceProvider['@id'])(ordString);
