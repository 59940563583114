<div class="row fullscreen justify-content-center align-items-center">
  <div class="col-12 col-md-4 col-xl-3 card">
    <form class="card-body" #loginForm="ngForm" (ngSubmit)="reset()">
      <h3>{{ "Can't remember your password?" | translate }}</h3>
      <p class="text-muted">
        {{ "No worries, enter a new password below (one you can remember) and you're off!" | translate }}
      </p>
      <div class="mb-3">
        <label class="form-label" for="newPass1">{{ 'New Password' | translate }}</label>
        <input
          [(ngModel)]="newPass1"
          name="newPass1"
          type="password"
          placeholder="{{ 'Enter your new password' | translate }}"
          class="form-control"
          autocomplete="new-password"
          autofocus
        />
      </div>
      <div class="mb-3">
        <label class="form-label" for="newPass2">{{ 'Repeat new password' | translate }}</label>
        <input
          [(ngModel)]="newPass2"
          name="newPass2"
          type="password"
          placeholder="{{ 'Enter your new password again' | translate }}"
          class="form-control"
          autocomplete="new-password"
        />
      </div>
      <div class="mb-3">
        <app-loading-button btnType="submit" [isLoading]="loading">
          {{ 'Set new password' | translate }}
        </app-loading-button>
        <a routerLink="/" class="btn float-start">{{ 'Ready to log in?' | translate }}</a>
      </div>
    </form>
  </div>
</div>
