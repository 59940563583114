<div *ngIf="!loading && (items[0] || []).length == 0">
  <div class="fw-bold" *ngIf="items[1] !== 'EARNER' || !showProviderPromo">
    {{ 'No transactions found' | translate }}
  </div>
  <div *ngIf="items[1] === 'EARNER' && showProviderPromo">
    <p class="fw-bold">{{ 'No earnings here!' | translate }}</p>
    <p>{{ 'But did you know that you can also earn money with cinvio?' | translate }}</p>
    <p>{{ 'Do you have customers you offer services to? Then you are also a service provider and a potential cinvio partner. Chances are that some of your customers are already using cinvio as their single, digital wallet for their logistics transactions.' | translate }}</p>
    <p>
      {{ 'Want to benefit from pre-payment heaven as a partner? Drop us an email at' | translate }}
      <a href="mailto:hello@cinvio.com">hello@cinvio.com</a>.
    </p>
  </div>
</div>

<div *ngFor="let itemGroup of groupedItems">
  <div class="fw-bold">
    {{ itemGroup[0].chargedAt | date:'dd-MM-yyyy' }}
  </div>
  <div class="transaction-block mb-3">
    <button
      *ngFor="let item of itemGroup"
      class="btn btn-link transaction"
      (click)="showTransactionPopup(item)"
    >
      <div class="left">
        <span *ngIf="getServiceProviderName(item)">{{ getServiceProviderName(item) }}</span>
        <span *ngIf="getServiceProviderName(item) && getServiceLabel(item)"> - </span>
        <span *ngIf="getServiceLabel(item)">{{ getServiceLabel(item) }}</span>
        <br>
        {{ item.description | translate }}
      </div>
      <div class="right" *ngIf="getMainPosting(item); let mainPosting">
        <span class="text-muted">{{ item.chargedAt | date:'HH:mm:ss' }}</span>
        <br>
        <b *ngIf="item.softCharged" class="reserved">
          {{ 'Reserved' | translate }}:
          <money [amount]="mainPosting.amount" [currency]="mainPosting.currency"></money>
        </b>
        <b
          *ngIf="!item.softCharged"
          [ngClass]="mainPosting.amount >= 0 ? 'text-success' : 'text-danger'"
        >
          <money [amount]="mainPosting.amount" [currency]="mainPosting.currency"></money>
        </b>
      </div>
    </button>
  </div>
</div>
