import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, TenantActions } from 'app/store/actions';
import { TenantState } from 'app/store/models';

const empty: TenantState = {
  items: null,
  loadingItems: false,
  savingItem: false,
  paginatedItems: null,
  currentPage: null,
  totalItems: null,
  reimbursementItems: null
};

const reducer = createReducer<TenantState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(TenantActions.loadTenants, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(TenantActions.loadTenantsSuccess, (state, { tenants }) => ({
    ...state,
    items: tenants,
    loadingItems: false,
  })),
  on(TenantActions.loadTenantsFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(TenantActions.loadPaginatedTenants, (state) => ({
    ...state,
    loading: true,
    paginatedItems: null,
    totalItems: null,
  })),
  on(TenantActions.loadPaginatedTenantsSuccess, (state, { items, totalItems }) => ({
    ...state,
    loading: false,
    totalItems,
    paginatedItems: items,
  })),
  on(TenantActions.loadPaginatedTenantsFailure, (state) => ({
    ...state,
    loading: false,
  })),

  on(TenantActions.createTenant, (state) => ({
    ...state,
    savingItem: true,
  })),
  on(TenantActions.createTenantSuccess, (state, { tenant }) => ({
    ...state,
    items: (state.items !== null) ? [...state.items, tenant] : [tenant],
    savingItem: false,
  })),
  on(TenantActions.createTenantFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(TenantActions.updateTenant, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(TenantActions.updateTenantSuccess, (state, { tenant }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === tenant.id ? tenant : x),
    paginatedItems: (state.paginatedItems || []).map(x => x.id === tenant.id ? tenant : x),
    savingItem: false,
  })),
  on(TenantActions.updateTenantFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(TenantActions.deleteTenant, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(TenantActions.deleteTenantSuccess, (state, { tenantId }) => ({
    ...state,
    items: (state.items || []).filter(x => x.id !== tenantId),
    paginatedItems: (state.paginatedItems || []).filter(x => x.id !== tenantId),
    savingItem: false,
  })),
  on(TenantActions.deleteTenantFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(TenantActions.loadReimbursementTenants, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(TenantActions.loadReimbursementTenantsSuccess, (state, { tenants }) => ({
    ...state,
    reimbursementItems: tenants,
    loadingItems: false,
  })),
  on(TenantActions.loadReimbursementTenantsFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),
);

export function tenantReducer(state: TenantState | undefined, action: Action) {
  return reducer(state, action);
}
