import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { concatMap, filter, map, share, take, withLatestFrom } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { ToastService } from 'app/services/toast.service';
import { HydraCollection, Service, ServiceProvider, State, Tenant } from 'app/store/models';
import { selectCurrentTenant } from 'app/store/selectors';
import { notNull } from 'app/utils/rxjs';

interface PairingParams {
  clientApp: string;
  userCompany: string;
  userCompanyName: string | null;
  userCompanyVatNumber: string | null;
  fundsTransfer: boolean | null;
  callback: string | null;
}

interface PairingResponse {
  id: string;
  tenant: string;
  oAuthClient: string;
  externalId: string;
  externalCompanyName: string | null;
  fundsTransferCallbackUrl: string | null;
}

@Component({
  selector: 'app-pairing',
  templateUrl: './pairing.component.html',
})
export class PairingComponent {
  params$: Observable<PairingParams | null>;
  serviceList$: Observable<Service[] | null>;
  companyInfo: ServiceProvider | null = null;
  companyMisconfigured: boolean = false;
  savingTenant = false;
  errorMessage: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private sanitizer: DomSanitizer,
    private toastr: ToastService,
    private store: Store<State>,
    private translate: TranslateService,
  ) {
    this.params$ = this.route.queryParams.pipe(
      map(ps => {
        // Lowercase queryParams keys
        const c = {} as { [k: string]: string };
        Object.keys(ps).forEach(k => c[k.toLowerCase()] = ps[k]);
        return c;
      }),
      map(({
        clientapplicationid, usercompanyid, usercompanyname, usercompanyvatnumber, callbackurl, fundstransfer,
      }) => {
        if (!clientapplicationid || !usercompanyid) {
          return null;
        }
        return {
          clientApp: clientapplicationid,
          userCompany: usercompanyid,
          userCompanyName: usercompanyname || null,
          userCompanyVatNumber: usercompanyvatnumber || null,
          fundsTransfer: fundstransfer ? (fundstransfer === '1') : null,
          callback: callbackurl || null,
        };
      }),
      take(1),
    );

    this.serviceList$ = this.params$.pipe(
      filter(notNull),
      concatMap(ps => this.api.get<HydraCollection<Service>>({
        path: `/services_for_account_pairing/${ps.clientApp}`,
      })),
      map(res => res['hydra:member']),
      share(),
    );

    this.params$.pipe(
      filter(notNull),
      concatMap(ps => this.api.get<ServiceProvider>({
        path: `/service_providers/${ps.clientApp}/for_account_pairing`,
      })),
      share(),
    ).subscribe(t => {
      this.companyInfo = t;
    });
  }

  authorize() {
    this.savingTenant = true;
    this.errorMessage = null;
    this.params$.pipe(
      filter(notNull),
      take(1),
      withLatestFrom(this.store.select(selectCurrentTenant).pipe(
        filter(notNull),
      )),
      concatMap(([params, tenant]) => this.api.post({
        path: '/provider_auth_links',
        body: {
          oAuthClient: `/api/v1/o_auth_clients/${params.clientApp}`,
          tenant: tenant['@id'],
          externalId: params.userCompany,
          externalCompanyName: params.userCompanyName,
          externalCompanyVatNumber: params.userCompanyVatNumber,
          fundsTransfer: params.fundsTransfer,
        },
      }).pipe(map(response => [response, params] ))),
    ).toPromise().then(
      (payload: any) => {
        const response: PairingResponse = payload[0];
        const params: PairingParams = payload[1];
        if (params.callback) {
          if (response.fundsTransferCallbackUrl !== null) {
            location.assign(params.callback + '?fundsTransferCallback=' + response.fundsTransferCallbackUrl);
          } else {
            location.assign(params.callback);
          }
        } else {
          this.router.navigateByUrl('/');
        }
      },
      (error) => {
        this.savingTenant = false;
        if (error.error && error.error.detail === 'externalCompanyVatNumber: VAT number mismatch.') {
          this.errorMessage = this.translate.instant(
            'We were unable to pair your account because your VAT number in the external application ' +
            'is different from your VAT number in cinvio. We will contact you soon to resolve this.',
          );
        } else {
          this.toastr.showDanger({
            title: 'Something went wrong',
            message: 'Failed to authorize account linking',
          });
        }
      },
    );
  }

  goBack(e?: MouseEvent) {
    if (history.length <= 1) {
      window.close();
    } else {
      history.back();
    }
    if (e) {
      e.stopPropagation();
    }
  }

  sanitize(x: string) {
    return this.sanitizer.bypassSecurityTrustUrl(x);
  }
}
