import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-redirect',
  template: '',
})
export class DashboardRedirectComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit() {
    // We shouldn't get here at all, this should be handled by the router
    this.router.navigateByUrl('/overview/user');
  }
}
