import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { UserNotificationActions } from 'app/store/actions';
import { Notification, State, Tenant, UserContext } from 'app/store/models';
import { NotificationType } from 'app/store/models/notification.model';
import { selectCurrentTenant, selectCurrentUserContext } from 'app/store/selectors';
import { notNull, untilDestroyed } from 'app/utils/rxjs';

@Component({
  selector: 'app-settings-notifications',
  templateUrl: './settings-notifications.component.html',
})
export class SettingsNotificationsComponent implements OnDestroy {
  loadingNotifications$: Observable<boolean>;
  lowBalanceNotification?: Notification;
  subscriptionRenewalBalanceNotification?: Notification;
  subscriptionTokensNotification?: Notification;
  payoutNotification?: Notification;
  currentTenant: Tenant | null = null;
  currentUserContext: UserContext | null = null;
  notificationsForm: UntypedFormGroup;
  saveFlags: boolean[] = [];

  constructor(
    private store: Store<State>,
    private fb: UntypedFormBuilder,
    private actions$: Actions,
  ) {
    this.loadingNotifications$ = this.store.select(x => x.notification.loadingItems);
    this.saveFlags = [false, false, false, false];
    this.notificationsForm = this.fb.group({
      lowBalance: [null],
      lowBalanceThreshold: [null, Validators.required],
      subscriptionRenewalBalance: [null],
      subscriptionTokens: [null],
      payout: [null],
    });
    this.notificationsForm.reset();
    this.notificationsForm.controls['lowBalance'].disable();
    this.notificationsForm.controls['lowBalanceThreshold'].disable();
    this.notificationsForm.controls['subscriptionRenewalBalance'].disable();
    this.notificationsForm.controls['subscriptionTokens'].disable();
    this.notificationsForm.controls['payout'].disable();

    // load each notification separately here and patch the form
    this.store.select(x => x.notification.items).pipe(
      untilDestroyed(this),
      filter(notNull),
      startWith([]),
    ).subscribe((notifications: Notification[]) => {
      this.notificationsForm.controls['lowBalance'].enable();
      this.lowBalanceNotification = notifications.find(notification => notification.type === NotificationType.LOW_BALANCE);
      if (this.lowBalanceNotification) {
        this.notificationsForm.patchValue({
          lowBalance: true,
          lowBalanceThreshold: this.lowBalanceNotification.data['threshold'],
        });
        this.notificationsForm.controls['lowBalanceThreshold'].enable();
      }

      this.subscriptionRenewalBalanceNotification = notifications.find(notification => notification.type === NotificationType.SUBSCRIPTION_RENEWAL_BALANCE);
      if (this.subscriptionRenewalBalanceNotification) {
        this.notificationsForm.patchValue({
          subscriptionRenewalBalance: true,
        });
      }
      this.notificationsForm.controls['subscriptionRenewalBalance'].enable();

      this.subscriptionTokensNotification = notifications.find(notification => notification.type === NotificationType.SUBSCRIPTION_TOKENS);
      if (this.subscriptionTokensNotification) {
        this.notificationsForm.patchValue({
          subscriptionTokens: true,
        });
      }
      this.notificationsForm.controls['subscriptionTokens'].enable();

      this.payoutNotification = notifications.find(notification => notification.type === NotificationType.PAYOUT);
      if (this.payoutNotification) {
        this.notificationsForm.patchValue({
          payout: true,
        });
      }
      this.notificationsForm.controls['payout'].enable();
    });

    this.store.select(selectCurrentTenant).pipe(
      untilDestroyed(this),
      filter(notNull),
    ).subscribe(tenant => {
      this.currentTenant = tenant;
    });

    this.store.select(selectCurrentUserContext).pipe(
      untilDestroyed(this),
      filter(notNull),
    ).subscribe(userContext => {
      this.currentUserContext = userContext;
    });

    this.actions$.pipe(
      ofType(
        UserNotificationActions.createNotificationSuccess,
        UserNotificationActions.createNotificationFailure,
        UserNotificationActions.updateNotificationSuccess,
        UserNotificationActions.updateNotificationFailure,
        UserNotificationActions.deleteNotificationSuccess,
        UserNotificationActions.deleteNotificationFailure,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      this.saveFlags.fill(false);
    });

    this.store.dispatch(UserNotificationActions.loadNotifications());
  }

  onNotificationsSubmit(formKey: number) {
    if (this.notificationsForm.invalid) {
      this.notificationsForm.markAllAsTouched();
      return;
    }

    // store each notification separately here
    if (formKey === 0 && this.lowBalanceNotification) {
      this.saveFlags[formKey] = true;
      if (this.notificationsForm.value.lowBalance) {
        this.store.dispatch(UserNotificationActions.updateNotification({
          notification: {
            id: this.lowBalanceNotification.id,
            data: { threshold: this.notificationsForm.value.lowBalanceThreshold },
          },
        }));
      } else {
        this.store.dispatch(UserNotificationActions.deleteNotification({
          notificationId: this.lowBalanceNotification.id,
        }));
      }
    } else if (formKey === 0 && this.notificationsForm.value.lowBalance) {
      this.saveFlags[formKey] = true;
      this.store.dispatch(UserNotificationActions.createNotification({
        notification: {
          type: NotificationType.LOW_BALANCE,
          data: { threshold: this.notificationsForm.value.lowBalanceThreshold },
        },
      }));
    }

    if (formKey === 1 && this.subscriptionRenewalBalanceNotification) {
      this.saveFlags[formKey] = true;
      if (this.notificationsForm.value.subscriptionRenewalBalance) {
        this.store.dispatch(UserNotificationActions.updateNotification({
          notification: {
            id: this.subscriptionRenewalBalanceNotification.id,
            data: { },
          },
        }));
      } else {
        this.store.dispatch(UserNotificationActions.deleteNotification({
          notificationId: this.subscriptionRenewalBalanceNotification.id,
        }));
      }
    } else if (formKey === 1 && this.notificationsForm.value.subscriptionRenewalBalance) {
      this.saveFlags[formKey] = true;
      this.store.dispatch(UserNotificationActions.createNotification({
        notification: {
          type: NotificationType.SUBSCRIPTION_RENEWAL_BALANCE,
          data: { },
        },
      }));
    }

    if (formKey === 2 && this.subscriptionTokensNotification) {
      this.saveFlags[formKey] = true;
      if (this.notificationsForm.value.subscriptionTokens) {
        this.store.dispatch(UserNotificationActions.updateNotification({
          notification: {
            id: this.subscriptionTokensNotification.id,
            data: { },
          },
        }));
      } else {
        this.store.dispatch(UserNotificationActions.deleteNotification({
          notificationId: this.subscriptionTokensNotification.id,
        }));
      }
    } else if (formKey === 2 && this.notificationsForm.value.subscriptionTokens) {
      this.saveFlags[formKey] = true;
      this.store.dispatch(UserNotificationActions.createNotification({
        notification: {
          type: NotificationType.SUBSCRIPTION_TOKENS,
          data: { },
        },
      }));
    }

    if (formKey === 3 && this.payoutNotification) {
      this.saveFlags[formKey] = true;
      if (this.notificationsForm.value.payout) {
        this.store.dispatch(UserNotificationActions.updateNotification({
          notification: {
            id: this.payoutNotification.id,
            data: { },
          },
        }));
      } else {
        this.store.dispatch(UserNotificationActions.deleteNotification({
          notificationId: this.payoutNotification.id,
        }));
      }
    } else if (formKey === 3 && this.notificationsForm.value.payout) {
      this.saveFlags[formKey] = true;
      this.store.dispatch(UserNotificationActions.createNotification({
        notification: {
          type: NotificationType.PAYOUT,
          data: { },
        },
      }));
    }
  }

  toggleLowBalance(e: any) {
    if (e.target.checked) {
      this.notificationsForm.controls['lowBalanceThreshold'].enable();
    } else {
      this.notificationsForm.controls['lowBalanceThreshold'].disable();
      this.notificationsForm.controls['lowBalanceThreshold'].setValue(null);
    }
  }

  enablePayoutNotification() {
    return (this.currentTenant && (!!this.currentTenant.isServiceProvider || !!this.currentTenant.isLegalEntity));
  }

  ngOnDestroy() { }
}
