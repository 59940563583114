import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, DocumentActions } from 'app/store/actions';
import { DocumentState } from 'app/store/models';

const empty: DocumentState = {
  items: null,
  purchaseOrders: null,
  totalItems: null,
  loadingItems: false,
};

const reducer = createReducer<DocumentState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(DocumentActions.loadDocuments, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(DocumentActions.loadDocumentsSuccess, (state, { items }) => ({
    ...state,
    loadingItems: false,
    items: items,
  })),
  on(DocumentActions.loadDocumentsFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(DocumentActions.loadPurchaseOrders, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(DocumentActions.loadPurchaseOrdersSuccess, (state, { items }) => ({
    ...state,
    loadingItems: false,
    purchaseOrders: items,
  })),
  on(DocumentActions.loadPurchaseOrdersFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(DocumentActions.resetDocuments, (state) => ({
    ...state,
    items: [],
    totalItems: 0,
  })),
);

export function documentReducer(state: DocumentState | undefined, action: Action) {
  return reducer(state, action);
}
