import { formatDate } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { I18nService } from 'app/services/i18n.service';
import { State, Subscription } from 'app/store/models';
import { notNull, untilDestroyed } from 'app/utils/rxjs';
import { SubscriptionActions } from 'app/store/actions';

import { ConfirmActionModalComponent } from './confirm-action-modal.component';
import { SubscriptionUpgradeModalComponent } from './subscription-upgrade-modal.component';
import { SubscriptionService } from 'app/store/models/subscription.model';

@Component({
  selector: 'app-my-subscriptions',
  templateUrl: './my-subscriptions.component.html',
  styleUrls: ['./my-subscriptions.component.scss'],
})
export class MySubscriptionsComponent implements OnDestroy {
  loadingSubscriptions$: Observable<boolean>;
  subscriptions$: Observable<Subscription[]>;
  saveFlags: { [key: string]: boolean } = {};
  modalInstance: any;

  constructor(
    private store: Store<State>,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private modalService: NgbModal,
    private i18nService: I18nService,
  ) {
    this.loadingSubscriptions$ = this.store.select(x => x.subscription.loadingItems);

    this.subscriptions$ = this.store.select(x => x.subscription.items).pipe(
      untilDestroyed(this),
      filter(notNull),
      startWith([]),
    );
    this.subscriptions$.subscribe(items => items.forEach(item => this.saveFlags[item.id] = false));

    this.store.dispatch(SubscriptionActions.loadSubscriptions());
  }

  allowCancel(subscription: Subscription): boolean
  {
    return (subscription.status === 'expired' || subscription.status === 'active') && !subscription.activeUntil && !!subscription.renewsOn && !subscription.scheduledUpgrade;
  }

  allowReactivate(subscription: Subscription): boolean
  {
    return (subscription.status === 'expired' || subscription.status === 'active') && !!subscription.activeUntil && !subscription.renewsOn && !subscription.scheduledUpgrade;
  }

  allowUpgrade(subscription: Subscription): boolean
  {
    if (subscription.status !== 'active' || subscription.activeUntil || subscription.scheduledUpgrade || !subscription.upgradeTiers) {
      return false;
    }

    let allowUpgrade = false;
    subscription.upgradeTiers.forEach(t => {
      if (t.allowUpgrade) {
        allowUpgrade = true;
      }
    });
    return allowUpgrade;
  }

  allowCancelUpgrade(subscription: Subscription): boolean
  {
    return subscription.status === 'active' && subscription.scheduledUpgrade !== null;
  }

  openSubscriptionUpgradeModal(subscription: Subscription) {
    const modalRef = this.modalService.open(SubscriptionUpgradeModalComponent, { size: 'lg' });
    modalRef.componentInstance.setSubscription(subscription);
    modalRef.componentInstance.setUpgradeTiers(subscription.upgradeTiers);
    modalRef.result.then(() => {
    }, () => {});
  }

  cancelSubscription(subscription: Subscription): void {
    if (subscription.renewsOn === null) {
      return;
    }
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    const renewalDate = new Date(subscription.renewsOn);
    modalRef.componentInstance.title = this.translate.instant('Cancel subscription');
    modalRef.componentInstance.message =
      '<p>' + this.translate.instant('You are about to cancel the following subscription') + ':</p>' +
      '<p><u><b>' + subscription.subscriptionTemplateTier.subscriptionTemplate.label + '</p></u></b>';
    if (subscription.status !== 'expired') {
      modalRef.componentInstance.message += '<p>' + this.translate.instant('If you confirm, your subscription will continue to be active until <b>{{ date }}</b>.', { date: formatDate(renewalDate, 'longDate', this.i18nService.language) }) + '</p>';
    }
    modalRef.componentInstance.confirmButtonText = marker('Confirm');
    modalRef.result.then(
      () => { this.saveFlags[subscription.id] = true; this.store.dispatch(SubscriptionActions.cancelSubscription({ id: subscription.id})); },
      () => {},
    );
  }

  reactivateSubscription(subscription: Subscription): void {
    if (subscription.activeUntil === null) {
      return;
    }
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    const renewalDate = new Date(subscription.activeUntil);
    modalRef.componentInstance.title = this.translate.instant('Reactivate subscription');
    modalRef.componentInstance.message =
      '<p>' + this.translate.instant('You are about to reactivate the following subscription') + ':</p>' +
      '<p><u><b>' + subscription.subscriptionTemplateTier.subscriptionTemplate.label + '</p></u></b>';
    if (subscription.status !== 'expired') {
      modalRef.componentInstance.message += '<p>' + this.translate.instant('If you confirm, your subscription will continue to be active after <b>{{ date }}</b>.', { date: formatDate(renewalDate, 'longDate', this.i18nService.language) }) + '</p>';
    }
    modalRef.componentInstance.confirmButtonText = marker('Confirm');
    modalRef.result.then(
      () => { this.saveFlags[subscription.id] = true; this.store.dispatch(SubscriptionActions.reactivateSubscription({ id: subscription.id})); },
      () => {},
    );
  }

  cancelScheduledUpgrade(subscription: Subscription): void {
    if (subscription.renewsOn === null) {
      return;
    }
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.title = this.translate.instant('Cancel upgrade');
    modalRef.componentInstance.message =
      '<p>' + this.translate.instant('Are you sure you want to cancel your scheduled upgrade? You will still have the option to upgrade to a different plan at any time in the future.') + '</p>';
    modalRef.componentInstance.confirmButtonText = marker('Confirm');
    modalRef.result.then(
      () => { this.saveFlags[subscription.id] = true; this.store.dispatch(SubscriptionActions.cancelScheduledUpgrade({ id: subscription.id})); },
      () => {},
    );
  }

  getSubscriptionServices(subscription: Subscription): SubscriptionService[] {
    let services = subscription.subscriptionServices;
    return services.filter(s => !s.deleted);
  }

  sanitize(x: string) {
    return this.sanitizer.bypassSecurityTrustUrl(x);
  }

  ngOnDestroy() { }
}
