import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { from, of, Subject } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { ToastService } from 'app/services/toast.service';
import { CommonActions, SessionActions } from 'app/store/actions';
import { HydraCollection, State, Tenant, UserContext } from 'app/store/models';
import { selectOriginalUrl } from 'app/store/selectors';

@Injectable()
export class SessionEffects {
  destroy$ = new Subject<boolean>();

  constructor(
    private api: ApiService,
    private actions$: Actions,
    private router: Router,
    private toastr: ToastService,
    private store: Store<State>,
  ) { }

  initSession$ = createEffect(() => this.actions$.pipe(
    ofType(SessionActions.initSession),
    switchMap(({ session }) => {
      const out: Action[] = [];
      if (session && session.token) {
        out.push(SessionActions.loadUserTenants());
      }
      if (session && session.currentTenantId) {
        out.push(SessionActions.loadUserContext());
      }
      return out;
    }),
  ));

  login$ = createEffect(() => this.actions$.pipe(
    ofType(SessionActions.login),
    switchMap(action => {
      const params = new HttpParams()
        .append('username', action.mail)
        .append('password', action.pass);
      return this.api.getToken(params).pipe(
        map(token => SessionActions.loginSuccess({ token })),
        catchError(error => of(SessionActions.loginFailure(error))),
      );
    }),
  ));

  refresh$ = createEffect(() => this.actions$.pipe(
    ofType(SessionActions.refresh),
    switchMap((action) => {
      return this.api.refreshToken(
        new URLSearchParams({ refresh_token: action.refreshToken }),
      ).pipe(
        map(token => SessionActions.refreshSuccess({ token })),
        catchError(err => {
          return from([
            SessionActions.logout(),
            SessionActions.refreshFailure(err),
          ]);
        }),
      );
    }),
  ));

  register$ = createEffect(() => this.actions$.pipe(
    ofType(SessionActions.register),
    switchMap(({ registration }) => {
      return this.api.post({
        path: '/public/registration_requests',
        body: registration,
      }).pipe(
        map(() => {
          this.router.navigateByUrl('/thank-you');
          return SessionActions.registerSuccess();
        }),
        catchError(err => {
          return of(SessionActions.registerFailure(err));
        }),
      );
    }),
  ));

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(SessionActions.loginSuccess),
    map(() => {
      this.router.navigateByUrl('/select-tenant');
      return SessionActions.loadUserTenants();
    }),
  ));

  pickTenant$ = createEffect(() => this.actions$.pipe(
    ofType(SessionActions.pickTenant),
    withLatestFrom(this.store.select(selectOriginalUrl)),
    map(([_, originalUrl]) => {
      this.router.navigateByUrl(originalUrl || '/overview');
      return SessionActions.loadUserContext();
    }),
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(SessionActions.logout),
    map(() => {
      this.router.navigateByUrl('/login');
      return CommonActions.clearAll();
    }),
  ));

  dropTenant$ = createEffect(() => this.actions$.pipe(
    ofType(SessionActions.dropTenant),
    map(() => {
      this.router.navigateByUrl('/select-tenant');
      return CommonActions.clearAll();
    }),
  ));

  loadTenants$ = createEffect(() => this.actions$.pipe(
    ofType(SessionActions.loadUserTenants),
    concatMap(() => this.api.get<HydraCollection<Tenant>>({
      path: '/me/tenants',
    }).pipe(
      map(data => SessionActions.loadUserTenantsSuccess({ tenants: data['hydra:member'] })),
      catchError(error => from([
        SessionActions.logout(),
        SessionActions.loadUserTenantsFailure(error),
      ])),
    )),
  ));

  loadUserContext$ = createEffect(() => this.actions$.pipe(
    ofType(SessionActions.loadUserContext),
    concatMap(() => this.api.get<UserContext>({
      path: '/me/user_context',
    }).pipe(
      map(data => SessionActions.loadUserContextSuccess({ userContext: data })),
      catchError(error => from([
        SessionActions.logout(),
        SessionActions.loadUserContextFailure(error),
      ])),
    )),
  ));
}
