import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable()
export class ToastService {
  constructor(private toastr: ToastrService, private translate: TranslateService) { }

  showSuccess(toast: { title: string, message?: string }) {
    this.toastr.success(
      toast.message ? this.translate.instant(toast.message) : null,
      toast.title ? this.translate.instant(toast.title) : null,
    );
  }

  showDanger(toast: { title: string, message?: string }) {
    this.toastr.error(
      toast.message ? this.translate.instant(toast.message) : null,
      toast.title ? this.translate.instant(toast.title) : null,
      {
        positionClass: 'toast-top-full-width',
      },
    );
  }

  showWarning(toast: { title: string, message?: string }) {
    this.toastr.warning(
      toast.message ? this.translate.instant(toast.message) : null,
      toast.title ? this.translate.instant(toast.title) : null,
    );
  }

  showInfo(toast: { title: string, message?: string, override?: Partial<IndividualConfig> }) {
    this.toastr.info(
      toast.message ? this.translate.instant(toast.message) : null,
      toast.title ? this.translate.instant(toast.title) : null,
      toast.override
    );
  }

  showInfoLoading(toast: { title: string, message?: string }) {
    this.toastr.info(
      toast.message ? this.translate.instant(toast.message) : null,
      toast.title ? this.translate.instant(toast.title) : null,
      {
        positionClass: 'toast-bottom-full-width',
        progressBar: true,
        progressAnimation: 'increasing',
      },
    );
  }

  clear() {
    this.toastr.clear();
  }
}
