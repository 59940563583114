<div class="pagination" *ngIf="show">
  <button
    class="btn btn-primary me-1"
    (click)="pageSource.next(1)"
    [disabled]="currentPage === 1"
  >
    <i class="far fa-fast-backward"></i>
  </button>
  <button
    class="btn btn-primary me-2"
    (click)="pageSource.next(currentPage - 1)"
    [disabled]="currentPage === 1"
  >
    <i class="far fa-step-backward"></i>
  </button>
  <button
    *ngFor="let i of pages"
    (click)="pageSource.next(i)"
    class="btn btn-primary me-1"
    [ngClass]="{'active': i === currentPage}"
  >
    {{ i }}
  </button>
  <button
    class="btn btn-primary ms-1 me-1"
    (click)="pageSource.next(currentPage + 1)"
    [disabled]="currentPage === totalPages"
  >
    <i class="far fa-step-forward"></i>
  </button>
  <button
    class="btn btn-primary me-1"
    (click)="pageSource.next(totalPages)"
    [disabled]="currentPage === totalPages"
  >
    <i class="far fa-fast-forward"></i>
  </button>
</div>
