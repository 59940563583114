import { makeActions, ofType } from 'app/store/actions/utils';
import { Notification, NotificationForm } from 'app/store/models/notification.model';

export const UserNotificationActions = makeActions({
  loadNotifications: null,
  loadNotificationsSuccess: ofType<{ items: Notification[]; }>(),
  loadNotificationsFailure: ofType<{ payload: any; }>(),

  createNotification: ofType<{ notification: NotificationForm; }>(),
  createNotificationSuccess: ofType<{ notification: Notification; }>(),
  createNotificationFailure: ofType<{ error: any; }>(),

  updateNotification: ofType<{ notification: NotificationForm; }>(),
  updateNotificationSuccess: ofType<{ notification: Notification; }>(),
  updateNotificationFailure: ofType<{ error: any; }>(),

  deleteNotification: ofType<{ notificationId: string; }>(),
  deleteNotificationSuccess: ofType<{ notificationId: string; }>(),
  deleteNotificationFailure: ofType<{ error: any; }>(),
});
