import { makeActions, ofType } from 'app/store/actions/utils';
import {
  RegistrationForm, SessionState, Tenant, Token, UserContext,
} from 'app/store/models';

export const SessionActions = makeActions({
  initSession: ofType<{ session: SessionState | null; }>(),

  login: ofType<{ mail: string; pass: string; }>(),
  loginSuccess: ofType<{ token: Token; }>(),
  loginFailure: ofType<{ payload: any; }>(),

  refresh: ofType<{ refreshToken: string; }>(),
  refreshSuccess: ofType<{ token: Token; }>(),
  refreshFailure: ofType<{ payload: any; }>(),

  register: ofType<{ registration: RegistrationForm; }>(),
  registerSuccess: null,
  registerFailure: ofType<{ payload: any; }>(),

  logout: null,
  pickTenant: ofType<{ tenantId: string; }>(),
  dropTenant: null,

  loadUserTenants: null,
  loadUserTenantsSuccess: ofType<{ tenants: Tenant[]; }>(),
  loadUserTenantsFailure: ofType<{ payload: any; }>(),

  loadUserContext: null,
  loadUserContextSuccess: ofType<{ userContext: UserContext; }>(),
  loadUserContextFailure: ofType<{ payload: any; }>(),
});
