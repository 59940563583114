<div class="modal-header">
  <h4 class="modal-title">{{ 'Let’s upgrade!' | translate }}</h4>
  <button type="button" class="btn-close" tabindex="-1" (click)="handleCancel()">
    <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <p [innerHTML]="message"></p>

  <div class="form">
    <div class="form-check mb-3" *ngIf="upgradeTier?.allowUpgradeNow">
      <input
        id="now"
        name="now"
        class="form-check-input"
        type="radio"
        [(ngModel)]="upgradeOptionsModel"
        value="now"
      />
      <label class="form-label form-check-label" for="now">
        <span class="title"><b>{{ 'Upgrade now' | translate }}</b>: {{ messageNow }}</span>
      </label>
    </div>
    <div class="form-check mb-3" *ngIf="upgradeTier?.allowUpgrade">
      <input
        id="schedule"
        name="schedule"
        class="form-check-input"
        type="radio"
        [(ngModel)]="upgradeOptionsModel"
        value="schedule"
      />
      <label class="form-label form-check-label" for="schedule">
        <span class="title"><b>{{ 'Upgrade in the next period' | translate }}</b>: {{ 'stay on your current subscription till the end of the active period and activate your upgrade in the next.' | translate }}</span>
      </label>
    </div>
  </div>

  <app-loading-button btnType="button"
    (click)="handleConfirm()"
    [isLoading]="upgradingSubscription$ | async"
  >
    {{ 'Confirm' | translate }}
  </app-loading-button>

  <foot-note *ngIf="upgradeTier?.allowUpgradeNow"></foot-note>
</div>
