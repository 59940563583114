import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

export declare const arraysAreNotAllowedMsg = 'arrays are not allowed in action creators';
declare type ArraysAreNotAllowed = typeof arraysAreNotAllowedMsg;
export declare const typePropertyIsNotAllowedMsg = 'type property is not allowed in action creators';
declare type TypePropertyIsNotAllowed = typeof typePropertyIsNotAllowedMsg;
export declare type NotAllowedCheck<T> = T extends any[] ? ArraysAreNotAllowed : T extends {
  type: any;
} ? TypePropertyIsNotAllowed : unknown;

type Creators<R> = {
  [K in Extract<keyof R, string>]:
  R[K] extends null
  ? ActionCreator<K, () => TypedAction<K>>
  : ActionCreator<K, (props: R[K]) => R[K] & TypedAction<K>>;
};
export function makeActions<R extends {
  [K in keyof R]: NotAllowedCheck<R[K]>;
}>(defs: R): Creators<R> {
  const cs = {} as Creators<R>;
  for (const k in defs) {
    if (defs.hasOwnProperty(k)) {
      if (defs[k] === null) {
        cs[k] = createAction(k) as any;
      } else {
        cs[k] = createAction(k, defs[k] as any) as any;
      }
    }
  }
  return cs;
}

export const ofType = <T extends object>() => ({ _as: 'props' } as any as T);
