import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, UserTransactionActions } from 'app/store/actions';
import { UserTransactionState } from 'app/store/models';

const empty: UserTransactionState = {
  items: null,
  currentPage: null,
  totalItems: null,
  loading: false,
};

const reducer = createReducer<UserTransactionState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(UserTransactionActions.loadTransactions, (state) => ({
    ...state,
    loading: true,
    items: null,
    totalItems: null,
  })),
  on(UserTransactionActions.loadTransactionsSuccess, (state, { items, totalItems }) => ({
    ...state,
    loading: false,
    totalItems,
    items,
  })),
  on(UserTransactionActions.loadTransactionsFailure, (state) => ({
    ...state,
    loading: false,
  })),
);

export function userTransactionReducer(state: UserTransactionState | undefined, action: Action) {
  return reducer(state, action);
}
