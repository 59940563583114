<h2>{{ 'Countries' | translate }}</h2>
<button class="btn btn-link" (click)="newCountry()">
  {{ 'New country' | translate }}
</button>
<table class="table">
  <tr>
    <th>{{ 'Country' | translate }}</th>
    <th>{{ 'Code' | translate }}</th>
    <th>{{ 'Legal entity' | translate }}</th>
    <th>{{ 'Payment providers' | translate }}</th>
    <th>{{ 'Company identifier type' | translate }}</th>
    <th></th>
  </tr>
  <tr *ngFor="let country of countries$ | async | sortOn: 'code'">
    <td><span class="fi fi-{{country.code | lowercase}}"></span></td>
    <td>{{country.code}}</td>
    <td>{{ country.legalEntity?.name }}</td>
    <td>
      <span *ngFor="let paymentProvider of country.paymentProviders ; last as isLast">
        {{ paymentProvider.label }}<span *ngIf="!isLast">, </span>
      </span>
    </td>
    <td>{{ 'label_' + country.companyIdentifierType | translate }}</td>
    <td>
      <button *ngIf="!country.deleted" class="btn btn-sm btn-link" (click)="editCountry(country)">
        {{ 'Edit country' | translate }}
      </button>
      <button *ngIf="!country.deleted" class="btn btn-sm btn-link" (click)="deactivateCountry(country)">
        {{ 'Deactivate country' | translate }}
      </button>
      <button *ngIf="country.deleted" class="btn btn-sm btn-link" (click)="activateCountry(country)">
        {{ 'Activate country' | translate }}
      </button>
    </td>
  </tr>
</table>

<ng-template #countryModal let-self>
  <div class="modal-header">
    <h4 *ngIf="!activeCountry" class="modal-title">{{ 'Add new country' | translate }}</h4>
    <h4 *ngIf="activeCountry" class="modal-title">{{ 'Edit country' | translate }} <span class="fi fi-{{activeCountry.code | lowercase}}"></span></h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      (ngSubmit)="activeCountry ? onEditCountrySubmit() : onCreateCountrySubmit()"
      [formGroup]="countryForm"
      class="p-3"
    >
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Code' | translate }}</label>
            <input class="form-control" formControlName="code" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Legal entity' | translate }}</label>
            <ng-select
              formControlName="legalEntity"
              [items]="legalEntityOptions$ | async"
              bindLabel="label"
              bindValue="value"
            ></ng-select>
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Payment providers' | translate }}</label>
            <ng-select
              formControlName="paymentProviders"
              [items]="paymentProviderOptions$ | async"
              [clearable]="false"
              bindLabel="label"
              bindValue="value"
              [multiple]="true"
            ></ng-select>
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Company identifier type' | translate }}</label>
            <ng-select
              formControlName="companyIdentifierType"
              [items]="companyIdentifierTypeOptions"
              bindLabel="label"
              bindValue="value"
            >
              <ng-template ng-label-tmp let-item="item">
                {{ item.label | translate }}
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                {{ item.label | translate }}
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
      <app-loading-button btnType="submit" [isLoading]="savingCountry$ | async">
        {{ 'Submit' | translate }}
      </app-loading-button>
    </form>
  </div>
</ng-template>
