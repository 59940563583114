import { makeActions, ofType } from 'app/store/actions/utils';
import { Service, ServiceConfiguration, Tenant } from 'app/store/models';

export const ServiceConfigurationActions = makeActions({
  loadServicesForConfigurator: null,
  loadServicesForConfiguratorSuccess: ofType<{ items: Service[]; }>(),
  loadServicesForConfiguratorFailure: ofType<{ payload: any; }>(),

  loadTenantsForConfigurator: null,
  loadTenantsForConfiguratorSuccess: ofType<{ items: Tenant[]; }>(),
  loadTenantsForConfiguratorFailure: ofType<{ payload: any; }>(),

  updateServiceConfiguration: ofType<{ id: string, configuration: ServiceConfiguration; }>(),
  updateServiceConfigurationSuccess: ofType<{ service: Service; }>(),
  updateServiceConfigurationFailure: ofType<{ error: any; }>(),
});
