import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { group, NonEmptyArray } from 'fp-ts/es6/NonEmptyArray';
import { interval, Observable, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { LazyLoaderService } from 'app/services/lazy-loader.service';
import { SessionActions } from 'app/store/actions';
import { Account, AccountType, Service, ServiceProvider, State } from 'app/store/models';
import { byServiceProvider } from 'app/store/models/service.model';
import { selectCurrentTenant, selectPaymentMethodsLoading } from 'app/store/selectors';
import { notNull, untilDestroyed } from 'app/utils/rxjs';
import { PaymentMethod } from 'app/store/models/payment-provider.model';

@Component({
  selector: 'app-dashboard-user',
  templateUrl: './dashboard-user.component.html',
  styleUrls: ['./dashboard-user.component.scss']
})
export class DashboardUserComponent implements OnDestroy, OnInit {
  account$: Observable<Account>;
  linkedServices$: Observable<NonEmptyArray<Service>[]>;
  unlinkedServices$: Observable<NonEmptyArray<Service>[]>;
  subProviders$: Observable<ServiceProvider[]>;
  latestTransactionTime: Date = new Date();
  autoRefreshSubscription?: Subscription;
  payLater: boolean = false;
  paymentMethods$: Observable<PaymentMethod[]>;
  availableMethodsLoading$: Observable<boolean> = this.store.select(selectPaymentMethodsLoading);

  constructor(
    private store: Store<State>,
    private modalService: NgbModal,
    private loader: LazyLoaderService,
    private api: ApiService,
  ) {
    this.linkedServices$ = this.loader.getLinkedServices().pipe(map(group(byServiceProvider)));
    this.unlinkedServices$ = this.loader.getUnlinkedServices().pipe(map(group(byServiceProvider)));
    this.subProviders$ = this.loader.getSubProviders();
    this.account$ = this.store.select(selectCurrentTenant).pipe(
      filter(notNull),
      map(accs => accs.accounts.find(a => a.type === AccountType.SPENDER) || null),
      filter(notNull),
    );

    this.paymentMethods$ = this.store.select(x => x.admin.paymentProvider.availablePaymentMethods).pipe(
      untilDestroyed(this),
      filter(notNull),
      startWith([]),
    );

    // setup auto-refresh
    this.autoRefreshSubscription = interval(5000).subscribe(() => {
      this.api.get<{ exists: boolean }>({
        path: `/new-transaction-exists?since=${this.latestTransactionTime.toISOString()}`,
      }).toPromise().then(res => {
        if (res.exists) {
          this.store.dispatch(SessionActions.loadUserContext());
          this.latestTransactionTime = new Date();
        }
      }, () => { });
    });
  }

  showRecharge(modal: TemplateRef<any>) {
    this.modalService.open(modal, { size: 'lg' });
  }

  ngOnInit() {
    // Refresh account state
    this.store.dispatch(SessionActions.loadUserContext());
  }

  handlePaymentChoiceChange(value: boolean) {
    this.payLater = value;
  }

  ngOnDestroy() {
    if (this.autoRefreshSubscription) {
      this.autoRefreshSubscription.unsubscribe();
      this.autoRefreshSubscription = undefined;
    }
  }

  getPayLater(methods: PaymentMethod[] | null): boolean {
    return !!methods?.find(method => method.paymentProvider.code === 'BNPL')
  }
}
