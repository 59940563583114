import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { UserActions } from 'app/store/actions';
import { HydraCollection, User } from 'app/store/models';

@Injectable()
export class UserEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadUsers$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.loadUsers),
    concatMap(() => this.api.get<HydraCollection<User>>({
      path: '/users',
    }).pipe(
      map(data => UserActions.loadUsersSuccess({ users: data['hydra:member'] })),
      catchError(err => of(UserActions.loadUsersFailure(err))),
    )),
  ));

  createUser$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.createUser),
    concatMap(() => this.api.post<User>({
      path: '/users',
    }).pipe(
      map(user => UserActions.createUserSuccess({ user })),
      catchError(err => of(UserActions.createUserFailure(err))),
    )),
  ));

  updateUser$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.updateUser),
    concatMap(({ user }) => this.api.put<User>({
      path: `/users/${user.id}`,
      body: user,
    }).pipe(
      map(data => UserActions.updateUserSuccess({ user: data })),
      catchError(err => of(UserActions.updateUserFailure(err))),
    )),
  ));

  deleteUser$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.deleteUser),
    concatMap(({ userId }) => this.api.delete({
      path: `/users/${userId}`,
    }).pipe(
      map(() => UserActions.deleteUserSuccess({ userId })),
      catchError(err => of(UserActions.deleteUserFailure(err))),
    )),
  ));
}
