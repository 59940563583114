import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { AppConfig } from 'app/app.config';
import { Token } from 'app/store/models';

interface Headers {
  [key: string]: string;
}
export interface ApiCall {
  path: string;
  params?: { [key: string]: any };
  body?: any;
}

@Injectable()
export class ApiService {
  constructor(private http: HttpClient, private config: AppConfig) { }

  get apiUrl() {
    return `${this.config.getConfig('apiUrl')}/api/${this.config.getConfig('apiVersion')}`;
  }

  public get<T>(options: ApiCall, headers: Headers = {}): Observable<T> {
    return this.http.get<T>(
      this.apiUrl + options.path,
      { observe: 'body', responseType: 'json', headers },
    );
  }

  public getBlob(options: ApiCall, headers: Headers = {}): Observable<Blob> {
    return this.http.get(
      this.apiUrl + options.path,
      { observe: 'body', responseType: 'blob', headers },
    );
  }

  public getBlobWithResponse(options: ApiCall, headers: Headers = {}): Observable<HttpResponse<Blob>> {
    return this.http.get(
      this.apiUrl + options.path,
      { observe: 'response', responseType: 'blob', headers },
    );
  }

  public post<T>(options: ApiCall, headers: Headers = {}): Observable<T> {
    if (options.params && options.params.headers) {
      headers = { ...headers, ...options.params.headers };
    }
    return this.http.post<T>(
      this.apiUrl + options.path,
      options.body,
      { ...options.params, observe: 'body', responseType: 'json', headers },
    );
  }

  public put<T>(options: ApiCall, headers: Headers = {}): Observable<T> {
    if (options.params && options.params.headers) {
      headers = { ...headers, ...options.params.headers };
    }
    return this.http.put<T>(
      this.apiUrl + options.path,
      options.body,
      { ...options.params, observe: 'body', responseType: 'json', headers },
    );
  }

  public delete<T>(options: ApiCall): Observable<T> {
    return this.http.delete<T>(
      this.apiUrl + options.path,
      { ...options.params, observe: 'body', responseType: 'json' },
    );
  }

  public refreshToken(body: URLSearchParams): Observable<Token> {
    const clientId = this.config.getConfig('clientId');
    const clientSecret = this.config.getConfig('clientSecret');
    const oauthUrl = this.config.getConfig('oauthUrl');
    if (!clientId || !clientSecret || !oauthUrl) {
      return throwError('Failed to load config');
    }
    body.append('grant_type', 'refresh_token');
    body.append('client_id', clientId);
    body.append('client_secret', clientSecret);
    return this.http.post<Token>(oauthUrl, body.toString(), {
      headers: {
        Authorization: 'Bearer ',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }

  public getToken(options: HttpParams): Observable<Token> {
    const clientId = this.config.getConfig('clientId');
    const clientSecret = this.config.getConfig('clientSecret');
    const oauthUrl = this.config.getConfig('oauthUrl');
    if (!clientId || !clientSecret || !oauthUrl) {
      return throwError('Failed to load config');
    }
    options = options
      .append('grant_type', 'password')
      .append('client_id', clientId)
      .append('client_secret', clientSecret);
    return this.http.post<Token>(oauthUrl, options.toString(), {
      headers: {
        Authorization: 'Bearer ',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }
}
