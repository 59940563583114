<div class="row fullscreen justify-content-center align-items-center">
  <div *ngIf="noTenant" class="alert alert-danger" role="alert">
    <i class="far fa-info-circle"></i>
    {{
      'Your account has been deleted. Please contact other cinvio users within your company for more information.'
      | translate
    }}
    <div class="text-end">
      <button (click)="logout()" class="btn btn-link pb-0 pe-0">{{ 'Logout' | translate }}</button>
    </div>
  </div>
  <div *ngIf="!noTenant"  class="col-12 col-md-6 col-lg-4 card p-0">
    <div class="card-title card-title-bb p-3 m-0">
      <h5 data-cy="title"><strong>{{ 'Choose a company' | translate }}</strong></h5>
      <i class="far fa-info-circle"></i>
      <span data-cy="perex">
        {{
          'Your account is linked to multiple companies. Pick the company you want to activate.'
          | translate
        }}
      </span>
      <div class="progress" *ngIf="loading$ | async">
        <div role="progressbar" style="width:100%" class="progress-bar progress-bar-striped progress-bar-animated bg-info"></div>
      </div>
    </div>
    <div class="card-body p-0">
      <ul class="tenants list-group list-group-flush" data-cy="tenant-list">
        <button
          *ngFor="let tenant of tenants$ | async | sortOn: 'name'"
          [tabindex]="tenant.id"
          class="list-group-item list-group-item-action list-group-item-light"
          (click)="pickTenant(tenant.id)"
        >
          {{ tenant.name }}
        </button>
      </ul>
    </div>
    <div class="card-footer text-muted text-end">
      <button (click)="logout()" class="btn btn-small btn-default" data-cy="logout-button">{{ 'Logout' | translate }}</button>
    </div>
  </div>
</div>
