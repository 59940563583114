import { Component } from '@angular/core';
import { group, NonEmptyArray } from 'fp-ts/es6/NonEmptyArray';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LazyLoaderService } from 'app/services/lazy-loader.service';
import { Service, ServiceProvider } from 'app/store/models';
import { byServiceProvider } from 'app/store/models/service.model';

@Component({
  selector: 'app-service-page',
  templateUrl: './service-page.component.html',
  styleUrls: ['./service-page.component.scss'],
})
export class ServicePageComponent {
  linkedServices$: Observable<NonEmptyArray<Service>[]>;
  unlinkedServices$: Observable<NonEmptyArray<Service>[]>;
  subProviders$: Observable<ServiceProvider[]>;
    
  constructor(private loader: LazyLoaderService) {
    this.linkedServices$ = this.loader.getLinkedServices().pipe(map(group(byServiceProvider)));
    this.unlinkedServices$ = this.loader.getUnlinkedServices().pipe(map(group(byServiceProvider)));
    this.subProviders$ = this.loader.getSubProviders();
  }
}
