import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, ServiceProviderActions } from 'app/store/actions';
import { ServiceProviderState } from 'app/store/models';

const empty: ServiceProviderState = {
  items: null,
  loadingItems: false,
  savingItem: false,
};

const reducer = createReducer<ServiceProviderState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(ServiceProviderActions.loadServiceProviders, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(ServiceProviderActions.loadServiceProvidersSuccess, (state, { serviceProviders }) => ({
    ...state,
    items: serviceProviders,
    loadingItems: false,
  })),
  on(ServiceProviderActions.loadServiceProvidersFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(ServiceProviderActions.createServiceProvider, (state) => ({
    ...state,
    savingItem: true,
  })),
  on(ServiceProviderActions.createServiceProviderSuccess, (state, { serviceProvider }) => ({
    ...state,
    items: [...state.items || [], serviceProvider],
    savingItem: false,
  })),
  on(ServiceProviderActions.createServiceProviderFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(ServiceProviderActions.updateServiceProvider, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(ServiceProviderActions.updateServiceProviderSuccess, (state, { serviceProvider }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === serviceProvider.id ? serviceProvider : x),
    savingItem: false,
  })),
  on(ServiceProviderActions.updateServiceProviderFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(ServiceProviderActions.deleteServiceProvider, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(ServiceProviderActions.deleteServiceProviderSuccess, (state, { serviceProviderId }) => ({
    ...state,
    items: (state.items || []).filter(x => x.id !== serviceProviderId),
    savingItem: false,
  })),
  on(ServiceProviderActions.deleteServiceProviderFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),
);

export function serviceProviderReducer(state: ServiceProviderState | undefined, action: Action) {
  return reducer(state, action);
}
