import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { State } from 'app/store/models';

@Injectable()
export class NotLoggedInGuard  {
  constructor(
    private store: Store<State>,
    private router: Router,
  ) { }

  canActivate(): Observable<boolean> {
    return this.store.select(x => x.session).pipe(map(session => {
      if (session.token) {
        if (session.currentTenantId) {
          this.router.navigateByUrl('/overview');
        } else {
          this.router.navigateByUrl('/select-tenant');
        }
        return false;
      }
      return true;
    }));
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }
}
