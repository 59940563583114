<h2>
  {{ 'Users' | translate }}
  <button class="btn btn-link pb-0" (click)="newUser(addEditUserModal)">
    {{ 'Add User' | translate }}
  </button>
</h2>
<table class="table">
  <tr *ngFor="let userContext of userContexts$ | async | sortOn: 'user.lastName'">
    <td>{{ userContext.user.firstName }} {{ userContext.user.lastName }}</td>
    <td>{{ userContext.phone }}</td>
    <td>
      <button class="btn btn-sm btn-link" (click)="editUser(userContext, addEditUserModal)">
        {{ 'Edit User' | translate }}
      </button>
      <button [disabled]="isCurrentUser(userContext)" class="btn btn-sm btn-link" (click)="deleteUser(userContext)">
        {{ 'Delete User' | translate }}
      </button>
    </td>
  </tr>
</table>

<ng-template #addEditUserModal let-self>
  <div class="modal-header">
    <h4 *ngIf="!activeUserContext" class="modal-title">{{ 'Create User' | translate }}</h4>
    <h4 *ngIf="activeUserContext" class="modal-title">
      {{ 'Edit User' | translate }} {{ activeUserContext?.user.firstName }}  {{ activeUserContext?.user.lastName }}
    </h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="p-3"
      [formGroup]="userContextForm"
      (ngSubmit)="activeUserContext ? onEditUserSubmit() : onNewUserSubmit()"
    >
      <div class="mb-3">
        <label class="form-label center-block">{{ 'E-mail' | translate }}</label>
        <input class="form-control" formControlName="email" />
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'First Name' | translate }}</label>
        <input class="form-control" formControlName="firstName" />
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Last Name' | translate }}</label>
        <input class="form-control" formControlName="lastName" />
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Phone Number' | translate }}</label>
        <input class="form-control" formControlName="phoneNumber" />
      </div>
      <app-loading-button btnType="submit" [isLoading]="savingUserContext$ | async">
        {{ 'Submit' | translate }}
      </app-loading-button>
    </form>
  </div>
</ng-template>
