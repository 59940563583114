<div class="modal-header">
  <h4 class="modal-title">{{ 'Let’s upgrade!' | translate }}</h4>
  <button type="button" class="btn-close" tabindex="-1" (click)="handleCancel()">
    <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <p>{{ 'Is your current subscription tier not enough? Select the tier you wish to upgrade to, click on UPGRADE and choose when you wish to start.' | translate }}</p>
  <p *ngIf="!!upgradeTiers"><b>{{ upgradeTiers[0].templateTier.subscriptionTemplate.serviceProvider.name }}</b> - {{ upgradeTiers[0].templateTier.subscriptionTemplate.label }}</p>
  <div class="tiers">
    <div class="tier" *ngFor="let upgradeTier of upgradeTiers">
      <div class="tier__content" [ngClass]="{'tier__content--highlighted': upgradeTier.templateTier.highlighted}">
        <h4>{{ upgradeTier.templateTier.label }}</h4>
        <div class="tier__content--segment">
          <table>
            <tr *ngFor="let subscriptionTemplateTierService of upgradeTier.templateTier.subscriptionTemplateTierServices">
              <th>
                <i class="far" [ngClass]="subscriptionTemplateTierService.service.icon"></i>
                {{ subscriptionTemplateTierService.service.label }}
              </th>
              <td>
                <span *ngIf="subscriptionTemplateTierService.tokens !== null">
                  {{ subscriptionTemplateTierService.tokens }}/{{ (upgradeTier.templateTier.priceMonthly ? 'Month' : 'Year') | translate | lowercase }}
                </span>
                <span *ngIf="subscriptionTemplateTierService.tokens === null">{{ 'Unlimited' | translate}}</span>
              </td>
            </tr>
          </table>
          <div class="form">
            <div class="form-check" *ngIf="upgradeTier.templateTier.priceMonthly && upgradeTier.allowMonthlyBilling">
              <input
                id="{{ 'monthly' + upgradeTier.templateTier.id }}"
                name="{{ 'monthly' + upgradeTier.templateTier.id }}"
                class="form-check-input"
                [ngClass]="{'form-check-input-hidden': !upgradeTier.allowUpgrade || !upgradeTier.templateTier.priceYearly}"
                type="radio"
                [(ngModel)]="billingOptionsModel[upgradeTier.templateTier.id]"
                value="monthly"
              />
              <label class="form-label form-check-label" for="{{ 'monthly' + upgradeTier.templateTier.id }}">
                <money [amount]="upgradeTier.templateTier.priceMonthly" [currency]="upgradeTier.templateTier.subscriptionTemplate.serviceProvider.tenant.currency.code"></money><span class="currency">/{{ 'Month' | translate | lowercase }}*</span>
              </label>
            </div>
            <div class="form-check" *ngIf="upgradeTier.templateTier.priceYearly">
              <input
                id="{{ 'yearly' + upgradeTier.templateTier.id }}"
                name="{{ 'yearly' + upgradeTier.templateTier.id }}"
                class="form-check-input"
                [ngClass]="{'form-check-input-hidden': !upgradeTier.allowUpgrade || !upgradeTier.templateTier.priceMonthly || !upgradeTier.allowMonthlyBilling}"
                type="radio"
                [(ngModel)]="billingOptionsModel[upgradeTier.templateTier.id]"
                value="yearly"
              />
              <label class="form-label form-check-label" for="{{ 'yearly' + upgradeTier.templateTier.id }}">
                <money [amount]="upgradeTier.templateTier.priceYearly" [currency]="upgradeTier.templateTier.subscriptionTemplate.serviceProvider.tenant.currency.code"></money><span class="currency">/{{ 'Year' | translate | lowercase }}*</span>
              </label>
            </div>
          </div>
        </div>
        <div class="tier__content__footer">
          <button
            class="btn btn-primary"
            *ngIf="upgradeTier.allowUpgrade"
            (click)="confirmSubscriptionUpgrade(upgradeTier)"
          >
            {{ 'Upgrade' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <foot-note *ngIf="upgradeTiers?.length"></foot-note>
</div>
