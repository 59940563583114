import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, UserActions } from 'app/store/actions';
import { UserState } from 'app/store/models';

const empty: UserState = {
  items: null,
  loadingItems: false,
  savingItem: false,
};

const reducer = createReducer<UserState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(UserActions.loadUsers, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(UserActions.loadUsersSuccess, (state, { users }) => ({
    ...state,
    items: users,
    loadingItems: false,
  })),
  on(UserActions.loadUsersFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(UserActions.createUser, (state) => ({
    ...state,
    savingItem: true,
  })),
  on(UserActions.createUserSuccess, (state, { user }) => ({
    ...state,
    items: [...state.items || [], user],
    savingItem: false,
  })),
  on(UserActions.createUserFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(UserActions.updateUser, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(UserActions.updateUserSuccess, (state, { user }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === user.id ? user : x),
    savingItem: false,
  })),
  on(UserActions.updateUserFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(UserActions.deleteUser, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(UserActions.deleteUserSuccess, (state, { userId }) => ({
    ...state,
    items: (state.items || []).filter(x => x.id !== userId),
    savingItem: false,
  })),
  on(UserActions.deleteUserFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),
);

export function userReducer(state: UserState | undefined, action: Action) {
  return reducer(state, action);
}
