import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { UserServiceActions } from 'app/store/actions';
import { HydraCollection, Service, ServiceProvider } from 'app/store/models';

@Injectable()
export class UserServiceEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadLinkedServices$ = createEffect(() => this.actions$.pipe(
    ofType(UserServiceActions.loadLinkedServices),
    concatMap(() => this.api.get<HydraCollection<Service>>({
      path: '/linked_services',
    }).pipe(
      map(data => UserServiceActions.loadLinkedServicesSuccess({ items: data['hydra:member'] })),
      catchError(err => of(UserServiceActions.loadLinkedServicesFailure(err))),
    )),
  ));

  loadUnlinkedServices$ = createEffect(() => this.actions$.pipe(
    ofType(UserServiceActions.loadUnlinkedServices),
    concatMap(() => this.api.get<HydraCollection<Service>>({
      path: '/not_linked_services',
    }).pipe(
      map(data => UserServiceActions.loadUnlinkedServicesSuccess({ items: data['hydra:member'] })),
      catchError(err => of(UserServiceActions.loadUnlinkedServicesFailure(err))),
    )),
  ));

  loadSubProviders$ = createEffect(() => this.actions$.pipe(
    ofType(UserServiceActions.loadSubProviders),
    concatMap(() => this.api.get<HydraCollection<ServiceProvider>>({
      path: '/sub_service_providers',
    }).pipe(
      map(data => UserServiceActions.loadSubProvidersSuccess({ items: data['hydra:member'] })),
      catchError(err => of(UserServiceActions.loadSubProvidersFailure(err))),
    )),
  ));
}
