<h2>{{ 'Unprocessed queue items' | translate }}</h2>
<table class="table">
  <thead>
    <tr>
      <th>{{ 'Action' | translate }}</th>
      <th>{{ 'Created At' | translate }}</th>
      <th>{{ 'Customer' | translate }}</th>
      <th>{{ 'Provider' | translate }}</th>
      <th>{{ 'Accountancy period' | translate }}</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of queue$ | async">
      <td>{{ formatAction(item.action) }}</td>
      <td>{{ item.createdAt | date:'dd-MM-yyyy HH:mm:ss' }}</td>
      <td>{{ getCustomer(item) }}</td>
      <td>{{ getProvider(item) }}</td>
      <td>
        <span *ngIf="item.accountancyPeriod">
          {{ item.accountancyPeriod?.start | date:'dd-MM-yyyy' }} -
          {{ item.accountancyPeriod?.end | date:'dd-MM-yyyy' }}
        </span>
      <td>
        <button class="btn btn-sm btn-link" (click)="showQueueItemDetails(item)">
          {{ 'Show details' | translate }}
        </button>
        <app-loading-button btnType="button" btnClass="btn btn-sm btn-link" (click)="retry(item)" [isLoading]="saveFlags[item.id]">
          {{ 'Retry' | translate }}
        </app-loading-button>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #queueItemDetailsModal let-self>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'Queue item details' | translate }}</h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table">
      <tr>
        <th>ID</th>
        <td>{{ activeQueueItem?.id }}</td>
      </tr>
      <tr>
        <th>Error response</th>
        <td>{{ activeQueueItem?.errorResponse }}</td>
      </tr>
      <tr>
        <th>Metadata</th>
        <td>{{ activeQueueItem?.data | json }}</td>
      </tr>
    </table>
  </div>
</ng-template>
