import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, UserStatisticsActions } from 'app/store/actions';
import { UserStatisticsState } from 'app/store/models';

const empty: UserStatisticsState = {
  earnings: null,
  loadingEarnings: false,
};

const reducer = createReducer<UserStatisticsState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(UserStatisticsActions.loadEarnings, (state) => ({
    ...state,
    earnings: null,
    loadingEarnings: true,
  })),
  on(UserStatisticsActions.loadEarningsSuccess, (state, { earnings }) => ({
    ...state,
    loadingEarnings: false,
    earnings,
  })),
  on(UserStatisticsActions.loadEarningsFailure, (state) => ({
    ...state,
    loadingEarnings: false,
  })),
);

export function userStatisticsReducer(state: UserStatisticsState | undefined, action: Action) {
  return reducer(state, action);
}
