import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaymentProviderActions } from 'app/store/actions';

@Component({
  selector: 'app-payment-choice',
  templateUrl: './payment-choice.component.html',
  styleUrls: ['./payment-choice.component.scss']
})
export class PaymentChoiceComponent implements OnInit {
  @Output() payLaterEmit = new EventEmitter();
  payLater: boolean = false;
  constructor(private readonly _store: Store) { }

  ngOnInit(): void {
  }

  changePaymentOption(payLater: boolean){
    this.payLater = payLater ? true : false;
    this.payLaterEmit.emit(payLater);
    this._store.dispatch(PaymentProviderActions.payLaterChanges({value: payLater}))
  }


}
