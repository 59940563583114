import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { ServiceConfigurationActions, UserServiceActions } from 'app/store/actions';
import { Service, ServiceConfiguration, State, Tenant } from 'app/store/models';
import { selectCurrentTenant } from 'app/store/selectors';
import { notNull, untilDestroyed } from 'app/utils/rxjs';

import { ConfirmActionModalComponent } from './confirm-action-modal.component';

@Component({
  selector: 'app-service-configurator',
  templateUrl: './service-configurator.component.html',
  styleUrls: ['./service-configurator.component.scss'],
})
export class ServiceConfiguratorComponent implements OnDestroy {
  loadingServices$: Observable<boolean>;
  savingService$: Observable<boolean>;
  services$: Observable<Service[]>;
  tenants$: Observable<Tenant[]>;
  currentTenant: Tenant | null = null;
  activeService: Service | null = null;
  serviceConfigurationForm: UntypedFormGroup;
  saveFlags: { [key: string]: boolean } = {};
  modalInstance: any;
  descriptionMaxLength = 300;

  @ViewChild('serviceConfigurationModal', { static: true })
  serviceModal?: TemplateRef<any>;

  constructor(
    private store: Store<State>,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private actions$: Actions,
    private translate: TranslateService,
  ) {
    this.loadingServices$ = this.store.select(x => x.serviceConfiguration.loadingItems);
    this.savingService$ = this.store.select(x => x.serviceConfiguration.savingItem);

    this.services$ = this.store.select(x => x.serviceConfiguration.items).pipe(
      filter(notNull),
      startWith([]),
    );

    this.tenants$ = this.store.select(x => x.serviceConfiguration.tenants).pipe(
      filter(notNull),
      startWith([]),
    );

    this.store.select(selectCurrentTenant).pipe(
      untilDestroyed(this),
      filter(notNull),
    ).subscribe(tenant => {
      this.currentTenant = tenant;
    });

    this.store.dispatch(ServiceConfigurationActions.loadServicesForConfigurator());
    this.store.dispatch(ServiceConfigurationActions.loadTenantsForConfigurator());

    this.actions$.pipe(
      ofType(
        ServiceConfigurationActions.updateServiceConfigurationSuccess,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      if (this.modalInstance) {
        this.modalInstance.dismiss();
      }
      Object.keys(this.saveFlags).forEach(key => this.saveFlags[key] = false);
      this.store.dispatch(UserServiceActions.loadLinkedServices());
      this.store.dispatch(UserServiceActions.loadUnlinkedServices());
    });

    this.serviceConfigurationForm = this.fb.group({
      label: [null],
      description: [null, Validators.maxLength(this.descriptionMaxLength)],
      defaultPrice: [null],
      customServicePrices: this.fb.array([this.createCustomServicePrice()]),
    });
  }

  createCustomServicePrice(): UntypedFormGroup {
    return this.fb.group({
      tenant: [null],
      price: [null],
    });
  }

  addCustomServicePrice(): void {
    const locations = <UntypedFormArray>this.serviceConfigurationForm.controls['customServicePrices'];
    locations.push(this.createCustomServicePrice());
  }

  removeCustomServicePrice(index: number): void {
    const customPrices = <UntypedFormArray>this.serviceConfigurationForm.controls['customServicePrices'];
    customPrices.removeAt(index);
    this.serviceConfigurationForm.markAsDirty();
  }

  get customServicePrices() {
    return this.serviceConfigurationForm.get('customServicePrices') as UntypedFormArray;
  }

  editServiceConfiguration(service: Service) {
    this.activeService = service;

    let configuration: any = {
      label: this.activeService.label,
      description: this.activeService.description,
      defaultPrice: this.activeService.defaultPrice
        ? parseFloat(this.activeService.defaultPrice)
        : null,
    };

    const cpGroup = <UntypedFormArray>this.serviceConfigurationForm.controls['customServicePrices'];
    cpGroup.clear();
    if (this.activeService.customServicePrices && this.activeService.customServicePrices.length > 0) {
      this.activeService.customServicePrices.forEach(() => {
        this.addCustomServicePrice();
      });
      configuration.customServicePrices = [];
      this.activeService.customServicePrices.forEach((cp: any) => {
        configuration.customServicePrices.push({
          tenant: cp.tenant['@id'] ? cp.tenant['@id'] : '/api/v1/tenants/' + cp.tenant.id,
          price: parseFloat(cp.price),
        });
      });
    } else {
      this.addCustomServicePrice();
    }

    this.serviceConfigurationForm.reset();
    this.serviceConfigurationForm.patchValue(configuration);
    this.modalInstance = this.modalService.open(this.serviceModal, { size: 'lg' });
  }

  toggleShowOnDashboard(service: Service) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    if (service.showOnDashboard) {
      modalRef.componentInstance.title = this.translate.instant('Are you sure you want to unpublish this service?');
      modalRef.componentInstance.message = this.translate.instant('The service will no longer be shown in your services list to the user companies and on the pairing screens.');
    } else {
      modalRef.componentInstance.title = this.translate.instant('Are you sure you want to publish this service?');
      modalRef.componentInstance.message = this.translate.instant('The service will get shown in your services list to the user companies and on the pairing screens.');
    }
    modalRef.componentInstance.confirmButtonText = marker('Yes');
    modalRef.result.then(
      () => {
        this.saveFlags[service.id] = true;
        this.store.dispatch(ServiceConfigurationActions.updateServiceConfiguration({
          id: service.id,
          configuration: {
            showOnDashboard: !service.showOnDashboard
          },
        }));
      },
      () => {},
    );
  }

  onEditServiceConfigurationSubmit() {
    if (this.serviceConfigurationForm.invalid || this.activeService === null) {
      this.serviceConfigurationForm.markAllAsTouched();
      return;
    }

    // validate that each tenant is used max once
    let valid = true;
    let usedTenants: string[] = [];
    const customServicePricesArray = <UntypedFormArray>this.serviceConfigurationForm.controls['customServicePrices'];
    const cpValue = this.serviceConfigurationForm.value['customServicePrices'];
    cpValue.forEach((cp: { tenant: string }, index: any) => {
      if (cp.tenant) {
        if (usedTenants.indexOf(cp.tenant) !== -1) {
          const customServicePriceGroup = <UntypedFormGroup>customServicePricesArray.controls[index];
          customServicePriceGroup.controls['tenant'].setErrors({'duplicate': true});
          valid = false;
        }
        usedTenants.push(cp.tenant);
      }
    });
    if (!valid) {
      this.serviceConfigurationForm.markAllAsTouched();
      return;
    }

    let configuration: ServiceConfiguration = {
      label: this.serviceConfigurationForm.value.label,
      description: this.serviceConfigurationForm.value.description ?? null,
      defaultPrice: (this.serviceConfigurationForm.value.defaultPrice)
        ? this.serviceConfigurationForm.value.defaultPrice.toFixed(2)
        : null,
    };

    // Filter out empty custom service prices
    configuration.customServicePrices = [];
    cpValue.forEach((cp: any) => {
      if (cp.price === null || cp.price === undefined || !cp.tenant) {
        return;
      }
      configuration.customServicePrices?.push({...cp, price: cp.price.toFixed(2)});
    });

    this.store.dispatch(ServiceConfigurationActions.updateServiceConfiguration({
      id: this.activeService.id,
      configuration: configuration,
    }));
  }

  ngOnDestroy() { }
}
