<div class="row fullscreen justify-content-center align-items-center">
  <div class="col-12 col-md-4 col-xl-3 card">
    <form class="card-body" #loginForm="ngForm" (ngSubmit)="reset()" *ngIf="!success">
      <h3>{{ 'Request a password reset' | translate }}</h3>
      <p class="text-muted">
        {{
          'Enter your email address to receive a link to reset your password.'
          | translate
        }}
      </p>
      <div class="mb-3">
        <input
          [(ngModel)]="email"
          name="mail"
          type="email"
          placeholder="{{ 'Your email address' | translate }}"
          class="form-control"
          autocomplete="username"
          autofocus
        />
      </div>
      <app-loading-button btnType="submit" [isLoading]="loading">
        {{ 'Reset' | translate }}
      </app-loading-button>
      <a routerLink="/" class="btn float-start">
        {{ 'Ready to log in?' | translate }}
      </a>
    </form>

    <div class="card-body" *ngIf="success">
      <h3>{{ 'A password reset email has been dispatched.' | translate }}</h3>
      <p class="text-muted">
        {{
          'Please check your mailbox. It should contain an email with a link to reset your password.'
          | translate
        }}
      </p>
      <a routerLink="/" class="btn float-start">
        {{ 'Return to login' | translate }}
      </a>
    </div>
  </div>
</div>
