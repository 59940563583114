import { makeActions, ofType } from 'app/store/actions/utils';
import { Country, Currency } from 'app/store/models';

export const CommonActions = makeActions({
  clearAll: null,

  loadPublicCountries: null,
  loadPublicCountriesSuccess: ofType<{ countries: Country[]; }>(),
  loadPublicCountriesFailure: ofType<{ error: any; }>(),

  loadPublicCurrencies: null,
  loadPublicCurrenciesSuccess: ofType<{ currencies: Currency[]; }>(),
  loadPublicCurrenciesFailure: ofType<{ error: any; }>(),

  loadInvoicingSoftware: null,
  loadInvoicingSoftwareSuccess: ofType<{ invoicingSoftware: string[]; }>(),
  loadInvoicingSoftwareFailure: ofType<{ error: any; }>(),

  setOriginalUrl: ofType<{ url: string; }>(),
});
