<div class="modal-header">
  <h4 class="modal-title">{{ 'Top up your balance' | translate }}</h4>
  <button type="button" class="btn-close" tabindex="-1" (click)="handleCancel()">
    <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <p *ngIf="message !== null">{{ message }}</p>
  <app-recharge-wallet [hideMessage]="message !== null" [redirectUrl]="redirectUrl"></app-recharge-wallet>
</div>
