import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { SessionActions, UserStatisticsActions } from 'app/store/actions';
import { Account, AccountType, Earning, State } from 'app/store/models';
import { selectCurrentTenant } from 'app/store/selectors';
import { notNull, untilDestroyed } from 'app/utils/rxjs';
import { RechargeWalletModalComponent } from './recharge-wallet-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentMethod } from 'app/store/models/payment-provider.model';

const LS_KEY = 'dashboard-period';
export enum Period {
  WEEK = '7d',
  TWO_WEEKS = '14d',
}

@Component({
  selector: 'app-dashboard-provider',
  templateUrl: './dashboard-provider.component.html',
})
export class DashboardProviderComponent implements OnDestroy, OnInit {
  account$: Observable<Account>;
  earnerAccount$: Observable<Account>;
  spenderAccount$: Observable<Account>;
  earnings$: Observable<Earning[]>;
  period$: BehaviorSubject<Period>;
  loading$: Observable<boolean>;
  Period = Period;
  latestTransactionTime: Date = new Date();
  autoRefreshSubscription?: Subscription;
  payLater: boolean = false;
  paymentMethods$: Observable<PaymentMethod[]>;

  constructor(
    private store: Store<State>,
    private api: ApiService,
    private modalService: NgbModal,
  ) {
    this.account$ = this.store.select(selectCurrentTenant).pipe(
      filter(notNull),
      map(accs => accs.accounts.find(a => a.type === AccountType.EARNER) || null),
      filter(notNull),
    );
    this.spenderAccount$ = this.store.select(selectCurrentTenant).pipe(
      filter(notNull),
      map(accs => accs.accounts.find(a => a.type === AccountType.SPENDER) || null),
      filter(notNull),
    );
    this.earnerAccount$ = this.store.select(selectCurrentTenant).pipe(
      filter(notNull),
      map(accs => accs.accounts.find(a => a.type === AccountType.EARNER) || null),
      filter(notNull),
    );
    this.earnings$ = this.store.select(x => x.statistics.earnings).pipe(
      filter(notNull),
    );
    this.loading$ = this.store.select(x => x.transaction.loading);

    this.paymentMethods$ = this.store.select(x => x.admin.paymentProvider.availablePaymentMethods).pipe(
      untilDestroyed(this),
      filter(notNull),
      startWith([]),
    );

    // Load earnings
    this.period$ = new BehaviorSubject<Period>(
      localStorage.getItem(LS_KEY) as Period || Period.WEEK,
    );
    this.period$.pipe(untilDestroyed(this)).subscribe(x => {
      localStorage.setItem(LS_KEY, x);
      this.loadEarnings();
    });

    // setup auto-refresh
    this.autoRefreshSubscription = interval(5000).subscribe(() => {
      this.api.get<{ exists: boolean }>({
        path: `/new-transaction-exists?since=${this.latestTransactionTime.toISOString()}`,
      }).toPromise().then(res => {
        if (res.exists) {
          this.reloadData();
        }
      }, () => { });
    });
  }

  ngOnInit() {
    this.period$.next(this.period$.getValue());
    // Refresh account state
    this.store.dispatch(SessionActions.loadUserContext());
  }

  reloadData(): void {
    this.loadEarnings();
    this.store.dispatch(SessionActions.loadUserContext());
    this.latestTransactionTime = new Date();
  }

  loadEarnings() {
    const period = this.period$.getValue() === Period.WEEK ? 6 : 13;
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - period);
    this.store.dispatch(UserStatisticsActions.loadEarnings({
      accountType: AccountType.EARNER,
      after: `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()} 00:00:00`,
      before: `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} 23:59:59`,
    }));
  }

  topUp(): void {
    const modalRef = this.modalService.open(RechargeWalletModalComponent, { size: 'lg' });
    modalRef.result.then(
      () => {},
      () => {},
    );
  }

  ngOnDestroy() {
    if (this.autoRefreshSubscription) {
      this.autoRefreshSubscription.unsubscribe();
      this.autoRefreshSubscription = undefined;
    }
  }

  handlePaymentChoiceChange(value: boolean) {
    this.payLater = value;
  }

  getPayLater(methods: PaymentMethod[] | null): boolean {
    return !!methods?.find(method => method.paymentProvider.code === 'BNPL')
  }
}
