import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, UserContextActions } from 'app/store/actions';
import { UserContextState } from 'app/store/models';

const empty: UserContextState = {
  items: null,
  loadingItems: false,
  savingItem: false,
};

const reducer = createReducer<UserContextState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(UserContextActions.loadUserContexts, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(UserContextActions.loadUserContextsSuccess, (state, { userContexts }) => ({
    ...state,
    items: userContexts,
    loadingItems: false,
  })),
  on(UserContextActions.loadUserContextsFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(UserContextActions.createUserContext, (state) => ({
    ...state,
    savingItem: true,
  })),
  on(UserContextActions.createUserContextSuccess, (state, { userContext }) => ({
    ...state,
    items: [...state.items || [], userContext],
    savingItem: false,
  })),
  on(UserContextActions.createUserContextFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(UserContextActions.createUserContextWithRole, (state) => ({
    ...state,
    savingItem: true,
  })),
  on(UserContextActions.createUserContextWithRoleSuccess, (state, { userContext }) => ({
    ...state,
    items: [...state.items || [], userContext],
    savingItem: false,
  })),
  on(UserContextActions.createUserContextWithRoleFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(UserContextActions.updateUserContext, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(UserContextActions.updateUserContextSuccess, (state, { userContext }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === userContext.id ? userContext : x),
    savingItem: false,
  })),
  on(UserContextActions.updateUserContextFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(UserContextActions.deleteUserContext, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(UserContextActions.deleteUserContextSuccess, (state, { userContextId }) => ({
    ...state,
    items: (state.items || []).filter(x => x.id !== userContextId),
    savingItem: false,
  })),
  on(UserContextActions.deleteUserContextFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),
);

export function userContextReducer(state: UserContextState | undefined, action: Action) {
  return reducer(state, action);
}
