<div style="max-width:900px;" *ngIf="{
  spenderAccounts: (spenderAccounts$ | async),
  earnerAccounts: (earnerAccounts$ | async)
}; let context">
  <div class="mt-3" style="height:150px">
    <ngx-charts-bar-vertical
      [scheme]="{domain: ['#f5c827', '#f5d871', '#f4cf4c', '#f5e095']}"
      [results]="earnings$ | async"
      [legend]="false"
      [gradient]="false"
      [xAxis]="true"
      [yAxis]="true"
      [showGridLines]="true"
      [noBarWhenZero]="false"
      [showDataLabel]="true"
    ></ngx-charts-bar-vertical>
  </div>
  <div class="pt-2 mb-4 text-muted">
    {{ 'Latest earnings' | translate }}
    ( <button
        class="btn btn-sm"
        [ngClass]="(period$ | async) === Period.WEEK ? 'btn-dark' : 'btn-outline-dark'"
        (click)="period$.next(Period.WEEK)"
      >7d</button>
    / <button
        class="btn btn-sm"
        [ngClass]="(period$ | async) === Period.TWO_WEEKS ? 'btn-dark' : 'btn-outline-dark'"
        (click)="period$.next(Period.TWO_WEEKS)"
      >14d</button>
    )
  </div>

  <div class="h1 pt-4 mb-4 container mx-0">
    <div class="row">
      <div class="col-sm-12 col-md-6 p-0 pe-2 mb-2">
        <h5 class="text-muted mt-2 mb-2">{{ 'Earner balance' | translate }}</h5>
        <accounts [accounts]="sortAccounts(context.earnerAccounts)" ></accounts>
      </div>
      <div *ngIf="context.spenderAccounts?.length" class="col-sm-12 col-md-6 p-0 mb-2">
        <div class="container">
          <div class="row">
            <div class="col-auto mt-2 p-0">
              <h5 class="text-muted ">{{ 'Spender balance' | translate }}</h5>
            </div>
          </div>
        </div>
        <accounts [accounts]="sortAccounts(context.spenderAccounts)" ></accounts>
      </div>
    </div>
  </div>

  <div *ngIf="context.spenderAccounts?.length" class="card">
    <div class="card-body">
      <app-recharge-wallet [headerText]="'Recharge your spender balance' | translate"></app-recharge-wallet>
    </div>
  </div>

  <div class="pb-2 text-muted">{{ 'Latest transactions' | translate }}</div>
  <app-transaction-list
    [loading]="loading$ | async" [items]="[transactions$ | async, 'CINVIO']"
  ></app-transaction-list>
  <a routerLink="/transactions">{{ 'Show all transactions' | translate }}</a>
</div>
