import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, UserServiceActions } from 'app/store/actions';
import { UserServiceState } from 'app/store/models';

const empty: UserServiceState = {
  linkedItems: null,
  unlinkedItems: null,
  subProviders: null,
  loadingLinkedItems: false,
  loadingUnlinkedItems: false,
  loadingSubProviders: false,
};

const reducer = createReducer<UserServiceState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(UserServiceActions.loadLinkedServices, (state) => ({
    ...state,
    loadingLinkedItems: true,
  })),
  on(UserServiceActions.loadLinkedServicesSuccess, (state, { items }) => ({
    ...state,
    loadingLinkedItems: false,
    linkedItems: items,
  })),
  on(UserServiceActions.loadLinkedServicesFailure, (state) => ({
    ...state,
    loadingLinkedItems: false,
  })),

  on(UserServiceActions.loadUnlinkedServices, (state) => ({
    ...state,
    loadingUnlinkedItems: true,
  })),
  on(UserServiceActions.loadUnlinkedServicesSuccess, (state, { items }) => ({
    ...state,
    loadingUnlinkedItems: false,
    unlinkedItems: items,
  })),
  on(UserServiceActions.loadUnlinkedServicesFailure, (state) => ({
    ...state,
    loadingUnlinkedItems: false,
  })),

  on(UserServiceActions.loadSubProviders, (state) => ({
    ...state,
    loadingSubProviders: true,
  })),
  on(UserServiceActions.loadSubProvidersSuccess, (state, { items }) => ({
    ...state,
    loadingSubProviders: false,
    subProviders: items,
  })),
  on(UserServiceActions.loadSubProvidersFailure, (state) => ({
    ...state,
    loadingSubProviders: false,
  })),
);

export function userServiceReducer(state: UserServiceState | undefined, action: Action) {
  return reducer(state, action);
}
