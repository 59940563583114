<div class="modal-header">
  <h4 *ngIf="!edit" class="modal-title">{{ 'Add a new tier' | translate }}</h4>
  <h4 *ngIf="edit" class="modal-title">{{ 'Edit tier' | translate }}</h4>
  <button type="button" class="btn-close" tabindex="-1" (click)="handleCancel()">
    <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
  </button>
</div>
<div *ngIf="tierForm" class="modal-body p-4">
  <p>{{ subscriptionLabel }}</p>
  <form
    (ngSubmit)="handleConfirm()"
    [formGroup]="tierForm"
  >
    <div class="mb-3">
      <label class="form-label center-block"><b>{{ 'Tier name' | translate }}</b></label>
      <div class="row">
        <div class="col-5">
          <input class="form-control" type="text" formControlName="label">
        </div>
      </div>
    </div>
    <div class="mb-3">
      <label class="form-label center-block"><b>{{ 'Monthly price' | translate }}</b></label>
      <div class="row">
        <div class="col-5">
          <div class="input-group">
            <input class="form-control" type="number" formControlName="priceMonthly" min="0" (change)="handleChangePrice($event)">
            <span class="input-group-text">{{ currentTenant?.currency.code }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <label class="form-label center-block"><b>{{ 'Yearly price' | translate }}</b></label>
      <div class="row">
        <div class="col-5">
          <div class="input-group">
            <input class="form-control" type="number" formControlName="priceYearly" min="0" (change)="handleChangePrice($event)">
            <span class="input-group-text">{{ currentTenant?.currency.code }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <div class="form-check mb-3">
        <input class="form-check-input mt-2" type="checkbox" formControlName="highlighted" />
        <label class="form-label form-check-label" for="highlighted">{{ 'Highlighted' | translate }}</label>
      </div>
    </div>
    <div class="mb-3">
      <label class="form-label center-block"><b>{{ 'Services included in subscription' | translate }}</b></label>
      <div
        formArrayName="subscriptionTemplateTierServices"
        class="mb-2"
        *ngFor="
          let subscriptionTemplateTierService of getServiceGroups().controls;
          let stts = index;
        "
      >
        <div [formGroupName]="stts" class="row">
          <div class="col-5">
            <ng-select
              [items]="services$ | async"
              bindLabel="label"
              bindValue="@id"
              data-cy="service"
              placeholder="{{ 'Select service' | translate }}"
              formControlName="service"
              [ngClass]="{'is-invalid': subscriptionTemplateTierService.get('service').invalid && subscriptionTemplateTierService.get('service').touched }"
            >
            </ng-select>
          </div>
          <div class="col-3">
            <input
              class="form-control"
              type="number"
              data-cy="tokens"
              placeholder="{{ 'Tokens' | translate }}"
              formControlName="tokens"
              min="0"
            >
          </div>
          <div class="col-2">
            <div class="form-check mb-3">
              <input class="form-check-input mt-2" type="checkbox" formControlName="unlimited" (change)="handleChangeUnlimited($event, stts)"/>
              <label class="form-label form-check-label" for="unlimited">{{ 'Unlimited' | translate }}</label>
            </div>
          </div>
          <div class="col-2">
            <button
              class="btn btn-danger text-white"
              [disabled]="stts === 0"
              type="button"
              (click)="removeServiceGroup(stts)"
            >
              <i class="far fa-times"></i>
            </button>
            <button
              class="btn btn-primary ms-1"
              *ngIf="stts === this.tierForm?.controls['subscriptionTemplateTierServices'].length - 1"
              type="button"
              (click)="addServiceGroup()"
            >
              <i class="far fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <app-loading-button btnType="submit">
      {{ 'Save' | translate }}
    </app-loading-button>
  </form>
</div>
