import { AfterViewInit, Component, isDevMode } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { interval } from 'rxjs';

import { UpdateToastComponent } from 'app/components/update-toast.component';
import { I18nService } from 'app/services/i18n.service';
import { SessionActions } from 'app/store/actions';
import { SessionState, State } from 'app/store/models';

declare const gtag: (...xs: any[]) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit {
  title = 'cinvio';

  constructor(
    private i18nService: I18nService,
    private router: Router,
    private updates: SwUpdate,
    private toastr: ToastrService,
    private store: Store<State>,
  ) {
    this.i18nService.language = 'en_GB';

    if (!isDevMode() && this.updates.isEnabled) {
      // check for updates every minute
      interval(60000).subscribe(() => updates.checkForUpdate());
      updates.available.subscribe(event => {
        if (!event) {
          return;
        }
        if (event['available']) {
          this.toastr.info(
            marker('A new version is available. Click here to update!'),
            marker('Update is available'),
            {
              disableTimeOut: true,
              closeButton: true,
              toastComponent: UpdateToastComponent,
              positionClass: 'toast-bottom-right',
            },
          );
        }
      });
    }
  }

  ngAfterViewInit(): void {
    const sessionSrc = localStorage.getItem('session');
    const session = sessionSrc ? JSON.parse(sessionSrc) as SessionState : null;
    this.store.dispatch(SessionActions.initSession({ session }));

    const preloader = document.getElementById('preloader');
    if (preloader) {
      preloader.classList.add('hidden');
      setTimeout(() => preloader.parentNode?.removeChild(preloader), 2000);
    }

    if (this.updates.isEnabled) {
      this.updates.checkForUpdate();
    }
    this.router.events.subscribe(event => {
      if (typeof gtag !== 'undefined' && event instanceof NavigationEnd) {
        gtag('set', 'page', event.urlAfterRedirects);
        gtag('send', 'pageview');
      }
    });
  }
}
