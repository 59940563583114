import { makeActions, ofType } from 'app/store/actions/utils';
import { OAuthClient } from 'app/store/models';

export const OAuthClientActions = makeActions({
  loadOAuthClients: null,
  loadOAuthClientsSuccess: ofType<{ oauthClients: OAuthClient[]; }>(),
  loadOAuthClientsFailure: ofType<{ error: any; }>(),

  createOAuthClient: ofType<{ oauthClient: OAuthClient; }>(),
  createOAuthClientSuccess: ofType<{ oauthClient: OAuthClient; }>(),
  createOAuthClientFailure: ofType<{ error: any; }>(),

  updateOAuthClient: ofType<{ oauthClient: OAuthClient; }>(),
  updateOAuthClientSuccess: ofType<{ oauthClient: OAuthClient; }>(),
  updateOAuthClientFailure: ofType<{ error: any; }>(),

  deleteOAuthClient: ofType<{ oauthClientId: string; }>(),
  deleteOAuthClientSuccess: ofType<{ oauthClientId: string; }>(),
  deleteOAuthClientFailure: ofType<{ error: any; }>(),

  unlinkPairedTenant: ofType<{ providerAuthLinkId: string; }>(),
  unlinkPairedTenantSuccess: ofType<{ providerAuthLinkId: string; }>(),
  unlinkPairedTenantFailure: ofType<{ error: any; }>(),
});
