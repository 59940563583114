<div class="d-flex flex-wrap justify-content-between mb-3">
  <!-- account type select -->
  <!-- <div class="pt-1" *ngIf="(accountTypes$ | async)?.length > 1">
    <div class="btn-group" *ngIf="account$ | async; let selected">
      <div
        class="btn"
        [ngClass]="type === selected ? 'btn-primary' : 'btn-light'"
        *ngFor="let type of accountTypes$ | async"
        (click)="account$.next(type)"
      >
        {{ type | translate }}
      </div>
    </div>
  </div> -->

  <div *ngIf="{accountTypes: (accountTypes$ | async)} let context" class="pt-1">
    <div *ngIf="context.accountTypes?.length > 1">
      <app-custom-cinvio-switch [firstOption]="'Spendings'" [secondOption]="'Earnings'" (changeEmitter)="handleAccountChange($event)" [firstOptionSelected]="isSpendingsAccountSelected()"></app-custom-cinvio-switch>
    </div>
  </div>

  <!-- toggle buttons -->
  <div class="d-flex flex-wrap pt-1">
    <!-- <div class="btn-group">
      <button
        class="btn btn-sm" [ngClass]="view === 'list' ? 'btn-primary' : 'btn-outline-primary'"
        (click)="view = 'list'"
      >
        <i class="far fa-stream"></i>
        {{ 'List' | translate }}
      </button>
      <button
        class="btn btn-sm" [ngClass]="view === 'table' ? 'btn-primary' : 'btn-outline-primary'"
        (click)="view = 'table'"
      >
        <i class="far fa-table"></i>
        {{ 'Table' | translate }}
      </button>
    </div> -->
    <app-custom-cinvio-switch [firstOption]="'List'" [secondOption]="'Table'" (changeEmitter)="handleViewChange($event)"></app-custom-cinvio-switch>
    <button
      class="btn" [ngClass]="showFilter ? 'btn-primary' : 'btn-link'"
      (click)="toggleFilter()"
    >
      <i class="far fa-filter"></i>
      {{ 'Filter' | translate }}
    </button><button class="btn btn-link" (click)="showExport(exportModal)">
      <i class="far fa-download"></i>
      {{ 'Export to CSV' | translate }}
    </button>
  </div>
</div>

<!-- search -->
<form class="d-flex flex-nowrap pt-1 align-items-center row mb-3" (submit)="searchSource.next(term.value)">
  <div class="col-6">
    <div class="input-group">
      <div class="input-group-text"><i class="far fa-search"></i></div>
      <input
        #term (keyup)="searchSource.next(term.value)" [value]="search$ | async"
        class="form-control" [placeholder]="'Search' | translate"
      >
    </div>
    <div class="ps-2 pe-2">
      <i class="fa fa-spinner fa-spin btn__loader" [ngClass]="{ 'd-none': !(loading$ | async) }"></i>
    </div>
  </div>
</form>

  <!-- filter -->
<div class="d-flex flex-row align-items-center flex-wrap justify-content-start mb-2" [ngClass]="showFilter ? 'd-flex' : 'd-none'">
  <div class="input-group">
    <label class="form-label pe-2">{{ 'From' | translate }}</label>
    <input
      #after="ngbDatepicker" ngbDatepicker
      class="form-control col-7" name="after"
      [startDate]="afterStartDate" [(ngModel)]="afterValue"
      (dateSelect)="afterSource.next($event)"
      (blur)="dateInputChange($event)"
      (keyup.enter)="dateInputChange($event)"
    >
        <button class="btn btn-secondary text-white" (click)="after.toggle()" type="button">
          <i class="far fa-calendar"></i>
        </button>
  </div>

  <div class="input-group">
    <label class="form-label pe-2">{{ 'Up to (incl.)' | translate }}</label>
    <input
      #before="ngbDatepicker" ngbDatepicker
      class="form-control col-7" name="before"
      [startDate]="beforeStartDate" [(ngModel)]="beforeValue"
      (dateSelect)="beforeSource.next($event)"
      (blur)="dateInputChange($event)"
      (keyup.enter)="dateInputChange($event)"
    >
      <button class="btn btn-secondary text-white" (click)="before.toggle()" type="button">
        <i class="far fa-calendar"></i>
      </button>
  </div>

  <div class="mb-3">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" [(ngModel)]="softCharged" id="soft-charged"/>
      <label class="form-label form-check-label" for="soft-charged">{{ 'Reserved' | translate }}</label>
    </div>
  </div>

  <button class="btn btn-link text-red" (click)="resetFilters(false)">
    <i class="far fa-times-circle"></i>
    {{ 'Clear all filters' | translate }}
  </button>
</div>

<app-transaction-list [loading]="loading$ | async" [items]="items$ | async" *ngIf="view === 'list'"
  [showProviderPromo]="showProviderPromo" (reload)="reloadData()">
</app-transaction-list>
<app-transaction-table [loading]="loading$ | async" [items]="items$ | async" *ngIf="view === 'table'" (reload)="reloadData()">
</app-transaction-table>

<app-pagination [totalPages]="pages$ | async" [pageSource]="pageSource"></app-pagination>

<ng-template #exportModal>
  <div class="modal-header">
    <b>{{ 'Export transactions' | translate }}</b>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="submitExport('csv')" [formGroup]="exportForm" class="p3">
      <div *ngIf="(accountTypes$ | async)?.length > 1">
        <div class="btn-group" *ngIf="account$ | async; let selected">
          <div
            class="btn"
            [ngClass]="type === selected ? 'btn-primary' : 'btn-light'"
            *ngFor="let type of accountTypes$ | async"
            (click)="account$.next(type)"
          >
            {{ type | translate }}
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label" for="createdAfter">{{ 'From' | translate }}</label>
        <div class="input-group">
          <input
            formControlName="createdAfter"
            #afterExport="ngbDatepicker" ngbDatepicker
            class="form-control col-7"
            [startDate]="afterStartDate"
          >
            <button class="btn btn-secondary text-white" (click)="afterExport.toggle()" type="button">
              <i class="far fa-calendar"></i>
            </button>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label" for="createdBefore">{{ 'Up to (incl.)' | translate }}</label>
        <div class="input-group">
          <input
            formControlName="createdBefore"
            #beforeExport="ngbDatepicker" ngbDatepicker
            class="form-control col-7"
            [startDate]="beforeStartDate"
          >
            <button class="btn btn-secondary text-white" (click)="beforeExport.toggle()" type="button">
              <i class="far fa-calendar"></i>
            </button>
        </div>
      </div>

      <app-loading-button btnType="submit" (click)="submitExport('csv')" [isLoading]="exporting">
        {{ 'Download as CSV' | translate }}
      </app-loading-button>
    </form>
  </div>
</ng-template>
