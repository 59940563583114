<h2>{{ 'Subscription overview' }}</h2>

<div class="d-flex flex-row align-items-center flex-wrap mb-3">
  <div class="input-group me-2">
    <div class="input-group-text"><i class="far fa-search"></i></div>
    <input
      class="form-control search-query"
      placeholder="{{ 'Search for company or subscription' | translate }}"
      [ngModel]="searchQuery"
      (ngModelChange)="searchQueryDebounced$.next($event)"
    />
  </div>

  <div class="mb-3">
    <ng-select
      [items]="statusTypes"
      bindLabel="label"
      bindValue="value"
      placeholder="{{ 'Filter by status' | translate }}"
      [clearable]="true"
      [ngModel]="status"
      (ngModelChange)="handleStatusChange($event)"
    ></ng-select>
  </div>
  <app-loading-button (click)="submitExport()" [isLoading]="exporting" [btnClass]="'btn btn-link'" [isDisabled]="exporting">
    {{ 'Export to CSV' | translate }}
  </app-loading-button>
</div>

<table class="table">
  <tr class="no-border">
    <th>{{ 'Company' | translate }}</th>
    <th>{{ 'Subscription' | translate }}</th>
    <th>{{ 'Version' | translate }}</th>
    <th>{{ 'Status' | translate }}</th>
    <th>{{ 'Renewal date' | translate }}</th>
    <th>{{ 'Action' | translate }}</th>
  </tr>
  <tr *ngFor="let subscription of subscriptions$ | async">
    <td>{{ subscription.tenant.name }}</td>
    <td>{{ subscription.subscriptionTemplateTier.subscriptionTemplate.label }} - {{ subscription.subscriptionTemplateTier.label }}</td>
    <td>{{ subscription.subscriptionTemplateTier.subscriptionTemplate.version }}</td>
    <td>{{ subscription.status | translate }}</td>
    <td>
      <span *ngIf="subscription.status === 'active' && subscription.renewsOn">
        {{ subscription.renewsOn | date:'dd-MM-yyyy' }}
      </span>
      <span *ngIf="subscription.status !== 'active' || !subscription.renewsOn">
        -
      </span>
    </td>
    <td>
      <button class="btn btn-sm btn-link" (click)="viewDetails(subscription)">
        {{ 'View detail' | translate }}
      </button>
    </td>
  </tr>
</table>

<app-pagination [totalPages]="pages$ | async" [pageSource]="page$"></app-pagination>

<ng-template #detailsModal let-self>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'Subscription details' | translate }}</h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <table>
      <tbody>
        <tr>
          <td>
            <i class="far fa-truck me-1"></i>
            {{ 'Company' | translate }}
          </td>
          <td>{{ activeSubscription?.tenant?.name }}</td>
        </tr>
        <tr>
          <td>
            <i class="far fa-file-invoice-dollar me-1"></i>
            {{ 'Subscription' | translate }}
          </td>
          <td>{{ activeSubscription?.subscriptionTemplateTier.subscriptionTemplate.label }} - {{ activeSubscription?.subscriptionTemplateTier.label }}</td>
        </tr>
        <tr>
          <td>
            <i class="far fa-copy me-1"></i>
            {{ 'Version' | translate }}
          </td>
          <td>{{ activeSubscription?.subscriptionTemplateTier.subscriptionTemplate.version }}</td>
        </tr>
        <tr>
          <td>
            <i *ngIf="activeSubscription?.status === 'active'" class="far fa-toggle-on me-1"></i>
            <i *ngIf="activeSubscription?.status !== 'active'" class="far fa-toggle-off me-1"></i>
            {{ 'Status' | translate }}
          </td>
          <td>
            {{ activeSubscription?.status | translate }}
            <span *ngIf="activeSubscription?.status === 'active' && activeSubscription?.activeUntil">
              ({{ 'Until' }} {{ activeSubscription?.activeUntil | date:'dd-MM-yyyy'}})
            </span>
          </td>
        </tr>
        <tr *ngIf="activeSubscription?.status === 'active' && activeSubscription?.renewsOn">
          <td>
            <i class="far fa-money-bill me-1"></i>
            {{ 'Renewal date' | translate }}
          </td>
          <td>
            {{ activeSubscription?.renewsOn | date:'dd-MM-yyyy' }}
            (<span *ngIf="activeSubscription?.billing === 'yearly'">
              <money [amount]="activeSubscription?.subscriptionTemplateTier.priceYearly" [currency]="activeSubscription?.subscriptionTemplateTier.subscriptionTemplate.tenant?.currency.code"></money><span class="currency">/{{ 'Year' | translate | lowercase }}*</span>
            </span>
            <span *ngIf="activeSubscription?.billing === 'monthly'">
              <money [amount]="activeSubscription?.subscriptionTemplateTier.priceMonthly" [currency]="activeSubscription?.subscriptionTemplateTier.subscriptionTemplate.tenant?.currency.code"></money><span class="currency">/{{ 'Month' | translate | lowercase }}*</span>
            </span>)
          </td>
        </tr>
        <tr *ngIf="activeSubscription?.status === 'active' && activeSubscription?.scheduledUpgrade">
          <td>
            <i class="far fa-upload me-1"></i>
            {{ 'Upgrades on' | translate }}
          </td>
          <td>
            {{ activeSubscription?.renewsOn | date:'dd-MM-yyyy' }}
            (<span *ngIf="activeSubscription?.scheduledUpgrade.toBilling === 'yearly'">
              <money [amount]="activeSubscription?.scheduledUpgrade.toTier.priceYearly" [currency]="activeSubscription?.subscriptionTemplateTier.subscriptionTemplate.tenant?.currency.code"></money><span class="currency">/{{ 'Year' | translate | lowercase }}*</span>
            </span>
            <span *ngIf="activeSubscription?.scheduledUpgrade.toBilling === 'monthly'">
              <money [amount]="activeSubscription?.scheduledUpgrade.toTier.priceMonthly" [currency]="activeSubscription?.subscriptionTemplateTier.subscriptionTemplate.tenant?.currency.code"></money><span class="currency">/{{ 'Month' | translate | lowercase }}*</span>
            </span>)
          </td>
        </tr>
        <tr *ngIf="activeSubscription?.status === 'active' && activeSubscription?.tokensResetOn && (activeSubscription?.activeUntil === null || activeSubscription?.activeUntil > activeSubscription?.tokensResetOn)">
          <td>
            <i class="far fa-redo me-1"></i>
            {{ 'Tokens reset date' | translate }}
          </td>
          <td>{{ activeSubscription?.tokensResetOn | date:'dd-MM-yyyy' }}</td>
        </tr>
        <tr *ngIf="activeSubscription?.status === 'active' && activeSubscription?.activeUntil">
          <td>
            <i class="far fa-toggle-off me-1"></i>
            {{ 'Expires on' | translate }}
          </td>
          <td>
            {{ activeSubscription?.activeUntil | date:'dd-MM-yyyy' }}
          </td>
        </tr>
        <tr class="divider">
          <th>{{ 'Service' | translate }}</th>
          <th *ngIf="activeSubscription?.status === 'active'">{{ 'Available tokens' | translate }}</th>
        </tr>
        <tr *ngFor="let subscriptionService of getSubscriptionServices(activeSubscription)">
          <td>
            <i class="far" [ngClass]="subscriptionService.subscriptionTemplateTierService.service.icon"></i>
            {{ subscriptionService.subscriptionTemplateTierService.service.label }}
          </td>
          <td *ngIf="activeSubscription?.status === 'active'">
            <span *ngIf="subscriptionService.tokensAvailable !== null">{{ subscriptionService.tokensAvailable }}/{{ subscriptionService.subscriptionTemplateTierService.tokens }}</span>
            <span *ngIf="subscriptionService.tokensAvailable === null">{{ 'Unlimited' | translate}}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <foot-note *ngIf="(activeSubscription?.status === 'active' && activeSubscription?.renewsOn) || (activeSubscription?.status === 'active' && activeSubscription?.scheduledUpgrade)"></foot-note>
  </div>
</ng-template>
