<div class="row fullscreen justify-content-center align-items-center">
  <div class="col-12 col-md-4 col-xl-3 card">
    <div class="card-body">
      <img src="/assets/img/cinvio-with-title.png" alt="cinvio"
        class="p-2" style="width:300px;max-width:100%" />
      <h3>{{ 'Create an account' | translate }}</h3>
      <div class="progress mb-2">
        <div class="progress-bar bg-secondary" [style.width]="(stage * 100 / 3) + '%'"></div>
      </div>

      <form
        #registerForm1="ngForm" (ngSubmit)="nextStage(registerForm1.form)"
        [ngClass]="{ 'd-none': stage !== 1 }"
      >
        <h5 class="pb-2">{{ 'First, tell us about your company' | translate }}</h5>
        <div class="mb-3">
          <input
            [(ngModel)]="form.companyName"
            name="organization-title"
            placeholder="{{ 'Company name' | translate }}"
            class="form-control"
            autocomplete="organization-title"
            required
          />
        </div>
        <div class="mb-3">
          <input
            [(ngModel)]="form.invoiceEmail"
            #email="ngModel"
            name="invoiceEmail"
            type="email"
            placeholder="{{ 'Email address' | translate }}"
            class="form-control"
            autocomplete="company email"
            required
            email
          />
        </div>

        <div class="row">
          <div class="mb-3 col-md-8">
            <label class="form-label" for="vat-number">{{ companyIdentifierLabel() | translate }}
            </label>
            <div class="input-group" ngModelGroup="vatNumber" #chks="ngModelGroup" appVatNumberValidator>
                <ng-select
                  [(ngModel)]="form.invoiceCountry"
                  [items]="countries$ | async | sortOn: 'value'"
                  [clearable]="false"
                  bindLabel="label"
                  bindValue="value"
                  name="invoiceCountry"
                  class="custom-select-styling"
                >
                  <ng-template ng-label-tmp let-item="item">
                    <span class="fi fi-{{item.value | lowercase}}"></span>
                    {{item.label}}
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item">
                    <span class="fi fi-{{item.value | lowercase}}"></span>
                    {{item.label}}
                  </ng-template>
                </ng-select>
              <input
                [(ngModel)]="form.vatNumber"
                name="vat-number"
                type="tel"
                placeholder="{{ companyIdentifierPlaceholder() || companyIdentifierLabel() | translate }}"
                class="form-control"
                autocomplete="organization"
                required
              />
              <span *ngIf="chks?.errors?.vatNumber?.pending" class="text-danger">{{ 'Oops, looks like someone beat you to it. Your company has already requested to register.' | translate }}</span>
              <span *ngIf="chks?.errors?.vatNumber?.exists" class="text-danger">{{ 'Oops! Your company has already registered. Contact your colleague to add you as a user. You don\'t know who created the account? Ask us to contact them in the chat below.' | translate }}</span>
            </div>
          </div>
          <div class="mb-3 col-md-4">
            <label class="form-label" for="currency">{{ 'Currency' | translate }}</label>
            <ng-select
              [(ngModel)]="form.currency"
              [items]="currencies$ | async"
              [clearable]="false"
              bindLabel="code"
              bindValue="code"
              name="currency"
              placeholder="{{ 'Account currency' | translate }}"
            ></ng-select>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label" for="street-and-number">{{ 'Address' | translate }}</label>
          <input
            [(ngModel)]="form.streetAndNumber"
            name="street-and-number"
            placeholder="{{ 'Street and number' | translate }}"
            class="form-control"
            autocomplete="organization address"
            required
          />
        </div>

        <div class="row">
          <div class="mb-3 col-md-4">
            <input
              [(ngModel)]="form.zipcode"
              name="organization-zip"
              placeholder="{{ 'Postcode' | translate }}"
              class="form-control"
              autocomplete="organization zip"
              required
            />
          </div>
          <div class="mb-3 col-md-8">
            <div class="input-group">
              <ng-select
                [(ngModel)]="form.country"
                [items]="countries$ | async | sortOn: 'value'"
                [clearable]="false"
                bindLabel="label"
                bindValue="value"
                name="country"
                class="custom-select-styling"
              >
                <ng-template ng-label-tmp let-item="item">
                  <span class="fi fi-{{item.value | lowercase}}"></span>
                  {{item.label}}
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <span class="fi fi-{{item.value | lowercase}}"></span>
                  {{item.label}}
                </ng-template>
              </ng-select>
              <input
                [(ngModel)]="form.city"
                name="organization-city"
                placeholder="{{ 'City' | translate }}"
                class="form-control"
                autocomplete="organization city"
                required
              />
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label" for="activity">{{ 'Type of activity' | translate }}</label>
          <ng-select
            [(ngModel)]="form.activity"
            [items]="activityTypes"
            [clearable]="false"
            bindLabel="label"
            bindValue="value"
            name="activity"
            required
          ></ng-select>
        </div>

        <a routerLink="/login" class="btn btn-light me-2">
          {{ 'Back' | translate }}
        </a>
        <button type="submit" class="btn btn-primary text-white">
          {{ 'Next' | translate }}
        </button>
      </form>

      <form
        #registerForm2="ngForm" (ngSubmit)="nextStage(registerForm2.form)"
        [ngClass]="{ 'd-none': stage !== 2 }"
      >
        <h5 class="pb-2">{{ 'Now, tell us who your first user is' | translate }}</h5>
        <div class="row">
          <div class="mb-3 col-md-6">
            <input
              [(ngModel)]="form.firstName"
              name="firstName"
              placeholder="{{ 'First name' | translate }}"
              class="form-control"
              autocomplete="name"
              required
            />
          </div>
          <div class="mb-3 col-md-6">
            <input
              [(ngModel)]="form.lastName"
              name="lastName"
              placeholder="{{ 'Last name' | translate }}"
              class="form-control"
              autocomplete="surname"
              required
            />
          </div>
        </div>
        <div class="mb-3">
          <input
            [(ngModel)]="form.email"
            #email="ngModel"
            name="email"
            type="email"
            placeholder="{{ 'Email address' | translate }}"
            class="form-control"
            autocomplete="email"
            required
            email
          />
        </div>
        <div class="mb-3">
          <input
            [(ngModel)]="form.phoneNumber"
            name="phoneNumber"
            type="tel"
            placeholder="{{ 'Phone number' | translate }}"
            class="form-control"
            autocomplete="company email"
            required
          />
        </div>
        <button type="button" class="btn btn-light me-2" (click)="prevStage()">
          {{ 'Back' | translate }}
        </button>
        <button type="submit" class="btn btn-primary text-white">
          {{ 'Next' | translate }}
        </button>
      </form>

      <form
        #registerForm3="ngForm" (ngSubmit)="register(registerForm3.form)"
        [ngClass]="{ 'd-none': stage !== 3 }"
      >
        <h5 class="pb-2">{{ "Let's double-check your details" | translate }}</h5>
        <h5>{{ form.companyName }}</h5>
        <span class="text-muted">{{ companyIdentifierLabel() | translate }}:</span>
        {{ form.invoiceCountry + form.vatNumber }}<br>
        <span class="text-muted">{{ 'Email address' | translate }}:</span>
        {{ form.invoiceEmail }}<br>
        <span class="text-muted">{{ 'Address' | translate }}:</span>
        {{ form.streetAndNumber }}, {{ form.zipcode }} {{ form.city }}, {{ form.country }}<br>
        <span class="text-muted">{{ 'Type of activity' | translate }}:</span>
        {{ form.activity | titlecase }}<br>
        <span class="text-muted">{{ 'Currency' | translate }}:</span>
        {{ form.currency }}<br>

        <h5 class="mt-3">{{ 'Your first user' | translate }}</h5>
        <span class="text-muted">{{ 'Name' | translate }}:</span>
        {{ form.firstName }} {{ form.lastName }}<br>
        <span class="text-muted">{{ 'Email address' | translate }}:</span>
        {{ form.email }}<br>
        <span class="text-muted">{{ 'Phone number' | translate }}:</span>
        {{ form.phoneNumber }}<br>
        <br>

        <div class="form-check mb-3">
          <input
            if="termsAndConditions"
            [(ngModel)]="form.termsAndConditions"
            type="checkbox"
            name="termsAndConditions"
            class="form-check-input mt-2"
            required
          />
          <label class="form-label form-check-label" for="termsAndConditions">
            {{ 'I agree to the cinvio' | translate }}
            <a href="https://cinvio.com/terms-and-conditions/" target="_blank">
              {{ 'Terms & Conditions' | translate }}
            </a>
            {{ 'and' | translate }}
            <a href="https://cinvio.com/privacy/" target="_blank">
              {{ 'Privacy Policy' | translate }}
            </a>
          </label>
        </div>

        <button type="button" class="btn btn-light me-2" (click)="prevStage()">
          {{ 'Back' | translate }}
        </button>
        <app-loading-button btnType="submit" [isLoading]="loading$ | async">
          {{ 'Register' | translate }}
        </app-loading-button>
      </form>
    </div>
  </div>
</div>
