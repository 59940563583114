import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { UserTransactionActions } from 'app/store/actions';
import { HydraCollection, Transaction } from 'app/store/models';

@Injectable()
export class UserTransactionEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadTransactions$ = createEffect(() => this.actions$.pipe(
    ofType(UserTransactionActions.loadTransactions),
    switchMap(params => this.api.get<HydraCollection<Transaction>>({
      path: `/transaction_history?accountType=${params.accountType}`
        + (params.before ? `&createdBefore=${encodeURIComponent(params.before)}` : '')
        + (params.after ? `&createdAfter=${encodeURIComponent(params.after)}` : '')
        + (params.search ? `&search=${encodeURIComponent(params.search)}` : '')
        + (params.page ? `&page=${encodeURIComponent(params.page)}` : '')
        + (params.pageSize ? `&pageSize=${encodeURIComponent(params.pageSize)}` : '')
        + (params.softCharged !== null && params.softCharged !== undefined ? `&softCharged=${params.softCharged}` : '')
        + (params.noCount !== null && params.noCount !== undefined ? `&noCount=${params.noCount}` : ''),
    }).pipe(
      map(data => UserTransactionActions.loadTransactionsSuccess({
        items: data['hydra:member'],
        totalItems: data['hydra:totalItems'],
      })),
      catchError(err => of(UserTransactionActions.loadTransactionsFailure(err))),
    )),
  ));

  cancelTransaction$ = createEffect(() => this.actions$.pipe(
    ofType(UserTransactionActions.cancelTransaction),
    concatMap(({ transactionId }) => this.api.post<Transaction>({
      path: `/transactions/${transactionId}/cancel-internal`,
      body: {}
    }).pipe(
      map(() => UserTransactionActions.cancelTransactionSuccess({ transactionId })),
      catchError(err => of(UserTransactionActions.cancelTransactionFailure(err))),
    )),
  ));
}
