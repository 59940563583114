<h2>
  {{ 'Bundle' | translate }}
  <button type="button" class="btn btn-link pb-0" (click)="openBundleCreationModal()">
    {{ 'Add a new bundle' | translate }}
  </button>
</h2>
<table class="table">
  <thead>
    <tr>
      <th>{{ 'Name' | translate }}</th>
      <th>{{ 'Tokens' | translate }}</th>
      <th>{{ 'Price per token' | translate }}</th>
      <th>{{ 'Price' | translate }}</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let bundleTemplate of bundleTemplates; let bti = index;">
      <td>
        {{ bundleTemplate.bundleTemplateService.service.label }}
      </td>
      <td class="fixed">
        <ng-select
          [(ngModel)]="bundleTemplate.selectedPrice"
          [items]="bundleTemplate.bundleTemplateService.bundleTemplateServicePrices"
          [clearable]="false"
          bindLabel="tokens"
        ></ng-select>
      </td>
      <td class="fixed">
        <money [amount]="bundleTemplate.selectedPrice.pricePerToken" *ngIf="bundleTemplate.selectedPrice" [currency]="currentTenant?.currency.code"></money><span class="currency">*</span>
      </td>
      <td class="fixed">
        <money [amount]="bundleTemplate.selectedPrice.pricePerToken * bundleTemplate.selectedPrice.tokens" *ngIf="bundleTemplate.selectedPrice" [currency]="currentTenant?.currency.code"></money><span class="currency">*</span>
      </td>
      <td>
        <button class="btn btn-link" (click)="openBundleEditModal(bundleTemplate)">
          {{ 'Edit' | translate }}
        </button>

        <button class="btn btn-link" (click)="viewVersionHistory(bundleTemplate)">
          {{ 'View version history' | translate }}
        </button>

        <button type="button" *ngIf="bundleTemplate.versionId" class="btn btn-link" (click)="removeBundleTemplate(bundleTemplate)">
          {{ 'Delete bundle' | translate }}
        </button>
      </td>
    </tr>
  </tbody>
</table>

<foot-note *ngIf="bundleTemplates.length"></foot-note>

<ng-template #versionHistoryModal let-self>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'Version history' | translate }}</h4>
    <button type="button" class="btn-close" (click)="self.dismiss()" tabindex="-1">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="!(loadingVersionHistory$ | async)">
      <div *ngFor="let templateVersion of versionHistory$ | async; let i = index;">
        <p class="mt-3">
          <b>{{ templateVersion.bundleTemplateService.service.label }}</b> ({{ 'Version' | translate }} {{ templateVersion.version }}, {{ templateVersion.createdAt | date:'dd-MM-yyyy HH:mm:ss' }})
          <span *ngIf="i === 0" class="badge badge-success">{{ 'live' | translate }}</span>
        </p>
        <table class="table">
          <thead>
            <tr>
              <th>{{ 'Tokens' | translate }}</th>
              <th>{{ 'Price per token' | translate }}</th>
              <th>{{ 'Price' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="price" *ngFor="let price of templateVersion.bundleTemplateService.bundleTemplateServicePrices" [ngClass]="{default: price.isDefault}">
              <td>
                {{ price.tokens }}
              </td>
              <td>
                <money [amount]="price.pricePerToken" [currency]="currentTenant?.currency.code"></money>
              </td>
              <td>
                <money [amount]="price.pricePerToken * price.tokens" [currency]="currentTenant?.currency.code"></money>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
    <foot-note *ngIf="(versionHistory$ | async)?.length"></foot-note>
  </div>
</ng-template>
