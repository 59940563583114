import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { UserContextActions } from 'app/store/actions';
import { RoleCode, State, UserContext } from 'app/store/models';
import { byRoleAuthority } from 'app/store/models/role.model';
import { selectCurrentUserContext, selectRoles } from 'app/store/selectors';
import { max, notNull, untilDestroyed } from 'app/utils/rxjs';

import { ConfirmActionModalComponent } from './confirm-action-modal.component';

@Component({
  selector: 'app-settings-users',
  templateUrl: './settings-users.component.html',
})
export class SettingsUsersComponent implements OnDestroy {
  loadingUserContexts$: Observable<boolean>;
  savingUserContext$: Observable<boolean>;
  userContexts$: Observable<UserContext[]>;
  currentUserContext: UserContext | null = null;
  maxRole: string;
  activeUserContext: UserContext | null = null;
  userContextForm: UntypedFormGroup;
  modalInstance: any;

  constructor(
    private store: Store<State>,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private actions$: Actions,
    private translate: TranslateService,
  ) {
    this.loadingUserContexts$ = this.store.select(x => x.userContext.loadingItems);
    this.savingUserContext$ = this.store.select(x => x.userContext.savingItem);
    this.userContexts$ = this.store.select(x => x.userContext.items).pipe(
      filter(notNull),
      startWith([]),
    );
    this.maxRole = RoleCode.user;
    this.store.select(selectRoles).pipe(
      filter(notNull),
      untilDestroyed(this),
    ).subscribe(roles => {
      this.maxRole = roles.map(r => r.code).reduce(max(byRoleAuthority), RoleCode.user);
      this.store.dispatch(UserContextActions.loadUserContexts({
        superAdmin: this.maxRole === RoleCode.superAdmin,
      }));
    });
    this.store.select(selectCurrentUserContext).pipe(
      untilDestroyed(this),
      filter(notNull),
    ).subscribe(userContext => {
      this.currentUserContext = userContext;
    });
    this.userContextForm = this.fb.group({
      email: [null, Validators.required],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phoneNumber: [null],
    });

    this.actions$.pipe(
      ofType(
        UserContextActions.createUserContextWithRoleSuccess,
        UserContextActions.updateUserContextSuccess,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      this.modalInstance.dismiss();
    });
  }

  newUser(modal: any) {
    this.activeUserContext = null;
    this.userContextForm.reset();
    this.modalInstance = this.modalService.open(modal);
  }

  editUser(userContext: UserContext, modal: any) {
    this.activeUserContext = userContext;
    this.userContextForm.reset();
    this.userContextForm.patchValue({
      ...this.activeUserContext.user,
      ...this.activeUserContext,
    });
    this.modalInstance = this.modalService.open(modal);
  }

  deleteUser(uc: UserContext) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.title = this.translate.instant('Delete User')
      + ' ' + uc.user.firstName + ' ' + uc.user.lastName;
    modalRef.componentInstance.message = this.translate.instant('Are you sure you want to delete this user?');
    modalRef.componentInstance.confirmButtonText = marker('Yes');
    modalRef.result.then(() => this.store.dispatch(UserContextActions.deleteUserContext({ userContextId: uc.id })));
  }

  onNewUserSubmit() {
    if (this.userContextForm.invalid) {
      this.userContextForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(UserContextActions.createUserContextWithRole({
      userContext: {
        ...this.userContextForm.value,
        role: this.maxRole,
      },
    }));
  }

  onEditUserSubmit() {
    if (this.userContextForm.invalid || this.activeUserContext === null) {
      this.userContextForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(UserContextActions.updateUserContext({
      userContext: {
        ...this.userContextForm.value,
        id: this.activeUserContext.id,
        user: {
          ...this.activeUserContext.user,
          '@id': undefined,
          '@type': undefined,
          email: this.userContextForm.value.email,
          firstName: this.userContextForm.value.firstName,
          lastName: this.userContextForm.value.lastName,
          superAdmin: undefined,
        },
      },
    }));
  }

  isCurrentUser(uc: UserContext) {
    return (this.currentUserContext !== null && uc.id === this.currentUserContext.id);
  }

  ngOnDestroy() { }
}
