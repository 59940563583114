import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions } from 'app/store/actions';
import { CommonState } from 'app/store/models';

const empty: CommonState = {
  countries: null,
  countriesLoading: false,
  currencies: null,
  currenciesLoading: false,
  originalUrl: null,
  invoicingSoftware: null,
  invoicingSoftwareLoading: false,
};

const reducer = createReducer<CommonState>(
  empty,
  on(CommonActions.clearAll, (state) => ({
    ...empty,
    originalUrl: state.originalUrl,
  })),

  on(CommonActions.loadPublicCountries, (state) => ({
    ...state,
    countriesLoading: true,
  })),
  on(CommonActions.loadPublicCountriesSuccess, (state, { countries }) => ({
    ...state,
    countries,
    countriesLoading: false,
  })),
  on(CommonActions.loadPublicCountriesFailure, (state) => ({
    ...state,
    countriesLoading: false,
  })),

  on(CommonActions.loadPublicCurrencies, (state) => ({
    ...state,
    currenciesLoading: true,
  })),
  on(CommonActions.loadPublicCurrenciesSuccess, (state, { currencies }) => ({
    ...state,
    currencies,
    currenciesLoading: false,
  })),
  on(CommonActions.loadPublicCurrenciesFailure, (state) => ({
    ...state,
    currenciesLoading: false,
  })),

  on(CommonActions.loadInvoicingSoftware, (state) => ({
    ...state,
    invoicingSoftwareLoading: true,
  })),
  on(CommonActions.loadInvoicingSoftwareSuccess, (state, { invoicingSoftware }) => ({
    ...state,
    invoicingSoftware,
    invoicingSoftwareLoading: false,
  })),
  on(CommonActions.loadInvoicingSoftwareFailure, (state) => ({
    ...state,
    invoicingSoftwareLoading: false,
  })),

  on(CommonActions.setOriginalUrl, (state, { url }) => ({
    ...state,
    originalUrl: url,
  })),
);

export function commonReducer(state: CommonState | undefined, action: Action) {
  return reducer(state, action);
}
