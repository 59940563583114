import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, ServiceActions } from 'app/store/actions';
import { ServiceState } from 'app/store/models';

const empty: ServiceState = {
  items: null,
  loadingItems: false,
  savingItem: false,
  totalItems: null,
};

const reducer = createReducer<ServiceState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(ServiceActions.loadServices, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(ServiceActions.loadServicesSuccess, (state, { services, totalItems }) => ({
    ...state,
    items: services,
    totalItems,
    loadingItems: false,
  })),
  on(ServiceActions.loadServicesFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(ServiceActions.createService, (state) => ({
    ...state,
    savingItem: true,
  })),
  on(ServiceActions.createServiceSuccess, (state, { service }) => ({
    ...state,
    items: [...state.items || [], service],
    savingItem: false,
  })),
  on(ServiceActions.createServiceFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(ServiceActions.updateService, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(ServiceActions.updateServiceSuccess, (state, { service }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === service.id ? service : x),
    savingItem: false,
  })),
  on(ServiceActions.updateServiceFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(ServiceActions.deleteService, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(ServiceActions.deleteServiceSuccess, (state, { serviceId }) => ({
    ...state,
    items: (state.items || []).filter(x => x.id !== serviceId),
    savingItem: false,
  })),
  on(ServiceActions.deleteServiceFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),
);

export function serviceReducer(state: ServiceState | undefined, action: Action) {
  return reducer(state, action);
}
