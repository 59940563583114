import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { HydraCollection } from 'app/store/models';
import { SubscriptionInvoicingActions } from 'app/store/actions';
import { SubscriptionTemplateTier } from '../models/subscription.model';

@Injectable()
export class SubscriptionInvoicingEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadSubscriptionTemplateTiersForInvoicing$ = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionInvoicingActions.loadSubscriptionTemplateTiers),
    concatMap(() => this.api.get<HydraCollection<SubscriptionTemplateTier>>({
      path: '/subscription_template_tiers_for_invoicing',
    }).pipe(
      map(data => SubscriptionInvoicingActions.loadSubscriptionTemplateTiersSuccess({ items: data['hydra:member'] })),
      catchError(err => of(SubscriptionInvoicingActions.loadSubscriptionTemplateTiersFailure(err))),
    )),
  ));

  updateSubscriptionTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionInvoicingActions.updateSubscriptionTemplateTier),
    concatMap(({ payload, id }) => this.api.put<SubscriptionTemplateTier>({
      path: `/subscription_template_tiers/${id}`,
      body: payload,
    }).pipe(
      map(data => SubscriptionInvoicingActions.updateSubscriptionTemplateTierSuccess({ tier: data })),
      catchError(err => of(SubscriptionInvoicingActions.updateSubscriptionTemplateTierFailure(err))),
    )),
  ));
}
