import { makeActions, ofType } from 'app/store/actions/utils';
import { Subscription, SubscriptionTemplate } from '../models/subscription.model';

export const SubscriptionActions = makeActions({
  loadSubscriptions: null,
  loadSubscriptionsSuccess: ofType<{ items: Subscription[]; }>(),
  loadSubscriptionsFailure: ofType<{ payload: any; }>(),

  loadSubscriptionsForProvider: ofType<{
    page?: number;
    pageSize?: number;
    query?: string;
    status?: string;
  }>(),
  loadSubscriptionsForProviderSuccess: ofType<{ items: Subscription[]; totalItems: number; }>(),
  loadSubscriptionsForProviderFailure: ofType<{ payload: any; }>(),

  loadSubscriptionTemplatesForUser: ofType<{ providerId: string; }>(),
  loadSubscriptionTemplatesForUserSuccess: ofType<{ items: SubscriptionTemplate[]; }>(),
  loadSubscriptionTemplatesForUserFailure: ofType<{ payload: any; }>(),

  startSubscription: ofType<{ payload: { subscriptionTemplateTier: string; billing: string; } }>(),
  startSubscriptionSuccess: ofType<{ subscription: Subscription; }>(),
  startSubscriptionFailure: ofType<{ error: any; }>(),

  cancelSubscription: ofType<{ id: string; }>(),
  cancelSubscriptionSuccess: ofType<{ subscription: Subscription; }>(),
  cancelSubscriptionFailure: ofType<{ error: any; }>(),

  reactivateSubscription: ofType<{ id: string; }>(),
  reactivateSubscriptionSuccess: ofType<{ subscription: Subscription; }>(),
  reactivateSubscriptionFailure: ofType<{ error: any; }>(),

  upgradeSubscription: ofType<{ id: string; tierId: string; billing: string; upgradeNow: boolean; }>(),
  upgradeSubscriptionSuccess: ofType<{ subscription: Subscription; }>(),
  upgradeSubscriptionFailure: ofType<{ error: any; }>(),

  cancelScheduledUpgrade: ofType<{ id: string; }>(),
  cancelScheduledUpgradeSuccess: ofType<{ subscription: Subscription; }>(),
  cancelScheduledUpgradeFailure: ofType<{ error: any; }>(),
});
