import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Transaction } from 'app/store/models';
import { CompanyIdentifierType } from 'app/store/models/country.model';
import { TransactionTypes } from 'app/store/models/transaction.model';

import * as en_GB from '../../assets/i18n/en.json';

@Injectable()
export class I18nService {
  defaultLanguage = 'en_GB';
  supportedLanguages: string[] = ['en_GB'];

  constructor(public translateService: TranslateService) {
    // Embed languages to avoid extra HTTP requests
    translateService.setTranslation('en_GB', en_GB.default);
    translateService.setDefaultLang('en_GB');

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem('language', event.lang);
    });
  }

  set language(language: string) {
    language = language || (localStorage.getItem(language) as string);
    let isSupportedLanguage = this.supportedLanguages.indexOf(language) !== -1;
    // If no exact match is found, search without the region
    if (language && !isSupportedLanguage) {
      language = language.split('_')[0];
      language = this.supportedLanguages.find(l => l.startsWith(language)) || '';
      isSupportedLanguage = Boolean(language);
    }

    // Fallback if language is not supported
    if (!isSupportedLanguage) {
      console.log('Language not found, falling back to default.');
      language = this.defaultLanguage;
      this.translateService.use(language);
      return;
    }

    localStorage.setItem('language', language);
    this.translateService.use(language);
  }

  get language(): string {
    return this.translateService.currentLang;
  }

  get timezone(): string|null {
    let timezone = null;

    if (typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function') {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    return timezone;
  }

  get country(): string|null {
    let country = null;

    if (typeof navigator === 'object' && typeof navigator.language === 'string') {
      country = navigator.language.slice(-2);
    }

    return country;
  }

  get languages(): { value: string; label: string }[] {
    return [
      {
        value: 'en_GB',
        label: 'English',
      },
    ];
  }

  getServiceLabel(transaction: Transaction): string|null {
    if (transaction.service) {
      return transaction.service.label;
    }

    if (transaction.type === TransactionTypes.TYPE_CASH_IN ||
      transaction.type === TransactionTypes.TYPE_VOUCHER_EXPIRATION ||
      transaction.type === TransactionTypes.TYPE_PAY_OUT ||
      transaction.type === TransactionTypes.TYPE_REIMBURSEMENT ||
      transaction.type === TransactionTypes.TYPE_ADMIN_FEE
    ) {
      return this.translateService.instant(transaction.type);
    }

    return null;
  }

  getServiceProviderName(transaction: Transaction): string|null {
    if (transaction.service) {
      return transaction.service.serviceProvider.name;
    } else if (transaction.subscription) {
      return transaction.subscription.subscriptionTemplateTier.subscriptionTemplate.serviceProvider.name;
    } else if (transaction.bundle) {
      return transaction.bundle.bundleTemplate.serviceProvider.name;
    }

    if (transaction.type === TransactionTypes.TYPE_CASH_IN ||
      transaction.type === TransactionTypes.TYPE_VOUCHER_EXPIRATION ||
      transaction.type === TransactionTypes.TYPE_PAY_OUT ||
      transaction.type === TransactionTypes.TYPE_REIMBURSEMENT ||
      transaction.type === TransactionTypes.TYPE_ADMIN_FEE
    ) {
      return 'cinvio'; // TODO load from legal entity
    }

    return null;
  }

  companyIdentifierLabel(companyIdentifierType: string): string {
    let label;

    if (companyIdentifierType === CompanyIdentifierType.COMPANY_IDENTIFIER_VAT) {
      label = 'VAT number';
    } else if (companyIdentifierType === CompanyIdentifierType.COMPANY_IDENTIFIER_UEN) {
      label = 'UEN number';
    } else {
      label = 'Company identifier';
    }

    return this.translateService.instant(label);
  }
}
