import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { SessionActions, UserStatisticsActions, UserTransactionActions } from 'app/store/actions';
import { Account, AccountType, Earning, State, Transaction } from 'app/store/models';
import { selectCurrentTenant } from 'app/store/selectors';
import { notNull, untilDestroyed } from 'app/utils/rxjs';
import { RechargeWalletModalComponent } from './recharge-wallet-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const LS_KEY = 'dashboard-period';
export enum Period {
  WEEK = '7d',
  TWO_WEEKS = '14d',
}

@Component({
  selector: 'app-dashboard-cinvio',
  templateUrl: './dashboard-cinvio.component.html',
})
export class DashboardCinvioComponent implements OnDestroy, OnInit {
  earnerAccounts$: Observable<Account[]>;
  spenderAccounts$: Observable<Account[]>;
  transactions$: Observable<Transaction[]>;
  earnings$: Observable<Earning[]>;
  period$: BehaviorSubject<Period>;
  loading$: Observable<boolean>;
  Period = Period;
  latestTransactionTime: Date = new Date();
  autoRefreshSubscription?: Subscription;

  constructor(
    private store: Store<State>,
    private api: ApiService,
    private modalService: NgbModal,
  ) {
    this.earnerAccounts$ = this.store.select(selectCurrentTenant).pipe(
      filter(notNull),
      map(accs => accs.accounts.filter(a => a.type === AccountType.CINVIO)),
      filter(notNull),
    );
    this.spenderAccounts$ = this.store.select(selectCurrentTenant).pipe(
      filter(notNull),
      map(accs => accs.accounts.filter(a => a.type === AccountType.SPENDER)),
      filter(notNull),
    );
    this.transactions$ = this.store.select(x => x.transaction.items).pipe(
      filter(notNull),
    );
    this.earnings$ = this.store.select(x => x.statistics.earnings).pipe(
      filter(notNull),
    );
    this.loading$ = this.store.select(x => x.transaction.loading);

    this.loadTransactions();

    // Load earnings
    this.period$ = new BehaviorSubject<Period>(
      localStorage.getItem(LS_KEY) as Period || Period.WEEK,
    );
    this.period$.pipe(untilDestroyed(this)).subscribe(x => {
      localStorage.setItem(LS_KEY, x);
      this.loadEarnings();
    });

    // setup auto-refresh
    this.autoRefreshSubscription = interval(5000).subscribe(() => {
      this.api.get<{ exists: boolean }>({
        path: `/new-transaction-exists?since=${this.latestTransactionTime.toISOString()}`,
      }).toPromise().then(res => {
        if (res.exists) {
          this.loadTransactions();
          this.loadEarnings();
          this.store.dispatch(SessionActions.loadUserContext());
          this.latestTransactionTime = new Date();
        }
      }, () => { });
    });
  }

  ngOnInit() {
    this.period$.next(this.period$.getValue());
    // Refresh account state
    this.store.dispatch(SessionActions.loadUserContext());
  }

  loadTransactions() {
    this.store.dispatch(UserTransactionActions.loadTransactions({
      accountType: AccountType.CINVIO,
      page: 1,
      pageSize: 30,
      noCount: true,
    }));
  }

  loadEarnings() {
    const period = this.period$.getValue() === Period.WEEK ? 6 : 13;
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - period);
    this.store.dispatch(UserStatisticsActions.loadEarnings({
      accountType: AccountType.CINVIO,
      after: `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()} 00:00:00`,
      before: `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} 23:59:59`,
    }));
  }

  ngOnDestroy() {
    if (this.autoRefreshSubscription) {
      this.autoRefreshSubscription.unsubscribe();
      this.autoRefreshSubscription = undefined;
    }
  }

  sortAccounts(accounts: Account[] | null) : Account[] {
    if (accounts) {
      return accounts.sort( (a,b) => {
        return (+b.available + +b.reserved) - (+a.available + +a.reserved)})
    }
    return []
  }
}
