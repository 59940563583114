import { Component, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { I18nService } from 'app/services/i18n.service';
import { LazyLoaderService } from 'app/services/lazy-loader.service';
import { SessionActions } from 'app/store/actions';
import { Country, RegistrationForm, State } from 'app/store/models';
import { ActivityTypes } from 'app/store/models/tenant.model';
import { selectOriginalUrl } from 'app/store/selectors';
import { CompanyIdentifierType } from 'app/store/models/country.model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  loading$: Observable<boolean>;
  currencies$: Observable<object[]>;
  currencyMap: { [k: string]: string } = {};
  countries$: Observable<object[]>;
  countryMap: { [k: string]: Country } = {};
  activityTypes: { label: string, value: string }[] = [];
  stage = 1;
  form: RegistrationForm = {
    vatNumber: '',
    invoiceCountry: 'BE',
    currency: 'EUR',
    companyName: '',
    streetAndNumber: '',
    zipcode: '',
    city: '',
    country: 'BE',
    activity: '',

    firstName: '',
    lastName: '',
    email: '',
    invoiceEmail: '',
    phoneNumber: '',
    termsAndConditions: false,
  };
  pairingUrl: string | null = null;

  @ViewChild('registerForm1', { static: true })
  registerForm?: NgForm;

  constructor(
    private store: Store<State>,
    private loader: LazyLoaderService,
    private translate: I18nService,
  ) {
    this.loading$ = this.store.select(x => x.session.loadingRegister);
    this.currencies$ = this.loader.getCurrencies().pipe(
      tap(xs => xs.forEach(x => this.currencyMap[x.code] = x['@id'])),
    );
    this.store.select(selectOriginalUrl).subscribe(originalUrl => {
      if (originalUrl === null) {
        return;
      }
      if ( // could we maybe ask the router here?
        originalUrl.toLowerCase().includes('pair') &&
        originalUrl.toLowerCase().includes('clientapplicationid') &&
        originalUrl.toLowerCase().includes('usercompanyid')
      ) {
        this.pairingUrl = originalUrl;

        // pre-fill vat number and country fields from stored pairing URL
        const vatNumber = new URL(originalUrl.toLocaleLowerCase(), 'http://example.com').searchParams.get('usercompanyvatnumber');
        if (vatNumber !== null && vatNumber.length > 2) {
          setTimeout(() => { 
            this.registerForm?.controls.country.setValue(vatNumber.substr(0, 2).toUpperCase());
            this.registerForm?.controls.vatNumber.setValue({invoiceCountry: vatNumber.substr(0, 2).toUpperCase(), 'vat-number': vatNumber.substr(2)});
            this.registerForm?.controls.vatNumber.markAsTouched();
          });
        }
      }
    });

    this.countries$ = this.loader.getCountries().pipe(
      tap(xs => {
        xs.forEach(x => this.countryMap[x.value] = x)
        // pre-fill country from browser locale (but only if it was not already set from pairing URL)
        if (this.translate.country && this.countryMap[this.translate.country] !== undefined && this.form.vatNumber === '') {
          setTimeout(() => { 
            this.registerForm?.controls.country.setValue(this.translate.country);
            this.registerForm?.controls.vatNumber.setValue({invoiceCountry: this.translate.country, 'vat-number': ''});
          });
        }
      }),
      map(xs => xs.map(x => ({
        label: x.value,
        value: x.value,
      }))),
    );

    Object.values(ActivityTypes).forEach(item => {
      this.activityTypes.push({ label: this.translate.translateService.instant('activity_' + item), value: item });
    });
  }

  prevStage() {
    this.stage = Math.max(1, this.stage - 1);
  }

  nextStage(form: UntypedFormGroup) {
    form.markAllAsTouched();
    if (form.valid) {
      this.stage = Math.min(3, this.stage + 1);
    }
  }

  register(form: UntypedFormGroup) {
    form.markAllAsTouched();
    if (!form.valid) {
      return;
    }
    this.store.dispatch(SessionActions.register({
      registration: {
        ...this.form,
        vatNumber: this.countryMap[this.form.invoiceCountry].companyIdentifierType === CompanyIdentifierType.COMPANY_IDENTIFIER_VAT
          ? this.form.invoiceCountry + this.form.vatNumber.toString()
          : this.form.vatNumber.toString(),
        currency: this.currencyMap[this.form.currency],
        country: this.countryMap[this.form.country]['@id'],
        pairingUrl: this.pairingUrl || undefined,
        termsAndConditions: undefined,
      },
    }));
  }

  companyIdentifierLabel(): string {
    const companyIdentifierType = this.countryMap[this.form.invoiceCountry]?.companyIdentifierType || CompanyIdentifierType.COMPANY_IDENTIFIER_VAT

    return this.translate.companyIdentifierLabel(companyIdentifierType);
  }

  companyIdentifierPlaceholder(): string|null {
    if (this.form.invoiceCountry === 'CH') {
      return 'E012345678MWST';
    }
    return null;
  }
}
