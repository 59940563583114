import { makeActions, ofType } from 'app/store/actions/utils';
import { Service, ServiceProvider } from 'app/store/models';

export const UserServiceActions = makeActions({
  loadLinkedServices: null,
  loadLinkedServicesSuccess: ofType<{ items: Service[]; }>(),
  loadLinkedServicesFailure: ofType<{ payload: any; }>(),

  loadUnlinkedServices: null,
  loadUnlinkedServicesSuccess: ofType<{ items: Service[]; }>(),
  loadUnlinkedServicesFailure: ofType<{ payload: any; }>(),

  loadSubProviders: null,
  loadSubProvidersSuccess: ofType<{ items: ServiceProvider[]; }>(),
  loadSubProvidersFailure: ofType<{ payload: any; }>(),
});
