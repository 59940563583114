import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fileSaver from 'file-saver-es';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, startWith } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { DocumentActions, ServiceConfigurationActions } from 'app/store/actions';
import { AccountType, Document, State, Tenant } from 'app/store/models';
import { selectCurrentTenant } from 'app/store/selectors';
import { notNull, untilDestroyed } from 'app/utils/rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nService } from 'app/services/i18n.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbDatepickerNavigateEvent, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ToastService } from 'app/services/toast.service';

@Component({
  selector: 'app-documents-exact',
  templateUrl: './documents-exact.component.html',
  styleUrls: ['./documents-exact.component.scss'],
})
export class DocumentsExactComponent implements OnDestroy {
  loadingDocuments$: Observable<boolean>;
  documents$: Observable<Document[]>;
  linkedTenants$: Observable<Tenant[]>;
  downloadFlags: { [k: string]: boolean } = {};
  currentTenantIsServiceProvider = false;
  currentTenantIsLegalEntity = false;
  userCompany?: string;
  account$ = new BehaviorSubject<AccountType|null>(null);
  accountSubscription: Subscription;
  tenantSubscription: Subscription;
  modalInstance: any;
  financialReportForm: UntypedFormGroup;
  financialReportMinDate: NgbDateStruct = { year: 2020, month: 1, day: 1 };
  financialReportMaxDate: NgbDateStruct = {
    year: (new Date()).getFullYear(),
    month: (new Date()).getMonth() ?? 12,
    day: 1
  };
  downloadingFinancialReport = false;

  @ViewChild('financialReportModal', { static: true })
  financialReportModal?: TemplateRef<any>;

  constructor(
    private store: Store<State>,
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private i18n: I18nService,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private toastr: ToastService,
  ) {
    this.loadingDocuments$ = this.store.select(x => x.document.loadingItems);

    this.tenantSubscription = this.store.select(selectCurrentTenant).pipe(
      untilDestroyed(this),
      filter(notNull),
    ).subscribe(tenant => {
      this.currentTenantIsServiceProvider = !!tenant.isServiceProvider;
      this.currentTenantIsLegalEntity = !!tenant.isLegalEntity;
      if (this.currentTenantIsLegalEntity) {
        this.account$.next(AccountType.CINVIO);
      } else if (this.currentTenantIsServiceProvider) {
        switch (this.route.snapshot.url[0]?.path) {
          case 'spender':
            this.account$.next(AccountType.SPENDER);
            break;
          case 'earner':
          default:
            this.store.dispatch(ServiceConfigurationActions.loadTenantsForConfigurator());
            this.account$.next(AccountType.EARNER);
        }
      } else {
        if (this.route.snapshot.url[0]?.path === 'earner') {
          this.router.navigateByUrl('/documents/spender');
        } else {
          this.account$.next(AccountType.SPENDER);
        }
      }
    });

    this.documents$ = this.store.select(x => x.document.items).pipe(
      filter(notNull),
      startWith([]),
    );

    this.linkedTenants$ = this.store.select(x => x.serviceConfiguration.tenants).pipe(
      filter(notNull),
      startWith([]),
    );

    // reload documents on account type change
    this.accountSubscription = this.account$.pipe(
      filter(notNull),
      distinctUntilChanged(),
      untilDestroyed(this),
    ).subscribe(accountType => {
      this.store.dispatch(DocumentActions.resetDocuments());
      if (accountType !== AccountType.EARNER) {
        this.store.dispatch(DocumentActions.loadDocuments({}));
      } else if (this.userCompany) {
        this.handleUserCompanyChange(this.userCompany);
      }
    });

    this.financialReportForm = this.fb.group({
      since: [null, Validators.required],
      until: [null, Validators.required],
    });
  }

  // reload documents on user company change
  handleUserCompanyChange(userCompany?: string) {
    this.userCompany = userCompany;
    if (this.userCompany) {
      this.store.dispatch(DocumentActions.loadDocuments({userCompany}));
    }
  }

  downloadDocument(document: Document) {
    this.downloadFlags[document.id] = true;
    this.api.getBlob({
      path: `/exact_online_documents/${document.id}/download`,
    }).toPromise().then(
      blob => {
        this.downloadFlags[document.id] = false;
        fileSaver.saveAs(blob);
      },
      () => {
        this.downloadFlags[document.id] = false;
      },
    );
  }

  downloadReport(invoiceId: string) {
    this.downloadFlags[invoiceId] = true;

    const timezone = this.i18n.timezone;
    const accountType = this.account$.getValue() ?? AccountType.SPENDER;
    const path = `/transaction_history/download/csv`
        + `?accountType=${encodeURIComponent(accountType)}`
        + `&invoiceId=${encodeURIComponent(invoiceId)}`
        + (timezone !== null ? `&timezone=${encodeURIComponent(timezone)}` : '')

    this.api.getBlob({
      path: path,
    }).toPromise().then(
      blob => {
        this.downloadFlags[invoiceId] = false;
        fileSaver.saveAs(blob, `transactions.csv`);
      },
      () => {
        this.downloadFlags[invoiceId] = false;
      },
    );
  }

  downloadFinancialReport() {
    this.modalInstance = this.modalService.open(this.financialReportModal, { size: 'md' });
  }

  financialReportFormDateChange(event: NgbDatepickerNavigateEvent, input: string) {
    this.financialReportForm.controls[input].setValue(event.next.year + '-' + String(event.next.month).padStart(2, '0'));
  }

  onFinancialReportFormSubmit() {
    if (this.financialReportForm.invalid) {
      this.financialReportForm.markAllAsTouched();
      return;
    }

    if (this.downloadingFinancialReport) {
      return;
    }

    this.downloadingFinancialReport = true;

    const path = `/export/financial-report/csv?since=${this.financialReportForm.value['since']}&until=${this.financialReportForm.value['until']}`;
    this.api.getBlob({
      path: path,
    }).toPromise().then(
      blob => {
        this.downloadingFinancialReport = false;
        fileSaver.saveAs(blob, `financial-report.csv`);
      },
      () => {
        this.api.get({
          path: path + '&schedule=true',
        }).toPromise().then(
          () => {
            this.downloadingFinancialReport = false;
            this.toastr.showInfo({
              title: marker('This is taking a while'),
              message: marker('We will send the report to your email address once it\'s ready.'),
              override: {
                disableTimeOut: true,
              }
            });
          },
          () => {
            this.downloadingFinancialReport = false;
          },
        );
      },
    );
  }

  ngOnDestroy() {
    this.accountSubscription.unsubscribe();
    this.tenantSubscription.unsubscribe();
  }
}
