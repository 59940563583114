import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { ServiceConfigurationActions } from 'app/store/actions';
import { HydraCollection, Service, Tenant } from 'app/store/models';

@Injectable()
export class ServiceConfigurationEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadServicesForConfigurator$ = createEffect(() => this.actions$.pipe(
    ofType(ServiceConfigurationActions.loadServicesForConfigurator),
    concatMap(() => this.api.get<HydraCollection<Service>>({
      path: '/services_for_configurator',
    }).pipe(
      map(data => ServiceConfigurationActions.loadServicesForConfiguratorSuccess({ items: data['hydra:member'] })),
      catchError(err => of(ServiceConfigurationActions.loadServicesForConfiguratorFailure(err))),
    )),
  ));

  loadTenantsForConfigurator$ = createEffect(() => this.actions$.pipe(
    ofType(ServiceConfigurationActions.loadTenantsForConfigurator),
    concatMap(() => this.api.get<HydraCollection<Tenant>>({
      path: '/tenants_for_service_configurator',
    }).pipe(
      map(data => ServiceConfigurationActions.loadTenantsForConfiguratorSuccess({ items: data['hydra:member'] })),
      catchError(err => of(ServiceConfigurationActions.loadTenantsForConfiguratorFailure(err))),
    )),
  ));

  updateServiceConfiguration$ = createEffect(() => this.actions$.pipe(
    ofType(ServiceConfigurationActions.updateServiceConfiguration),
    concatMap(({ id, configuration }) => this.api.put<Service>({
      path: `/services/${id}/configuration`,
      body: configuration,
    }).pipe(
      map(data => ServiceConfigurationActions.updateServiceConfigurationSuccess({ service: data })),
      catchError(err => of(ServiceConfigurationActions.updateServiceConfigurationFailure(err))),
    )),
  ));
}
