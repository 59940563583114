import { makeActions, ofType } from 'app/store/actions/utils';
import { SubscriptionTemplate } from '../models/subscription.model';

export const SubscriptionConfigurationActions = makeActions({
  loadSubscriptionTemplates: null,
  loadSubscriptionTemplatesSuccess: ofType<{ items: SubscriptionTemplate[]; }>(),
  loadSubscriptionTemplatesFailure: ofType<{ payload: any; }>(),

  loadSubscriptionTemplateVersions: ofType<{ versionId: string; }>(),
  loadSubscriptionTemplateVersionsSuccess: ofType<{ items: SubscriptionTemplate[]; }>(),
  loadSubscriptionTemplateVersionsFailure: ofType<{ payload: any; }>(),

  createSubscriptionTemplate: ofType<{ payload: SubscriptionTemplate; }>(),
  createSubscriptionTemplateSuccess: ofType<{ template: SubscriptionTemplate; }>(),
  createSubscriptionTemplateFailure: ofType<{ error: any; }>(),

  updateSubscriptionTemplate: ofType<{ payload: SubscriptionTemplate; id: string; }>(),
  updateSubscriptionTemplateSuccess: ofType<{ template: SubscriptionTemplate; }>(),
  updateSubscriptionTemplateFailure: ofType<{ error: any; }>(),

  deleteSubscriptionTemplate: ofType<{ templateId: string; }>(),
  deleteSubscriptionTemplateSuccess: ofType<{ templateId: string; }>(),
  deleteSubscriptionTemplateFailure: ofType<{ error: any; }>(),
});
