import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, SubscriptionConfigurationActions } from 'app/store/actions';
import { SubscriptionConfigurationState } from 'app/store/models';

const empty: SubscriptionConfigurationState = {
  items: null,
  versions: null,
  loadingItems: false,
  loadingVersions: false,
  savingItem: false,
};

const reducer = createReducer<SubscriptionConfigurationState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(SubscriptionConfigurationActions.loadSubscriptionTemplates, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(SubscriptionConfigurationActions.loadSubscriptionTemplatesSuccess, (state, { items }) => ({
    ...state,
    loadingItems: false,
    items: items,
  })),
  on(SubscriptionConfigurationActions.loadSubscriptionTemplatesFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(SubscriptionConfigurationActions.loadSubscriptionTemplateVersions, (state) => ({
    ...state,
    loadingVersions: true,
  })),
  on(SubscriptionConfigurationActions.loadSubscriptionTemplateVersionsSuccess, (state, { items }) => ({
    ...state,
    loadingVersions: false,
    versions: items,
  })),
  on(SubscriptionConfigurationActions.loadSubscriptionTemplateVersionsFailure, (state) => ({
    ...state,
    loadingVersions: false,
  })),

  on(SubscriptionConfigurationActions.createSubscriptionTemplate, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(SubscriptionConfigurationActions.createSubscriptionTemplateSuccess, (state, { template }) => ({
    ...state,
    items: state.items !== null && state.items.find(x => x.versionId === template.versionId)
      ? (state.items || []).map(x => x.versionId === template.versionId ? template : x)
      : [...state.items || [], template],
    savingItem: false,
  })),
  on(SubscriptionConfigurationActions.createSubscriptionTemplateFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(SubscriptionConfigurationActions.updateSubscriptionTemplate, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(SubscriptionConfigurationActions.updateSubscriptionTemplateSuccess, (state, { template }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === template.id ? template : x),
    savingItem: false,
  })),
  on(SubscriptionConfigurationActions.updateSubscriptionTemplateFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(SubscriptionConfigurationActions.deleteSubscriptionTemplate, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(SubscriptionConfigurationActions.deleteSubscriptionTemplateSuccess, (state, { templateId }) => ({
    ...state,
    items: (state.items || []).filter(x => x.id !== templateId),
    savingItem: false,
  })),
  on(SubscriptionConfigurationActions.deleteSubscriptionTemplateFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),
);

export function subscriptionConfigurationReducer(state: SubscriptionConfigurationState | undefined, action: Action) {
  return reducer(state, action);
}
