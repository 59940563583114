import { makeActions, ofType } from 'app/store/actions/utils';
import { AccountType, Earning } from 'app/store/models';

export const UserStatisticsActions = makeActions({
  loadEarnings: ofType<{
    before?: string;
    after?: string;
    accountType: AccountType;
  }>(),
  loadEarningsSuccess: ofType<{ earnings: Earning[]; }>(),
  loadEarningsFailure: ofType<{ payload: any; }>(),
});
