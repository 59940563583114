import { makeActions, ofType } from 'app/store/actions/utils';
import { Bundle, BundleTemplate } from '../models/bundle.model';

export const BundleConfigurationActions = makeActions({
  loadBundleTemplates: null,
  loadBundleTemplatesSuccess: ofType<{ items: BundleTemplate[]; }>(),
  loadBundleTemplatesFailure: ofType<{ payload: any; }>(),

  loadBundleTemplateVersions: ofType<{ versionId: string; }>(),
  loadBundleTemplateVersionsSuccess: ofType<{ items: BundleTemplate[]; }>(),
  loadBundleTemplateVersionsFailure: ofType<{ payload: any; }>(),

  createBundleTemplate: ofType<{ payload: BundleTemplate; }>(),
  createBundleTemplateSuccess: ofType<{ template: BundleTemplate; }>(),
  createBundleTemplateFailure: ofType<{ error: any; }>(),

  updateBundleTemplate: ofType<{ payload: BundleTemplate; id: string; }>(),
  updateBundleTemplateSuccess: ofType<{ template: BundleTemplate; }>(),
  updateBundleTemplateFailure: ofType<{ error: any; }>(),

  deleteBundleTemplate: ofType<{ templateId: string; }>(),
  deleteBundleTemplateSuccess: ofType<{ templateId: string; }>(),
  deleteBundleTemplateFailure: ofType<{ error: any; }>(),
});
