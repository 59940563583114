import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastService } from 'app/services/toast.service';

interface Config {
  apiVersion: 'v1';
  apiUrl?: string;
  sentryUrl?: string;
  clientId?: string;
  clientSecret?: string;
  oauthUrl?: string;
}

@Injectable()
export class AppConfig {

  public config: Config = {
    apiVersion: 'v1',
  };

  constructor(private injector: Injector) { }

  public getConfig<K extends keyof Config>(key: K): Config[K] {
    return this.config[key];
  }

  public load(): Promise<Config> {
    return new Promise(
      resolve => this.injector.get<HttpClient>(HttpClient)
        .get<Config>('assets/config.json')
        .pipe(catchError((e: any) => {
          this.injector.get<ToastService>(ToastService).showDanger({
            title: 'Failed to start application',
            message: 'Error while loading application configuration',
          });
          return of(this.config);
        }))
        .subscribe((res: Config) => {
          this.config = res;
          resolve(res);
        }),
    );
  }
}
