import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import { NgbDate, NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import * as fileSaver from 'file-saver-es';
import { reverse } from 'fp-ts/es6/Array';
import { BehaviorSubject, combineLatest, interval, merge, Observable, Subject, Subscription } from 'rxjs';
import {
  debounceTime, distinctUntilChanged, filter, map, skip, startWith, take, withLatestFrom,
} from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { I18nService } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
import { SessionActions, UserTransactionActions } from 'app/store/actions';
import { AccountType, RoleCode, State, Transaction } from 'app/store/models';
import { byRoleAuthority } from 'app/store/models/role.model';
import { selectCurrentTenant, selectRoles } from 'app/store/selectors';
import { max, notNull, untilDestroyed } from 'app/utils/rxjs';

@Component({
  selector: 'app-transaction-page',
  templateUrl: './transaction-page.component.html',
  styleUrls: ['./transaction-list.component.scss'],
})
export class TransactionPageComponent implements OnDestroy, OnInit {
  accountTypes$: Observable<AccountType[]>;
  pages$: Observable<number>;
  loading$: Observable<boolean>;
  items$: Observable<[Transaction[], AccountType]>;
  showFilter = false;
  exportForm: UntypedFormGroup;
  exporting = false;
  modal: NgbModalRef | null = null;
  view = 'list';
  showProviderPromo = false;
  showOnlyReserved = false;
  openSpender = false;

  account$ = new BehaviorSubject<AccountType>(AccountType.SPENDER);
  searchSource = new Subject<string>();
  search$: Observable<string>;
  pageSource = new Subject<number>();
  page$: Observable<number>;
  pageSize = 30;

  afterStartDate: NgbDateStruct;
  afterValue: NgbDate | null = null;
  afterSource = new Subject<NgbDate | null>();
  after$: Observable<NgbDate | null>;
  beforeStartDate: NgbDateStruct;
  beforeValue: NgbDate | null = null;
  beforeSource = new Subject<NgbDate | null>();
  before$: Observable<NgbDate | null>;
  softCharged$ = new BehaviorSubject<boolean>(false);

  latestTransactionTime: Date = new Date();
  autoRefreshSubscription?: Subscription;
  transactionsSubscription?: Subscription;
  transactionsSearchSubscription?: Subscription;

  constructor(
    private store: Store<State>,
    private fb: UntypedFormBuilder,
    private api: ApiService,
    private i18n: I18nService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private toastr: ToastService,
  ) {
    this.showOnlyReserved = this.route.snapshot.queryParamMap.get('showOnlyReserved') !== null;
    this.openSpender = this.route.snapshot.queryParamMap.get('openSpender') === 'true';
    if (this.showOnlyReserved) {
      this.showFilter = true;
      this.softCharged = true;
    }
    this.accountTypes$ = this.store.pipe(
      select(selectCurrentTenant),
      filter(notNull),
      map(tenant => [...new Set(
        reverse(tenant.accounts.map(a => a.type).filter(a => a !== AccountType.CINVIO_DEBT && a !== AccountType.CINVIO_VAT_BALANCE)),
      )]),
    );
    this.accountTypes$.pipe(
      untilDestroyed(this),
      withLatestFrom(this.store.select(selectRoles).pipe(filter(notNull))),
      take(1)
    ).subscribe(([types, roles]) => {
      const maxRole = roles.map(r => r.code).reduce(max(byRoleAuthority), RoleCode.user);
      this.showProviderPromo = maxRole === RoleCode.user;
      if ([RoleCode.superAdmin, RoleCode.admin].includes(maxRole)) {
        this.account$.next(AccountType.CINVIO);
      } else if (maxRole === RoleCode.serviceProvider) {
        this.account$.next(
          this.openSpender ? AccountType.SPENDER : AccountType.EARNER
        );
      } else if (maxRole === RoleCode.user) {
        this.account$.next(AccountType.SPENDER);
      } else {
        this.account$.next(types[0]);
      }
    });

    const state = this.store.select(x => x.transaction);
    this.loading$ = state.pipe(map(x => x.loading));
    this.items$ = state.pipe(map(x => x.items || []), withLatestFrom(this.account$));
    this.pages$ = state.pipe(map(x => {
      return Math.ceil((x.totalItems || 0) / this.pageSize);
    }));

    this.search$ = merge(
      this.searchSource.pipe(startWith('')),
      // Reset search when account type changes
      // It resets search also on auto-refresh, so I turned it off
      // this.account$.pipe(map(() => '')),
    );
    const searchDebounced = this.search$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
    );
    this.page$ = merge(
      this.pageSource.pipe(startWith(1)),
      // Reset paging when any of these changes
      merge(
        searchDebounced,
        this.account$,
        this.beforeSource,
        this.afterSource,
      ).pipe(map(() => 1)),
    );

    const now = new Date();
    this.afterStartDate = { year: 2020, month: 1, day: 1 };
    this.beforeStartDate = {
      year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate(),
    };
    this.after$ = this.afterSource.asObservable();
    this.before$ = this.beforeSource.asObservable();

    this.transactionsSubscription = combineLatest([
      this.account$, this.search$, this.page$, this.before$, this.after$, this.softCharged$,
    ]).pipe(
      filter(([a]) => a !== null), take(1),
    ).subscribe(([accountType, search, page, before, after, softCharged]) => {
      if (!accountType) {
        return;
      }
      this.store.dispatch(UserTransactionActions.loadTransactions({
        accountType,
        search,
        softCharged: softCharged || undefined,
        page,
        pageSize: this.pageSize,
        before: before ? `${before.year}-${before.month}-${before.day} 23:59:59` : undefined,
        after: after ? `${after.year}-${after.month}-${after.day} 00:00:00` : undefined,
      }));
    });

    this.transactionsSearchSubscription = combineLatest([
      this.account$, searchDebounced, this.page$, this.before$, this.after$, this.softCharged$,
    ]).pipe(
      skip(1),
      debounceTime(200),
      distinctUntilChanged(),
      untilDestroyed(this),
    ).subscribe(([accountType, search, page, before, after, softCharged]) => {
      if (!accountType) {
        return;
      }
      this.store.dispatch(UserTransactionActions.loadTransactions({
        accountType,
        search,
        softCharged: softCharged || undefined,
        page,
        pageSize: this.pageSize,
        before: before ? `${before.year}-${before.month}-${before.day} 23:59:59` : undefined,
        after: after ? `${after.year}-${after.month}-${after.day} 00:00:00` : undefined,
      }));
    });

    // setup auto-refresh
    this.autoRefreshSubscription = interval(5000).subscribe(() => {
      this.api.get<{ exists: boolean }>({
        path: `/new-transaction-exists?since=${this.latestTransactionTime.toISOString()}`,
      }).toPromise().then(res => {
        if (res.exists) {
          this.reloadData();
        }
      }, () => { });
    });

    this.exportForm = this.fb.group({
      format: null,
      createdAfter: null,
      createdBefore: null,
    });
  }

  reloadData(): void {
    this.store.dispatch(SessionActions.loadUserContext());
    this.latestTransactionTime = new Date();
  }

  ngOnInit() {
    // Trigger initial load
    this.resetFilters(true);
  }

  resetFilters(initial: boolean) {
    this.beforeSource.next(null);
    this.afterSource.next(null);
    this.beforeValue = NgbDate.from(this.beforeStartDate);
    this.afterValue = NgbDate.from(this.afterStartDate);
    // do not reset softCharged filter on initial component load, because it can be pre-set from query parameter
    if (initial !== true) {
      this.softCharged$.next(false);
    }
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  showExport(modal: any) {
    this.exporting = false;
    this.exportForm.patchValue({
      createdBefore: NgbDate.from(this.beforeStartDate),
      createdAfter: NgbDate.from(this.afterStartDate),
    });
    this.modal = this.modalService.open(modal);
  }

  closeExportModal() {
    this.exporting = false;
    if (this.modal) {
      this.modal.dismiss();
      this.modal = null;
    }
  }

  submitExport(format: string) {
    const { createdBefore, createdAfter } = this.exportForm.value;
    const before = `${createdBefore.year}-${createdBefore.month}-${createdBefore.day}`;
    const after = `${createdAfter.year}-${createdAfter.month}-${createdAfter.day}`;
    const timezone = this.i18n.timezone;
    const path = `/transaction_history/download/${format}`
        + `?accountType=${encodeURIComponent(this.account$.getValue())}`
        + `&createdBefore=${encodeURIComponent(before + ' 23:59:59')}`
        + `&createdAfter=${encodeURIComponent(after + ' 00:00:00')}`
        + (timezone !== null ? `&timezone=${encodeURIComponent(timezone)}` : '')
    this.exporting = true;
    this.api.getBlob({
      path: path,
    }).toPromise().then(
      blob => {
        this.closeExportModal();
        this.toastr.showSuccess({
          title: '',
          message: marker('Your file is now downloading.')
        });
        fileSaver.saveAs(blob, `transactions-${after}-${before}.${format}`);
      },
      () => {
        this.api.get({
          path: path + '&schedule=true',
        }).toPromise().then(
          () => {
            this.closeExportModal();
            this.toastr.showInfo({
              title: marker('This is taking a while'),
              message: marker('We will send the report to your email address once it\'s ready.'),
              override: {
                disableTimeOut: true,
              }
            });
          },
          () => {
            this.closeExportModal();
          },
        );
      },
    );
  }

  dateInputChange(event: any) {
    if ((event.type !== 'blur' && (event.type !== 'keyup' || event.keyCode !== 13)) || !event.target ||
      typeof this.beforeValue !== 'object' || typeof this.afterValue !== 'object' // validation
    ) {
      return;
    }
    switch (event.target.name) {
      case 'before':
        this.beforeSource.next(this.beforeValue);
        break;
      case 'after':
        this.afterSource.next(this.afterValue);
        break;
    }
  }

  get softCharged(): boolean {
    return this.softCharged$.value;
  }
  set softCharged(value: boolean) {
    this.softCharged$.next(value);
  }

  ngOnDestroy() {
    if (this.autoRefreshSubscription) {
      this.autoRefreshSubscription.unsubscribe();
      this.autoRefreshSubscription = undefined;
    }
    if (this.transactionsSubscription) {
      this.transactionsSubscription.unsubscribe();
      this.transactionsSubscription = undefined;
    }
    if (this.transactionsSearchSubscription) {
      this.transactionsSearchSubscription.unsubscribe();
      this.transactionsSearchSubscription = undefined;
    }
  }

  handleViewChange(value: boolean){
    this.view = value ? "list" : "table"
  }

  handleAccountChange(value: boolean) {
    this.account$.next(value ? AccountType.SPENDER : AccountType.EARNER);
  }

  isSpendingsAccountSelected() {
    return this.account$.value === AccountType.SPENDER
  }
}
