<div class="row fullscreen justify-content-center align-items-center">
  <div class="col-12 col-md-4 col-xl-3 card">
    <div class="card-body" *ngIf="companyMisconfigured">
      <p>
        {{ 'The company you are attempting to link to is misconfigured - either it offers no services, or it is using invalid linking parameters.' | translate }}
      </p>
      <p>
        {{ 'Please contact the administrators of your service provider.' | translate }}
      </p>
      <a routerLink="/" (click)="goBack($event)" class="btn btn-primary">{{ 'Go back' | translate }}</a>
    </div>

    <div class="card-body" *ngIf="!!companyInfo">
      <img
        *ngIf="companyInfo?.logo; let logo"
        [src]="sanitize(logo)"
        class="p-2" style="width:300px;max-width:100%"
      />
      <h3>{{ companyInfo?.name }}</h3>
      <p>{{ 'needs your authorisation to settle payments with your cinvio account.' | translate }}</p>

      <h4 *ngIf="(serviceList$ | async)?.length > 0">{{ 'Services' | translate }}</h4>
      <ul *ngIf="(serviceList$ | async)?.length > 0">
        <li *ngFor="let service of serviceList$ | async">
          <i class="far" [ngClass]="service.icon === 'fa-money' ? 'fa-money-bill' : service.icon"></i>
          {{ service.label }}
        </li>
      </ul>

      <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <div class="d-flex justify-items-around">
        <app-loading-button (click)="authorize()" [isLoading]="savingTenant">
          {{ 'Authorize' | translate }}
        </app-loading-button>
        <a routerLink="/" (click)="goBack($event)" class="btn btn-link">{{ 'Cancel' | translate }}</a>
      </div>
    </div>

    <div class="progress" *ngIf="!companyInfo && !companyMisconfigured">
      <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 100%"></div>
    </div>
  </div>
</div>
