<div class="mt-3 d-flex flex-column align-items-start">
  <div class="bundles" *ngFor="let bundles of bundlesByProvider$ | async">
    <div class="tenant-img">
      <img [src]="sanitize(bundles[0].bundleTemplate.serviceProvider.logo)">
    </div>
    <div class="services">
      <div class="service d-flex flex-column h-100 p-2">
        <table>
          <thead>
            <tr>
              <th>{{ 'Service' | translate }}</th>
              <th>{{ 'Available tokens' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let bundle of bundles">
              <td>
                <i class="far" [ngClass]="bundle.bundleService.bundleTemplateServicePrice.bundleTemplateService.service.icon"></i>
                {{ bundle.bundleService.bundleTemplateServicePrice.bundleTemplateService.service.label }}
              </td>
              <td>
                {{ bundle.bundleService.tokensAvailable }}/{{ bundle.bundleService.bundleTemplateServicePrice.tokens }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
