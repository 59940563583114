<div class="mt-3 d-flex flex-wrap">
  <app-provider-card
    *ngFor="let s of linkedServices$ | async"
    class="mb-2"
    [provider]="s[0].serviceProvider"
  ></app-provider-card>
  <app-provider-card
    *ngFor="let s of unlinkedServices$ | async"
    class="mb-2"
    [provider]="s[0].serviceProvider"
  ></app-provider-card>
  <app-provider-card
    *ngFor="let sp of subProviders$ | async"
    class="mb-2"
    [provider]="sp"
  ></app-provider-card>
</div>
