import { Service } from "./service.model";
import { Tenant } from "./tenant.model";
import { ServiceProvider } from "./service-provider.model";

export interface Subscription {
  '@id': string;
  '@type': string;
  id: string;
  billing: string;
  status: string;
  renewsOn: string|null;
  tokensResetOn: string|null;
  activeUntil: string|null;
  tenant?: Tenant;
  subscriptionTemplateTier: SubscriptionTemplateTier;
  subscriptionServices: SubscriptionService[];
  scheduledUpgrade?: SubscriptionUpgrade;
  upgradeTiers?: SubscriptionUpgradeTier[];
}

export interface SubscriptionTemplate {
  '@id': string;
  '@type': string;
  id: string;
  label: string;
  published: boolean;
  versionId: string;
  version: number;
  serviceProvider: ServiceProvider;
  subscriptionTemplateTiers?: SubscriptionTemplateTier[];
  allowedTenants?: Tenant[];
  active: boolean;
}

export interface SubscriptionTemplateTier {
  '@id': string;
  '@type': string;
  id: string;
  label: string;
  priceMonthly: string | null;
  priceYearly: string | null;
  highlighted: boolean;
  subscriptionTemplate: SubscriptionTemplate;
  subscriptionTemplateTierServices?: SubscriptionTemplateTierService[];
  eoInvoiceCode: string | null;
  eoSameCountryCode: string | null;
  eoDifferentEuCountryCode: string | null;
  eoDifferentNonEuCountryCode: string | null;
}

export interface SubscriptionTemplateTierService {
  service: Service;
  tokens: number | null;
}

export interface SubscriptionService {
  subscriptionTemplateTierService: SubscriptionTemplateTierService;
  tokensAvailable: number | null;
  tokensReserved: number;
  tokenConsumptions: number;
  deleted: boolean;
}

export interface SubscriptionUpgrade {
  toTier: SubscriptionTemplateTier;
  toBilling: string;
  scheduled: boolean;
}

export interface SubscriptionUpgradeTier {
  templateTier: SubscriptionTemplateTier;
  allowUpgrade: boolean;
  allowUpgradeNow: boolean;
  allowMonthlyBilling: boolean;
}

export enum BillingType {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum StatusType {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
}
