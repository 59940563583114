import { Injectable } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { ToastService } from 'app/services/toast.service';
import { TenantActions } from 'app/store/actions';
import { HydraCollection, Tenant } from 'app/store/models';

@Injectable()
export class TenantEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions,
    private toastr: ToastService,
  ) { }

  loadTenants$ = createEffect(() => this.actions$.pipe(
    ofType(TenantActions.loadTenants),
    concatMap(() => this.api.get<HydraCollection<Tenant>>({
      path: '/tenants?exists[deletedAt]=false&pagination=false',
    }).pipe(
      map(data => TenantActions.loadTenantsSuccess({ tenants: data['hydra:member'] })),
      catchError(err => of(TenantActions.loadTenantsFailure(err))),
    )),
  ));

  loadPaginatedTenants$ = createEffect(() => this.actions$.pipe(
    ofType(TenantActions.loadPaginatedTenants),
    switchMap(params => this.api.get<HydraCollection<Tenant>>({
      path: `/tenants?pagination=true&exists[deletedAt]=false`
        + (params.page ? `&page=${encodeURIComponent(params.page)}` : '')
        + (params.pageSize ? `&pageSize=${encodeURIComponent(params.pageSize)}` : '')
        + (params.query ? `&query=${encodeURIComponent(params.query)}` : ''),
    }).pipe(
      map(data => TenantActions.loadPaginatedTenantsSuccess({
        items: data['hydra:member'],
        totalItems: data['hydra:totalItems'],
      })),
      catchError(err => of(TenantActions.loadPaginatedTenantsFailure(err))),
    )),
  ));

  createTenant$ = createEffect(() => this.actions$.pipe(
    ofType(TenantActions.createTenant),
    concatMap(({ tenant }) => this.api.post<Tenant>({
      path: '/tenants',
      body: tenant,
    }).pipe(
      map(data => TenantActions.createTenantSuccess({ tenant: data })),
      catchError(err => of(TenantActions.createTenantFailure(err))),
    )),
  ));

  updateTenant$ = createEffect(() => this.actions$.pipe(
    ofType(TenantActions.updateTenant),
    concatMap(({ tenant }) => this.api.put<Tenant>({
      path: `/tenants/${tenant.id}`,
      body: tenant,
    }).pipe(
      map(data => {
        this.toastr.showSuccess({
          title: marker('Your changes have been saved successfully!'),
        });
        return TenantActions.updateTenantSuccess({ tenant: data });
      }),
      catchError(err => of(TenantActions.updateTenantFailure(err))),
    )),
  ));

  deleteTenant$ = createEffect(() => this.actions$.pipe(
    ofType(TenantActions.deleteTenant),
    concatMap(({ tenantId }) => this.api.delete({
      path: `/tenants/${tenantId}`,
    }).pipe(
      map(() => TenantActions.deleteTenantSuccess({ tenantId })),
      catchError(err => of(TenantActions.deleteTenantFailure(err))),
    )),
  ));

  loadReimbursementTenants$ = createEffect(() => this.actions$.pipe(
    ofType(TenantActions.loadReimbursementTenants),
    concatMap(() => this.api.get<HydraCollection<Tenant>>({
      path: '/tenants_for_reimbursement',
    }).pipe(
      map(data => TenantActions.loadReimbursementTenantsSuccess({ tenants: data['hydra:member'] })),
      catchError(err => of(TenantActions.loadReimbursementTenantsFailure(err))),
    )),
  ));
}
