import { makeActions, ofType } from 'app/store/actions/utils';
import { SubscriptionTemplateTier } from '../models/subscription.model';

export const SubscriptionInvoicingActions = makeActions({
  loadSubscriptionTemplateTiers: null,
  loadSubscriptionTemplateTiersSuccess: ofType<{ items: SubscriptionTemplateTier[]; }>(),
  loadSubscriptionTemplateTiersFailure: ofType<{ payload: any; }>(),

  updateSubscriptionTemplateTier: ofType<{ payload: SubscriptionTemplateTier; id: string; }>(),
  updateSubscriptionTemplateTierSuccess: ofType<{ tier: SubscriptionTemplateTier; }>(),
  updateSubscriptionTemplateTierFailure: ofType<{ error: any; }>(),
});
