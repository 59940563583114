import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, BundleInvoicingActions } from 'app/store/actions';
import { BundleInvoicingState } from 'app/store/models';

const empty: BundleInvoicingState = {
  items: null,
  loadingItems: false,
  savingItem: false,
};

const reducer = createReducer<BundleInvoicingState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(BundleInvoicingActions.loadBundleTemplatesForInvoicing, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(BundleInvoicingActions.loadBundleTemplatesForInvoicingSuccess, (state, { items }) => ({
    ...state,
    loadingItems: false,
    items: items,
  })),
  on(BundleInvoicingActions.loadBundleTemplatesForInvoicingFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(BundleInvoicingActions.updateBundleTemplateInvoiceCodes, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(BundleInvoicingActions.updateBundleTemplateInvoiceCodesSuccess, (state, { tier }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === tier.id ? tier : x),
    savingItem: false,
  })),
  on(BundleInvoicingActions.updateBundleTemplateInvoiceCodesFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),
);

export function bundleInvoicingReducer(state: BundleInvoicingState | undefined, action: Action) {
  return reducer(state, action);
}
