import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, PaymentProviderActions } from 'app/store/actions';
import { PaymentProviderState } from 'app/store/models';

const empty: PaymentProviderState = {
  items: null,
  paymentMethods: null,
  availablePaymentMethods: null,
  loadingItems: false,
  loadingPaymentMethods: false,
  loadingEstimation: false,
  estimation: null,
  savingItem: false,
  savingPaymentMethod: false,
};

const reducer = createReducer<PaymentProviderState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(PaymentProviderActions.loadPaymentProviders, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(PaymentProviderActions.loadPaymentProvidersSuccess, (state, { paymentProviders }) => ({
    ...state,
    items: paymentProviders,
    loadingItems: false,
  })),
  on(PaymentProviderActions.loadPaymentProvidersFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),
  on(PaymentProviderActions.fetchEstimation, (state) => ({
    ...state,
    loadingEstimation: true
  })),
  on(PaymentProviderActions.fetchEstimationFailure, (state) => ({
    ...state,
    loadingEstimation: false
  })),
  on(PaymentProviderActions.fetchEstimationSuccess, (state, data) => ({
    ...state,
    loadingEstimation: false,
    estimation: data
  })),
  on(PaymentProviderActions.updatePaymentProvider, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(PaymentProviderActions.updatePaymentProviderSuccess, (state, { paymentProvider }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === paymentProvider.id ? paymentProvider : x),
    savingItem: false,
  })),
  on(PaymentProviderActions.updatePaymentProviderFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(PaymentProviderActions.loadPaymentMethods, (state) => ({
    ...state,
    loadingPaymentMethods: true,
  })),
  on(PaymentProviderActions.loadPaymentMethodsSuccess, (state, { paymentMethods }) => ({
    ...state,
    paymentMethods: paymentMethods,
    loadingPaymentMethods: false,
  })),
  on(PaymentProviderActions.loadPaymentMethodsFailure, (state) => ({
    ...state,
    loadingPaymentMethods: false,
  })),

  on(PaymentProviderActions.loadAvailablePaymentMethodsSuccess, (state, { paymentMethods }) => ({
    ...state,
    availablePaymentMethods: paymentMethods,
  })),

  on(PaymentProviderActions.updatePaymentMethod, (state, _) => ({
    ...state,
    savingPaymentMethod: true,
  })),
  on(PaymentProviderActions.updatePaymentMethodSuccess, (state, { paymentMethod }) => ({
    ...state,
    paymentMethods: (state.paymentMethods || []).map(x => x.id === paymentMethod.id ? paymentMethod : x),
    savingPaymentMethod: false,
  })),
  on(PaymentProviderActions.updatePaymentProviderFailure, (state, _) => ({
    ...state,
    savingPaymentMethod: false,
  })),
);

export function paymentProviderReducer(state: PaymentProviderState | undefined, action: Action) {
  return reducer(state, action);
}
