import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, SubscriptionInvoicingActions } from 'app/store/actions';
import { SubscriptionInvoicingState } from 'app/store/models';

const empty: SubscriptionInvoicingState = {
  items: null,
  loadingItems: false,
  savingItem: false,
};

const reducer = createReducer<SubscriptionInvoicingState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(SubscriptionInvoicingActions.loadSubscriptionTemplateTiers, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(SubscriptionInvoicingActions.loadSubscriptionTemplateTiersSuccess, (state, { items }) => ({
    ...state,
    loadingItems: false,
    items: items,
  })),
  on(SubscriptionInvoicingActions.loadSubscriptionTemplateTiersFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(SubscriptionInvoicingActions.updateSubscriptionTemplateTier, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(SubscriptionInvoicingActions.updateSubscriptionTemplateTierSuccess, (state, { tier }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === tier.id ? tier : x),
    savingItem: false,
  })),
  on(SubscriptionInvoicingActions.updateSubscriptionTemplateTierFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),
);

export function subscriptionInvoicingReducer(state: SubscriptionInvoicingState | undefined, action: Action) {
  return reducer(state, action);
}
