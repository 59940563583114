import { formatDate } from '@angular/common';
import { contramap, getDualOrd, Ord, ordString } from 'fp-ts/es6/Ord';


import { AccountancyPeriod } from './accountancy-period.model';
import { Bundle } from './bundle.model';
import { Entity } from './hydra.model';
import { Posting } from './posting.model';
import { Service } from './service.model';
import { Subscription } from './subscription.model';

export interface Transaction extends Entity {
  '@id': string;
  '@type': 'Transaction';
  type: TransactionTypes;
  accountancyPeriod: AccountancyPeriod | [];
  cancelled: boolean;
  cancelling: boolean; // means cancelling some other transactions (which is cancelled)
  createdAt: string;
  chargedAt: string;
  data: [];
  description: string;
  reference: string|null;
  service: Service|null;
  subscription: Subscription|null;
  bundle: Bundle|null;
  softCharged: boolean;
  postings: Posting[];
}

export enum TransactionTypes {
  TYPE_CASH_IN = 'CASH_IN',
  TYPE_SERVICE = 'SERVICE',
  TYPE_PAY_OUT = 'PAY_OUT',
  TYPE_VOUCHER_EXPIRATION = 'VOUCHER_EXPIRATION',
  TYPE_SUBSCRIPTION = 'SUBSCRIPTION',
  TYPE_BUNDLE = 'BUNDLE',
  TYPE_REIMBURSEMENT = 'REIMBURSEMENT',
  TYPE_ADMIN_FEE = 'ADMIN_FEE',
}

export const byTransactionChargedAt: Ord<Transaction>
  = contramap<string, Transaction>(x => x.chargedAt)(getDualOrd(ordString));

export const byTransactionChargedDate: Ord<Transaction>
  = contramap<string, Transaction>(x => formatDate(x.chargedAt, 'dd-MM-yyyy', 'en').slice(0, 10))(ordString);
