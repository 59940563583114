<h2>
  {{ 'Payouts' | translate }}
</h2>
<div class="card mb-3">
  <h5 class="card-header">{{ 'Frequency' | translate }}</h5>
  <div class="card-body">
    <p class="card-text">{{ 'Choose how often you want to receive payouts.' | translate }}</p>
    <form
      (ngSubmit)="onPayoutsSubmit()"
      [formGroup]="payoutsForm"
    >
      <div class="form-check">
        <input
          id="frequency1"
          class="form-check-input"
          type="radio"
          formControlName="payoutsFrequency"
          value="weekly"
        />
        <label class="form-label form-check-label" for="frequency1">
          {{ 'Weekly' | translate }}
        </label>
      </div>
      <div class="form-check mb-2">
        <input
          id="frequency2"
          class="form-check-input"
          type="radio"
          formControlName="payoutsFrequency"
          value="monthly"
        />
        <label class="form-label form-check-label" for="frequency2">
          {{ 'Monthly' | translate }}
        </label>
      </div>
      <app-loading-button btnType="submit" [isLoading]="savingTenant$ | async" [isDisabled]="loadingTenants$ | async">
        {{ 'Save' | translate }}
      </app-loading-button>
    </form>
  </div>
</div>

<div class="card">
  <h5 class="card-header">{{ 'Bank account' | translate }}</h5>
  <div class="card-body">
    <p class="card-text">{{ 'You will be paid out to the below bank account number.' | translate }}</p>
    <div class="mb-3 col-sm-3 ps-0">
      <input class="form-control" type="text" placeholder="{{ bankAccountNumber }}" readonly>
    </div>
    <p class="card-text">{{ 'Contact us if you want to change your bank account number.' | translate }}</p>
  </div>
</div>
