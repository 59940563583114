import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { groupSort, NonEmptyArray } from 'fp-ts/es6/NonEmptyArray';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BundleActions } from 'app/store/actions';
import { Bundle, State } from 'app/store/models';
import { byBundleTemplateServiceProvider } from 'app/store/models/bundle.model';
import { notNull, untilDestroyed } from 'app/utils/rxjs';

@Component({
  selector: 'app-my-bundles',
  templateUrl: './my-bundles.component.html',
  styleUrls: ['./my-bundles.component.scss'],
})
export class MyBundlesComponent implements OnDestroy {
  loadingBundles$: Observable<boolean>;
  bundlesByProvider$: Observable<NonEmptyArray<Bundle>[]>;

  constructor(
    private store: Store<State>,
    private sanitizer: DomSanitizer,
  ) {
    this.loadingBundles$ = this.store.select(x => x.bundle.loadingItems);

    this.bundlesByProvider$ = this.store.select(x => x.bundle.items).pipe(
      untilDestroyed(this),
      filter(notNull),
      map(groupSort(byBundleTemplateServiceProvider)),
    );

    this.store.dispatch(BundleActions.loadBundles());
  }

  sanitize(x: string) {
    return this.sanitizer.bypassSecurityTrustUrl(x);
  }

  ngOnDestroy() { }
}
