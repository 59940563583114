import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { filter, startWith } from "rxjs/operators";

import { BundleConfigurationActions, ServiceConfigurationActions } from "app/store/actions";
import { Service, State, Tenant } from "app/store/models";
import { BundleTemplate } from "app/store/models/bundle.model";
import { selectCurrentTenant } from 'app/store/selectors';
import { notNull, untilDestroyed } from "app/utils/rxjs";
import { ConfirmActionModalComponent } from "../confirm-action-modal.component";

@Component({
  selector: 'app-bundle-add-edit-form',
  templateUrl: './bundle-add-edit-form.component.html',
  styleUrls: ['./bundle-add-edit-form.component.scss']
})
export class BundleAddEditFormComponent implements OnInit, OnDestroy {
  @Input()
  bundleTemplate: BundleTemplate | null = null;

  saving$: Observable<boolean>;
  publishing$: Observable<boolean>;
  bundleTemplateForm: UntypedFormGroup;
  services$: Observable<Service[]>;
  defaultValueOptions: { label: string, value: number }[] = [];
  serviceMap: { [k: string]: Service } = {};
  currentTenant: Tenant | null = null;

  constructor(
    private store: Store<State>,
    private fb: UntypedFormBuilder,
    private modal: NgbActiveModal,
    private translate: TranslateService,
    private modalService: NgbModal,
  ) {
    this.saving$ = this.store.select(x => x.bundleConfiguration.savingItem);
    this.publishing$ = this.store.select(x => x.bundleConfiguration.publishingItem);

    this.services$ = this.store.select(x => x.serviceConfiguration.items).pipe(
      untilDestroyed(this),
      filter(notNull),
      startWith([]),
    );
    this.services$.subscribe(services => {
      this.serviceMap = {};
      services.forEach(service => this.serviceMap[service['@id'] ?? '/api/v1/bundle_template_services/' + service.id] = service);
    })

    this.store.select(selectCurrentTenant).pipe(
      untilDestroyed(this),
      filter(notNull),
    ).subscribe(tenant => {
      this.currentTenant = tenant;
    });

    this.store.dispatch(ServiceConfigurationActions.loadServicesForConfigurator());

    this.bundleTemplateForm = this.fb.group({
      id: [null],
      service: [null, Validators.required],
      servicePrices: this.fb.array([this.createServicePriceItem()]),
      defaultValue: [0, Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.bundleTemplate && this.bundleTemplateForm) {
      let servicePrices = this.bundleTemplate.bundleTemplateService.bundleTemplateServicePrices;
      let defaultPriceKey = 0;

      // form array is only trimmed down to number of values; first form is already created
      if (servicePrices && servicePrices.length > 0) {
        for (let i = 1; i < servicePrices.length; i++) {
          this.addNewServicePrice();
        }
        servicePrices.forEach((price, key) => {
          if (price.isDefault) {
            defaultPriceKey = key;
          }
        });
      }

      this.bundleTemplateForm.patchValue({
        id: this.bundleTemplate.published ? undefined : this.bundleTemplate.id,
        service: this.bundleTemplate.bundleTemplateService.service['@id']
          ? this.bundleTemplate.bundleTemplateService.service['@id']
          : '/api/v1/services/' + this.bundleTemplate.bundleTemplateService.service.id,
        servicePrices: servicePrices,
      });
      this.bundleTemplateForm.controls['defaultValue'].setValue(defaultPriceKey);
      this.setDefaultValueOptions();
    }
  }

  createServicePriceItem(): UntypedFormGroup {
    const group = this.fb.group({
      tokens: [null, Validators.required],
      pricePerToken: [null, Validators.required]
    });
    return group;
  }

  addNewServicePrice(): void {
    const servicePrices = <UntypedFormArray>this.bundleTemplateForm.controls['servicePrices'];
    servicePrices.push(this.createServicePriceItem());
  }

  removeServicePrice(index: number): void {
    const servicePrices = <UntypedFormArray>this.bundleTemplateForm.controls['servicePrices'];
    servicePrices.removeAt(index);
    if (servicePrices.length === 0) {
      servicePrices.push(this.createServicePriceItem());
    }

    this.setDefaultValueOptions();
    if ((servicePrices.length - 1) < this.bundleTemplateForm.value.defaultValue) {
      this.bundleTemplateForm.controls['defaultValue'].setValue(0);
    }
  }

  setDefaultValueOptions(): void {
    this.defaultValueOptions = [];

    this.bundleTemplateForm.value.servicePrices.forEach((sp: any, index: number) => {
      this.defaultValueOptions.push({
        label: sp.tokens,
        value: index,
      });
    });
  }

  onSubmit(publish: boolean): void {
    if (this.bundleTemplateForm.valid) {
      var payload: any = {
        bundleTemplateService: {
          service: this.bundleTemplateForm.value.service,
          bundleTemplateServicePrices: []
        },
        published: publish,
        versionId: this.bundleTemplate ? this.bundleTemplate.versionId : undefined,
      };

      const defaultPriceKey = this.bundleTemplateForm.value.defaultValue;
      this.bundleTemplateForm.value.servicePrices.forEach(function (sp: any, key: number) {
        payload.bundleTemplateService.bundleTemplateServicePrices.push({
          tokens: sp.tokens,
          pricePerToken: sp.pricePerToken.toString(),
          isDefault: (key === defaultPriceKey)
        });
      });

      if (this.bundleTemplate && this.bundleTemplateForm.value.id) {
        payload.bundleTemplateService.bundleTemplate = this.bundleTemplate["@id"];
        payload.bundleTemplateService.id = this.bundleTemplate.bundleTemplateService["@id"]
          ? this.bundleTemplate.bundleTemplateService["@id"]
          : '/api/v1/bundle_template_services/' + this.bundleTemplate.bundleTemplateService.id
      }

      if (publish) {
        if (!this.serviceMap[this.bundleTemplateForm.value.service]?.showOnDashboard) {
          this.bundleTemplateForm.controls['service'].setErrors({'unpublished': true});
          this.bundleTemplateForm.markAllAsTouched();
          return;
        }
        const modalRef = this.modalService.open(ConfirmActionModalComponent);
        modalRef.componentInstance.title = this.translate.instant('Publish a new version');
        modalRef.componentInstance.message = this.translate.instant('Are you sure you want to publish a new version of bundle?');
        modalRef.componentInstance.confirmButtonText = marker('Yes');
        modalRef.result.then(
          () => { this.dispatchAction(payload); },
          () => {},
        );
      } else {
        this.dispatchAction(payload);
      }
    }
  }

  dispatchAction(payload: any) {
    if (this.bundleTemplateForm.value.id) {
      this.store.dispatch(
        BundleConfigurationActions.updateBundleTemplate({
          payload: payload,
          id: this.bundleTemplateForm.value.id
        })
      );
    } else {
      this.store.dispatch(
        BundleConfigurationActions.createBundleTemplate({payload: payload})
      );
    }
  }

  handleCancel(): void {
    this.modal.close();
  }

  ngOnDestroy(): void {
  }
}
