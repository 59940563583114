import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { I18nService } from 'app/services/i18n.service';

import { AccountType, Posting, Transaction } from 'app/store/models';

import { TransactionCardComponent } from './transaction-card.component';

@Component({
  selector: 'app-transaction-table',
  templateUrl: './transaction-table.component.html',
  styleUrls: ['./transaction-table.component.scss']
})
export class TransactionTableComponent {
  @Input()
  loading: boolean = false;
  @Input()
  items: [Transaction[], AccountType] = [[], AccountType.SPENDER];
  @Output()
  reload = new EventEmitter<boolean>();

  constructor(private modalService: NgbModal, private translate: I18nService) { }

  showTransactionPopup(transaction: Transaction) {
    const instance = this.modalService.open(TransactionCardComponent);
    instance.componentInstance.transaction = transaction;
    instance.componentInstance.accountType = this.items[1];
    instance.result.then(
      (result) => (result && result.reload) ? this.reload.emit(true) : null,
      () => { },
    );
  }

  getServiceProviderName(transaction: Transaction): string|null {
    return this.translate.getServiceProviderName(transaction);
  }

  getServiceLabel(transaction: Transaction): string|null {
    return this.translate.getServiceLabel(transaction);
  }

  getMainPosting(transaction: Transaction): Posting | undefined {
    return transaction.postings.find(p => p.account.type === this.items[1]);
  }

  getUserCompanyName(transaction: Transaction): string {
    const posting = transaction.postings.find(p => p.account.type === AccountType.SPENDER);
    return posting && posting.account.tenant ? posting.account.tenant.name : '';
  }
}
