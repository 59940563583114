import { OnDestroy } from '@angular/core';
import { chop, spanLeft } from 'fp-ts/es6/Array';
import { Eq } from 'fp-ts/es6/Eq';
import { Ord } from 'fp-ts/es6/Ord';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export function untilDestroyed<T extends OnDestroy, X>(instance: T):
  ((source: Observable<X>) => Observable<X>) {
  // TS hackery
  const x = instance as unknown as { __takeUntilDestroy: Subject<boolean> };
  return (source: Observable<X>) => {
    const originalDestroy = instance.ngOnDestroy;
    if (!x.__takeUntilDestroy) {
      x.__takeUntilDestroy = new Subject();

      Object.defineProperty(instance, 'ngOnDestroy', {
        value() {
          originalDestroy.apply(this);
          x.__takeUntilDestroy.next(true);
          x.__takeUntilDestroy.complete();
        },
        writable: true,
      });
    }
    return source.pipe(takeUntil<X>(x.__takeUntilDestroy));
  };
}

export function notNull<T>(x: T | null): x is T {
  return x !== null;
}

export function min<A>(O: Ord<A>): (x: A, y: A) => A {
  return (x, y) => O.compare(x, y) === 1 ? y : x;
}

export function max<A>(O: Ord<A>): (x: A, y: A) => A {
  return (x, y) => O.compare(x, y) === -1 ? y : x;
}
