import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss'],
})
export class LoadingButtonComponent {
  @Input()
  btnClass = 'btn btn-primary';
  @Input()
  btnType = 'button';
  @Input()
  isLoading = false;
  @Input()
  isDisabled = false;
  @Input()
  cy = undefined;
  @Output()
  ngClick = new EventEmitter<boolean>();
}
