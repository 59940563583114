import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, BundleActions } from 'app/store/actions';
import { BundleState } from 'app/store/models';

const empty: BundleState = {
  items: null,
  providerItems: null,
  totalProviderItems: null,
  loadingItems: false,
  savingItem: false,
};

const reducer = createReducer<BundleState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(BundleActions.loadBundles, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(BundleActions.loadBundlesSuccess, (state, { items }) => ({
    ...state,
    loadingItems: false,
    items: items,
  })),
  on(BundleActions.loadBundlesFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(BundleActions.loadBundlesForProvider, (state) => ({
    ...state,
    loading: true,
    providerItems: null,
    totalProviderItems: null,
  })),
  on(BundleActions.loadBundlesForProviderSuccess, (state, { items, totalItems }) => ({
    ...state,
    loading: false,
    totalProviderItems: totalItems,
    providerItems: items,
  })),
  on(BundleActions.loadBundlesForProviderFailure, (state) => ({
    ...state,
    loading: false,
  })),

  on(BundleActions.startBundle, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(BundleActions.startBundleSuccess, (state, { bundle }) => ({
    ...state,
    items: state.items !== null ? [...state.items, bundle] : [bundle],
    savingItem: false,
  })),
  on(BundleActions.startBundleFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),
);

export function bundleReducer(state: BundleState | undefined, action: Action) {
  return reducer(state, action);
}
