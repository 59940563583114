import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { UserStatisticsActions } from 'app/store/actions';
import { Earning } from 'app/store/models';

@Injectable()
export class UserStatisticsEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadEarnings$ = createEffect(() => this.actions$.pipe(
    ofType(UserStatisticsActions.loadEarnings),
    switchMap(params => this.api.get<Earning[]>({
      path: `/earnings_history?accountType=${params.accountType}`
        + (params.before ? `&createdBefore=${encodeURIComponent(params.before)}` : '')
        + (params.after ? `&createdAfter=${encodeURIComponent(params.after)}` : ''),
    }).pipe(
      map(earnings => UserStatisticsActions.loadEarningsSuccess({ earnings })),
      catchError(err => of(UserStatisticsActions.loadEarningsFailure(err))),
    )),
  ));
}
