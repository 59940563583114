<div class="text-center mt-2">
  <h1>
    {{ '404 Not found' | translate }}
    <i class="far fa-frown"></i>
  </h1>
  <div>{{ 'It looks like you got lost.' | translate }}</div>
  <br>
  <div>
    <a routerLink="/overview">{{ 'Return to the homepage' | translate }}</a>
  </div>
</div>
