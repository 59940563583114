import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { OAuthClientActions } from 'app/store/actions';
import { HydraCollection, OAuthClient } from 'app/store/models';

@Injectable()
export class OAuthClientEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadOAuthClients$ = createEffect(() => this.actions$.pipe(
    ofType(OAuthClientActions.loadOAuthClients),
    concatMap(() => this.api.get<HydraCollection<OAuthClient>>({
      path: '/o_auth_clients',
    }).pipe(
      map(data => OAuthClientActions.loadOAuthClientsSuccess({ oauthClients: data['hydra:member'] })),
      catchError(err => of(OAuthClientActions.loadOAuthClientsFailure(err))),
    )),
  ));

  createOAuthClient$ = createEffect(() => this.actions$.pipe(
    ofType(OAuthClientActions.createOAuthClient),
    concatMap(({ oauthClient }) => this.api.post<OAuthClient>({
      path: '/o_auth_clients',
      body: oauthClient,
    }).pipe(
      map(data => OAuthClientActions.createOAuthClientSuccess({ oauthClient: data })),
      catchError(err => of(OAuthClientActions.createOAuthClientFailure(err))),
    )),
  ));

  updateOAuthClient$ = createEffect(() => this.actions$.pipe(
    ofType(OAuthClientActions.updateOAuthClient),
    concatMap(({ oauthClient }) => this.api.put<OAuthClient>({
      path: `/o_auth_clients/${oauthClient.id}`,
      body: oauthClient,
    }).pipe(
      map(data => OAuthClientActions.updateOAuthClientSuccess({ oauthClient: data })),
      catchError(err => of(OAuthClientActions.updateOAuthClientFailure(err))),
    )),
  ));

  deleteOAuthClient$ = createEffect(() => this.actions$.pipe(
    ofType(OAuthClientActions.deleteOAuthClient),
    concatMap(({ oauthClientId }) => this.api.delete({
      path: `/o_auth_clients/${oauthClientId}`,
    }).pipe(
      map(() => OAuthClientActions.deleteOAuthClientSuccess({ oauthClientId })),
      catchError(err => of(OAuthClientActions.deleteOAuthClientFailure(err))),
    )),
  ));

  unlinkPairedTenant$ = createEffect(() => this.actions$.pipe(
    ofType(OAuthClientActions.unlinkPairedTenant),
    concatMap(({ providerAuthLinkId }) => this.api.delete({
      path: `/provider_auth_links/${providerAuthLinkId}`,
    }).pipe(
      map(() => OAuthClientActions.unlinkPairedTenantSuccess({ providerAuthLinkId })),
      catchError(err => of(OAuthClientActions.unlinkPairedTenantFailure(err))),
    )),
  ));
}
