import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { ToastService } from 'app/services/toast.service';
import { ExactOnlineActions } from 'app/store/actions';
import { AccountType, ExactOnlineQueue, State } from 'app/store/models';
import { ExactOnlineQueueItemType } from 'app/store/models/exact-online-queue.model';
import { notNull } from 'app/utils/rxjs';

@Component({
  selector: 'app-admin-exact-online',
  templateUrl: './admin-exact-online.component.html',
})
export class AdminExactOnlineComponent implements OnDestroy {
  loadingQueue$: Observable<boolean>;
  queue$: Observable<ExactOnlineQueue[]>;
  activeQueueItem: ExactOnlineQueue | null = null;
  modalInstance: any;
  saveFlags: { [k: string]: boolean } = {};
  @ViewChild('queueItemDetailsModal', { static: true })
  queueItemDetailsModal?: TemplateRef<any>;

  constructor(
    private store: Store<State>,
    private modalService: NgbModal,
    private api: ApiService,
    private toastr: ToastService,
  ) {
    this.loadingQueue$ = this.store.select(x => x.admin.exactOnlineQueue.loadingItems);
    this.queue$ = this.store.select(x => x.admin.exactOnlineQueue.items).pipe(
      filter(notNull),
      startWith([]),
    );
    this.store.dispatch(ExactOnlineActions.loadExactOnlineQueue());
  }

  public showQueueItemDetails(queueItem: ExactOnlineQueue) {
    this.activeQueueItem = queueItem;
    this.modalInstance = this.modalService.open(this.queueItemDetailsModal, { size: 'lg' });
  }

  public getCustomer(item: ExactOnlineQueue): string {
    let customer = '';

    switch (item.action) {
      case ExactOnlineQueueItemType.ACTION_CREATE_TRANSACTIONS_INVOICE:
      case ExactOnlineQueueItemType.ACTION_CREATE_TRANSACTIONS_FEE_INVOICE:
      case ExactOnlineQueueItemType.ACTION_CREATE_ADMIN_FEE_INVOICE:
        customer = item.customer ? item.customer.name : '';
        break;
      case ExactOnlineQueueItemType.ACTION_CREATE_VOUCHER_INVOICE:
        if (item.transaction && item.transaction.postings) {
          const spenderPostings = item.transaction.postings.filter(posting => {
            return (posting.account.type === AccountType.SPENDER);
          });
          if (spenderPostings && spenderPostings[0].account.tenant) {
            customer = spenderPostings[0].account.tenant.name;
          }
        }
        break;
    }

    return customer;
  }

  public getProvider(item: ExactOnlineQueue): string {
    let provider = '';

    switch (item.action) {
      case ExactOnlineQueueItemType.ACTION_CREATE_TRANSACTIONS_INVOICE:
      case ExactOnlineQueueItemType.ACTION_CREATE_TRANSACTIONS_FEE_INVOICE:
      case ExactOnlineQueueItemType.ACTION_CREATE_ADMIN_FEE_INVOICE:
        provider = item.provider ? item.provider.name : '';
        break;
      case ExactOnlineQueueItemType.ACTION_CREATE_VOUCHER_INVOICE:
        if (item.transaction && item.transaction.postings) {
          const cinvioPostings = item.transaction.postings.filter(posting => {
            return (posting.account.type === AccountType.CINVIO_DEBT);
          });
          if (cinvioPostings && cinvioPostings[0].account.tenant) {
            provider = cinvioPostings[0].account.tenant.name;
          }
        }
        break;
    }

    return provider;
  }

  public formatAction(action: string): string {
    switch (action) {
      case ExactOnlineQueueItemType.ACTION_CREATE_VOUCHER_INVOICE:
        return 'Voucher invoice';
      case ExactOnlineQueueItemType.ACTION_CREATE_TRANSACTIONS_INVOICE:
        return 'Transactions invoice';
      case ExactOnlineQueueItemType.ACTION_CREATE_TRANSACTIONS_FEE_INVOICE:
        return 'Transactions fee invoice';
      case ExactOnlineQueueItemType.ACTION_CREATE_ADMIN_FEE_INVOICE:
        return 'Admin fee invoice';
      case ExactOnlineQueueItemType.ACTION_GET_DIVISIONS:
        return 'Get divisions';
      default:
        return action;
    }

  }

  public retry(queueItem: ExactOnlineQueue): void {
    this.saveFlags[queueItem.id] = true;
    this.api.post<ExactOnlineQueue>({
      path: '/exact_online_queues/' + queueItem.id + '/retry',
      body: {},
    }).toPromise().then(
      (result) => {
        this.store.dispatch(ExactOnlineActions.loadExactOnlineQueue());
        this.saveFlags = {};
        if (result.processed) {
          this.toastr.showSuccess({ title: 'Item processed!' });
        } else {
          let toast: any = { title: 'Processing failed!' };
          if (result.errorResponse) {
            toast.message = result.errorResponse;
          }
          this.toastr.showWarning(toast);
        }
      },
      () => {
        this.saveFlags = {};
      },
    );
  }

  ngOnDestroy() { }
}
