import { contramap, Ord, ordNumber } from 'fp-ts/Ord';

export interface Role {
  '@id': string;
  '@type': string;
  id: string;
  name: string;
  code: RoleCode;
}

export enum RoleCode {
  superAdmin = 'ROLE_SUPER_ADMIN',
  admin = 'ROLE_ADMIN',
  serviceProvider = 'ROLE_SERVICE_PROVIDER',
  user = 'ROLE_USER',
}

const role2authority: Record<RoleCode, number> = {
  [RoleCode.superAdmin]: 4,
  [RoleCode.admin]: 3,
  [RoleCode.serviceProvider]: 2,
  [RoleCode.user]: 1,
};

export const byRoleAuthority: Ord<RoleCode>
  = contramap<number, RoleCode>(x => role2authority[x])(ordNumber);
