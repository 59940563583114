import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CommonActions, SessionActions } from 'app/store/actions';
import { State } from 'app/store/models';

@Injectable()
export class AuthGuard  {
  constructor(private store: Store<State>) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(x => x.session.token).pipe(map(token => {
      if (!token) {
        this.store.dispatch(CommonActions.setOriginalUrl({ url: state.url }));
        this.store.dispatch(SessionActions.logout());
      }
      return true;
    }));
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
