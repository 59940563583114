import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { ToastService } from 'app/services/toast.service';
import { BundleConfigurationActions } from 'app/store/actions';
import { Service, State, Tenant } from 'app/store/models';
import { selectCurrentTenant } from 'app/store/selectors';
import { notNull, untilDestroyed } from 'app/utils/rxjs';
import { BundleTemplate } from "../../store/models/bundle.model";
import { BundleAddEditFormComponent } from "../bundle-add-edit-form/bundle-add-edit-form.component";
import { ConfirmActionModalComponent } from "../confirm-action-modal.component";

@Component({
  selector: 'app-bundle-configurator',
  templateUrl: './bundle-configurator.component.html',
  styleUrls: ['./bundle-configurator.component.scss']
})
export class BundleConfiguratorComponent implements OnDestroy {
  loadingBundleTemplates$: Observable<boolean>;
  loadingVersionHistory$: Observable<boolean>;
  versionHistory$: Observable<BundleTemplate[]>
  savingBundles$: Observable<boolean>;
  successMessageSubscription$: Subscription;
  bundleTemplates: BundleTemplate[] = [];
  currentTenant: Tenant | null = null;
  activeService: Service | null = null;
  bundleConfigurationForm: UntypedFormGroup;
  modalInstance: any;

  @ViewChild('versionHistoryModal', { static: true })
  versionHistoryModal?: TemplateRef<any>;

  constructor(
    private store: Store<State>,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private actions$: Actions,
    private translate: TranslateService,
    private toastr: ToastService,
  ) {

    this.loadingBundleTemplates$ = this.store.select(x => x.bundleConfiguration.loadingItems);
    this.loadingVersionHistory$ = this.store.select(x => x.bundleConfiguration.loadingVersions);
    this.savingBundles$ = this.store.select(x => x.bundleConfiguration.savingItem);

    this.store.select(x => x.bundleConfiguration.items).pipe(
      filter(notNull),
      startWith([]),
    ).subscribe((bundleTemplates) => {
      bundleTemplates.forEach((bundleTemplate) => {
        if (!bundleTemplate.bundleTemplateService.bundleTemplateServicePrices) {
          return;
        }
        bundleTemplate.selectedPrice = bundleTemplate.bundleTemplateService.bundleTemplateServicePrices.find(
          price => price.isDefault
        ) ?? bundleTemplate.bundleTemplateService.bundleTemplateServicePrices[0]
      })
      this.bundleTemplates = bundleTemplates;
    });

    this.store.select(selectCurrentTenant).pipe(
      untilDestroyed(this),
      filter(notNull),
    ).subscribe(tenant => {
      this.currentTenant = tenant;
    });

    this.versionHistory$ = this.store.select(x => x.bundleConfiguration.versions).pipe(
      untilDestroyed(this),
      filter(notNull),
      startWith([]),
    );

    this.store.dispatch(BundleConfigurationActions.loadBundleTemplates())

    this.successMessageSubscription$ = this.actions$.pipe(
      ofType(
        BundleConfigurationActions.createBundleTemplateSuccess,
        BundleConfigurationActions.updateBundleTemplateSuccess,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      this.toastr.showSuccess({
        title: marker('Your changes have been saved successfully!'),
      });
      if (this.modalInstance) {
        this.modalInstance.dismiss();
      }
    });

    this.bundleConfigurationForm = this.fb.group({
      defaultPrice: [null],
      customServicePrices: this.fb.array([]),
    });
  }

  openBundleEditModal(bundleTemplate: BundleTemplate) {
    this.modalInstance = this.modalService.open(BundleAddEditFormComponent);
    this.modalInstance.componentInstance.bundleTemplate = bundleTemplate;
  }

  openBundleCreationModal() {
    this.modalInstance = this.modalService.open(BundleAddEditFormComponent)
  }

  removeBundleTemplate(bundleTemplate: BundleTemplate) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);

    modalRef.componentInstance.title = this.translate.instant('Delete Subscription');
    modalRef.componentInstance.message = this.translate.instant('Are you sure you want to delete') + ' ' + bundleTemplate.bundleTemplateService.service.label + ' bundle?'
      + ' ' + this.translate.instant('User companies will not be able to start a new bundle anymore, but existing bundles will still be active.');
    modalRef.componentInstance.confirmButtonText = marker('Yes');
    modalRef.result.then(
      () => this.store.dispatch(BundleConfigurationActions.deleteBundleTemplate({
        templateId: bundleTemplate.id,
      })),
      () => {},
    );
  }

  viewVersionHistory(bundle: BundleTemplate): void {
    this.store.dispatch(BundleConfigurationActions.loadBundleTemplateVersions({ versionId: bundle.versionId }));

    this.modalInstance = this.modalService.open(this.versionHistoryModal, { size: 'xl' });
  }

  ngOnDestroy() {
    this.successMessageSubscription$.unsubscribe();
  }
}
