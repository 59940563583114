import { makeActions, ofType } from 'app/store/actions/utils';
import { Bundle, BundleTemplate } from '../models/bundle.model';

export const BundleActions = makeActions({
  loadBundles: null,
  loadBundlesSuccess: ofType<{ items: Bundle[]; }>(),
  loadBundlesFailure: ofType<{ payload: any; }>(),

  loadBundlesForProvider: ofType<{
    page?: number;
    pageSize?: number;
    query?: string;
  }>(),
  loadBundlesForProviderSuccess: ofType<{ items: Bundle[]; totalItems: number; }>(),
  loadBundlesForProviderFailure: ofType<{ payload: any; }>(),

  loadBundleTemplatesForUser: ofType<{ providerId: string; }>(),
  loadBundleTemplatesForUserSuccess: ofType<{ items: BundleTemplate[]; }>(),
  loadBundleTemplatesForUserFailure: ofType<{ payload: any; }>(),

  startBundle: ofType<{ payload: { bundleTemplateServicePrice: string; } }>(),
  startBundleSuccess: ofType<{ bundle: Bundle; }>(),
  startBundleFailure: ofType<{ error: any; }>(),
});
