import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { RegistrationActions } from 'app/store/actions';
import { HydraCollection, Registration } from 'app/store/models';

@Injectable()
export class RegistrationEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadRegistrations$ = createEffect(() => this.actions$.pipe(
    ofType(RegistrationActions.loadRegistrations),
    concatMap((payload) => this.api.get<HydraCollection<Registration>>({
      path: (payload.processed
          ? '/registration_requests?status[]=approved&status[]=declined'
          : '/registration_requests?status=pending')
        + (payload.page ? `&page=${encodeURIComponent(payload.page)}` : '')
        + (payload.pageSize ? `&pageSize=${encodeURIComponent(payload.pageSize)}` : ''),
    }).pipe(
      map(data => RegistrationActions.loadRegistrationsSuccess({
        items: data['hydra:member'],
        totalItems: data['hydra:totalItems'],
      })),
      catchError(err => of(RegistrationActions.loadRegistrationsFailure(err))),
    )),
  ));

  updateRegistration$ = createEffect(() => this.actions$.pipe(
    ofType(RegistrationActions.updateRegistration),
    concatMap(({ registration }) => this.api.put<Registration>({
      path: `/registration_requests/${registration.id}`,
      body: registration,
    }).pipe(
      map(data => RegistrationActions.updateRegistrationSuccess({ registration: data })),
      catchError(err => of(RegistrationActions.updateRegistrationFailure(err))),
    )),
  ));

  approveRegistration$ = createEffect(() => this.actions$.pipe(
    ofType(RegistrationActions.approveRegistration),
    concatMap(({ registrationId }) => this.api.post<Registration>({
      path: `/registration_requests/${registrationId}/approve`,
    }).pipe(
      map(() => RegistrationActions.approveRegistrationSuccess({ registrationId })),
      catchError(err => of(RegistrationActions.approveRegistrationFailure(err))),
    )),
  ));

  declineRegistration$ = createEffect(() => this.actions$.pipe(
    ofType(RegistrationActions.declineRegistration),
    concatMap(({ registrationId }) => this.api.post({
      path: `/registration_requests/${registrationId}/decline`,
    }).pipe(
      map(() => RegistrationActions.declineRegistrationSuccess({ registrationId })),
      catchError(err => of(RegistrationActions.declineRegistrationFailure(err))),
    )),
  ));

  toggleProcessed$ = createEffect(() => this.actions$.pipe(
    ofType(RegistrationActions.toggleProcessed),
    concatMap((payload) => this.api.get<HydraCollection<Registration>>({
      path: (!payload.processed
          ? '/registration_requests?status[]=approved&status[]=declined'
          : '/registration_requests?status=pending')
        + (payload.page ? `&page=${encodeURIComponent(payload.page)}` : '')
        + (payload.pageSize ? `&pageSize=${encodeURIComponent(payload.pageSize)}` : ''),
    }).pipe(
      map(data => RegistrationActions.loadRegistrationsSuccess({
        items: data['hydra:member'],
        totalItems: data['hydra:totalItems'],
      })),
      catchError(err => of(RegistrationActions.loadRegistrationsFailure(err))),
    )),
  ));
}
