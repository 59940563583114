import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { DocumentActions } from 'app/store/actions';
import { Document, PurchaseOrder } from 'app/store/models/document.model';

@Injectable()
export class DocumentEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadDocuments$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentActions.loadDocuments),
    concatMap((params) => this.api.get<Document[]>({
      path: '/exact_online_documents'
        + (params.userCompany ? `?userCompanyId=${encodeURIComponent(params.userCompany)}` : ''),
    }).pipe(
      map(data => DocumentActions.loadDocumentsSuccess({ items: data })),
      catchError(err => of(DocumentActions.loadDocumentsFailure(err))),
    )),
  ));

  loadPurchaseOrders$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentActions.loadPurchaseOrders),
    concatMap((params) => this.api.get<PurchaseOrder[]>({
      path: '/purchase-orders',
    }).pipe(
      map(data => DocumentActions.loadPurchaseOrdersSuccess({ items: data })),
      catchError(err => of(DocumentActions.loadPurchaseOrdersFailure(err))),
    )),
  ));
}
