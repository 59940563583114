import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, CurrencyPairActions, ExactOnlineActions } from 'app/store/actions';
import { ExactOnlineQueueState } from 'app/store/models';

const empty: ExactOnlineQueueState = {
  items: null,
  loadingItems: false,
};

const reducer = createReducer<ExactOnlineQueueState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(ExactOnlineActions.loadExactOnlineQueue, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(ExactOnlineActions.loadExactOnlineQueueSuccess, (state, { exactOnlineQueue }) => ({
    ...state,
    items: exactOnlineQueue,
    loadingItems: false,
  })),
  on(ExactOnlineActions.loadExactOnlineQueueFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),
);

export function exactOnlineReducer(state: ExactOnlineQueueState | undefined, action: Action) {
  return reducer(state, action);
}
