import { Component, Input, OnDestroy } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { I18nService } from 'app/services/i18n.service';

import { AccountType, Posting, State, Transaction } from 'app/store/models';
import { UserTransactionActions } from 'app/store/actions';
import { untilDestroyed } from 'app/utils/rxjs';

import { ConfirmActionModalComponent } from './confirm-action-modal.component';
import { TransactionTypes } from 'app/store/models/transaction.model';

@Component({
  selector: 'app-transaction-card',
  templateUrl: './transaction-card.component.html',
})
export class TransactionCardComponent implements OnDestroy {
  @Input()
  transaction: Transaction = {} as unknown as Transaction;
  @Input()
  accountType: AccountType = AccountType.SPENDER;
  JSON = JSON;
  parseInt = parseInt;
  cancelling = false;
  modalInstance: any;

  constructor(
    private store: Store<State>,
    public modal: NgbActiveModal,
    private actions$: Actions,
    private translate: I18nService,
    private modalService: NgbModal,
    private translateService: TranslateService,
  ) {
    this.actions$.pipe(
      ofType(UserTransactionActions.cancelTransactionSuccess),
      untilDestroyed(this),
    ).subscribe(() => {
      this.cancelling = false;
      this.modal.close({ reload: true });
    });

    this.actions$.pipe(
      ofType(UserTransactionActions.cancelTransactionFailure),
      untilDestroyed(this),
    ).subscribe(() => this.cancelling = false);
  }

  getMainPosting(transaction: Transaction): Posting | undefined {
    return transaction.postings.find(p => p.account.type === this.accountType);
  }

  getServiceProviderName(transaction: Transaction): string|null {
    return this.translate.getServiceProviderName(transaction);
  }

  getServiceLabel(transaction: Transaction): string|null {
    return this.translate.getServiceLabel(transaction);
  }

  getUserCompanyName(transaction: Transaction): string|null {
    const posting = transaction.postings.find(p => p.account.type === AccountType.SPENDER);
    return posting && posting.account.tenant ? posting.account.tenant.name : null;
  }

  allowRefund(transaction: Transaction): boolean {
    return (
        this.accountType === AccountType.EARNER && transaction.cancelled === false && transaction.cancelling === false
        && transaction.softCharged === false && this.getUserCompanyName(transaction) !== null
        && (transaction.service !== null || transaction.subscription !== null || transaction.bundle !== null)
      )
      ||
      (
        this.accountType === AccountType.CINVIO && transaction.cancelled === false && transaction.cancelling === false
        && transaction.softCharged === false && this.getUserCompanyName(transaction) !== null
        && transaction.type === TransactionTypes.TYPE_VOUCHER_EXPIRATION
      )
  }

  refund(transaction: Transaction): void {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.title = this.translateService.instant('Refund transaction');
    modalRef.componentInstance.message = this.translateService.instant('Are you sure you want to refund transaction') + ' ' + transaction.description + '?'
      + ' ' + this.translateService.instant('A reverse transaction will be created.');
    if (transaction.subscription !== null) {
      modalRef.componentInstance.message += ' ' + this.translateService.instant('{{userCompany}} will no longer be able to use the linked subscription.', {
        userCompany: this.getUserCompanyName(transaction)
      });
    }
    if (transaction.bundle !== null) {
      modalRef.componentInstance.message += ' ' + this.translateService.instant('{{userCompany}} will no longer be able to use the linked bundle.', {
        userCompany: this.getUserCompanyName(transaction)
      });
    }
    modalRef.componentInstance.confirmButtonText = marker('Refund');
    modalRef.result.then(
      () => {
        let transactionId = transaction.id;
        if (!transactionId) {
          transactionId = transaction['@id'].substring(transaction['@id'].lastIndexOf('/') + 1)
        }
        this.cancelling = true;
        this.store.dispatch(UserTransactionActions.cancelTransaction({ transactionId }));
      },
      () => { },
    );
  }

  isScalar(value: any): boolean {
    return typeof value !== 'object'
  }

  ngOnDestroy() { }
}
