import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { BundleInvoicingActions, SubscriptionInvoicingActions } from 'app/store/actions';
import { State } from 'app/store/models';
import { notNull, untilDestroyed } from 'app/utils/rxjs';
import { SubscriptionTemplateTier } from 'app/store/models/subscription.model';
import { BundleTemplate } from 'app/store/models/bundle.model';

@Component({
  selector: 'app-admin-invoice-codes',
  templateUrl: './admin-invoice-codes.component.html',
})
export class AdminInvoiceCodesComponent implements OnDestroy {
  loadingSubscriptionTemplateTiers$: Observable<boolean>;
  subscriptionTemplateTierForms: UntypedFormGroup[] = [];
  loadingBundleTemplates$: Observable<boolean>;
  bundleTemplateForms: UntypedFormGroup[] = [];
  tierMap: SubscriptionTemplateTier[] = [];
  bundleTemplateMap: BundleTemplate[] = [];
  saveFlags: boolean[] = [];
  bundleSaveFlags: boolean[] = [];

  constructor(
    private store: Store<State>,
    private fb: UntypedFormBuilder,
    private actions$: Actions,
  ) {
    this.loadingSubscriptionTemplateTiers$ = this.store.select(x => x.admin.subscriptionInvoicing.loadingItems);
    this.loadingBundleTemplates$ = this.store.select(x => x.admin.bundleInvoicing.loadingItems);

    this.store.select(x => x.admin.subscriptionInvoicing.items).pipe(
      filter(notNull),
      startWith([]),
    ).subscribe(tiers => {
      this.subscriptionTemplateTierForms = [];
      this.tierMap = [];
      tiers.forEach(tier => {
        const i = this.addTierForm(tier);
        this.tierMap[i] = tier;
        this.saveFlags[i] = false;
      });
    });

    this.store.select(x => x.admin.bundleInvoicing.items).pipe(
      filter(notNull),
      startWith([]),
    ).subscribe(templates => {
      this.bundleTemplateForms = [];
      this.bundleTemplateMap = [];
      templates.forEach(template => {
        const i = this.addBundleTemplateForm(template);
        this.bundleTemplateMap[i] = template;
        this.bundleSaveFlags[i] = false;
      });
    });

    this.store.dispatch(SubscriptionInvoicingActions.loadSubscriptionTemplateTiers());
    this.store.dispatch(BundleInvoicingActions.loadBundleTemplatesForInvoicing());

    this.actions$.pipe(
      ofType(
        SubscriptionInvoicingActions.updateSubscriptionTemplateTierSuccess,
        SubscriptionInvoicingActions.updateSubscriptionTemplateTierFailure,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      this.saveFlags.fill(false);
    });

    this.actions$.pipe(
      ofType(
        BundleInvoicingActions.updateBundleTemplateInvoiceCodesSuccess,
        BundleInvoicingActions.updateBundleTemplateInvoiceCodesFailure,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      this.bundleSaveFlags.fill(false);
    });
  }

  addTierForm(tier: SubscriptionTemplateTier): number {
    let tierForm = this.fb.group({
      id: [null],
      eoInvoiceCode: [null],
      eoSameCountryCode: [null],
      eoDifferentEuCountryCode: [null],
      eoDifferentNonEuCountryCode: [null],
      eoCostCenter: [null],
      eoCostUnit: [null],
    });

    tierForm.patchValue(tier);

    return this.subscriptionTemplateTierForms.push(tierForm) - 1;
  }

  addBundleTemplateForm(template: BundleTemplate): number {
    let bundleForm = this.fb.group({
      id: [null],
      eoInvoiceCode: [null],
      eoSameCountryCode: [null],
      eoDifferentEuCountryCode: [null],
      eoDifferentNonEuCountryCode: [null],
      eoCostCenter: [null],
      eoCostUnit: [null],
    });

    bundleForm.patchValue(template);

    return this.bundleTemplateForms.push(bundleForm) - 1;
  }

  save(form: UntypedFormGroup, i: number, bundle: boolean) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    let payload: any = {
      eoInvoiceCode: form.value.eoInvoiceCode ? form.value.eoInvoiceCode : null,
      eoSameCountryCode: form.value.eoSameCountryCode ? form.value.eoSameCountryCode : null,
      eoDifferentEuCountryCode: form.value.eoDifferentEuCountryCode ? form.value.eoDifferentEuCountryCode : null,
      eoDifferentNonEuCountryCode: form.value.eoDifferentNonEuCountryCode ? form.value.eoDifferentNonEuCountryCode : null,
      eoCostCenter: form.value.eoCostCenter ? form.value.eoCostCenter : null,
      eoCostUnit: form.value.eoCostUnit ? form.value.eoCostUnit : null,
    };
    if (bundle) {
      this.bundleSaveFlags[i] = true;
      this.store.dispatch(BundleInvoicingActions.updateBundleTemplateInvoiceCodes({
        payload: payload,
        id: form.value.id,
      }));
    } else {
      this.saveFlags[i] = true;
      this.store.dispatch(SubscriptionInvoicingActions.updateSubscriptionTemplateTier({
        payload: payload,
        id: form.value.id,
      }));
    }
  }

  ngOnDestroy() { }
}
