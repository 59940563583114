import { Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { Service, Tenant } from 'app/store/models';

@Component({
  selector: 'app-subscription-template-tier',
  templateUrl: './subscription-template-tier.component.html',
})
export class SubscriptionTemplateTierComponent implements OnDestroy {

  @Input()
  services$: Observable<Service[]>|null = null;

  @Input()
  currentTenant: Tenant|null = null;

  @Input()
  tierForm: UntypedFormGroup|null = null;

  @Input()
  edit: boolean = false;

  @Input()
  subscriptionLabel: string|null = null;

  constructor(
    private fb: UntypedFormBuilder,
    private modal: NgbActiveModal,
  ) {
  }

  createServiceGroup(): UntypedFormGroup {
    return this.fb.group({
      service: [null],
      tokens: [null],
      unlimited: [null],
    });
  }

  addServiceGroup(): void {
    const services = <UntypedFormArray>this.tierForm?.controls['subscriptionTemplateTierServices'];
    services.push(this.createServiceGroup());
  }

  removeServiceGroup(index: number): void {
    const services = <UntypedFormArray>this.tierForm?.controls['subscriptionTemplateTierServices'];
    services.removeAt(index);
    this.tierForm?.markAsDirty();
  }

  getServiceGroups(): UntypedFormArray {
    return <UntypedFormArray>this.tierForm?.controls['subscriptionTemplateTierServices'];
  }

  handleChangePrice(event: any) {
    if (parseFloat(event.currentTarget.value)) {
      this.tierForm?.controls['priceMonthly'].setErrors(null);
      this.tierForm?.controls['priceYearly'].setErrors(null);
    }
  }

  handleChangeUnlimited(event: any, index: number) {
    const services = <UntypedFormArray>this.tierForm?.controls['subscriptionTemplateTierServices'];
    const serviceGroup = services.at(index);
    const tokens = serviceGroup.get('tokens');
  
    if (event.currentTarget.checked) {
      tokens?.disable();
      tokens?.setValue(null);
    } else {
      tokens?.enable();
    }
  }

  handleConfirm() {
    if (!this.tierForm || this.tierForm.invalid) {
      this.tierForm?.markAllAsTouched();
      return;
    }

    // validate that each service is used max once
    let valid = true;
    let usedServices: string[] = [];
    const tierServicesArray = <UntypedFormArray>this.tierForm.controls['subscriptionTemplateTierServices'];
    const tsValue = this.tierForm.value['subscriptionTemplateTierServices'];
    tsValue.forEach((ts: { service: string; tokens: number; unlimited: boolean; }, index: any) => {
      if (ts.service) {
        const tierServiceGroup = <UntypedFormGroup>tierServicesArray.controls[index];
        if (usedServices.indexOf(ts.service) !== -1) {
          tierServiceGroup.controls['service'].setErrors({'duplicate': true});
          valid = false;
        }
        if (!ts.tokens && !ts.unlimited) {
          tierServiceGroup.controls['tokens'].setErrors({'empty': true});
          valid = false;
        }
        usedServices.push(ts.service);
      }
    });

    // validate at least one service is filled in correcty
    if (!usedServices.length) {
      const tierServiceGroup = <UntypedFormGroup>tierServicesArray.controls[0];
      tierServiceGroup.controls['service'].setErrors({'empty': true});
      valid = false;
    }

    // validate that either priceMonthly or priceYearly is filled in
    if (!this.tierForm.value.priceMonthly && !this.tierForm.value.priceYearly) {
      this.tierForm.controls['priceMonthly'].setErrors({'incorrect': true});
      this.tierForm.controls['priceYearly'].setErrors({'incorrect': true});
      valid = false;
    }

    if (!valid) {
      this.tierForm.markAllAsTouched();
      return;
    }

    this.modal.close(this.tierForm);
  }

  handleCancel() {
    this.modal.dismiss();
  }

  ngOnDestroy() { }
}
