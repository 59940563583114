import { makeActions, ofType } from 'app/store/actions/utils';
import { PaymentMethod, PaymentProvider } from 'app/store/models/payment-provider.model';

export const PaymentProviderActions = makeActions({
  loadPaymentProviders: null,
  loadPaymentProvidersSuccess: ofType<{ paymentProviders: PaymentProvider[]; }>(),
  loadPaymentProvidersFailure: ofType<{ error: any; }>(),

  updatePaymentProvider: ofType<{ paymentProvider: any; }>(),
  updatePaymentProviderSuccess: ofType<{ paymentProvider: PaymentProvider; }>(),
  updatePaymentProviderFailure: ofType<{ error: any; }>(),

  loadPaymentMethods: null,
  loadPaymentMethodsSuccess: ofType<{ paymentMethods: PaymentMethod[]; }>(),
  loadPaymentMethodsFailure: ofType<{ error: any; }>(),

  updatePaymentMethod: ofType<{ paymentMethod: PaymentMethod; }>(),
  updatePaymentMethodSuccess: ofType<{ paymentMethod: PaymentMethod; }>(),
  updatePaymentMethodFailure: ofType<{ error: any; }>(),

  loadAvailablePaymentMethods: null,
  loadAvailablePaymentMethodsSuccess: ofType<{ paymentMethods: PaymentMethod[]; }>(),
  loadAvailablePaymentMethodsFailure: ofType<{ error: any; }>(),

  payLaterChanges: ofType<{value: boolean}>(),

  fetchEstimation: ofType<{paymentMethodId: string, termsType: string, amount: number}>(),
  fetchEstimationSuccess: ofType<any>(),
  fetchEstimationFailure: ofType<{error: any}>()
});
