import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, SubscriptionActions } from 'app/store/actions';
import { SubscriptionState } from 'app/store/models';

const empty: SubscriptionState = {
  items: null,
  providerItems: null,
  totalProviderItems: null,
  loadingItems: false,
  savingItem: false,
};

const reducer = createReducer<SubscriptionState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(SubscriptionActions.loadSubscriptions, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(SubscriptionActions.loadSubscriptionsSuccess, (state, { items }) => ({
    ...state,
    loadingItems: false,
    items: items,
  })),
  on(SubscriptionActions.loadSubscriptionsFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(SubscriptionActions.loadSubscriptionsForProvider, (state) => ({
    ...state,
    loading: true,
    providerItems: null,
    totalProviderItems: null,
  })),
  on(SubscriptionActions.loadSubscriptionsForProviderSuccess, (state, { items, totalItems }) => ({
    ...state,
    loading: false,
    totalProviderItems: totalItems,
    providerItems: items,
  })),
  on(SubscriptionActions.loadSubscriptionsForProviderFailure, (state) => ({
    ...state,
    loading: false,
  })),

  on(SubscriptionActions.startSubscription, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(SubscriptionActions.startSubscriptionSuccess, (state, { subscription }) => ({
    ...state,
    items: state.items !== null ? [...state.items, subscription] : [subscription],
    savingItem: false,
  })),
  on(SubscriptionActions.startSubscriptionFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(SubscriptionActions.cancelSubscription, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(SubscriptionActions.cancelSubscriptionSuccess, (state, { subscription }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === subscription.id ? subscription : x),
    savingItem: false,
  })),
  on(SubscriptionActions.cancelSubscriptionFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(SubscriptionActions.reactivateSubscription, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(SubscriptionActions.reactivateSubscriptionSuccess, (state, { subscription }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === subscription.id ? subscription : x),
    savingItem: false,
  })),
  on(SubscriptionActions.reactivateSubscriptionFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(SubscriptionActions.upgradeSubscription, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(SubscriptionActions.upgradeSubscriptionSuccess, (state, { subscription }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === subscription.id ? subscription : x),
    savingItem: false,
  })),
  on(SubscriptionActions.upgradeSubscriptionFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(SubscriptionActions.cancelScheduledUpgrade, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(SubscriptionActions.cancelScheduledUpgradeSuccess, (state, { subscription }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === subscription.id ? subscription : x),
    savingItem: false,
  })),
  on(SubscriptionActions.cancelScheduledUpgradeFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),
);

export function subscriptionReducer(state: SubscriptionState | undefined, action: Action) {
  return reducer(state, action);
}
