import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, startWith, tap } from 'rxjs/operators';

import { CurrencyActions, CurrencyPairActions } from 'app/store/actions';
import { Currency, CurrencyPair, State } from 'app/store/models';
import { notNull, untilDestroyed } from 'app/utils/rxjs';

@Component({
  selector: 'app-admin-currency',
  templateUrl: './admin-currency.component.html',
})
export class AdminCurrencyComponent implements OnDestroy {
  loadingCurrencies$: Observable<boolean>;
  loadingCurrencyPairs$: Observable<boolean>;
  savingCurrency$: Observable<boolean>;
  savingCurrencyPair$: Observable<boolean>;
  currencies$: Observable<Currency[]>;
  currencyMap: { [k: string]: string } = {};
  currencyPairs$: Observable<CurrencyPair[]>;
  counterCurrencyOptions$: Observable<{ [key: string]: { value: string; label: string }[] }>;
  activeCurrency: Currency | null = null;
  activeCurrencyPair: CurrencyPair | null = null;
  currencyForm: UntypedFormGroup;
  currencyPairForm: UntypedFormGroup;
  modalInstance: any;
  @ViewChild('currencyModal', { static: true })
  currencyModal?: TemplateRef<any>;
  @ViewChild('currencyPairModal', { static: true })
  currencyPairModal?: TemplateRef<any>;

  constructor(
    private store: Store<State>,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private actions$: Actions,
  ) {
    this.loadingCurrencies$ = this.store.select(x => x.admin.currency.loadingItems);
    this.loadingCurrencyPairs$ = this.store.select(x => x.admin.currencyPair.loadingItems);
    this.savingCurrency$ = this.store.select(x => x.admin.currency.savingItem);
    this.savingCurrencyPair$ = this.store.select(x => x.admin.currencyPair.savingItem);
    this.currencies$ = this.store.select(x => x.admin.currency.items).pipe(
      filter(notNull),
      tap(currencies => currencies.forEach(
        x => this.currencyMap[x.id] = x['@id'] ? x['@id'] : '/api/v1/currencies/' + x.id,
      )),
      startWith([]),
    );
    this.currencyPairs$ = this.store.select(x => x.admin.currencyPair.items).pipe(
      filter(notNull),
      startWith([]),
    );
    this.counterCurrencyOptions$ = this.store.select(x => {
      const currencies = x.admin.currency.items;
      const pairs = x.admin.currencyPair.items;
      if (currencies === null || pairs === null) {
        return {};
      }
      const missingPairs: { [key: string]: { value: string; label: string }[] } = {};
      missingPairs['all'] = [];
      currencies.forEach((baseCurrency: Currency) => {
        missingPairs[baseCurrency.id] = [];
        missingPairs['all'].push({
          label: baseCurrency.code,
          value: baseCurrency.id,
        });
        currencies.forEach((counterCurrency: Currency) => {
          if (baseCurrency.id === counterCurrency.id) {
            return;
          }
          const found = pairs.find(
            pair =>
              pair.baseCurrency.id === baseCurrency.id &&
              pair.counterCurrency.id === counterCurrency.id,
          );
          if (typeof found === 'undefined') {
            missingPairs[baseCurrency.id].push({
              label: counterCurrency.code,
              value: counterCurrency.id,
            });
          }
        });
      });
      return missingPairs;
    });
    this.currencyForm = this.fb.group({
      code: [null, Validators.required],
    });
    this.currencyPairForm = this.fb.group({
      counterCurrency: [null, Validators.required],
      margin: [null],
    });
    this.store.dispatch(CurrencyActions.loadCurrencies());
    this.store.dispatch(CurrencyPairActions.loadCurrencyPairs());

    this.actions$
      .pipe(ofType(
        CurrencyActions.createCurrencySuccess,
        CurrencyActions.updateCurrencySuccess,
        CurrencyPairActions.createCurrencyPairSuccess,
        CurrencyPairActions.updateCurrencyPairSuccess,
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.modalInstance.dismiss();
      });
  }

  newCurrency() {
    this.activeCurrency = null;
    this.currencyForm.reset();
    this.currencyForm.controls['code'].enable();
    this.modalInstance = this.modalService.open(this.currencyModal);
  }

  newCurrencyPair(currency: Currency) {
    this.activeCurrency = currency;
    this.activeCurrencyPair = null;
    this.currencyPairForm.reset();
    this.currencyPairForm.controls['counterCurrency'].enable();
    this.modalInstance = this.modalService.open(this.currencyPairModal);
  }

  editCurrencyPair(currencyPair: CurrencyPair) {
    this.activeCurrencyPair = currencyPair;
    this.currencyPairForm.reset();
    this.currencyPairForm.patchValue({
      ...this.activeCurrencyPair,
      counterCurrency: this.activeCurrencyPair.counterCurrency.id,
      margin: this.activeCurrencyPair.margin ? parseFloat(this.activeCurrencyPair.margin) : null,
    });
    this.currencyPairForm.controls['counterCurrency'].disable();
    this.modalInstance = this.modalService.open(this.currencyPairModal);
  }

  onNewCurrencySubmit() {
    if (this.currencyForm.invalid) {
      this.currencyForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(CurrencyActions.createCurrency({
      currency: this.currencyForm.value,
    }));
  }

  onNewCurrencyPairSubmit() {
    if (this.currencyPairForm.invalid || this.activeCurrency === null) {
      this.currencyPairForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(CurrencyPairActions.createCurrencyPair({
      currencyPair: {
        ...this.currencyPairForm.value,
        baseCurrency: this.activeCurrency['@id']
          ? this.activeCurrency['@id']
          : '/api/v1/currencies/' + this.activeCurrency.id,
        counterCurrency: this.currencyMap[this.currencyPairForm.value.counterCurrency],
        margin: (this.currencyPairForm.value.margin !== null)
          ? this.currencyPairForm.value.margin.toFixed(2)
          : null,
      },
    }));
  }

  onEditCurrencyPairSubmit() {
    if (this.currencyPairForm.invalid || this.activeCurrencyPair === null) {
      this.currencyPairForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(CurrencyPairActions.updateCurrencyPair({
      currencyPair: {
        ...this.currencyPairForm.value,
        id: this.activeCurrencyPair.id,
        margin: (this.currencyPairForm.value.margin !== null)
          ? this.currencyPairForm.value.margin.toFixed(2)
          : null,
      },
    }));
  }

  ngOnDestroy() { }
}
