<h2 class="mb-3">
  {{ 'Subscription configurator' | translate }}
  <button type="button" class="btn btn-link  pb-0" (click)="addTemplateForm(undefined, scrollAnchor)">
    {{ 'Add a new subscription' | translate }}
  </button>
</h2>

<div *ngFor="let subscriptionTemplateForm of subscriptionTemplateForms; let stf = index;">
  <form
    [formGroup]="subscriptionTemplateForm"
    class="form"
  >
    <div class="row">
      <div class="col-6">
        <div class="mb-3">
          <label class="form-label center-block"><b>{{ 'Subscription name' | translate }}</b></label>
          <input class="form-control" type="text" formControlName="label">
        </div>
        <div class="mb-3">
          <label class="form-label center-block">
            {{ 'Private Subscription' | translate }}
            <i
              ngbTooltip="{{ 'This subscription will be available to these companies. If left empty, it will be available to all companies' | translate }}"
              class="far fa-info-circle">
            </i>
          </label>
          <ng-select
            formControlName="allowedTenants"
            [items]="tenants$ | async"
            placeholder="{{ 'Select tenants' | translate }}"
            [clearable]="false"
            bindLabel="name"
            bindValue="@id"
            [multiple]="true"
          ></ng-select>
        </div>
      </div>
    </div>
    <div class="tiers">
      <div
        class="tier"
        formArrayName="subscriptionTemplateTiers"
        *ngFor="
          let subscriptionTemplateTier of getTiers(subscriptionTemplateForm).controls;
          let stt = index;
        "
      >
        <div [formGroupName]="stt" class="tier__content" [ngClass]="{'tier__content--highlighted': subscriptionTemplateTier.value.highlighted, 'invalid': invalidTierMap.indexOf(stf + '-' + stt) > -1}">
          <h4>{{ subscriptionTemplateTier.value.label }}</h4>
          <table>
            <tr
              *ngFor="
                let subscriptionTemplateTierService of getServiceGroups(subscriptionTemplateTier).controls;
              "
            >
              <ng-container *ngIf="subscriptionTemplateTierService.value.service && (subscriptionTemplateTierService.value.tokens || subscriptionTemplateTierService.value.unlimited)">
                <th>
                  <i class="far" [ngClass]="serviceIconMap[subscriptionTemplateTierService.value.service]"></i>
                  {{ serviceMap[subscriptionTemplateTierService.value.service]?.label }}
                  <br>
                  <span *ngIf="invalidServiceMap.indexOf(subscriptionTemplateTierService.value.service) > -1" class="invalid">
                    {{ 'You cannot publish a subscription that includes an unpublished service.' | translate }}
                  </span>
                </th>
                <td>
                  <span *ngIf="!subscriptionTemplateTierService.value.unlimited">
                    {{ subscriptionTemplateTierService.value.tokens }}/{{ (subscriptionTemplateTier.value.priceMonthly ? 'Month' : 'Year') | translate | lowercase }}
                  </span>
                  <span *ngIf="subscriptionTemplateTierService.value.unlimited">{{ 'Unlimited' | translate}}</span>
                </td>
              </ng-container>
            </tr>
          </table>
          <p *ngIf="subscriptionTemplateTier.value.priceMonthly">
            <money [amount]="subscriptionTemplateTier.value.priceMonthly" [currency]="currentTenant?.currency.code"></money><span class="currency">/{{ 'Month' | translate | lowercase }}*</span>
          </p>
          <p *ngIf="subscriptionTemplateTier.value.priceYearly">
            <money [amount]="subscriptionTemplateTier.value.priceYearly" [currency]="currentTenant?.currency.code"></money><span class="currency">/{{ 'Year' | translate | lowercase | lowercase }}*</span>
          </p>
          <div class="tier__content__footer">
            <button type="button" class="btn btn-link" (click)="editTier(subscriptionTemplateForm, stt)">{{ 'Edit' | translate }}</button>
            <button type="button" class="btn btn-link" (click)="removeTier(subscriptionTemplateForm, stt)">{{ 'Remove' | translate }}</button>
          </div>
          <span
            *ngIf="stt < getTiers(subscriptionTemplateForm).controls.length - 1"
            ngbTooltip="{{ 'Users will only be able to upgrade following this sequence' | translate }}"
            class="chevron">
          </span>
        </div>
      </div>
      <div class="tier">
        <div class="tier__content" [ngClass]="{ 'invalid': subscriptionTemplateForm.get('subscriptionTemplateTiers').invalid }">
          <button type="button" class="btn btn-link" (click)="addTier(subscriptionTemplateForm)">{{ 'Add a new tier' | translate }}</button>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <app-loading-button btnType="button" btnClass="btn btn-link ps-0" (click)="onSubscriptionTemplateSubmit(stf, false)" [isLoading]="saveFlags[stf]">
        {{ 'Save a draft' | translate }}
      </app-loading-button>
      <app-loading-button btnType="button" btnClass="btn btn-link ps-0 ms-3" (click)="onSubscriptionTemplateSubmit(stf, true)" [isLoading]="publishFlags[stf]">
        {{ 'Publish a new version' | translate }}
      </app-loading-button>
      <button type="button" *ngIf="subscriptionTemplateForm.value.versionId" class="btn btn-link ps-0 ms-3" (click)="viewVersionHistory(stf)">
        {{ 'View version history' | translate }}
      </button>
      <button type="button" *ngIf="!subscriptionTemplateForm.value.versionId" class="btn btn-link ps-0 ms-3" (click)="discardSubscription(stf)">
        {{ 'Discard subscription' | translate }}
      </button>
      <button type="button" *ngIf="subscriptionTemplateForm.value.versionId" class="btn btn-link ps-0 ms-3" (click)="deleteSubscription(stf)">
        {{ 'Delete subscription' | translate }}
      </button>
    </div>
  </form>
</div>

<foot-note *ngIf="subscriptionTemplateForms.length"></foot-note>

<ng-template #versionHistoryModal let-self>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'Version history' | translate }}</h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="!(loadingVersionHistory$ | async)">
      <div *ngFor="let template of templateVersionHistory$ | async; let i = index;">
        <p class="mt-3">
          <b>{{ template.label }}</b> ({{ 'Version' | translate }} {{ template.version }}, {{ template.createdAt | date:'dd-MM-yyyy HH:mm:ss' }})
          <span *ngIf="i === 0" class="badge badge-success">{{ 'live' | translate }}</span>
        </p>
        <div class="tiers">
          <div class="tier" *ngFor="let subscriptionTemplateTier of template.subscriptionTemplateTiers">
            <div class="tier__content" [ngClass]="{'tier__content--highlighted': subscriptionTemplateTier.highlighted}">
              <h4>{{ subscriptionTemplateTier.label }}</h4>
              <table>
                <tr *ngFor="let subscriptionTemplateTierService of subscriptionTemplateTier?.subscriptionTemplateTierServices">
                  <ng-container *ngIf="subscriptionTemplateTierService.service">
                    <th>{{ serviceMap[subscriptionTemplateTierService.service['@id']]?.label }}</th>
                    <td>
                      <span *ngIf="subscriptionTemplateTierService.tokens">
                        {{ subscriptionTemplateTierService.tokens }}/{{ (subscriptionTemplateTier.priceMonthly ? 'Month' : 'Year') | translate | lowercase }}
                      </span>
                      <span *ngIf="!subscriptionTemplateTierService.tokens">{{ 'Unlimited' | translate}}</span>
                    </td>
                  </ng-container>
                </tr>
              </table>
              <p *ngIf="subscriptionTemplateTier.priceMonthly">
                <money [amount]="subscriptionTemplateTier.priceMonthly" [currency]="currentTenant?.currency.code"></money><span class="currency">/{{ 'Month' | translate | lowercase }}*</span>
              </p>
              <p *ngIf="subscriptionTemplateTier.priceYearly">
                <money [amount]="subscriptionTemplateTier.priceYearly" [currency]="currentTenant?.currency.code"></money><span class="currency">/{{ 'Year' | translate }}*</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <foot-note *ngIf="(templateVersionHistory$ | async)?.length"></foot-note>
  </div>
</ng-template>

<div #scrollAnchor></div>
