import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, UserNotificationActions } from 'app/store/actions';
import { UserNotificationState } from 'app/store/models';

const empty: UserNotificationState = {
  items: null,
  loadingItems: false,
  savingItem: false,
};

const reducer = createReducer<UserNotificationState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(UserNotificationActions.loadNotifications, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(UserNotificationActions.loadNotificationsSuccess, (state, { items }) => ({
    ...state,
    loadingItems: false,
    items,
  })),
  on(UserNotificationActions.loadNotificationsFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(UserNotificationActions.createNotification, (state) => ({
    ...state,
    savingItem: true,
  })),
  on(UserNotificationActions.createNotificationSuccess, (state, { notification }) => ({
    ...state,
    items: [...state.items || [], notification],
    savingItem: false,
  })),
  on(UserNotificationActions.createNotificationFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(UserNotificationActions.updateNotification, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(UserNotificationActions.updateNotificationSuccess, (state, { notification }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === notification.id ? notification : x),
    savingItem: false,
  })),
  on(UserNotificationActions.updateNotificationFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(UserNotificationActions.deleteNotification, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(UserNotificationActions.deleteNotificationSuccess, (state, { notificationId }) => ({
    ...state,
    items: (state.items || []).filter(x => x.id !== notificationId),
    savingItem: false,
  })),
  on(UserNotificationActions.deleteNotificationFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),
);

export function userNotificationReducer(state: UserNotificationState | undefined, action: Action) {
  return reducer(state, action);
}
