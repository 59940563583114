import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { SessionActions } from 'app/store/actions';
import { State, Tenant } from 'app/store/models';
import { notNull } from 'app/utils/rxjs';

@Component({
  selector: 'app-tenant-picker',
  templateUrl: './tenant-picker.component.html',
})
export class TenantPickerComponent {
  loading$: Observable<boolean>;
  tenants$: Observable<Tenant[]>;
  noTenant = false;

  constructor(private store: Store<State>) {
    this.loading$ = this.store.select(x => x.session.loading);
    this.tenants$ = this.store.select(x => x.session.tenants).pipe(
      filter(notNull),
      startWith([]),
    );
    this.tenants$.subscribe((tenants: Tenant[]) => {
      this.noTenant = (tenants.length === 0);
    });
  }

  pickTenant(tenantId: string) {
    this.store.dispatch(SessionActions.pickTenant({ tenantId }));
  }

  logout() {
    this.store.dispatch(SessionActions.logout());
  }
}
