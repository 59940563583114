import { makeActions, ofType } from 'app/store/actions/utils';
import { LegalEntity } from 'app/store/models';

export const LegalEntityActions = makeActions({
  loadLegalEntities: null,
  loadLegalEntitiesSuccess: ofType<{ legalEntities: LegalEntity[]; }>(),
  loadLegalEntitiesFailure: ofType<{ error: any; }>(),

  createLegalEntity: ofType<{ legalEntity: LegalEntity; }>(),
  createLegalEntitySuccess: ofType<{ legalEntity: LegalEntity; }>(),
  createLegalEntityFailure: ofType<{ error: any; }>(),

  updateLegalEntity: ofType<{ legalEntity: LegalEntity; }>(),
  updateLegalEntitySuccess: ofType<{ legalEntity: LegalEntity; }>(),
  updateLegalEntityFailure: ofType<{ error: any; }>(),

  deleteLegalEntity: ofType<{ legalEntityId: string; }>(),
  deleteLegalEntitySuccess: ofType<{ legalEntityId: string; }>(),
  deleteLegalEntityFailure: ofType<{ error: any; }>(),
});
