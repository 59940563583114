import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-cinvio-switch',
  templateUrl: './custom-cinvio-switch.component.html',
  styleUrls: ['./custom-cinvio-switch.component.scss']
})
export class CustomCinvioSwitchComponent implements OnInit {

  @Output() changeEmitter = new EventEmitter();
  @Input() firstOptionSelected: boolean = true;
  constructor() { }

  @Input() firstOption?: string;
  @Input() secondOption?: string;

  ngOnInit(): void {
  }

  changeSwitchOption(value: boolean){
    this.firstOptionSelected = value ? true : false;
    this.changeEmitter.emit(value);
  }
}
