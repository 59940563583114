import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { HydraCollection } from 'app/store/models';
import { BundleConfigurationActions } from 'app/store/actions';
import { BundleTemplate } from 'app/store/models/bundle.model';

@Injectable()
export class BundleConfigurationEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadBundleTemplates$ = createEffect(() => this.actions$.pipe(
    ofType(BundleConfigurationActions.loadBundleTemplates),
    concatMap(() => this.api.get<HydraCollection<BundleTemplate>>({
      path: '/bundle_templates_latest_versions',
    }).pipe(
      map(data => BundleConfigurationActions.loadBundleTemplatesSuccess({ items: data['hydra:member'] })),
      catchError(err => of(BundleConfigurationActions.loadBundleTemplatesFailure(err))),
    )),
  ));
  
  loadBundleTemplateHistory$ = createEffect(() => this.actions$.pipe(
    ofType(BundleConfigurationActions.loadBundleTemplateVersions),
    concatMap(({ versionId }) => this.api.get<HydraCollection<BundleTemplate>>({
      path: `/bundle_template_versions/${versionId}` ,
    }).pipe(
      map(data => BundleConfigurationActions.loadBundleTemplateVersionsSuccess({ items: data['hydra:member'] })),
      catchError(err => of(BundleConfigurationActions.loadBundleTemplateVersionsFailure(err))),
    )),
  ));

  createBundleTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(BundleConfigurationActions.createBundleTemplate),
    concatMap(({ payload }) => this.api.post<BundleTemplate>({
      path: `/bundle_templates`,
      body: payload,
    }).pipe(
      map(data => BundleConfigurationActions.createBundleTemplateSuccess({ template: data })),
      catchError(err => of(BundleConfigurationActions.createBundleTemplateFailure(err))),
    )),
  ));

  updateBundleTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(BundleConfigurationActions.updateBundleTemplate),
    concatMap(({ payload, id }) => this.api.put<BundleTemplate>({
      path: `/bundle_templates/${id}`,
      body: payload,
    }).pipe(
      map(data => BundleConfigurationActions.updateBundleTemplateSuccess({ template: data })),
      catchError(err => of(BundleConfigurationActions.updateBundleTemplateFailure(err))),
    )),
  ));

  deleteBundleTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(BundleConfigurationActions.deleteBundleTemplate),
    concatMap(({ templateId }) => this.api.delete({
      path: `/bundle_templates/${templateId}`,
    }).pipe(
      map(() => BundleConfigurationActions.deleteBundleTemplateSuccess({ templateId })),
      catchError(err => of(BundleConfigurationActions.deleteBundleTemplateFailure(err))),
    )),
  ));
}
