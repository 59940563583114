import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { HydraCollection } from 'app/store/models';
import { BundleActions } from 'app/store/actions';
import { Bundle, BundleTemplate } from 'app/store/models/bundle.model';

@Injectable()
export class BundleEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadBundles$ = createEffect(() => this.actions$.pipe(
    ofType(BundleActions.loadBundles),
    concatMap(() => this.api.get<HydraCollection<Bundle>>({
      path: '/bundles?exists[deletedAt]=false&bundleService.tokensAvailable[gt]=0',
    }).pipe(
      map(data => BundleActions.loadBundlesSuccess({ items: data['hydra:member'] })),
      catchError(err => of(BundleActions.loadBundlesFailure(err))),
    )),
  ));

  loadBundlesForProvider$ = createEffect(() => this.actions$.pipe(
    ofType(BundleActions.loadBundlesForProvider),
    switchMap(params => this.api.get<HydraCollection<Bundle>>({
      path: `/bundles_for_provider?pagination=true`
        + (params.page ? `&page=${encodeURIComponent(params.page)}` : '')
        + (params.pageSize ? `&pageSize=${encodeURIComponent(params.pageSize)}` : '')
        + (params.query ? `&query=${encodeURIComponent(params.query)}` : '')
    }).pipe(
      map(data => BundleActions.loadBundlesForProviderSuccess({
        items: data['hydra:member'],
        totalItems: data['hydra:totalItems'],
      })),
      catchError(err => of(BundleActions.loadBundlesForProviderFailure(err))),
    )),
  ));

  loadBundleTemplatesForUser$ = createEffect(() => this.actions$.pipe(
    ofType(BundleActions.loadBundleTemplatesForUser),
    concatMap(({ providerId }) => this.api.get<HydraCollection<BundleTemplate>>({
      path: `/bundle_templates_latest_versions/${providerId}`,
    }).pipe(
      map(data => BundleActions.loadBundleTemplatesForUserSuccess({ items: data['hydra:member'] })),
      catchError(err => of(BundleActions.loadBundleTemplatesForUserFailure(err))),
    )),
  ));

  startBundle$ = createEffect(() => this.actions$.pipe(
    ofType(BundleActions.startBundle),
    concatMap(({ payload }) => this.api.post<Bundle>({
      path: `/bundles`,
      body: payload,
    }).pipe(
      map(data => BundleActions.startBundleSuccess({ bundle: data })),
      catchError(err => of(BundleActions.startBundleFailure(err))),
    )),
  ));
}
