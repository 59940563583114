import { Injectable } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { ToastService } from 'app/services/toast.service';
import { UserContextActions } from 'app/store/actions';
import { HydraCollection, UserContext } from 'app/store/models';

@Injectable()
export class UserContextEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions,
    private toastr: ToastService,
  ) { }

  loadUserContexts$ = createEffect(() => this.actions$.pipe(
    ofType(UserContextActions.loadUserContexts),
    concatMap(({ superAdmin }) => this.api.get<HydraCollection<UserContext>>({
      path: `/user_contexts${superAdmin === false ? '?user.superAdmin=false' : ''}`,
    }).pipe(
      map(data => UserContextActions.loadUserContextsSuccess({ userContexts: data['hydra:member'] })),
      catchError(err => of(UserContextActions.loadUserContextsFailure(err))),
    )),
  ));

  createUserContext$ = createEffect(() => this.actions$.pipe(
    ofType(UserContextActions.createUserContext),
    concatMap(({ userContext }) => this.api.post<UserContext>({
      path: '/user_contexts',
      body: userContext,
    }).pipe(
      map(data => {
        this.toastr.showSuccess({
          title: marker('Your changes have been saved successfully!'),
        });
        return UserContextActions.createUserContextSuccess({ userContext: data });
      }),
      catchError(err => of(UserContextActions.createUserContextFailure(err))),
    )),
  ));

  createUserContextWithRole$ = createEffect(() => this.actions$.pipe(
    ofType(UserContextActions.createUserContextWithRole),
    concatMap(({ userContext }) => this.api.post<UserContext>({
      path: '/user_contexts_with_role',
      body: userContext,
    }).pipe(
      map(data => {
        this.toastr.showSuccess({
          title: marker('Your changes have been saved successfully!'),
        });
        return UserContextActions.createUserContextWithRoleSuccess({ userContext: data });
      }),
      catchError(err => of(UserContextActions.createUserContextWithRoleFailure(err))),
    )),
  ));

  updateUserContext$ = createEffect(() => this.actions$.pipe(
    ofType(UserContextActions.updateUserContext),
    concatMap(({ userContext }) => this.api.put<UserContext>({
      path: `/user_contexts/${userContext.id}`,
      body: userContext,
    }).pipe(
      map(data => {
        this.toastr.showSuccess({
          title: marker('Your changes have been saved successfully!'),
        });
        return UserContextActions.updateUserContextSuccess({ userContext: data });
      }),
      catchError(err => of(UserContextActions.updateUserContextFailure(err))),
    )),
  ));

  deleteUserContext$ = createEffect(() => this.actions$.pipe(
    ofType(UserContextActions.deleteUserContext),
    concatMap(({ userContextId }) => this.api.delete({
      path: `/user_contexts/${userContextId}`,
    }).pipe(
      map(() => UserContextActions.deleteUserContextSuccess({ userContextId })),
      catchError(err => of(UserContextActions.deleteUserContextFailure(err))),
    )),
  ));
}
