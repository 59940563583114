import { makeActions, ofType } from 'app/store/actions/utils';
import { UserContext, UserContextForm } from 'app/store/models/user-context.model';

export const UserContextActions = makeActions({
  loadUserContexts: ofType<{ superAdmin: boolean }>(),
  loadUserContextsSuccess: ofType<{ userContexts: UserContext[]; }>(),
  loadUserContextsFailure: ofType<{ error: any; }>(),

  createUserContext: ofType<{ userContext: UserContextForm; }>(),
  createUserContextSuccess: ofType<{ userContext: UserContext; }>(),
  createUserContextFailure: ofType<{ error: any; }>(),

  createUserContextWithRole: ofType<{ userContext: UserContextForm; }>(),
  createUserContextWithRoleSuccess: ofType<{ userContext: UserContext; }>(),
  createUserContextWithRoleFailure: ofType<{ error: any; }>(),

  updateUserContext: ofType<{ userContext: UserContext; }>(),
  updateUserContextSuccess: ofType<{ userContext: UserContext; }>(),
  updateUserContextFailure: ofType<{ error: any; }>(),

  deleteUserContext: ofType<{ userContextId: string; }>(),
  deleteUserContextSuccess: ofType<{ userContextId: string; }>(),
  deleteUserContextFailure: ofType<{ error: any; }>(),
});
