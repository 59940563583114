<table class="table">
  <tr class="no-border">
    <th>{{ 'Period' | translate }}</th>
    <th>
      <span *ngIf="currentTenantIsServiceProvider">{{ 'Legal entity' | translate }}</span>
      <span *ngIf="currentTenantIsLegalEntity">{{ 'Service Provider' | translate }}</span>
    </th>
    <th>{{ 'Action' | translate }}</th>
  </tr>
  <tr *ngFor="let purchaseOrder of purchaseOrders$ | async">
    <td>{{ purchaseOrder.start | date:'dd-MM-yyyy' }} - {{ purchaseOrder.end | date:'dd-MM-yyyy' }}</td>
    <td>
      <span *ngIf="currentTenantIsServiceProvider">{{ purchaseOrder.legalEntity }}</span>
      <span *ngIf="currentTenantIsLegalEntity">{{ purchaseOrder.serviceProvider }}</span>
    </td>
    <td>
      <app-loading-button
        btnType="button"
        btnClass="btn btn-sm btn-link"
        (click)="download(purchaseOrder)"
        [isLoading]="downloadFlags[purchaseOrder.id + (purchaseOrder.serviceProviderId || '')]"
      >
        {{ 'Download' | translate }}
      </app-loading-button>
    </td>
  </tr>
</table>
