import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { ServiceActions } from 'app/store/actions';
import { HydraCollection, Service } from 'app/store/models';

@Injectable()
export class ServiceEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadServices$ = createEffect(() => this.actions$.pipe(
    ofType(ServiceActions.loadServices),
    switchMap((params) => this.api.get<HydraCollection<Service>>({
      path: `/services?pagination=true`
        + (params.page ? `&page=${encodeURIComponent(params.page)}` : '')
        + (params.pageSize ? `&pageSize=${encodeURIComponent(params.pageSize)}` : '')
        + (params.query ? `&query=${encodeURIComponent(params.query)}` : ''),
    }).pipe(
      map(data => ServiceActions.loadServicesSuccess({
        services: data['hydra:member'],
        totalItems: data['hydra:totalItems'],
      })),
      catchError(err => of(ServiceActions.loadServicesFailure(err))),
    )),
  ));

  createService$ = createEffect(() => this.actions$.pipe(
    ofType(ServiceActions.createService),
    concatMap(({ service }) => this.api.post<Service>({
      path: '/services',
      body: service,
    }).pipe(
      map(data => ServiceActions.createServiceSuccess({ service: data })),
      catchError(err => of(ServiceActions.createServiceFailure(err))),
    )),
  ));

  updateService$ = createEffect(() => this.actions$.pipe(
    ofType(ServiceActions.updateService),
    concatMap(({ service }) => this.api.put<Service>({
      path: `/services/${service.id}`,
      body: service,
    }).pipe(
      map(data => ServiceActions.updateServiceSuccess({ service: data })),
      catchError(err => of(ServiceActions.updateServiceFailure(err))),
    )),
  ));

  deleteService$ = createEffect(() => this.actions$.pipe(
    ofType(ServiceActions.deleteService),
    concatMap(({ serviceId }) => this.api.delete({
      path: `/services/${serviceId}`,
    }).pipe(
      map(() => ServiceActions.deleteServiceSuccess({ serviceId })),
      catchError(err => of(ServiceActions.deleteServiceFailure(err))),
    )),
  ));
}
