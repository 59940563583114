import { makeActions, ofType } from 'app/store/actions/utils';
import { Currency } from 'app/store/models';

export const CurrencyActions = makeActions({
  loadCurrencies: null,
  loadCurrenciesSuccess: ofType<{ currencies: Currency[]; }>(),
  loadCurrenciesFailure: ofType<{ error: any; }>(),

  createCurrency: ofType<{ currency: Currency; }>(),
  createCurrencySuccess: ofType<{ currency: Currency; }>(),
  createCurrencyFailure: ofType<{ error: any; }>(),

  updateCurrency: ofType<{ currency: Currency; }>(),
  updateCurrencySuccess: ofType<{ currency: Currency; }>(),
  updateCurrencyFailure: ofType<{ error: any; }>(),

  deleteCurrency: ofType<{ currencyId: string; }>(),
  deleteCurrencySuccess: ofType<{ currencyId: string; }>(),
  deleteCurrencyFailure: ofType<{ error: any; }>(),
});
