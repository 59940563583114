import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { LazyLoaderService } from 'app/services/lazy-loader.service';
import { Account, Currency } from 'app/store/models';

@Component({
  selector: 'accounts', // tslint:disable-line component-selector
  templateUrl: 'accounts.component.html',
  styleUrls: ['accounts.component.scss'],
})
export class AccountsComponent {
  @Input()
  accounts: Account[] = [];
  @Input()
  isSpender: boolean = false;

	public isCollapsed = true;
}
