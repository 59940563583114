import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-recharge-wallet-modal',
  templateUrl: './recharge-wallet-modal.component.html',
})
export class RechargeWalletModalComponent implements OnDestroy {
  message: string|null = null;
  redirectUrl?: string = undefined;
  modalInstance: any;

  constructor(private modal: NgbActiveModal, private router: Router) {}

  setMessage(message: string) {
    this.message = message;
    this.redirectUrl = window.location.origin + this.router.url;
  }

  handleCancel() {
    this.modal.dismiss();
  }

  ngOnDestroy() { }
}
