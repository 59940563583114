<h2>
  {{ 'Notifications' | translate }}
  <small *ngIf="currentUserContext?.user?.email" class="text-muted">{{ 'for' | translate }} {{ currentUserContext?.user?.email }}</small>
</h2>
<div class="card">
  <h5 class="card-header">{{ 'Low balance warning' | translate }}</h5>
  <div class="card-body">
    <form
      (ngSubmit)="onNotificationsSubmit(0)"
      [formGroup]="notificationsForm"
    >
      <div class="input-group">
        <div class="form-check mt-2">
          <input
            id="lowBalance"
            class="form-check-input"
            type="checkbox"
            formControlName="lowBalance"
            data-cy="lowBalance"
            (change)="toggleLowBalance($event)"
          />
          <label class="form-label form-check-label" for="lowBalance">
            {{ 'Send me an email whenever my wallet goes under the following value (email will be sent out on a Monday):' | translate }}
          </label>
        </div>
        <div class="mb-3 ms-3">
          <div class="input-group">
            <input
              class="form-control"
              type="number"
              formControlName="lowBalanceThreshold"
              data-cy="lowBalanceThreshold"
              placeholder="{{ 'Threshold' | translate }}"
            />
            <span class="input-group-text">{{ currentTenant?.currency.code }}</span>
          </div>
        </div>
      </div>
      <app-loading-button btnType="submit" [isLoading]="saveFlags[0]" [isDisabled]="loadingNotifications$ | async">
        {{ 'Save' | translate }}
      </app-loading-button>
    </form>
  </div>
</div>

<div class="card mt-3">
  <h5 class="card-header">{{ 'Insufficient balance for subscription renewal' | translate }}</h5>
  <div class="card-body">
    <form
      (ngSubmit)="onNotificationsSubmit(1)"
      [formGroup]="notificationsForm"
    >
      <div class="input-group">
        <div class="form-check mt-1">
          <input
            id="payout"
            class="form-check-input"
            type="checkbox"
            formControlName="subscriptionRenewalBalance"
            data-cy="subscriptionRenewalBalance"
          />
          <label class="form-label form-check-label" for="subscriptionRenewalBalance">
            {{ 'Send me an email whenever my wallet doesn’t have enough funds to renew my subscription(s).' | translate }}
          </label>
        </div>
      </div>
      <app-loading-button btnType="submit" btnClass="btn btn-primary mt-3" [isLoading]="saveFlags[1]" [isDisabled]="loadingNotifications$ | async">
        {{ 'Save' | translate }}
      </app-loading-button>
    </form>
  </div>
</div>

<div class="card mt-3">
  <h5 class="card-header">{{ 'All tokens consumed' | translate }}</h5>
  <div class="card-body">
    <form
      (ngSubmit)="onNotificationsSubmit(2)"
      [formGroup]="notificationsForm"
    >
      <div class="input-group">
        <div class="form-check mt-1">
          <input
            id="payout"
            class="form-check-input"
            type="checkbox"
            formControlName="subscriptionTokens"
            data-cy="subscriptionTokens"
          />
          <label class="form-label form-check-label" for="subscriptionTokens">
            {{ 'Send me an email whenever I have used up all tokens in my subscription.' | translate }}
          </label>
        </div>
      </div>
      <app-loading-button btnType="submit" btnClass="btn btn-primary mt-3" [isLoading]="saveFlags[2]" [isDisabled]="loadingNotifications$ | async">
        {{ 'Save' | translate }}
      </app-loading-button>
    </form>
  </div>
</div>

<div class="card mt-3" *ngIf="enablePayoutNotification()">
  <h5 class="card-header">{{ 'Payout initiated' | translate }}</h5>
  <div class="card-body">
    <form
      (ngSubmit)="onNotificationsSubmit(3)"
      [formGroup]="notificationsForm"
    >
      <div class="input-group">
        <div class="form-check mt-1">
          <input
            id="payout"
            class="form-check-input"
            type="checkbox"
            formControlName="payout"
            data-cy="payout"
          />
          <label class="form-label form-check-label" for="payout">
            {{ 'Send me an email every time cinvio initiates a payout to my company bank account.' | translate }}
          </label>
        </div>
      </div>
      <app-loading-button btnType="submit" btnClass="btn btn-primary mt-3" [isLoading]="saveFlags[3]" [isDisabled]="loadingNotifications$ | async">
        {{ 'Save' | translate }}
      </app-loading-button>
    </form>
  </div>
</div>
