import { makeActions, ofType } from 'app/store/actions/utils';
import { Registration } from 'app/store/models';

export const RegistrationActions = makeActions({
  loadRegistrations: ofType<{
    processed: boolean;
    page?: number;
    pageSize?: number;
  }>(),
  loadRegistrationsSuccess: ofType<{ items: Registration[]; totalItems: number; }>(),
  loadRegistrationsFailure: ofType<{ error: any; }>(),

  updateRegistration: ofType<{ registration: Registration; }>(),
  updateRegistrationSuccess: ofType<{ registration: Registration; }>(),
  updateRegistrationFailure: ofType<{ error: any; }>(),

  approveRegistration: ofType<{ registrationId: string; }>(),
  approveRegistrationSuccess: ofType<{ registrationId: string; }>(),
  approveRegistrationFailure: ofType<{ error: any; }>(),

  declineRegistration: ofType<{ registrationId: string; }>(),
  declineRegistrationSuccess: ofType<{ registrationId: string; }>(),
  declineRegistrationFailure: ofType<{ error: any; }>(),

  toggleProcessed: ofType<{
    processed: boolean;
    page?: number;
    pageSize?: number;
  }>(),
});
