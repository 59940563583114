import { Injectable } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { ToastService } from 'app/services/toast.service';
import { UserNotificationActions } from 'app/store/actions';
import { HydraCollection, Notification } from 'app/store/models';

@Injectable()
export class UserNotificationEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions,
    private toastr: ToastService,
  ) { }

  loadNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(UserNotificationActions.loadNotifications),
    concatMap(() => this.api.get<HydraCollection<Notification>>({
      path: '/notifications',
    }).pipe(
      map(data => UserNotificationActions.loadNotificationsSuccess({ items: data['hydra:member'] })),
      catchError(err => of(UserNotificationActions.loadNotificationsFailure(err))),
    )),
  ));

  createNotification$ = createEffect(() => this.actions$.pipe(
    ofType(UserNotificationActions.createNotification),
    concatMap(({ notification }) => this.api.post<Notification>({
      path: '/notifications',
      body: notification,
    }).pipe(
      map(data => {
        this.toastr.showSuccess({
          title: marker('Your changes have been saved successfully!'),
        });
        return UserNotificationActions.createNotificationSuccess({ notification: data });
      }),
      catchError(err => of(UserNotificationActions.createNotificationFailure(err))),
    )),
  ));

  updateNotification$ = createEffect(() => this.actions$.pipe(
    ofType(UserNotificationActions.updateNotification),
    concatMap(({ notification }) => this.api.put<Notification>({
      path: `/notifications/${notification.id}`,
      body: notification,
    }).pipe(
      map(data => {
        this.toastr.showSuccess({
          title: marker('Your changes have been saved successfully!'),
        });
        return UserNotificationActions.updateNotificationSuccess({ notification: data });
      }),
      catchError(err => of(UserNotificationActions.updateNotificationFailure(err))),
    )),
  ));

  deleteNotification$ = createEffect(() => this.actions$.pipe(
    ofType(UserNotificationActions.deleteNotification),
    concatMap(({ notificationId }) => this.api.delete({
      path: `/notifications/${notificationId}`,
    }).pipe(
      map(() => {
        this.toastr.showSuccess({
          title: marker('Your changes have been saved successfully!'),
        });
        return UserNotificationActions.deleteNotificationSuccess({ notificationId });
      }),
      catchError(err => of(UserNotificationActions.deleteNotificationFailure(err))),
    )),
  ));
}
