import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { ServiceProviderActions } from 'app/store/actions';
import { HydraCollection, ServiceProvider } from 'app/store/models';

@Injectable()
export class ServiceProviderEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadServiceProviders$ = createEffect(() => this.actions$.pipe(
    ofType(ServiceProviderActions.loadServiceProviders),
    concatMap(() => this.api.get<HydraCollection<ServiceProvider>>({
      path: '/service_providers',
    }).pipe(
      map(data => ServiceProviderActions.loadServiceProvidersSuccess({ serviceProviders: data['hydra:member'] })),
      catchError(err => of(ServiceProviderActions.loadServiceProvidersFailure(err))),
    )),
  ));

  createServiceProvider$ = createEffect(() => this.actions$.pipe(
    ofType(ServiceProviderActions.createServiceProvider),
    concatMap(({ serviceProvider }) => this.api.post<ServiceProvider>({
      path: '/service_providers',
      body: serviceProvider,
    }).pipe(
      map(data => ServiceProviderActions.createServiceProviderSuccess({ serviceProvider: data })),
      catchError(err => of(ServiceProviderActions.createServiceProviderFailure(err))),
    )),
  ));

  updateServiceProvider$ = createEffect(() => this.actions$.pipe(
    ofType(ServiceProviderActions.updateServiceProvider),
    concatMap(({ serviceProvider }) => this.api.put<ServiceProvider>({
      path: `/service_providers/${serviceProvider.id}`,
      body: serviceProvider,
    }).pipe(
      map(data => ServiceProviderActions.updateServiceProviderSuccess({ serviceProvider: data })),
      catchError(err => of(ServiceProviderActions.updateServiceProviderFailure(err))),
    )),
  ));

  deleteServiceProvider$ = createEffect(() => this.actions$.pipe(
    ofType(ServiceProviderActions.deleteServiceProvider),
    concatMap(({ serviceProviderId }) => this.api.delete({
      path: `/service_providers/${serviceProviderId}`,
    }).pipe(
      map(() => ServiceProviderActions.deleteServiceProviderSuccess({ serviceProviderId })),
      catchError(err => of(ServiceProviderActions.deleteServiceProviderFailure(err))),
    )),
  ));
}
