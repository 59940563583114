import { Action, createReducer, on, Store } from '@ngrx/store';

import { CommonActions, OAuthClientActions } from 'app/store/actions';
import { OAuthClientState } from 'app/store/models';

const empty: OAuthClientState = {
  items: null,
  loadingItems: false,
  savingItem: false,
};

const reducer = createReducer<OAuthClientState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(OAuthClientActions.loadOAuthClients, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(OAuthClientActions.loadOAuthClientsSuccess, (state, { oauthClients }) => ({
    ...state,
    items: oauthClients,
    loadingItems: false,
  })),
  on(OAuthClientActions.loadOAuthClientsFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(OAuthClientActions.createOAuthClient, (state) => ({
    ...state,
    savingItem: true,
  })),
  on(OAuthClientActions.createOAuthClientSuccess, (state, { oauthClient }) => ({
    ...state,
    items: [...state.items || [], oauthClient],
    savingItem: false,
  })),
  on(OAuthClientActions.createOAuthClientFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(OAuthClientActions.updateOAuthClient, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(OAuthClientActions.updateOAuthClientSuccess, (state, { oauthClient }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === oauthClient.id ? oauthClient : x),
    savingItem: false,
  })),
  on(OAuthClientActions.updateOAuthClientFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(OAuthClientActions.deleteOAuthClient, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(OAuthClientActions.deleteOAuthClientSuccess, (state, { oauthClientId }) => ({
    ...state,
    items: (state.items || []).filter(x => x.id !== oauthClientId),
    savingItem: false,
  })),
  on(OAuthClientActions.deleteOAuthClientFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(OAuthClientActions.unlinkPairedTenantSuccess, (state, { providerAuthLinkId }) => ({
    ...state,
    items: state.items === null ? null : state.items.map(item => ({
      ...item,
      providerAuthLinks: item.providerAuthLinks.map(
        link => link.id === providerAuthLinkId
          ? ({ ...link, deleted: true })
          : link,
        ),
    })),
  })),
);

export function oauthClientReducer(state: OAuthClientState | undefined, action: Action) {
  return reducer(state, action);
}
