<div style="margin:-1rem" class="pt-3">
  <ul class="nav nav-tabs ps-3">
    <li *ngIf="currentTenantIsServiceProvider || currentTenantIsLegalEntity" class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/documents/earner">
        {{ 'Earner' | translate }}
      </a>
    </li>
    <li *ngIf="currentTenantIsServiceProvider" class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/documents/spender">
        {{ 'Spender' | translate }}
      </a>
    </li>
    <li *ngIf="currentTenantIsServiceProvider || currentTenantIsLegalEntity" class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/documents/po">
        {{ 'PO' | translate }}
      </a>
    </li>
  </ul>
  <div class="p-3">
    <router-outlet></router-outlet>
  </div>
</div>
