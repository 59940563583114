import { makeActions, ofType } from 'app/store/actions/utils';
import { Document, PurchaseOrder } from '../models/document.model';

export const DocumentActions = makeActions({
  loadDocuments: ofType<{ userCompany?: string }>(),
  loadDocumentsSuccess: ofType<{ items: Document[]; }>(),
  loadDocumentsFailure: ofType<{ payload: any; }>(),

  loadPurchaseOrders: null,
  loadPurchaseOrdersSuccess: ofType<{ items: PurchaseOrder[]; }>(),
  loadPurchaseOrdersFailure: ofType<{ payload: any; }>(),

  resetDocuments: null,
});
