import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BillingType, Subscription, SubscriptionUpgradeTier } from 'app/store/models/subscription.model';

import { SubscriptionUpgradeConfirmModalComponent } from './subscription-upgrade-confirm-modal.component';

@Component({
  selector: 'app-subscription-upgrade-modal',
  templateUrl: './subscription-upgrade-modal.component.html',
  styleUrls: ['./subscription-upgrade-modal.component.scss'],
})
export class SubscriptionUpgradeModalComponent implements OnDestroy {
  upgradeTiers: SubscriptionUpgradeTier[]|null = null;
  subscription: Subscription|null = null;
  billingOptionsModel: { [k: string]: string } = {};
  modalInstance: any;

  constructor(private modal: NgbActiveModal, private modalService: NgbModal) {}

  setSubscription(subscription: Subscription): void {
    this.subscription = subscription;
  }

  setUpgradeTiers(upgradeTiers: SubscriptionUpgradeTier[]) {
    this.upgradeTiers = upgradeTiers;

    this.upgradeTiers.forEach((upgradeTier: SubscriptionUpgradeTier) => {
      const tier: any = upgradeTier.templateTier;
      this.billingOptionsModel[tier.id] = tier.priceMonthly && upgradeTier.allowMonthlyBilling
        ? BillingType.MONTHLY
        : BillingType.YEARLY;
    });
  }

  confirmSubscriptionUpgrade(upgradeTier: SubscriptionUpgradeTier) {
    const modalRef = this.modalService.open(SubscriptionUpgradeConfirmModalComponent, { size: 'lg' });
    const billing = this.billingOptionsModel[upgradeTier.templateTier.id];

    modalRef.componentInstance.setSubscription(this.subscription);
    modalRef.componentInstance.setUpgradeTier(upgradeTier);
    modalRef.componentInstance.setBilling(billing);
    modalRef.result.then(() => {
      this.modal.close();
    }, () => {});
  }

  handleCancel() {
    this.modal.dismiss();
  }

  ngOnDestroy() { }
}
