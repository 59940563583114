<span *ngIf="accounts.length" class="ps-3 pe-2 row">
  <div class="col-auto h2 account mb-0 ps-0">
    <div>
      <money [amount]="accounts[0]?.available" [currency]="accounts[0]?.currency"></money>
    </div>
  </div>
  <div class="col-auto p-0 mt-2 reserved-row">
    <span class="h4 p-0 text-muted inline-block align-self-end mb-1" *ngIf="(+accounts[0]?.reserved) > 0">
      <a routerLink="/transactions" [queryParams]="{showOnlyReserved: true, openSpender: isSpender}">
        <div class="row p-0 m-0 reserved-row">
          <div class="col-auto ps-0 pe-1 d-flex">
            <money [amount]="accounts[0]?.reserved" [currency]="accounts[0]?.currency" [isReserved]="true"></money>
          </div>
        </div>
      </a>
    </span>
  </div>
  <div class="col-auto ps-2 pe-0">
    <p *ngIf="accounts.length > 1" class="mb-0">
      <button type="button" class="btn btn-outline-primary mb-3" (click)="isCollapsed = ! isCollapsed"
        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample" placement="top" ngbTooltip="Show other accounts">
        <i class="far fa-chevron-down"></i>
      </button>
    </p>
  </div>
</span>

<div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
  <div class="card container mt-2 card-container">
      <span *ngFor="let account of accounts.slice(1)" class="px-2 row">
        <div class="col-auto account h3 mb-0 ps-0">
          <div class="">
            <money [amount]="account.available" [currency]="account.currency"></money>
          </div>
        </div>
        <div class="col-auto p-0 mt-1 reserved-row">
          <span class="h4 p-0 text-muted inline-block align-self-end mb-1" *ngIf="(+account.reserved) > 0">
            <a routerLink="/transactions" [queryParams]="{showOnlyReserved: true, openSpender: isSpender}">
              <div class="row p-0 m-0 reserved-row">
                <div class="col-auto ps-0 pe-2 d-flex">
                  <money [amount]="account.reserved" [currency]="account.currency" [isReserved]="true"></money>
                </div>
              </div>
            </a>
          </span>
        </div>
      </span>
  </div>
</div>
