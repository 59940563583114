import { Component, Input } from '@angular/core';

@Component({
  selector: 'foot-note', // tslint:disable-line component-selector
  templateUrl: 'foot-note.component.html',
  styleUrls: ['foot-note.component.scss'],
})
export class FootNoteComponent {
  @Input()
  note: string = 'All prices are excl. VAT/GST';
  @Input()
  reduceBottomMargin: boolean = false;
}
