import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { HydraCollection } from 'app/store/models';
import { BundleInvoicingActions } from 'app/store/actions';
import { BundleTemplate } from '../models/bundle.model';

@Injectable()
export class BundleInvoicingEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadBundleTemplatesForInvoicing$ = createEffect(() => this.actions$.pipe(
    ofType(BundleInvoicingActions.loadBundleTemplatesForInvoicing),
    concatMap(() => this.api.get<HydraCollection<BundleTemplate>>({
      path: '/bundle_templates_for_invoicing',
    }).pipe(
      map(data => BundleInvoicingActions.loadBundleTemplatesForInvoicingSuccess({ items: data['hydra:member'] })),
      catchError(err => of(BundleInvoicingActions.loadBundleTemplatesForInvoicingFailure(err))),
    )),
  ));

  updateSubscriptionTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(BundleInvoicingActions.updateBundleTemplateInvoiceCodes),
    concatMap(({ payload, id }) => this.api.put<BundleTemplate>({
      path: `/bundle_templates/${id}/invoice_codes`,
      body: payload,
    }).pipe(
      map(data => BundleInvoicingActions.updateBundleTemplateInvoiceCodesSuccess({ tier: data })),
      catchError(err => of(BundleInvoicingActions.updateBundleTemplateInvoiceCodesFailure(err))),
    )),
  ));
}
