import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ToastService } from 'app/services/toast.service';
import { State } from 'app/store/models';
import { notNull } from 'app/utils/rxjs';

@Injectable()
export class AdminGuard  {
  constructor(
    private router: Router,
    private toastr: ToastService,
    private store: Store<State>,
  ) { }

  canActivate(): Observable<boolean> {
    return this.store.select(x => x.session.userContext).pipe(
      filter(notNull),
      map(ctx => {
        if (ctx.user.superAdmin) {
          return true;
        }
        this.router.navigateByUrl('/overview');
        this.toastr.showDanger({
          title: 'Not allowed',
          message: 'Only system administrators can access this page.',
        });
        return false;
      }),
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }
}
