import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { CountryActions, LegalEntityActions, PaymentProviderActions } from 'app/store/actions';
import { Country, State } from 'app/store/models';
import { CompanyIdentifierType } from 'app/store/models/country.model';
import { notNull, untilDestroyed } from 'app/utils/rxjs';

import { ConfirmActionModalComponent } from './confirm-action-modal.component';

@Component({
  selector: 'app-admin-country',
  templateUrl: './admin-country.component.html',
  styleUrls: ['./admin-country.component.scss']
})
export class AdminCountryComponent implements OnDestroy {
  loadingCountries$: Observable<boolean>;
  savingCountry$: Observable<boolean>;
  legalEntityOptions$: Observable<{ value: string; label: string }[]>;
  paymentProviderOptions$: Observable<{ value: string; label: string }[]>;
  companyIdentifierTypeOptions: { label: string, value: string }[] = [];
  legalEntityMap: { [k: string]: string } = {};
  paymentProviderMap: { [k: string]: string } = {};
  countries$: Observable<Country[]>;
  activeCountry: Country | null = null;
  countryForm: UntypedFormGroup;
  modalInstance: any;

  @ViewChild('countryModal', { static: true })
  countryModal?: TemplateRef<any>;

  constructor(
    private store: Store<State>,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private actions$: Actions,
  ) {
    this.loadingCountries$ = this.store.select(x => x.admin.country.loadingItems);
    this.savingCountry$ = this.store.select(x => x.admin.country.savingItem);
    this.store.select(x => x.admin.legalEntity.items).pipe(
      filter(notNull),
      startWith([]),
      untilDestroyed(this),
    ).subscribe(legalEntities => legalEntities.forEach(
        x => this.legalEntityMap[x.id] = x['@id'] ? x['@id'] : '/api/v1/legal_entities/' + x.id,
    ));
    this.legalEntityOptions$ = this.store.select(
      x => (x.admin.legalEntity.items || [])
        .map(legalEntity => ({ label: legalEntity.name, value: legalEntity.id })),
    );
    this.store.select(x => x.admin.paymentProvider.items).pipe(
      filter(notNull),
      startWith([]),
      untilDestroyed(this),
    ).subscribe(paymentProviders => paymentProviders.forEach(
        x => {
          this.paymentProviderMap[x.id] = x['@id'] ? x['@id'] : '/api/v1/payment_providers/' + x.id;
        }
    ));
    this.paymentProviderOptions$ = this.store.select(
      x => (x.admin.paymentProvider.items || [])
        .map(paymentProvider => ({ label: paymentProvider.label, value: paymentProvider.id })),
    );
    this.companyIdentifierTypeOptions = Object.values(CompanyIdentifierType).map((type) => ({ label: 'label_' + type, value: type }));
    this.countries$ = this.store.select(x => x.admin.country.items).pipe(
      filter(notNull),
      startWith([]),
    );
    this.countryForm = this.fb.group({
      legalEntity: [null],
      paymentProviders: [null, Validators.required],
      code: [null, Validators.required],
      companyIdentifierType: [null, Validators.required],
    });
    this.store.dispatch(LegalEntityActions.loadLegalEntities());
    this.store.dispatch(PaymentProviderActions.loadPaymentProviders());
    this.store.dispatch(CountryActions.loadCountries());
    this.actions$.pipe(
      ofType(
        CountryActions.createCountrySuccess,
        CountryActions.updateCountrySuccess,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      this.modalInstance.dismiss();
    });
  }

  newCountry() {
    this.activeCountry = null;
    this.countryForm.reset();
    this.modalInstance = this.modalService.open(this.countryModal);
  }

  editCountry(country: Country) {
    this.activeCountry = country;
    this.countryForm.reset();
    this.countryForm.patchValue({
      ...this.activeCountry,
      legalEntity: this.activeCountry.legalEntity?.id,
      paymentProviders: this.activeCountry.paymentProviders.map(paymentProvider => paymentProvider.id),
    });

    this.modalInstance = this.modalService.open(this.countryModal);
  }

  deactivateCountry(country: Country) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.title = marker('Are you sure you want to deactivate this country?');
    modalRef.componentInstance.confirmButtonText = marker('Deactivate');
    modalRef.result.then(() => this.store.dispatch(CountryActions.deleteCountry({ countryId: country.id })));
  }

  activateCountry(country: Country) {
    this.store.dispatch(CountryActions.reactivateCountry({ countryId: country.id }));
  }

  onCreateCountrySubmit() {
    if (this.countryForm.invalid) {
      this.countryForm.markAllAsTouched();
      return;
    }

    this.store.dispatch(CountryActions.createCountry({
      country: this.prepareCountryPayload(),
    }));
  }

  onEditCountrySubmit() {
    if (this.countryForm.invalid || this.activeCountry === null) {
      this.countryForm.markAllAsTouched();
      return;
    }

    const payload = this.prepareCountryPayload(this.activeCountry);

    if (payload.legalEntity !== this.activeCountry.legalEntity['@id']) {
      const modalRef = this.modalService.open(ConfirmActionModalComponent);
      modalRef.componentInstance.title = marker('Are you sure you want to change the legal entity of this country?');
      modalRef.componentInstance.message = marker('For this accountancy period, there will be one invoice per legal entity and users will no longer be able to see the documents linked to the current legal entity.');
      modalRef.componentInstance.confirmButtonText = marker('Confirm');
      modalRef.result.then(
        () => this.store.dispatch(CountryActions.updateCountry({
          country: payload
        })),
        () => {}
      );
    } else {
      this.store.dispatch(CountryActions.updateCountry({
        country: payload,
      }));
    }
  }

  prepareCountryPayload(country?: Country) {
    const payload = {
      ...this.countryForm.value,
      id: country?.id,
      legalEntity: this.legalEntityMap[this.countryForm.value.legalEntity] || null,
      paymentProviders: (this.countryForm.value.paymentProviders || []).map((paymentProviderId: string) => this.paymentProviderMap[paymentProviderId]),
    };

    return payload;
  }

  ngOnDestroy() { }
}
