<h2>{{ 'Registration Requests' | translate }}</h2>
<button class="btn btn-link" (click)="toggleProcessed()">
    {{ (showProcessed ? 'Show pending' : 'Show processed') | translate }}
</button>
<table class="table">
  <tr *ngFor="let registration of registrations$ | async | sortOn: 'companyName'">
    <td>{{ registration.companyName }}</td>
    <td>{{ registration.vatNumber }}</td>
    <td>{{ registration.invoiceEmail }}</td>
    <td>{{ registration.phoneNumber }}</td>
    <td>
      <button class="btn btn-sm btn-link" (click)="editRegistration(registration, editRegistrationModal)">{{ 'Edit' | translate }}</button>
      <button
        *ngIf="registration.status !== 'approved'" class="btn btn-sm btn-link"
        (click)="approveRegistration(registration)"
      >
        {{ 'Approve' | translate }}
      </button>
      <button
        *ngIf="registration.status === 'pending'" class="btn btn-sm btn-link"
        (click)="declineRegistration(registration)"
      >
        {{ 'Decline' | translate }}
      </button>
    </td>
  </tr>
</table>

<app-pagination [totalPages]="pages$ | async" [pageSource]="page$"></app-pagination>

<ng-template #editRegistrationModal let-self>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'Edit Registration' | translate }} {{ activeRegistration?.companyName }}</h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="uenVerificationResult || vatVerificationResult || duplicateVatNumberTenant || duplicateNameTenant">
      <div
        *ngIf="vatVerificationResult" class="alert"
        [ngClass]="vatNumberIsValid ? 'alert-success' : 'alert-danger'"
      >
        <div>
          <p><b>{{ (vatNumberIsValid ? 'VIES check OK' : 'VIES check failed') | translate }}</b></p>
        </div>
        <div>
          {{ 'VAT number:' | translate }}&nbsp;
          {{ vatVerificationResult.countryCode}}{{ vatVerificationResult.vatNumber }}
        </div>
        <div>
          {{ 'Name:' | translate }}&nbsp;{{ vatVerificationResult.name }}
        </div>
        <div>
          {{ 'Address:' | translate }}&nbsp;{{ vatVerificationResult.address }}
        </div>
      </div>
      <div
        *ngIf="uenVerificationResult" class="alert"
        [ngClass]="vatNumberIsValid ? 'alert-success' : 'alert-danger'"
      >
        <div>
          <p><b>{{ (vatNumberIsValid ? 'Check OK' : 'Check failed') | translate }}</b></p>
        </div>
        <div>
          {{ 'UEN number:' | translate }}&nbsp;
          {{ uenVerificationResult.uen}}
        </div>
        <div>
          {{ 'Name:' | translate }}&nbsp;{{ uenVerificationResult.entity_name }}
        </div>
        <div>
          {{ 'Address:' | translate }}&nbsp;{{ uenVerificationResult.reg_street_name }}
        </div>
      </div>
      <div *ngIf="duplicateVatNumberTenant" class="alert alert-danger">
        <div>
          <p><b>{{ 'Tenant with the same VAT number found' | translate }}</b></p>
        </div>
        <div>
          {{ 'Name:' | translate }}&nbsp;{{ duplicateVatNumberTenant.name }}
        </div>
        <div>
          {{ 'VAT number:' | translate }}&nbsp;{{ duplicateVatNumberTenant.vatNumber }}
        </div>
        <div>
          {{ 'Address:' | translate }}&nbsp;{{ duplicateVatNumberTenant.address }}
        </div>
      </div>
      <div *ngIf="duplicateNameTenant" class="alert alert-danger">
        <div>
          <p><b>{{ 'Tenant with the same name found' | translate }}</b></p>
        </div>
        <div>
          {{ 'Name:' | translate }}&nbsp;{{ duplicateNameTenant.name }}
        </div>
        <div>
          {{ 'VAT number:' | translate }}&nbsp;{{ duplicateNameTenant.vatNumber }}
        </div>
        <div>
          {{ 'Address:' | translate }}&nbsp;{{ duplicateNameTenant.address }}
        </div>
      </div>
    </div>
    <form
      (ngSubmit)="onEditRegistrtionSubmit()"
      [formGroup]="registrationForm"
      class="p-3"
    >
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Company Name' | translate }}</label>
        <input class="form-control" formControlName="companyName">
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Invoice E-mail' | translate }}</label>
        <input class="form-control" formControlName="invoiceEmail">
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ getVatNumberLabel() }}</label>
        <div class="input-group">
          <input
            class="form-control"
            formControlName="vatNumber"
            data-cy="vatNumber"
            [ngClass]="{
              'is-invalid':
                (registrationForm.controls['vatNumber'].invalid && registrationForm.controls['vatNumber'].touched)
                || (vatNumberIsVerified && !vatNumberIsValid) || duplicateVatNumberTenant !== undefined || duplicateNameTenant !== undefined,
              'is-valid': vatNumberIsValid && duplicateVatNumberTenant === undefined && duplicateNameTenant === undefined
            }"
          />
            <app-loading-button
              (click)="verifyVatNumber()"
              [btnClass]="'btn btn-dark'"
              [isLoading]="isVerifyingVatNumber"
              [btnType]="'button'"
            >
              {{ getVatNumberButtonLabel() }}
            </app-loading-button>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Currency' | translate }}</label>
        <ng-select
          formControlName="currency"
          [items]="currencyOptions$ | async"
          [clearable]="false"
          bindLabel="label"
          bindValue="value"
        ></ng-select>
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Address' | translate }}</label>
        <input class="form-control" formControlName="streetAndNumber">
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Zipcode' | translate }}</label>
        <input class="form-control" formControlName="zipcode">
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'City' | translate }}</label>
        <input class="form-control" formControlName="city">
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Country' | translate }}</label>
        <ng-select
          formControlName="country"
          [items]="countries$ | async"
          [clearable]="false"
          bindLabel="label"
          bindValue="value"
        >
          <ng-template ng-label-tmp let-item="item">
            <span class="fi fi-{{item.value | lowercase}}"></span>
            {{item.label}}
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <span class="fi fi-{{item.value | lowercase}}"></span>
            {{item.label}}
          </ng-template>
        </ng-select>
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Type of activity' | translate }}</label>
        <ng-select
          formControlName="activity"
          [items]="activityTypes"
          [clearable]="false"
          bindLabel="label"
          bindValue="value"
        ></ng-select>
      </div>
      <hr>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'First Name' | translate }}</label>
        <input class="form-control" formControlName="firstName">
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Last Name' | translate }}</label>
        <input class="form-control" formControlName="lastName">
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'E-mail' | translate }}</label>
        <input class="form-control" formControlName="email">
      </div>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Phone Number' | translate }}</label>
        <input class="form-control" formControlName="phoneNumber">
      </div>
      <app-loading-button btnType="submit" [isLoading]="savingRegistration$ | async">
        {{ 'Submit' | translate }}
      </app-loading-button>
    </form>
  </div>
</ng-template>
