import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fileSaver from 'file-saver-es';
import { Observable } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { marker } from "@biesbjerg/ngx-translate-extract-marker";

import { ApiService } from 'app/services/api.service';
import { ToastService } from 'app/services/toast.service';
import { DocumentActions } from 'app/store/actions';
import { State } from 'app/store/models';
import { PurchaseOrder } from 'app/store/models/document.model';
import { selectCurrentTenant } from 'app/store/selectors';
import { notNull, untilDestroyed } from 'app/utils/rxjs';

@Component({
  selector: 'app-documents-purchase-orders',
  templateUrl: './documents-purchase-orders.component.html',
  styleUrls: ['./documents-purchase-orders.component.scss'],
})
export class DocumentsPurchaseOrdersComponent implements OnDestroy {
  loadingDocuments$: Observable<boolean>;
  purchaseOrders$: Observable<PurchaseOrder[]>;
  downloadFlags: { [k: string]: boolean } = {};
  currentTenantIsServiceProvider = false;
  currentTenantIsLegalEntity = false;

  constructor(
    private store: Store<State>,
    private api: ApiService,
    private toastr: ToastService,
  ) {
    this.loadingDocuments$ = this.store.select(x => x.document.loadingItems);

    this.store.select(selectCurrentTenant).pipe(
      untilDestroyed(this),
      filter(notNull),
    ).subscribe(tenant => {
      this.currentTenantIsServiceProvider = !!tenant.isServiceProvider;
      this.currentTenantIsLegalEntity = !!tenant.isLegalEntity;
    });

    this.purchaseOrders$ = this.store.select(x => x.document.purchaseOrders).pipe(
      filter(notNull),
      startWith([]),
    );

    this.store.dispatch(DocumentActions.loadPurchaseOrders());
  }

  download(document: PurchaseOrder) {
    const downloadFlag = document.id + (document.serviceProviderId || '');
    this.downloadFlags[downloadFlag] = true;
    this.api.getBlobWithResponse({
      path: `/purchase-orders/${document.id}/download${document.serviceProviderId ? '/' + document.serviceProviderId : ''}`,
    }).toPromise().then(
      response => {
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition?.split(';')[1]?.split('filename')[1]?.split('=')[1]?.trim() || 'po.xlsx';
        this.downloadFlags[downloadFlag] = false;
        fileSaver.saveAs(response.body ?? '', filename);
      },
      () => this.api.get({
        path: `/purchase-orders/${document.id}/download${document.serviceProviderId ? '/' + document.serviceProviderId : ''}?schedule=true`,
      }).toPromise().then(
        () => {
          this.toastr.showInfo({
            title: marker('This is taking a while'),
            message: marker('We will send the report to your email address once it\'s ready.'),
            override: {
              disableTimeOut: true,
            }
          });
          this.downloadFlags[downloadFlag] = false;
        },
        () => {
          this.downloadFlags[downloadFlag] = false;
        }
      )
    );
  }

  ngOnDestroy() {
  }
}
