import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, CountryActions } from 'app/store/actions';
import { CountryState } from 'app/store/models';

const empty: CountryState = {
  items: null,
  loadingItems: false,
  savingItem: false,
};

const reducer = createReducer<CountryState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(CountryActions.loadCountries, (state) => ({
    ...state,
    loadingItems: true,
  })),
  on(CountryActions.loadCountriesSuccess, (state, { countries }) => ({
    ...state,
    items: countries,
    loadingItems: false,
  })),
  on(CountryActions.loadCountriesFailure, (state) => ({
    ...state,
    loadingItems: false,
  })),

  on(CountryActions.createCountry, (state) => ({
    ...state,
    savingItem: true,
  })),
  on(CountryActions.createCountrySuccess, (state, { country }) => ({
    ...state,
    items: [...state.items || [], country],
    savingItem: false,
  })),
  on(CountryActions.createCountryFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(CountryActions.updateCountry, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(CountryActions.updateCountrySuccess, (state, { country }) => ({
    ...state,
    items: (state.items || []).map(x => x.id === country.id ? country : x),
    savingItem: false,
  })),
  on(CountryActions.updateCountryFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(CountryActions.deleteCountry, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(CountryActions.deleteCountrySuccess, (state, { countryId }) => ({
    ...state,
    items: (state.items || []).map(x => x.id !== countryId ? x : {...x, deleted: true}),
    savingItem: false,
  })),
  on(CountryActions.deleteCountryFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),

  on(CountryActions.reactivateCountry, (state, _) => ({
    ...state,
    savingItem: true,
  })),
  on(CountryActions.reactivateCountrySuccess, (state, { countryId }) => ({
    ...state,
    items: (state.items || []).map(x => x.id !== countryId ? x : {...x, deleted: false}),
    savingItem: false,
  })),
  on(CountryActions.reactivateCountryFailure, (state, _) => ({
    ...state,
    savingItem: false,
  })),
);

export function countryReducer(state: CountryState | undefined, action: Action) {
  return reducer(state, action);
}
