import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { CountryActions } from 'app/store/actions';
import { Country, HydraCollection } from 'app/store/models';

@Injectable()
export class CountryEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadCountries$ = createEffect(() => this.actions$.pipe(
    ofType(CountryActions.loadCountries),
    concatMap(() => this.api.get<HydraCollection<Country>>({
      path: '/countries',
    }).pipe(
      map(data => CountryActions.loadCountriesSuccess({ countries: data['hydra:member'] })),
      catchError(err => of(CountryActions.loadCountriesFailure(err))),
    )),
  ));

  createCountry$ = createEffect(() => this.actions$.pipe(
    ofType(CountryActions.createCountry),
    concatMap(({ country }) => this.api.post<Country>({
      path: '/countries',
      body: country,
    }).pipe(
      map(data => CountryActions.createCountrySuccess({ country: data })),
      catchError(err => of(CountryActions.createCountryFailure(err))),
    )),
  ));

  updateCountry$ = createEffect(() => this.actions$.pipe(
    ofType(CountryActions.updateCountry),
    concatMap(({ country }) => this.api.put<Country>({
      path: `/countries/${country.id}`,
      body: country,
    }).pipe(
      map(data => CountryActions.updateCountrySuccess({ country: data })),
      catchError(err => of(CountryActions.updateCountryFailure(err))),
    )),
  ));

  deleteCountry$ = createEffect(() => this.actions$.pipe(
    ofType(CountryActions.deleteCountry),
    concatMap(({ countryId }) => this.api.delete({
      path: `/countries/${countryId}`,
    }).pipe(
      map(() => CountryActions.deleteCountrySuccess({ countryId })),
      catchError(err => of(CountryActions.deleteCountryFailure(err))),
    )),
  ));

  reactivateCountry$ = createEffect(() => this.actions$.pipe(
    ofType(CountryActions.reactivateCountry),
    concatMap(({ countryId }) => this.api.put<Country>({
      path: `/countries/${countryId}/reactivate`,
      body: [],
    }).pipe(
      map(() => CountryActions.reactivateCountrySuccess({ countryId })),
      catchError(err => of(CountryActions.reactivateCountryFailure(err))),
    )),
  ));
}
