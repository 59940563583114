import { makeActions, ofType } from 'app/store/actions/utils';
import { Country } from 'app/store/models';

export const CountryActions = makeActions({
  loadCountries: null,
  loadCountriesSuccess: ofType<{ countries: Country[]; }>(),
  loadCountriesFailure: ofType<{ error: any; }>(),

  createCountry: ofType<{ country: Country; }>(),
  createCountrySuccess: ofType<{ country: Country; }>(),
  createCountryFailure: ofType<{ error: any; }>(),

  updateCountry: ofType<{ country: Country; }>(),
  updateCountrySuccess: ofType<{ country: Country; }>(),
  updateCountryFailure: ofType<{ error: any; }>(),

  deleteCountry: ofType<{ countryId: string; }>(),
  deleteCountrySuccess: ofType<{ countryId: string; }>(),
  deleteCountryFailure: ofType<{ error: any; }>(),

  reactivateCountry: ofType<{ countryId: string; }>(),
  reactivateCountrySuccess: ofType<{ countryId: string; }>(),
  reactivateCountryFailure: ofType<{ error: any; }>(),
});
