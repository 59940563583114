import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { LazyLoaderService } from 'app/services/lazy-loader.service';
import { ValidationService } from 'app/services/validation.service';
import { TenantActions } from 'app/store/actions';
import { State, Tenant } from 'app/store/models';
import { ActivityTypes } from 'app/store/models/tenant.model';
import { selectCurrentTenant } from 'app/store/selectors';
import { notNull, untilDestroyed } from 'app/utils/rxjs';

@Component({
  selector: 'app-settings-company',
  templateUrl: './settings-company.component.html',
})
export class SettingsCompanyComponent implements OnDestroy {
  savingTenant$: Observable<boolean>;
  currentTenant: Tenant | null = null;
  countries$: Observable<object[]>;
  activityTypes: { label: string, value: string }[] = [];
  tenantForm: UntypedFormGroup;
  modalInstance: any;
  editing = false;

  constructor(
    private store: Store<State>,
    private loader: LazyLoaderService,
    private fb: UntypedFormBuilder,
    private actions$: Actions,
    private translate: TranslateService,
    private validationService: ValidationService,
  ) {
    this.savingTenant$ = this.store.select(x => x.admin.tenant.savingItem);

    this.tenantForm = this.fb.group({
      name: [null, Validators.required],
      vatNumber: [null],
      streetAndNumber: [null, Validators.required],
      zipcode: [null, Validators.required],
      city: [null, Validators.required],
      country: [null, Validators.required],
      activity: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      invoiceEmail: [null, [Validators.required, Validators.email]],
      eoAdditionalInvoiceEmails: [null, this.validationService.emailsValidator()],
      purchaseOrderEmail: [null, Validators.email],
      additionalPurchaseOrderEmails: [null, this.validationService.emailsValidator()],
    });
    this.tenantForm.controls['vatNumber'].disable();
    this.tenantForm.controls['country'].disable();

    this.store.select(selectCurrentTenant).pipe(
      untilDestroyed(this),
      filter(notNull),
    ).subscribe(tenant => {
      this.currentTenant = tenant;
      if (this.currentTenant.name === 'cinvio') {
        this.tenantForm.controls['name'].disable();
      }
      this.tenantForm.patchValue({
        ...tenant,
        country: tenant.country.code
      });
    });

    this.countries$ = this.loader.getCountries().pipe(
      map(xs => xs.map(x => ({
        label: x.value,
        value: x.value,
      }))),
    );

    Object.values(ActivityTypes).forEach(item => {
      this.activityTypes.push({ label: this.translate.instant('activity_' + item), value: item });
    });

    this.actions$.pipe(
      ofType(TenantActions.updateTenantSuccess),
      untilDestroyed(this),
    ).subscribe(({ tenant }) => {
      this.editing = false;
      this.tenantForm.patchValue(tenant);
      this.currentTenant = {
        ...this.currentTenant,
        ...tenant,
      };
    });
  }

  cancel() {
    this.editing = false;
    if (this.currentTenant) {
      this.tenantForm.patchValue(this.currentTenant);
    }
  }

  submit() {
    if (this.tenantForm.invalid || this.currentTenant === null) {
      this.tenantForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(TenantActions.updateTenant({
      tenant: {
        ...this.tenantForm.value,
        id: this.currentTenant.id,
        vatNumber: undefined,
        currency: undefined,
      },
    }));
  }

  onAdditionalEmailsClose(event: any) {
    const currentValue = event.target.value;
    if (currentValue && !this.tenantForm.value.eoAdditionalInvoiceEmails?.includes(currentValue)) {
      this.tenantForm.patchValue({
        eoAdditionalInvoiceEmails: [...(this.tenantForm.value.eoAdditionalInvoiceEmails || []), currentValue]
      });
    }
    event.target.value = '';
  }

  onAdditionalPurchaseOrderEmailsClose(event: any) {
    const currentValue = event.target.value;
    if (currentValue && !this.tenantForm.value.additionalPurchaseOrderEmails?.includes(currentValue)) {
      this.tenantForm.patchValue({
        additionalPurchaseOrderEmails: [...(this.tenantForm.value.additionalPurchaseOrderEmails || []), currentValue]
      });
    }
    event.target.value = '';
  }

  ngOnDestroy() { }
}
