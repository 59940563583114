<div class="row fullscreen justify-content-center align-items-center">
  <div class="col-12 col-md-4 col-xl-3 card">
    <div class="card-body">
      <img src="/assets/img/cinvio-with-title.png" alt="cinvio"
        class="p-2" style="width:300px;max-width:100%" />
      <h3>{{ 'Congrats!' | translate }}</h3>

      <p>
        {{ 'Congrats! Your request went through.' | translate }}
      </p>
      <p>
        {{ 'All we have to do now is revise the information you provided.' | translate }}
      </p>
      <p>
        {{ 'Once that\'s done, you\'ll receive an email to complete your registration and set your password.' | translate }}
      </p>
    </div>
  </div>
</div>
