import { createSelector } from '@ngrx/store';

import {
  CommonState, SessionState, State, UserContext, UserServiceState,
} from './models';

export const selectToken = createSelector(
  (state: State) => state.session,
  (session: SessionState) => session.token,
);

export const selectCurrentTenant = createSelector(
  (state: State) => state.session.userContext,
  (userContext) => userContext
    ? userContext.tenant
    : null,
);

export const selectCurrentUserContext = createSelector(
  (state: State) => state.session,
  (session: SessionState) => session.userContext,
);

export const selectRoles = createSelector(
  (state: State) => state.session.userContext,
  (ctx: UserContext | null) => ctx ? ctx.roles : null,
);

export const selectOriginalUrl = createSelector(
  (state: State) => state.common,
  (common: CommonState) => common.originalUrl,
);

export const selectLinkedServices = createSelector(
  (state: State) => state.service,
  (service: UserServiceState) => service.linkedItems,
);

export const selectUnlinkedServices = createSelector(
  (state: State) => state.service,
  (service: UserServiceState) => service.unlinkedItems,
);

export const selectPaymentMethodsLoading = createSelector(
  (state: State) => state,
  (admin: any) => admin.paymentProvider.loadingPaymentMethods
)
export const selectEstimationLoading = createSelector(
  (state: State) => state,
  (admin: any) => !!admin.paymentProvider?.loadingEstimation
)
