import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { LazyLoaderService } from 'app/services/lazy-loader.service';
import { LegalEntityActions, ServiceProviderActions, TenantActions } from 'app/store/actions';
import { LegalEntity, ServiceProvider, State } from 'app/store/models';
import { InvoiceSoftware, InvoiceTypes } from 'app/store/models/invoice-type.model';
import { notNull, untilDestroyed } from 'app/utils/rxjs';

import { ConfirmActionModalComponent } from './confirm-action-modal.component';

@Component({
  selector: 'app-admin-provider',
  templateUrl: './admin-provider.component.html',
  styleUrls: ['./admin-provider.component.scss']
})
export class AdminProviderComponent implements OnDestroy {
  loadingTenants$: Observable<boolean>;
  loadingLegalEntities$: Observable<boolean>;
  loadingServiceProviders$: Observable<boolean>;
  savingLegalEntity$: Observable<boolean>;
  savingServiceProvider$: Observable<boolean>;
  tenantOptions$: Observable<{ value: string; label: string }[]>;
  tenantOptionsAll$: Observable<{ value: string; label: string }[]>;
  tenantMap: { [k: string]: string } = {};
  legalEntities$: Observable<LegalEntity[]>;
  serviceProviders$: Observable<ServiceProvider[]>;
  activeLegalEntity: LegalEntity | null = null;
  activeServiceProvider: ServiceProvider | null = null;
  legalEntityForm: UntypedFormGroup;
  serviceProviderForm: UntypedFormGroup;
  logo: string | null = null;
  invoicingSoftwareOptions$: Observable<{ value: string; label: string }[]>;
  modalInstance: any;

  @ViewChild('legalEntityModal', { static: true })
  legalEntityModal?: TemplateRef<any>;

  @ViewChild('serviceProviderModal', { static: true })
  serviceProviderModal?: TemplateRef<any>;

  constructor(
    private store: Store<State>,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private actions$: Actions,
    private loader: LazyLoaderService,
    private sanitizer: DomSanitizer,
  ) {
    this.loadingTenants$ = this.store.select(x => x.admin.tenant.loadingItems);
    this.loadingLegalEntities$ = this.store.select(x => x.admin.legalEntity.loadingItems);
    this.loadingServiceProviders$ = this.store.select(x => x.admin.serviceProvider.loadingItems);
    this.savingLegalEntity$ = this.store.select(x => x.admin.legalEntity.savingItem);
    this.savingServiceProvider$ = this.store.select(x => x.admin.serviceProvider.savingItem);
    this.store.select(x => x.admin.tenant.items).pipe(
      filter(notNull),
      startWith([]),
      untilDestroyed(this),
    ).subscribe(tenants => tenants.forEach(
        x => this.tenantMap[x.id] = x['@id'] ? x['@id'] : '/api/v1/tenants/' + x.id,
    ));
    this.tenantOptions$ = this.store.select(
      x => (x.admin.tenant.items || [])
        .filter(t => !t.isServiceProvider && !t.isLegalEntity && t.name !== 'cinvio')
        .map(tenant => ({ label: tenant.name, value: tenant.id })),
    );
    this.tenantOptionsAll$ = this.store.select(
      x => (x.admin.tenant.items || [])
        .map(tenant => ({ label: tenant.name, value: tenant.id })),
    );
    this.legalEntities$ = this.store.select(x => x.admin.legalEntity.items).pipe(
      filter(notNull),
      startWith([]),
    );
    this.serviceProviders$ = this.store.select(x => x.admin.serviceProvider.items).pipe(
      filter(notNull),
      startWith([]),
    );
    this.legalEntityForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      tenant: [null, Validators.required],
      name: [null, Validators.required],
      timezone: [null, Validators.required],
      bankAccountNumber: [null],
      bic: [null],
      voucherBankAccountNumber: [null],
      voucherBic: [null],
      cashInFeeVatRatePercentage: [null],
      transactionFeeVatRatePercentage: [null],
      voucherInvoiceTemplate: [null],
      voucherInvoiceEmailTemplate: [null],
      transactionsFeeInvoiceTemplate: [null],
      transactionsFeeInvoiceEmailTemplate: [null],
      adminFeeInvoiceTemplate: [null],
      adminFeeInvoiceEmailTemplate: [null],
      eoDivision: [null],
      eoSenderEmail: [null],
      eoCashInFeeInvoiceCode: [null],
      eoCashInFeeSameCountryCode: [null],
      eoCashInFeeDifferentEuCountryCode: [null],
      eoCashInFeeDifferentNonEuCountryCode: [null],
      eoTransactionFeeInvoiceCode: [null],
      eoTransactionFeeSameCountryCode: [null],
      eoTransactionFeeDifferentEuCountryCode: [null],
      eoTransactionFeeDifferentNonEuCountryCode: [null],
      eoAdminFeeJournal: [null],
    });
    this.serviceProviderForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      tenant: [null, Validators.required],
      name: [null, Validators.required],
      bankAccountNumber: [null, Validators.required],
      bic: [null, Validators.required],
      platformUrl: [null],
      fixedMargin: [null],
      percentageMargin: [null],
      subscriptionVatRatePercentage: [null, Validators.required],
      invoicingSoftware: [null],
      eoDivision: [null],
      eoSenderEmail: [null],
      resellerSetup: [null],
      eoJournal: [null],
      transactionsInvoiceTemplate: [null],
      transactionsInvoiceEmailTemplate: [null],
    });
    this.invoicingSoftwareOptions$ = this.loader.getInvoicingSoftwareOptions().pipe(
      map(ss => ss.map(s => ({
        label: s,
        value: s,
      }))),
    );
    this.store.dispatch(TenantActions.loadTenants());
    this.store.dispatch(LegalEntityActions.loadLegalEntities());
    this.store.dispatch(ServiceProviderActions.loadServiceProviders());
    this.actions$.pipe(
      ofType(
        LegalEntityActions.createLegalEntitySuccess,
        LegalEntityActions.updateLegalEntitySuccess,
        ServiceProviderActions.createServiceProviderSuccess,
        ServiceProviderActions.updateServiceProviderSuccess,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      this.modalInstance.dismiss();
    });
  }

  newLegalEntity() {
    this.activeLegalEntity = null;
    this.legalEntityForm.reset();
    this.legalEntityForm.controls['tenant'].enable();
    this.modalInstance = this.modalService.open(this.legalEntityModal, { size: 'lg' });
  }

  editLegalEntity(legalEntity: LegalEntity) {
    this.activeLegalEntity = legalEntity;
    this.legalEntityForm.reset();
    this.legalEntityForm.patchValue({
      ...this.activeLegalEntity,
      tenant: this.activeLegalEntity.tenant.id,
      cashInFeeVatRatePercentage: this.activeLegalEntity.cashInFeeVatRatePercentage
        ? parseFloat(this.activeLegalEntity.cashInFeeVatRatePercentage)
        : null,
      transactionFeeVatRatePercentage: this.activeLegalEntity.transactionFeeVatRatePercentage
        ? parseFloat(this.activeLegalEntity.transactionFeeVatRatePercentage)
        : null,
    });

    // load invoice types
    const voucherInvoiceType = legalEntity.invoiceTypes.filter(
      it => it.software === InvoiceSoftware.EXACT_ONLINE && it.action === InvoiceTypes.CREATE_VOUCHER_INVOICE
    )[0];
    if (voucherInvoiceType) {
      this.legalEntityForm.controls['voucherInvoiceTemplate'].setValue(voucherInvoiceType.templateName);
      this.legalEntityForm.controls['voucherInvoiceEmailTemplate'].setValue(voucherInvoiceType.emailTemplateName);
    }
    const transactionsFeeInvoiceType = legalEntity.invoiceTypes.filter(
      it => it.software === InvoiceSoftware.EXACT_ONLINE && it.action === InvoiceTypes.CREATE_TRANSACTIONS_FEE_INVOICE
    )[0];
    if (transactionsFeeInvoiceType) {
      this.legalEntityForm.controls['transactionsFeeInvoiceTemplate'].setValue(transactionsFeeInvoiceType.templateName);
      this.legalEntityForm.controls['transactionsFeeInvoiceEmailTemplate'].setValue(transactionsFeeInvoiceType.emailTemplateName);
    }
    const adminFeeInvoiceType = legalEntity.invoiceTypes.filter(
      it => it.software === InvoiceSoftware.EXACT_ONLINE && it.action === InvoiceTypes.CREATE_ADMIN_FEE_INVOICE
    )[0];
    if (adminFeeInvoiceType) {
      this.legalEntityForm.controls['adminFeeInvoiceTemplate'].setValue(adminFeeInvoiceType.templateName);
      this.legalEntityForm.controls['adminFeeInvoiceEmailTemplate'].setValue(adminFeeInvoiceType.emailTemplateName);
    }

    this.legalEntityForm.controls['tenant'].disable();

    this.modalInstance = this.modalService.open(this.legalEntityModal, { size: 'lg' });
  }

  deleteLegalEntity(legalEntity: LegalEntity) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.title = marker('Are you sure you want to delete this legal entity?');
    modalRef.componentInstance.message = marker('This action will delete the legal entity ') + legalEntity.name;
    modalRef.componentInstance.confirmButtonText = marker('Delete');
    modalRef.result.then(() => this.store.dispatch(LegalEntityActions.deleteLegalEntity({ legalEntityId: legalEntity.id })));
  }

  onCreateLegalEntitySubmit() {
    if (this.legalEntityForm.invalid) {
      this.legalEntityForm.markAllAsTouched();
      return;
    }

    this.store.dispatch(LegalEntityActions.createLegalEntity({
      legalEntity: this.prepareLegalEntityPayload(),
    }));
  }

  onEditLegalEntitySubmit() {
    if (this.legalEntityForm.invalid || this.activeLegalEntity === null) {
      this.legalEntityForm.markAllAsTouched();
      return;
    }

    this.store.dispatch(LegalEntityActions.updateLegalEntity({
      legalEntity: this.prepareLegalEntityPayload(this.activeLegalEntity),
    }));
  }

  prepareLegalEntityPayload(legalEntity?: LegalEntity) {
    let payload = {
      ...this.legalEntityForm.value,
      id: legalEntity?.id,
      tenant: this.tenantMap[this.legalEntityForm.value.tenant],
      cashInFeeVatRatePercentage: (this.legalEntityForm.value.cashInFeeVatRatePercentage !== null)
        ? this.legalEntityForm.value.cashInFeeVatRatePercentage.toFixed(2)
        : null,
      transactionFeeVatRatePercentage: (this.legalEntityForm.value.transactionFeeVatRatePercentage !== null)
        ? this.legalEntityForm.value.transactionFeeVatRatePercentage.toFixed(2)
        : null,
      bankAccountNumber: this.legalEntityForm.value.bankAccountNumber || null,
      bic: this.legalEntityForm.value.bic || null,
      voucherBankAccountNumber: this.legalEntityForm.value.voucherBankAccountNumber || null,
      voucherBic: this.legalEntityForm.value.voucherBic || null,
      eoDivision: this.legalEntityForm.value.eoDivision || null,
      eoSenderEmail: this.legalEntityForm.value.eoSenderEmail || null,
      eoCashInFeeInvoiceCode: this.legalEntityForm.value.eoCashInFeeInvoiceCode || null,
      eoCashInFeeSameCountryCode: this.legalEntityForm.value.eoCashInFeeSameCountryCode || null,
      eoCashInFeeDifferentEuCountryCode: this.legalEntityForm.value.eoCashInFeeDifferentEuCountryCode || null,
      eoCashInFeeDifferentNonEuCountryCode: this.legalEntityForm.value.eoCashInFeeDifferentNonEuCountryCode || null,
      eoTransactionFeeInvoiceCode: this.legalEntityForm.value.eoTransactionFeeInvoiceCode || null,
      eoTransactionFeeSameCountryCode: this.legalEntityForm.value.eoTransactionFeeSameCountryCode || null,
      eoTransactionFeeDifferentEuCountryCode: this.legalEntityForm.value.eoTransactionFeeDifferentEuCountryCode || null,
      eoTransactionFeeDifferentNonEuCountryCode: this.legalEntityForm.value.eoTransactionFeeDifferentNonEuCountryCode || null,
      eoAdminFeeJournal: this.legalEntityForm.value.eoAdminFeeJournal || null,
      invoiceTypes: [],
    };

    // add invoice types
    if (this.legalEntityForm.value.voucherInvoiceTemplate || this.legalEntityForm.value.voucherInvoiceEmailTemplate) {
      payload.invoiceTypes.push({
        legalEntity: legalEntity ? legalEntity['@id'] || '/api/v1/legal_entities/' + legalEntity.id : undefined,
        software: InvoiceSoftware.EXACT_ONLINE,
        action: InvoiceTypes.CREATE_VOUCHER_INVOICE,
        templateName: this.legalEntityForm.value.voucherInvoiceTemplate || null,
        emailTemplateName: this.legalEntityForm.value.voucherInvoiceEmailTemplate || null,
      });
    }
    if (this.legalEntityForm.value.transactionsFeeInvoiceTemplate || this.legalEntityForm.value.transactionsFeeInvoiceEmailTemplate) {
      payload.invoiceTypes.push({
        legalEntity: legalEntity ? legalEntity['@id'] || '/api/v1/legal_entities/' + legalEntity.id : undefined,
        software: InvoiceSoftware.EXACT_ONLINE,
        action: InvoiceTypes.CREATE_TRANSACTIONS_FEE_INVOICE,
        templateName: this.legalEntityForm.value.transactionsFeeInvoiceTemplate || null,
        emailTemplateName: this.legalEntityForm.value.transactionsFeeInvoiceEmailTemplate || null,
      });
    }
    if (this.legalEntityForm.value.adminFeeInvoiceTemplate || this.legalEntityForm.value.adminFeeInvoiceEmailTemplate) {
      payload.invoiceTypes.push({
        legalEntity: legalEntity ? legalEntity['@id'] || '/api/v1/legal_entities/' + legalEntity.id : undefined,
        software: InvoiceSoftware.EXACT_ONLINE,
        action: InvoiceTypes.CREATE_ADMIN_FEE_INVOICE,
        templateName: this.legalEntityForm.value.adminFeeInvoiceTemplate || null,
        emailTemplateName: this.legalEntityForm.value.adminFeeInvoiceEmailTemplate || null,
      });
    }

    return payload;
  }

  newServiceProvider() {
    this.activeServiceProvider = null;
    this.serviceProviderForm.reset();
    this.serviceProviderForm.controls['tenant'].enable();
    this.modalInstance = this.modalService.open(this.serviceProviderModal, { size: 'lg' });
  }

  editServiceProvider(serviceProvider: ServiceProvider) {
    this.activeServiceProvider = serviceProvider;
    this.serviceProviderForm.reset();
    this.serviceProviderForm.patchValue({
      ...this.activeServiceProvider,
      tenant: this.activeServiceProvider.tenant.id,
      fixedMargin: this.activeServiceProvider.fixedMargin
        ? parseFloat(this.activeServiceProvider.fixedMargin)
        : null,
      percentageMargin: this.activeServiceProvider.percentageMargin
        ? parseFloat(this.activeServiceProvider.percentageMargin)
        : null,
      subscriptionVatRatePercentage: this.activeServiceProvider.subscriptionVatRatePercentage
        ? parseFloat(this.activeServiceProvider.subscriptionVatRatePercentage)
        : null,
      resellerSetup: this.activeServiceProvider.resellerSetup ? 'on' : 'off',
    });
    
    // load invoice types
    const transactionsInvoiceType = this.activeServiceProvider.invoiceTypes.filter(
      it => it.software === InvoiceSoftware.EXACT_ONLINE && it.action === InvoiceTypes.CREATE_TRANSACTIONS_INVOICE
    )[0];
    if (transactionsInvoiceType) {
      this.serviceProviderForm.controls['transactionsInvoiceTemplate'].setValue(transactionsInvoiceType.templateName);
      this.serviceProviderForm.controls['transactionsInvoiceEmailTemplate'].setValue(transactionsInvoiceType.emailTemplateName);
    }

    this.serviceProviderForm.controls['tenant'].disable();

    this.modalInstance = this.modalService.open(this.serviceProviderModal, { size: 'lg' });
  }

  deleteServiceProvider(serviceProvider: ServiceProvider) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.title = marker('Are you sure you want to delete this service provider?');
    modalRef.componentInstance.message = marker('This action will delete the service provider ') + serviceProvider.name;
    modalRef.componentInstance.confirmButtonText = marker('Delete');
    modalRef.result.then(() => this.store.dispatch(ServiceProviderActions.deleteServiceProvider({ serviceProviderId: serviceProvider.id })));
  }

  onCreateServiceProviderSubmit() {
    if (this.serviceProviderForm.invalid) {
      this.serviceProviderForm.markAllAsTouched();
      return;
    }

    this.store.dispatch(ServiceProviderActions.createServiceProvider({
      serviceProvider: this.prepareServiceProviderPayload(),
    }));
  }

  onEditServiceProviderSubmit() {
    if (this.serviceProviderForm.invalid || this.activeServiceProvider === null) {
      this.serviceProviderForm.markAllAsTouched();
      return;
    }

    this.store.dispatch(ServiceProviderActions.updateServiceProvider({
      serviceProvider: this.prepareServiceProviderPayload(this.activeServiceProvider),
    }));
  }

  prepareServiceProviderPayload(serviceProvider?: ServiceProvider) {
    let payload = {
      ...this.serviceProviderForm.value,
      id: serviceProvider?.id,
      tenant: this.tenantMap[this.serviceProviderForm.value.tenant],
      logo: this.logo || undefined,
      fixedMargin: (this.serviceProviderForm.value.fixedMargin !== null)
        ? this.serviceProviderForm.value.fixedMargin.toFixed(2)
        : null,
      percentageMargin: (this.serviceProviderForm.value.percentageMargin !== null)
        ? this.serviceProviderForm.value.percentageMargin.toFixed(2)
        : null,
      subscriptionVatRatePercentage: (this.serviceProviderForm.value.subscriptionVatRatePercentage !== null)
        ? this.serviceProviderForm.value.subscriptionVatRatePercentage.toFixed(2)
        : null,
      invoicingSoftware: this.serviceProviderForm.value.invoicingSoftware || null,
      eoDivision: this.serviceProviderForm.value.eoDivision || null,
      eoSenderEmail: this.serviceProviderForm.value.eoSenderEmail || null,
      resellerSetup: this.serviceProviderForm.value.resellerSetup === 'on' || false,
      eoJournal: this.serviceProviderForm.value.eoJournal || null,
      bankAccountNumber: this.serviceProviderForm.value.bankAccountNumber || null,
      bic: this.serviceProviderForm.value.bic || null,
      platformUrl: this.serviceProviderForm.value.platformUrl || null,
      invoiceTypes: [],
    };

    // add invoice types
    if (this.serviceProviderForm.value.transactionsInvoiceTemplate || this.serviceProviderForm.value.transactionsInvoiceEmailTemplate) {
      payload.invoiceTypes.push({
        serviceProvider: serviceProvider ? serviceProvider['@id'] || '/api/v1/service_providers/' + serviceProvider.id : undefined,
        software: InvoiceSoftware.EXACT_ONLINE,
        action: InvoiceTypes.CREATE_TRANSACTIONS_INVOICE,
        templateName: this.serviceProviderForm.value.transactionsInvoiceTemplate || null,
        emailTemplateName: this.serviceProviderForm.value.transactionsInvoiceEmailTemplate || null,
      });
    }

    return payload;
  }

  handleLogoChange(e: any) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const reader = new FileReader();
    const pattern = /image-*/;
    if (!file.type.match(pattern)) {
      console.log('Wrong image format ' + file.type + '.');
      return;
    }
    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        const enc = new TextDecoder('utf-8');
        this.logo = enc.decode(reader.result);
      } else {
        this.logo = reader.result;
      }
    };
    reader.onerror = err => console.log(err);
    reader.readAsDataURL(file);
  }

  sanitize(url: string | null) {
    return this.sanitizer.bypassSecurityTrustUrl(url || '');
  }

  ngOnDestroy() { }
}
