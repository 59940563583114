import { makeActions, ofType } from 'app/store/actions/utils';
import { AccountType, Transaction } from 'app/store/models';

export const UserTransactionActions = makeActions({
  loadTransactions: ofType<{
    before?: string;
    after?: string;
    softCharged?: boolean;
    search?: string;
    page?: number;
    pageSize?: number;
    accountType: AccountType;
    noCount?: boolean;
  }>(),
  loadTransactionsSuccess: ofType<{ items: Transaction[]; totalItems: number }>(),
  loadTransactionsFailure: ofType<{ payload: any; }>(),

  cancelTransaction: ofType<{ transactionId: string; }>(),
  cancelTransactionSuccess: ofType<{ transactionId: string; }>(),
  cancelTransactionFailure: ofType<{ error: any; }>(),
});
