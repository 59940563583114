import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { LazyLoaderService } from 'app/services/lazy-loader.service';
import { Currency } from 'app/store/models';

@Component({
  selector: 'money', // tslint:disable-line component-selector
  templateUrl: 'money.component.html',
  styleUrls: ['money.component.scss'],
})
export class MoneyComponent implements OnChanges, OnDestroy {
  @Input()
  amount: number = NaN;
  @Input()
  currency: Currency | string = 'EUR';
  currencyCode = 'EUR';
  subscription?: Subscription;
  @Input()
  isReserved: boolean = false;
  
  constructor(private lazyLoader: LazyLoaderService) { }

  ngOnChanges(changes: SimpleChanges) {
    const currency = changes['currency'] && changes['currency'].currentValue;
    if (!currency) {
      return;
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }

    if (typeof currency === 'string' && currency.length === 3) {
      // 'EUR', 'CZK'
      this.currencyCode = currency;
    } else if (typeof currency === 'string' && currency.length > 3) {
      // Currency IRI
      this.subscription = this.lazyLoader.getCurrencies().subscribe(currencies => {
        const cur = currencies.find(x => x['@id'] === currency || x.id === currency);
        this.currencyCode = cur ? cur.code : 'EUR';
      });
    } else if (typeof currency === 'object' && currency.code) {
      // Currency object
      this.currencyCode = currency.code;
    }
  }

  ngOnDestroy() { }
}
