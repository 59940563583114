import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { RoleCode, State } from 'app/store/models';
import { byRoleAuthority } from 'app/store/models/role.model';
import { selectRoles } from 'app/store/selectors';
import { max, notNull } from 'app/utils/rxjs';

@Injectable()
export class DashboardGuard  {
  constructor(private store: Store<State>, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(selectRoles).pipe(
      filter(notNull),
      map(roles => {
        const maxRole = roles.map(r => r.code).reduce(max(byRoleAuthority), RoleCode.user);

        // Match on the last component of the URL (e.g. /overview/user)
        let d = route.url[route.url.length - 1] ? route.url[route.url.length - 1].path : '';
        if (!['user', 'provider', 'cinvio'].includes(d)) {
          d = 'unknown';
        }
        // Redirect to the "most authorized" dashboard
        if ([RoleCode.superAdmin].includes(maxRole)) {
          this.router.navigateByUrl('/admin/tenants');
        } else if ([RoleCode.admin].includes(maxRole) && d !== 'cinvio') {
          this.router.navigateByUrl('/overview/cinvio');
        } else if (maxRole === RoleCode.serviceProvider && d !== 'provider') {
          this.router.navigateByUrl('/overview/provider');
        } else if (maxRole === RoleCode.user && d !== 'user') {
          this.router.navigateByUrl('/overview/user');
        }
        return true;
      }),
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(route);
  }
}
