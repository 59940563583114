import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { ServiceProvider } from 'app/store/models';

@Component({
  selector: 'app-provider-card',
  templateUrl: './provider-card.component.html',
  styleUrls: ['./provider-card.component.scss'],
})
export class ProviderCardComponent {
  @Input()
  provider?: ServiceProvider;

  constructor(private sanitizer: DomSanitizer, private router: Router) { }

  openMarketplace() {
    let providerId = this.provider?.id;
    if (!providerId && this.provider?.['@id']) {
      providerId = this.provider['@id'].substring(this.provider['@id'].lastIndexOf('/') + 1);
    }
    if (providerId) {
      this.router.navigate(['/services/providers', providerId ])
    }
  }

  sanitize(x: string) {
    return this.sanitizer.bypassSecurityTrustUrl(x);
  }
}
