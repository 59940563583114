import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ApiService } from 'app/services/api.service';
import { ToastService } from 'app/services/toast.service';

interface IAlert {
  icon: string;
  type: string;
  message: string;
}

@Component({
  selector: 'app-reset-password-update',
  templateUrl: './reset-password-update.component.html',
})
export class ResetPasswordUpdateComponent {
  user = '';
  code = '';
  newPass1 = '';
  newPass2 = '';
  loading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastService,
    private api: ApiService,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.code = params['code'];
      this.user = params['user'];
    });
  }

  reset() {
    if (this.newPass1 !== this.newPass2) {
      // this.alert = {
      //   icon: 'fa-exclamation-triangle',
      //   type: 'danger',
      //   message: 'Your passwords do not match'
      // };
      return;
    }

    this.loading = true;
    this.api.post({
      path: '/user/' + this.user + '/code/' + this.code,
      body: JSON.stringify({ password: this.newPass1 }),
    }).toPromise().then(
      () => {
        this.loading = false;
        this.toastr.showSuccess({ title: 'Reset successful!' });
        const message = 'Reset Password Successful! Please log in to continue.';
        this.router.navigate(['/login'], { queryParams: { message } });
      },
      error => {
        this.loading = false;
        if (error.error && error.error.violations) {
          this.toastr.showDanger({
            title: 'Something went wrong',
            message: error.error.violations[0].message,
          });
        } else if (error.error && error.error.detail === 'Reset code already used.') {
          this.toastr.showDanger({
            title: 'Something went wrong',
            message: 'This link to reset your password got already used. Please request a new reset password link and try again.',
          });
        } else {
          this.toastr.showDanger({
            title: 'Something went wrong',
            message:
              'Something went wrong while resetting your password. Please ' +
              'request a new reset and try again. If you keep encountering ' +
              'this issue, contact us at support@cinvio.com.',
          });
        }
      },
    );
  }
}
