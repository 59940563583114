<div class="row fullscreen justify-content-center align-items-center">
  <div class="col-12 col-md-4 col-xl-3 card">
    <form class="card-body" #loginForm="ngForm" (ngSubmit)="login()" style="padding: 1.25rem;">
      <img src="/assets/img/cinvio-with-title.png" alt="cinvio"
        class="p-2" style="width:300px;max-width:100%" />
      <p class="text-muted" data-cy="perex">
        {{ 'Enter your email address and password to access the platform.' | translate }}
      </p>
      <div class="mb-3">
        <input
          [(ngModel)]="mail"
          name="mail"
          type="email"
          placeholder="johndoe@gmail.com"
          class="form-control"
          autocomplete="username"
          autofocus
        />
      </div>
      <div class="mb-3">
        <input
          [(ngModel)]="pass"
          name="pass"
          type="password"
          placeholder="{{ 'Enter password' | translate }}"
          class="form-control"
          autocomplete="current-password"
        />
      </div>

      <app-loading-button btnType="submit" [isLoading]="loading$ | async" cy="login-button" btnClass="btn btn-primary btn-block w-100">
        {{ 'Login' | translate }}
      </app-loading-button>

      <div class="d-flex w-100">
        <hr class="flex-grow-1">
        <div class="ps-2 pe-2 pt-1 text-muted text-uppercase">{{ 'or' | translate }}</div>
        <hr class="flex-grow-1">
      </div>

      <a routerLink="/register" class="btn btn-outline-primary btn-block w-100">
        {{ 'Create an account' | translate }}
      </a>

      <a routerLink="/reset-password" class="btn btn-block w-100 text-decoration-none">
        {{ 'Forgot password' | translate }}?
      </a>
    </form>
  </div>
</div>
