import { Component, OnDestroy } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as fileSaver from 'file-saver-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { State, Bundle } from 'app/store/models';
import { BundleActions } from 'app/store/actions';
import { notNull } from 'app/utils/rxjs';

@Component({
  selector: 'app-bundle-page',
  templateUrl: './bundle-page.component.html',
  styleUrls: ['./bundle-page.component.scss'],
})
export class BundlePageComponent implements OnDestroy {
  loadingBundles$: Observable<boolean>;
  bundles$: Observable<Bundle[]>;

  page$ = new BehaviorSubject<number>(1);
  pages$: Observable<number>;
  pageSize = 10;
  searchQuery?: string;
  searchQueryDebounced$ = new BehaviorSubject<string|undefined>(undefined);
  modalInstance: any;

  exportModal: NgbModalRef | null = null;
  exporting = false;

  constructor(
    private store: Store<State>,
    private api: ApiService,
  ) {
    this.loadingBundles$ = this.store.select(x => x.bundle.loadingItems);

    this.bundles$ = this.store.select(x => x.bundle.providerItems).pipe(
      filter(notNull),
      startWith([]),
    );

    this.pages$ = this.store.select(x => x.bundle).pipe(map(x => {
      return Math.ceil((x.totalProviderItems || 0) / this.pageSize);
    }));
    this.page$.subscribe(page => {
      this.store.dispatch(BundleActions.loadBundlesForProvider({
        page,
        pageSize: this.pageSize,
        query: this.searchQueryDebounced$.getValue(),
      }));
    });
    this.searchQueryDebounced$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    )
    .subscribe(() => this.page$.next(1));
  }

  submitExport() {
    this.exporting = true;
    this.api.getBlob({
      path: `/bundles_for_provider_report`
        + (this.searchQueryDebounced$.getValue() ? `?search=${this.searchQueryDebounced$.getValue()}` : ''),
    }).toPromise().then(
      blob => {
        this.exporting = false;
        if (this.exportModal) {
          this.exportModal.dismiss();
          this.exportModal = null;
        }
        fileSaver.saveAs(blob, `bundles.csv`);
      },
      () => {
        this.exporting = false;
      },
    );
  }

  ngOnDestroy() { }
}
